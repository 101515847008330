import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import { FormattedMessage } from "react-intl";
import { lampStatus } from "../../function";
import { Table } from "../common";

function DowntimeDistribution({ data, startDate, endDate }) {
  const second = 60 * 60 * 24;
  const display = data.map(({ state, time }) => {
    const { color } = lampStatus(state);
    return {
      width: (time / second) * 100,
      color,
    };
  });
  let totalTime = [];
  for (let i = 0; i < 24; i++) {
    totalTime.push(i < 10 ? `0${i}:00` : `${i}:00`);
  }
  if (endDate) {
    const day = moment(endDate).diff(startDate, "d");
    if (day > 0) {
      totalTime = [];
      const date = moment(startDate);
      for (let i = 0; i < day + 1; ++i) {
        totalTime.push(date.format("MM/DD"));
        date.add(1, "d");
      }
    }
  }

  return (
    <div className="table-area">
      <table className="w-100">
        <thead>
          <tr>
            <th className="timeline">
              <ul className="list-unstyled">
                {totalTime.map((time) => (
                  <li key={time} style={{ height: "50px" }}>
                    <b>{time}</b>
                  </li>
                ))}
              </ul>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="timeline">
              <div>
                {display.map(({ width, color }, i) =>
                  data[i].state !== 3 ? (
                    <Tooltip
                      key={i}
                      title={
                        <div
                          className="flex-column ai-center"
                          style={{ paddingTop: "10px", color: "white" }}
                        >
                          <h3>
                            <b style={{ color: "white" }}>
                              {data[i].description}
                            </b>
                          </h3>

                          <h6
                            style={{
                              color: "white",
                              fontSize: "16px",
                              textAlign: "center",
                            }}
                          >
                            {data[i].startTime} ~ {data[i].endTime} (
                            {data[i].duration})
                          </h6>
                        </div>
                      }
                    >
                      <Box
                        style={{ width: `${width}%`, backgroundColor: color }}
                      />
                    </Tooltip>
                  ) : (
                    <Box
                      key={i}
                      style={{ width: `${width}%`, backgroundColor: color }}
                    />
                  )
                )}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

function DowntimeChart({
  data: {
    DowntimeReasonDataList,
    DowntimeReasonDescriptionList,
    DowntimeReasonPlatoDataList,
  },
}) {
  const yAxisMax =
    Math.max(...DowntimeReasonDataList.map((value) => value)) * 1.2;
  const options = {
    title: null,
    credits: { enabled: false },
    exporting: { enabled: false },
    xAxis: {
      type: "category",
      categories: DowntimeReasonDescriptionList,
    },
    yAxis: [
      {
        min: 0,
        max: yAxisMax,
        gridLineColor: "#ddd",
        title: null,
        labels: {
          formatter: function () {
            if (this.value >= 1000)
              return Highcharts.numberFormat(this.value / 1000, 1) + "K"; // maybe only switch if > 1000
            return Highcharts.numberFormat(this.value, 0);
          },
        },
      },
      {
        min: 0,
        max: 100,
        gridLineWidth: 0,
        tickInterval: 20,
        opposite: true,
        title: null,
        labels: {
          format: "{value} %",
        },
      },
    ],
    legend: { enabled: true },
    tooltip: {
      formatter: function () {
        let str = `<b>${this.x}</b><br/>`;
        if (this.points[0]) str += `停機時間：${this.points[0].y}<br/>`;
        if (this.points[1]) str += `柏拉圖：${this.points[1].y}%`;
        return str;
      },
      shared: true,
    },
    series: [
      {
        name: "停機時間",
        type: "column",
        color: "#75b756",
        data: DowntimeReasonDataList,
        dataLabels: {
          enabled: false,
        },
      },
      {
        name: "柏拉圖",
        type: "line",
        color: "#e62F34",
        data: DowntimeReasonPlatoDataList.map((val) => Math.round(val[1])),
        dataLabels: {
          enabled: false,
        },
        alignTicks: true,
        yAxis: 1,
      },
    ],
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
}

function DowntimeTable({ data }) {
  const columns = [
    {
      Header: <FormattedMessage id="DowntimeReason" /> /*停機原因*/,
      accessor: "reason",
      Filter: "",
    },
    {
      Header: <FormattedMessage id="Status" />,
      accessor: "group",
      Filter: "",
    },
    {
      Header: <FormattedMessage id="classify_id" />,
      accessor: "classify",
      Filter: "",
    },
    {
      Header: <FormattedMessage id="Downtime" /> /*停機時間*/,
      accessor: "time",
      Filter: "",
    },
    {
      Header: <FormattedMessage id="Percentage" /> /*百分比*/,
      accessor: "percent",
      Filter: "",
    },
  ];

  const display = data.map((item) => {
    const { status } = lampStatus(item.lamp_status);
    return {
      ...item,
      group: status,
      percent: `${item.percent}%`,
    };
  });

  return <Table columns={columns} data={display} />;
}

export { DowntimeDistribution, DowntimeChart, DowntimeTable };
