import React, { useEffect, useState, useContext } from "react";
import { MdCreate, MdDelete } from "react-icons/md";
import { FormattedMessage, useIntl } from "react-intl";
import { ColumnFilter, Table } from "../common";
import { InputModalBase } from "./Management";
import {
  getManagementClosingReason,
  postManagementClosingReason,
  putManagementClosingReason,
} from "../../api.js";
import Context from "../../context";

export function DowntimeTable({ groupList, showModal, modal }) {
  const [data, setData] = useState([]);
  const { factory } = useContext(Context);

  useEffect(() => {
    if (groupList.length > 0 && !modal) {
      getManagementClosingReason({
        factory_id: factory,
      })
        .then((res) => {
          res.data.forEach((item) => {
            item.group = groupList.find(
              ({ id }) => id === item.classify_id
            )?.classify;
          });
          setData(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [groupList, factory, modal]);

  const columns = [
    {
      Header: <FormattedMessage id="description" /> /*描述*/,
      accessor: "description",
      Filter: ({ column }) => ColumnFilter(column, "description"),
    },
    {
      Header: <FormattedMessage id="vietnamese" /> /*描述*/,
      accessor: "vietnamese",
      Filter: ({ column }) => ColumnFilter(column, "vietnamese"),
    },
    {
      Header: <FormattedMessage id="english" /> /*描述*/,
      accessor: "english",
      Filter: ({ column }) => ColumnFilter(column, "english"),
    },
    {
      Header: <FormattedMessage id="classify_id" />,
      accessor: "group",
      Filter: ({ column }) => ColumnFilter(column, "classify_id"),
    },
    {
      Header: <FormattedMessage id="Edit" />,
      accessor: "editor",
      Filter: "",
    },
  ];

  const display = data.map((item) => ({
    ...item,
    editor:
      item.is_default === 1 ? (
        ""
      ) : (
        <span>
          <MdCreate
            className="icon-btn"
            size="20px"
            onClick={() => showModal("edit", item)}
          />
          <MdDelete
            style={{ marginLeft: "10px" }}
            className="icon-btn"
            size="20px"
            onClick={() => showModal("delete", item)}
          />
        </span>
      ),
  }));

  return <Table columns={columns} data={display} />;
}

export const InputModal = ({ modal, onHide, data, groupList }) => {
  const intl = useIntl();
  const { factory } = useContext(Context);
  const [select, setSelect] = useState({});

  const columns = [
    {
      id: "classify_id",
      type: "select",
      required: true,
      options: groupList.map(({ id, classify }) => ({
        label: classify,
        value: id,
      })),
    },
    { id: "description", type: "text", required: true },
    { id: "english", type: "text" },
    { id: "vietnamese", type: "text" },
  ];

  useEffect(() => {
    setSelect(JSON.parse(JSON.stringify(data)));
  }, [data]);

  const submit = () => {
    return new Promise((resolve, reject) => {
      let params = {
        factory_id: factory,
      };
      if (modal === "new") {
        postManagementClosingReason(params, select).then(resolve).catch(reject);
      } else {
        putManagementClosingReason(select.id, params, select)
          .then(resolve)
          .catch(reject);
      }
    });
  };

  const title = intl.formatMessage(
    { id: modal === "new" ? "Create" : "EditThe" },
    { name: intl.formatMessage({ id: "DowntimeReason" }) }
  );

  return (
    <InputModalBase
      show={modal === "new" || modal === "edit"}
      onHide={onHide}
      title={title}
      columns={columns}
      select={select}
      setSelect={setSelect}
      submit={submit}
    />
  );
};
