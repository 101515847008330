import React, { useContext, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Form from "react-bootstrap/Form";
import { ManagementTabs } from "../../component/management/Management";
import {
  DateRadio,
  RangeDatePicker,
  DeleteModal,
} from "../../component/common";
import StopIcon from "@material-ui/icons/Stop";
import {
  RightColumn,
  TimelineHead,
  TimeRow,
  ScheduleModal,
  DragLayer,
} from "../../component/management/Schedule";
import {
  getOrderScheduleNonWorkingHours,
  ApiOrder_GetSchedule,
  baseURL,
  ApiOrder_DeleteOrder,
  getDevice,
} from "../../api";
import "../pages.css";
import moment from "moment";
import Context from "../../context";

const today = moment().format("YYYY-MM-DD");

function ManagementSchedule() {
  const intl = useIntl();
  const { factory, auth } = useContext(Context);
  const [date, setDate] = useState({ startDate: today, endDate: today });
  const [onScroll, setScroll] = useState(false);
  const [deviceList, setDeviceList] = useState([]);
  const [nonWorking, setNonWorking] = useState([]);
  const [orderList, setOrderList] = useState([]);
  const [select, setSelect] = useState({});
  const [trigger, setTrigger] = useState(false);
  const [modal, setModal] = useState();
  const [isDragging, setIsDragging] = useState(false);
  const [insertMode, setInsertMode] = useState(false);

  let timeArr = [];
  const days = moment(date.endDate).diff(date.startDate, "d") + 1;
  for (let i = 0; i < days * 12; ++i) {
    timeArr.push(
      moment(date.startDate)
        .add(i * 2, "h")
        .format("YYYY-MM-DD HH:mm")
    );
  }

  useEffect(() => {
    getDevice({ factory_id: [factory] }).then((res) => setDeviceList(res.data));
  }, [factory]);

  useEffect(() => {
    if (!modal) {
      getOrderScheduleNonWorkingHours({
        factory_id: factory,
        dateStart: date.startDate,
        dateEnd: date.endDate,
      }).then((res) => setNonWorking(res.data));
      ApiOrder_GetSchedule({
        factory_id: factory,
        dateStart: date.startDate,
        dateEnd: date.endDate,
      }).then((res) => {
        for (let item of res.data) {
          const h = Math.floor(item.duration / 3600);
          const m = Math.ceil((item.duration - h * 3600) / 60);
          const order = item.order[0];
          item.duration_label = `${h}h${m}m`;

          for (let i = 1; i < item.order.length; i++) {
            if (order.endDate < item.order[i].endDate)
              order.endDate = item.order[i].endDate;
          }
          item.left =
            moment(order.startDate).diff(
              moment(date.startDate).startOf("d"),
              "s"
            ) /
            (days * 24 * 36);
          item.width =
            moment(order.endDate).diff(order.startDate, "s") / (days * 24 * 36);
          if (item.left < 0) {
            item.width = item.width + item.left;
            item.left = 0;
          }
          item.maxWidth = `${100 - item.left}%`;
          item.width = `${item.width}%`;
          item.left = `${item.left}%`;
          for (let order of item.order) {
            const h = Math.floor(order.duration / 3600);
            const m = Math.ceil((order.duration - h * 3600) / 60);
            order.duration_label = `${h}h${m}m`;
            if (order.photo_path) {
              order.photo_path = baseURL + order.photo_path;
            }
          }
        }
        setOrderList(res.data);
      });
    }
  }, [factory, date, trigger, modal]);

  const onMouseMove = (e) => {
    if (onScroll) {
      const ele = document.getElementById("scroll-area");
      ele.scrollLeft -= e.movementX;
    }
  };

  const showModal = (modal, select) => {
    select.start_time = select.startDate;
    setSelect(select);
    setModal(modal);
  };

  const onHide = () => {
    setModal();
    setSelect({});
    refresh();
  };

  const deleteData = async () => {
    ApiOrder_DeleteOrder({
      factory_id: factory,
      order_id: select.id,
    })
      .then(onHide)
      .catch(console.log);
  };

  const refresh = () => {
    setTrigger(!trigger);
  };

  const list = [
    { color: "rgba(125, 168, 255, 0.4)", name: "UsedBefore" },
    { color: "rgba(131, 255, 125, 0.4)", name: "MoldOutOfSide" },
    { color: "rgba(248, 255, 125, 0.4)", name: "NozzleOutOfSide" },
    { color: "rgba(222, 125, 255, 0.4)", name: "ShotWeightInsufficient" },
    { color: "rgba(255, 166, 102, 0.4)", name: "ClampingForceInsufficient" },
  ];

  return (
    <div>
      <DragLayer date={date} />
      <ManagementTabs />
      <div className="page-content shadow-lg ai-stretch">
        <div style={{ width: "75%" }} className="mr-3">
          <div className="ai-center mb-3">
            <DateRadio
              list={[
                {
                  label: intl.formatMessage({ id: "Today" }),
                  value: today,
                },
                {
                  label: intl.formatMessage({ id: "Tomorrow" }),
                  value: moment().add(1, "d").format("YYYY-MM-DD"),
                },
              ]}
              onChange={(e) =>
                setDate({
                  startDate: e.target.value,
                  endDate: e.target.value,
                })
              }
              value={
                date.startDate === date.endDate ? date.startDate : undefined
              }
              style={{ marginRight: "20px" }}
            />
            <RangeDatePicker
              startDate={date.startDate}
              endDate={date.endDate}
              onApply={(startDate, endDate) => setDate({ startDate, endDate })}
            />
            {auth.management_schedule_insert && (
              <Form.Check
                className="ml-3"
                label={intl.formatMessage({ id: "InsertMode" })}
                onChange={() => setInsertMode(!insertMode)}
                checked={insertMode}
              />
            )}
          </div>
          <div>
            <div
              id="scroll-area"
              onMouseDown={() => setScroll(true)}
              onMouseUp={() => setScroll(false)}
              onMouseMove={onMouseMove}
              style={{ width: "100%%" }}
            >
              <TimelineHead timeArr={timeArr} />
              {deviceList.map(({ id, device_name }) => (
                <TimeRow
                  key={id}
                  device_id={id}
                  name={device_name}
                  nonWorking={nonWorking[id]}
                  orderList={orderList.filter(({ order }) => order[0].device_id === id)}
                  date={date}
                  timeArr={timeArr}
                  refresh={refresh}
                  showModal={showModal}
                  isDragging={isDragging}
                  setIsDragging={setIsDragging}
                  insertMode={insertMode}
                />
              ))}
            </div>
            <div>
              {list.map(({ color, name }) => (
                <div key={name}>
                  <StopIcon fontSize="large" style={{ color }} />
                  <FormattedMessage id={name} />
                </div>
              ))}
            </div>
          </div>
        </div>
        <RightColumn
          style={{ width: "25%" }}
          trigger={trigger}
          refresh={refresh}
          showModal={showModal}
          setIsDragging={setIsDragging}
        />
      </div>

      <ScheduleModal
        data={select}
        modal={modal}
        onHide={onHide}
        deviceList={deviceList}
        insertMode={insertMode}
      />
      <DeleteModal
        show={modal === "delete"}
        onHide={onHide}
        callback={deleteData}
        name={select.order_group_name || select.order_num_name}
        type="Order"
      />
    </div>
  );
}

export default ManagementSchedule;
