import React, { useContext, useEffect, useState } from "react";
import Highcharts from "highcharts";
import { useIntl } from "react-intl";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
import { apiGetOEEDataList, apiGetAssignDeviceOEEDataList } from "../../api";
import { handleRequest } from "./Analysis";
import Context from "../../context";

export function OEEChart({ handleSelect, date, device, sort, setAverage }) {
  const [data, setData] = useState({});
  const { factory } = useContext(Context);
  const intl = useIntl();

  useEffect(() => {
    if (device.length !== 0) {
      let req = {
        FactoryID: factory,
        DeviceIDList: device.map(({ DeviceID }) => DeviceID),
      };
      req = handleRequest(req, sort, date);
      apiGetOEEDataList(req)
        .then((res) => {
          setData(res.data);
          Object.keys(res.data.DeviceAverageValue).forEach((key) => {
            res.data.DeviceAverageValue[key] =
              Math.floor(res.data.DeviceAverageValue[key] * 100) / 100;
          });
          setAverage(res.data.DeviceAverageValue);
        })
        .catch(console.log);
    } else {
      setData({});
    }
  }, [date, device]);

  const options = {
    chart: { height: 370 },
    credits: { enabled: false },
    exporting: { enabled: false },
    title: null,
    plotOptions: {
      series: {
        cursor: "pointer",
        events: {
          click: (event) => handleSelect(event.point.category),
        },
      },
    },
    tooltip: {
      headerFormat: '<span style="font-size: 10px">{point.key}</span><table>',
      pointFormat:
        "<tr>" +
        '<td style="color: {series.color}; padding: 0;"><i className="icon-fiber_manual_record" style="margin-right: 5px;"/></td>' +
        '<td style="padding: 0;">{series.name}: {point.y:.2f}</td>' +
        "</tr>",
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
    },
    xAxis: {
      categories: device.map(({ DeviceName }) => DeviceName),
    },
    yAxis: {
      gridLineColor: "#ececec",
      title: {
        text: `OEE / ${intl.formatMessage({
          id: "Utilization",
        })} / ${intl.formatMessage({
          id: "Performance",
        })} / ${intl.formatMessage({
          id: "Yield",
        })}`,
        style: {
          color: "#646464",
        },
      },
    },
    series: [
      {
        name: "OEE",
        type: "line",
        data: data.OEE,
        color: "#31CE75",
        zIndex: 1,
      },
      {
        name: intl.formatMessage({
          id: "Utilization",
        }),
        type: "column",
        data: data.utilization,
        color: "#97B9FF",
      },
      {
        name: intl.formatMessage({ id: "Performance" }),
        type: "column",
        data: data.performance,
        color: "#C3B7F0",
      },
      {
        name: intl.formatMessage({
          id: "Yield",
        }),
        type: "column",
        data: data.yield,
        color: "#FFD07F",
      },
    ],
    lang: {
      useHTML: true,
      noData: intl.formatMessage({ id: "NoData" }),
    },
    noData: {
      style: {
        fontWeight: "bold",
        fontSize: "18px",
        color: "#A1A0B0",
        letterSpacing: "2px",
      },
    },
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
}

export function AssignDeviceChart({ device, date, dataType, sort }) {
  const [data, setData] = useState([]);
  const { factory } = useContext(Context);

  useEffect(() => {
    if (device.DeviceID) {
      let req = {
        FactoryID: factory,
        DeviceID: device.DeviceID,
        DataTitle: dataType,
      };
      req = handleRequest(req, sort, date);
      apiGetAssignDeviceOEEDataList(req)
        .then((res) => setData(res.data))
        .catch(console.log);
    }
  }, [device, date, dataType]);

  const startTime = moment(
    sort === "dateTime" ? `${date.startDate} ${date.startTime}` : date.startDate
  );
  const options = {
    chart: {
      type: "column",
      height: 300,
    },
    colors: ["#97B9FF"],
    credits: { enabled: false },
    exporting: { enabled: false },
    title: null,
    xAxis: {
      type: "datetime",
    },
    legend: { enabled: false },
    series: [
      {
        name: device.DeviceName,
        data: data,
        pointStart: Date.UTC(...startTime.toArray()),
        pointInterval: 1000 * 60 * 30,
        dataLabels: {
          enabled: true,
          rotation: 0,
          color: "#4F86F2",
          align: "center",
          style: {
            fontSize: "12px",
            textOutline: "none",
          },
        },
      },
    ],
  };
  return <HighchartsReact highcharts={Highcharts} options={options} />;
}
