import React, { useState, useMemo, useEffect } from "react";
import { IntlProvider } from "react-intl";
import { Redirect, Route } from "react-router-dom";
import moment from "moment";
import { Pages } from "./pages/pages";
import { Login, Mobile, Reset } from "./pages/login";
import { getUserSelf, request, getGroup } from "./api";
import Context from "./context";

// import 翻譯資料
import en from "./i18n/en.js";
import zh from "./i18n/zh-tw.js";
import vi from "./i18n/vi.js";

import "@formatjs/intl-relativetimeformat/polyfill";
import "@formatjs/intl-relativetimeformat/locale-data/en";
import "@formatjs/intl-relativetimeformat/locale-data/ja";
import "@formatjs/intl-relativetimeformat/locale-data/zh";

function App() {
  console.log({
    version: process.env.REACT_APP_VERSION,
    userAgent: navigator.userAgent,
  });
  const [locale, setLocale] = useState(
    localStorage.getItem("locale")
      ? localStorage.getItem("locale")
      : navigator.language.includes("zh")
      ? "zh-TW"
      : navigator.language
  );
  const [factory, setFactory] = useState(
    localStorage.getItem("factory")
      ? parseInt(localStorage.getItem("factory"))
      : undefined
  );
  const [name, setName] = useState();
  const [photoPath, setPhotoPath] = useState();
  const [group_id, setGroupID] = useState();
  const [token, setToken] = useState();
  const [auth, setAuth] = useState();
  moment.locale(locale);

  useEffect(() => {
    mobileTest();
    const token = localStorage.getItem("token");
    if (token) {
      request.defaults.headers.common["Authorization"] = token;
      setToken(token);
    } else {
      setToken(null);
    }
  }, []);

  useEffect(() => {
    if (token) {
      getGroup()
        .then((res) => {
          const groupList = res.data;
          getUserSelf()
            .then((res) => {
              let group = groupList.find(({ id }) => id === res.data.group_id);
              if (!group) {
                group = {};
              }
              setName(res.data.name);
              setPhotoPath(res.data.photo_path?.replace("/web", ""));
              setGroupID(res.data.group_id);
              const unneeded = [
                "id",
                "group_id",
                "email",
                "name",
                "photo_path",
                "auth_level",
                "role_id",
                "remark",
                "salt",
                "token",
                "notification_time",
                "description",
              ];
              const groupAuth = ["root", "employee", "quality"];
              const auth = { ...group, ...res.data };
              auth.root_sql = 2;
              unneeded.forEach((key) => delete auth[key]);
              Object.keys(auth).forEach((key) => {
                groupAuth.forEach((group) => {
                  if (key.startsWith(group)) {
                    auth[key] = auth[group];
                  }
                });
              });
              groupAuth.forEach((key) => delete auth[key]);
              setAuth(auth);
              setName(res.data.name);
              setPhotoPath(res.data.photo_path?.replace("/web", ""));
              setGroupID(res.data.group_id);
            })
            .catch(console.log);
        })
        .catch(() => {
          localStorage.clear();
          setToken(null);
        });
    }
  }, [token]);

  const mobileTest = () => {
    const mobileDevice = ["Android", "iPhone"];
    let isMobileDevice = mobileDevice.some((e) => navigator.userAgent.match(e));
    if (isMobileDevice) {
      alert("手持裝置請使用專屬APP iMF進入本服務");
      if (navigator.userAgent.match("Android")) {
        window.location = process.env.REACT_APP_LINK_APP_ANDROID;
      }
      if (navigator.userAgent.match("iPhone")) {
        window.location = "https://apps.apple.com/tw/app/imf/id1522259343";
      }
    }
  };

  let messages = en;
  const obj = {
    "zh-TW": zh,
    en,
    vi,
  };
  if (obj[locale]) {
    messages = obj[locale];
  }

  const content = useMemo(
    () => (
      <IntlProvider
        locale={locale}
        key={{ locale }}
        messages={messages}
        setLocale={setLocale}
      >
        <div className="wrapper_area">
          <Route path="/" exact>
            <Redirect to="/pages" />
          </Route>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/mobile">
            <Mobile />
          </Route>
          <Route path="/reset-password/:token">
            <Reset />
          </Route>
          <Route path="/pages">
            <Pages locale={locale} setLocale={setLocale} />
          </Route>
        </div>
      </IntlProvider>
    ),
    [locale, messages]
  );

  return (
    <Context.Provider
      value={{
        factory,
        setFactory,
        name,
        setName,
        photoPath,
        setPhotoPath,
        group_id,
        setGroupID,
        token,
        setToken,
        auth,
        setAuth,
        locale,
      }}
    >
      {content}
    </Context.Provider>
  );
}

export default App;
