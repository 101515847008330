const root = {
  //Root-工廠管理-工廠清單
  FactoryManagement: "Factory Management",
  FactoryList: "Factory List",
  Factory: "Factory",
  factory_num: "Factory Number",
  FactoryName: "Factory Name",
  address: "Address",
  region_id: "region",
  time_zone_id: "Time Zone",
  PushNotificationToken: "Push Notification Token",
  sql_id: "Database",
  group_id: "Group",

  //Root-工廠管理-機台清單
  DeviceList: "Device List",
  Device: "Device",
  DeviceNumber: "Device Number",
  Manufacturer: "Manufacturer",
  Tonnes: "Tonnes",
  Connection: "Connection",
  DevicePhoto: "Device Photo",
  ScrewDiameter: "Screw Diameter",
  NozzleAngleR1: "Nozzle AngleR 1",
  NozzleAngleR2: "Nozzle AngleR 2",
  NozzleAperture1: "Nozzle Aperture 1",
  NozzleAperture2: "Nozzle Aperture 2",
  ShotWeight: "Shot Weight",
  ShotSchedule: "Shot Schedule",
  IntensificationRatio: "Intensification Ratio",
  InjectionPressure: "Injection Pressure",
  InjectionVelocity: "Injection Velocity",
  MoldStackWidthMaxH: "Mold Stack Width Max H",
  MoldStackHeightMaxV: "Mold Stack Height Max V",
  MoldStackWidthMinH: "Mold Stack Width Min H",
  MoldStackHeightMinV: "Mold Stack Height Min V",
  MoldOpenDaylightMax: "Mold Open Daylight Max",
  TiebarClearanceWidth: "Tiebar Clearance Width",
  TiebarClearanceLength: "Tiebar Clearance Length",
  MoldThicknessMax: "Mold Thickness Max",
  MoldThicknessMin: "Mold Thickness Min",
  MoldWidthMinH: "Mold Width Min H",
  MoldHeightMinV: "Mold Height Min V",

  //Root-群組管理
  GroupManagement: "Group Management",
  Group: "Group",
  GroupName: "Group Name",
  Trademark: "Trademark",

  DatabaseManagement: "Database Management",
  Database: "Database",
  account: "Account",
  password: "Password",
  database_name: "Database Name",
  connect_ip: "Connect IP",
  connect_port: "Connect Port",

  RoleManagement: "Role Management",
  Role: "Role",
  MonitoringPoints: "Monitoring Points",
  ScatterDiagram: "Scatter Diagram",
  SqlManagement: "Sql Management",
};
export default root;
