import React, { useState, useEffect, useRef, useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { EmployeeTabs } from "../../component/employee/Employee";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "react-bootstrap";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { MdPlayCircleFilled } from "react-icons/md";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import {
  MachineCard,
  StaffCard,
  WorkItemCard,
} from "../../component/employee/machineList/Cards";
import {
  OrderFinishModal,
  OrderStartModal,
  AddBadOrderModal,
  ProductionSopModel,
  QualitySipModel,
  WaterwayModal

} from "../../component/employee/machineList/Modals";
import {
  ApiEmployee_GetDeviceDataList,
  ApiEmployee_GetEmployee,
  ApiEmployee_GetEmployeeWorkList,
  ApiEmployee_GetEmployeeWorkItem,
  ApiEmployee_UpdateEmployeeWorkItemData,
  ApiPes_GetDeviceCurrentOrderData,
  ApiPes_OrderManualFinish,
  ApiPes_OrderManualStart,
  ApiEmployee_GetDefectiveByOrder,
  ApiSysGetDocumentbyDevice,
  baseURL
} from "../../api";
import { RunningOrderList, WaitingOrderList } from "../../component/mes/production";
import moment from "moment";
import "../../scss/employee.scss";
import Modal from "react-bootstrap/Modal";
import { lampStatus, machineMode } from "../../function";
import { handleError } from "../../component/common";
import Context from "../../context";
import Alert from '@material-ui/lab/Alert';
import { MdDone, MdTimer } from "react-icons/md";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";



const useStyles = makeStyles((theme) => ({
  listBlock: {
    overflowY: "auto",
    overflowX: "hidden",
  },
  arrow: {
    color: "white",
    backgroundColor: "#F0F0F0",
    cursor: "pointer",
  },
  machineSubTitle: {
    fontSize: "30px",
    fontWeight: "bold",
    padding: "5px 20px",
    borderRadius: "10px",
    color: "white",
  },
  submitButton: {
    backgroundColor: "#2E355B",
    fontSize: "35px",
    color: "white",
    fontWeight: "bold",
  },
  button: {
    padding: "10px 20px",
    backgroundColor: "#2E355B",
    "&.disable": {
      backgroundColor: "#D0D0D0",
    },
  },
}));
function OrderCard({
  data: {
    id,
    photo_path,
    order,
    product,
    mold,
    starttime,
    endtime,
    order_status,
    isMaterialDifferent,
    isMoldDifferent,
  },
  buttonType,
  showModal,
  clickable,
  className,
}) {
  const intl = useIntl();
  const { auth } = useContext(Context);
  const data = { order_status, id, order };
  return (
    <div className={`order jc-space-between ${className}`}>
      <div className="ai-stretch">
        <img src={photo_path ? `${baseURL}/${photo_path}` : ""} />
        <div className="flex-column mr-5">
          <h4>
            <b>{order}</b>
          </h4>
          <h6>
            <FormattedMessage id="product_num" />：<b>{product}</b>
          </h6>
          <h6>
            <FormattedMessage id="MoldNumberUsed" />：<b>{mold}</b>
          </h6>
          {starttime ? (
            <h6>
              <FormattedMessage id="StartTime" />：<b>{starttime}</b>
            </h6>
          ) : (
            <h6>
              <FormattedMessage id="EndTime" />：<b>{endtime}</b>
            </h6>
          )}
        </div>
        <div className="flex-column jc-space-between p-2">
          {isMoldDifferent === 1 ? (
            <Alert severity="warning">
              {intl.formatMessage({ id: "MoldDifferent" })}
            </Alert>
          ) : (
            <div />
          )}
          {isMaterialDifferent === 1 ? (
            <Alert severity="warning">
              {intl.formatMessage({ id: "MaterialDifferent" })}
            </Alert>
          ) : (
            <div />
          )}
        </div>
      </div>

      <div className="ai-center">
        {/* {order_status === 2 ? (
          <div className="flex" style={{ marginRight: "10px" }}>
            <button
              className="btn_finish center flex-column"
              onClick={() => showModal("split", data)}
            >
              <span>
                <FormattedMessage id="Split" />
              </span>
            </button>
          </div>
        ) : null}
        {buttonType === "finish" ? (
          <div className="flex" style={{ marginRight: "10px" }}>
            <button
              className="btn_finish center flex-column"
              onClick={() => showModal("pause", data)}
            >
              {order_status === 2 ? <PauseIcon /> : <PlayArrowIcon />}
              <span>
                {order_status === 2 ? (
                  <FormattedMessage id="Pause" />
                ) : (
                  <FormattedMessage id="Start" />
                )}
              </span>
            </button>
          </div>
        ) : null}
        {clickable ? (
          <div className="flex">
            <button
              className="btn_finish center flex-column"
              onClick={() => showModal(buttonType, data)}
            >
              {buttonType === "finish" ? (
                <MdDone size="30px" />
              ) : (
                <MdTimer size="30px" />
              )}
              <span>
                <FormattedMessage
                  id={
                    buttonType === "finish" ? "ClickToComplete" : "ClickToStart"
                  }
                />
              </span>
            </button>
          </div>
        ) : null} */}
      </div>
    </div>
  );
}


function DeviceRunningOrder ({ data, showModal }) {
  if (data) {
    return (
      <div className="border">
        <OrderCard
          data={data}
          showModal={showModal}
          buttonType="finish"
          clickable={true}
        />
      </div>
    );
  } else {
    return (
      <div className="order">
        <span className="no-order center">
          <FormattedMessage id="NoOrderRunning" />
        </span>
      </div>
    );
  }
}
//機台列表頁面
export const EmployeeMachineList = function ({ checkEditAuth }) {
  const classes = useStyles();
  const listBlockRef = useRef(null);
  const [machineList, setMachineList] = useState([]);
  const [modal, setModal] = useState(false);
  const [select, setSelect] = useState({});
  const intl = useIntl();
  const { factory } = useContext(Context);

  const scroll = (direction) => {
    listBlockRef.current.scrollTop +=
      (direction * (90 * window.innerHeight)) / 100;
  };

  const openModal = (data) => {
    setSelect(data);
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
  };

  useEffect(() => {
    ApiEmployee_GetDeviceDataList({
      FactoryID: factory,
    })
      .then((res) => setMachineList(res.data))
      .catch((e) => handleError(e, intl));
  }, []);

  return (
    <div className="flex-column">
      <EmployeeTabs tab="0" />
      <div
        className="page-content shadow-lg ai-stretch"
        style={{ height: "75vh" }}
      >
        <div
          style={{ flex: 5 }}
          ref={listBlockRef}
          className={`${classes.listBlock} h-100 mr-4`}
        >
          <Grid container spacing={2}>
            {machineList.map((item, i) => (
              <MachineCard
                key={item.DeviceID}
                onSelect={openModal}
                index={i}
                data={item}
              />
            ))}
          </Grid>
        </div>
        <div className="flex-column flex-1">
          <div
            className={`${classes.arrow} center flex-1 mb-3`}
            onClick={() => scroll(-1)}
          >
            <ArrowDropUpIcon style={{ fontSize: "200px" }} />
          </div>
          <div
            className={`${classes.arrow} center flex-1 mb-3`}
            onClick={() => scroll(1)}
          >
            <ArrowDropDownIcon style={{ fontSize: "200px" }} />
          </div>
        </div>
      </div>
      <Dialog
        fullScreen
        open={modal}
        onClose={closeModal}
        className="modal_z1000"
      >
        <MachineRealTimePage
          onClose={closeModal}
          machine={select}
          checkEditAuth={checkEditAuth}
        />
      </Dialog>
    </div>
  );
};

//機台即時狀況頁面
function MachineRealTimePage({ onClose, machine, checkEditAuth }) {
  const classes = useStyles();

  const [deviceData, setDeviceData] = useState({ Operation: [] });
  const [modal, setModal] = useState("");
  const [staffList, setStaffList] = useState([]);
  const [period, setPeriod] = useState("");
  const [workItem, setWorkItem] = useState({});
  const [defective, setDefective] = useState();
  const [currentOrder, setCurrentOrder] = useState({});
  const [orderList, setOrderList] = useState([]);
  const [orderData, setOrderData] = useState({});
  const [SopModel, setSopModal] = useState(false);
  const [SipModel, setSipModal] = useState(false);
  const [WaterModel, setWaterModal] = useState(false);
  const [time, setTime] = useState({});
  const intl = useIntl();
  const [documentData, setDocumentData] = useState([])
  const [error, setError] = useState(false);
  const { factory } = useContext(Context);

  useEffect(() => {
    if (!modal) {
      loadData();
      const temp = setInterval(() => {
        loadOrderData();
        loadDefectiveData();
      }, 30000);
      return () => clearInterval(temp);
    }
  }, [machine.DeviceID, currentOrder.order_num]);

  useEffect(() => {
    const { StartTime, EndTime } = time;
    if (StartTime) {
      setPeriod(formatTime(StartTime, EndTime));
      if (!EndTime) {
        const temp = setInterval(
          () => setPeriod(formatTime(StartTime, EndTime)),
          1000
        );
        return () => clearInterval(temp);
      }
    } else {
      setPeriod("");
    }
  }, [time]);

  const formatTime = (start, end) => {
    let str = "";
    let diff = 0;
    if (start) {
      if (end) {
        diff = moment(end).diff(start, "s");
      } else {
        diff = moment().diff(start, "s");
      }
      const h = Math.floor(diff / 3600);
      const m = Math.floor((diff - h * 3600) / 60);
      const s = Math.floor(diff - h * 3600 - m * 60);
      str = `${h}:${m >= 10 ? m : `0${m}`}:${s >= 10 ? s : `0${s}`}`;
    }
    return str;
  };

  const loadWorkData = () => {
    ApiEmployee_GetEmployee({
      FactoryID: factory,
      DeviceID: machine.DeviceID,
    }).then((res) => {
      setDeviceData(res.data);
      setStaffList(
        res.data.EmployeeId.map((EmployeeId, i) => ({
          EmployeeId,
          EmployeeName: res.data.EmployeeName[i],
        }))
      );
      setWorkItem({ WorkItemName: res.data.WorkItemName });
      const index = res.data.Operation.findIndex(
        ({ WorkItemStep }) => WorkItemStep === res.data.ItemStep
      );
      if (index > -1) {
        const { StartTime, EndTime } = res.data.Operation[index];
        setTime({ StartTime, EndTime });
      }
    });
  };

  const loadOrderData = () => {
    ApiPes_GetDeviceCurrentOrderData({
      FactoryID: factory,
      DeviceID: machine.DeviceID,
    })
      .then((res) => {
        setCurrentOrder(res.data[0] ? res.data[0] : {});
        setOrderList(res.data.slice(1));
      })
      .catch(console.log);
  };
  const loadDefectiveData = () => {
    if (currentOrder.order_num) {
      ApiEmployee_GetDefectiveByOrder({
        headers: { factory_id: factory },
        params: { order_num: currentOrder.order_num },
      }).then((res) => setDefective(res.data.length));
    }
  };
  const loadDocumentData = () => {

    ApiSysGetDocumentbyDevice(factory, machine.DeviceID)
      .then((res) => {
        setDocumentData(res.data);
      })
      .catch((err) => {
        if (err) {
          setError(true);
        }
      });

  };
  const loadData = () => {
    loadDocumentData();
    loadWorkData();
    loadOrderData();
    loadDefectiveData();
  };

  const open_modal = (type, data) => {
    switch (type) {
      case "SopModel":
        setSopModal(true);
        break;
      case "SipModel":
        setSipModal(true);
        break;
      case "WaterModel":
        setWaterModal(true);
        break;
      default:
        break;
    }
  };

  const close_modal = () => {
    setSopModal(false);
    setSipModal(false);
    setWaterModal(false);
  };

  const openModal = (modal) => {
    setModal(modal);
  };

  const closeModal = () => {
    if (modal === "defective") {
      loadDefectiveData();
    }
    setModal("");
  };

  const handleOrder = (modal, order) => {
    if (!checkEditAuth()) {
      return;
    }
    setOrderData(order);
    setModal(modal);
  };

  const manualFinish = () => {
    ApiPes_OrderManualFinish({
      FactoryID: factory,
      account: localStorage.getItem("account"),
      OrderID: orderData.id,
    })
      .then(() => {
        setCurrentOrder({});
        setOrderData(orderList.length > 0 ? orderList[0] : {});
        setModal(orderList.length > 0 ? "start" : "");
      })
      .catch((err) => handleError(err, intl));
  };

  const manualStart = () => {
    ApiPes_OrderManualStart({
      account: localStorage.getItem("account"),
      FactoryID: factory,
      DeviceID: machine.DeviceID,
      OrderID: orderData.id,
    })
      .then(() => {
        loadData();
        closeModal();
      })
      .catch((err) => handleError(err, intl));
  };

  const startWork = () => {
    if (staffList.length > 0 && workItem.WorkItemId !== undefined) {
      const employee_id = staffList.map(({ EmployeeId }) => EmployeeId);
      ApiEmployee_UpdateEmployeeWorkItemData({
        account: localStorage.getItem("account"),
        factory_id: factory,
        device_id: machine.DeviceID,
        employee_id,
        work_item_id: workItem.WorkItemId,
      })
        .then(() => {
          loadWorkData();
          closeModal();
        })
        .catch((e) => handleError(e, intl));
    } else {
      alert(
        intl.formatMessage({ id: "PleasePickThe" }) +
        intl.formatMessage({ id: "Operator" }) +
        intl.formatMessage({ id: "And" }) +
        intl.formatMessage({ id: "Work" })
      );
      closeModal();
    }
  };

  const done = () => {
    ApiEmployee_UpdateEmployeeWorkItemData({
      account: localStorage.getItem("account"),
      factory_id: factory,
      device_id: machine.DeviceID,
      employee_id: staffList.map(({ EmployeeId }) => EmployeeId),
    }).then(() => {
      loadWorkData();
      closeModal();
    });
  };

  const classifyColor = (type) => {
    switch (type) {
      case "RUN 正常生產":
        return "#28FF24";
      case "IDLE 閒置":
        return "#FFCF0D";
      case "DOWN 異常":
        return "#FF2619";
      case "PM 保養":
        return "#0116FF";
      case "DMQC 換料/日常檢點/換線":
        return "#00ACFF";
      case "TEST 試模/品檢":
        return "#CC009C";
      default:
        return "#F9F9F9";
    }
  };

  //狀態資訊區塊
  const StatusInfo = () => {
    const { DeviceName, ClassifyName, LampStatus, MachineMode } = deviceData;
    const { status, color } = lampStatus(LampStatus);
    const mode = machineMode(MachineMode);
    return (
      <div className="flex-column ai-center">
        <div style={{ color, fontSize: "80px" }} className="ai-center">
          <b>{DeviceName}</b>
          <FiberManualRecordIcon style={{ fontSize: "80px" }} />
        </div>
        <div style={{ color, fontSize: "30px" }} className="mb-3">
          <b>
            {status}
            {mode ? "：" : ""}
            {mode}
          </b>
        </div>
        {ClassifyName ? (
          <div
            style={{
              backgroundColor: classifyColor(ClassifyName),
            }}
            className={classes.machineSubTitle}
          >
            {ClassifyName}
          </div>
        ) : null}
      </div>
    );
  };

  const { NextStep, OperationStatus, Operation, ItemStep } = deviceData;

  const buttonList = [
    {
      id: "ChoosePeople",
      available: !OperationStatus && NextStep,
      onClick: () => openModal("staff"),
    },
    {
      id: "Start",
      available: staffList.length > 0 && workItem.WorkItemId,
      onClick: () => openModal("startWork"),
    },
    {
      id: "Complete",
      available: OperationStatus,
      onClick: () => openModal("done"),
    },
  ];
  return (
    <React.Fragment>

      <div className="h-100 flex-column ai-stretch">
        {/* title */}
        <div className="border-bottom p-4 w-100 ai-center jc-space-between">
          <b style={{ fontSize: "40px" }}>
            <FormattedMessage id="RealtimeStatus" />
          </b>

          <div style={{display:'flex'}}>
            {!(Object.entries(documentData).length === 0) ?
              <>
                <Button
                  style={{ marginRight: '10px' }}
                  variant="secondary" size="lg"
                  onClick={() => open_modal("SopModel")}
                >
                  <FormattedMessage id="ProductionSop" />
                </Button>

                <Button
                  style={{ marginRight: '10px' }}
                  variant="secondary" size="lg"
                  onClick={() => open_modal("SipModel")}
                >
                  <FormattedMessage id="QualityInspection" />
                </Button>
                <Button
                  variant="secondary" size="lg"
                  onClick={() => open_modal("WaterModel")}
                >
                  <FormattedMessage id="MoldingConditions" />
                </Button>

              </>:
                <Alert severity="error" style={{height:'50px'}}><FormattedMessage id="NoData" />
                </Alert>

            }


            <IconButton onClick={onClose}>
              <CloseIcon style={{ fontSize: "40px" }} />
            </IconButton>
          </div>

        </div>
        {/* body */}
        <div className="p-5 ai-stretch flex-1">
          {/* left */}
          <div className="flex-1 mr-5 jc-space-between flex-column">
            {/* top */}
            <div className="h-50 flex-column jc-center" style={{ flex: 2 }}>
              <StatusInfo />
              <Stepper
                activeStep={Operation.findIndex(
                  ({ WorkItemStep }) => WorkItemStep === ItemStep
                )}
                alternativeLabel
              >
                {deviceData.Operation.map(
                  ({ WorkItemId, WorkItemName, StartTime, EndTime }) => (
                    <Step key={WorkItemId}>
                      <StepLabel>
                        <h5>
                          <b>{WorkItemName}</b>
                        </h5>
                        <h6>{EndTime ? formatTime(StartTime, EndTime) : ""}</h6>
                      </StepLabel>
                    </Step>
                  )
                )}
              </Stepper>
            </div>
            {/* bottom */}
            <div
              className="flex-1 ai-stretch w-100"
              style={{ maxHeight: "175px" }}
            >
              <div
                style={{
                  backgroundColor: "#F8F8F8",
                  fontSize: "24px",
                  flex: 3,
                }}
                className="flex-column jc-space-between mr-3 p-3"
              >
                <span>
                  <FormattedMessage id="CurrentOperationItem" />：
                  {workItem.WorkItemName}
                </span>
                <span>
                  <FormattedMessage id="Operator" />：
                  {staffList.map(({ EmployeeName }) => EmployeeName).toString()}
                </span>
                <span>
                  <FormattedMessage id="Ontime" />：{period}
                </span>
              </div>
              <div className="flex-column jc-space-between ai-stretch flex-1">
                {buttonList.map(({ id, onClick, available }) => (
                  <Button
                    key={id}
                    className={classes.button}
                    style={{
                      backgroundColor: available ? "#2E355B" : "#D0D0D0",
                    }}
                    onClick={onClick}
                    disabled={!available}
                  >
                    <FormattedMessage id={id} />
                  </Button>
                ))}
              </div>
            </div>
          </div>
          {/* right */}
          <div className="flex-1 pes-area flex-column jc-space-between ai-stretch">
            <div className="order-area flex-column mb-3">
              <div className="mb-5">
                <h5 className="ai-center">
                  <MdPlayCircleFilled className="mr-2" />
                  <FormattedMessage id="OrderRunning" />
                </h5>
                <DeviceRunningOrder showModal={handleOrder} data={currentOrder} />
              </div>
              <div>
                <h5 className="ai-center">
                  <MdPlayCircleFilled className="mr-2" />
                  <FormattedMessage id="NextOrder" />
                </h5>
                <WaitingOrderList
                  showModal={handleOrder}
                  data={orderList}
                  busy={currentOrder}
                  style={{ maxHeight: "30vh" }}
                />
              </div>
            </div>
            <Button
              className={`${classes.submitButton} pt-5 pb-5`}
              onClick={() => setModal("defective")}
              style={{
                backgroundColor: defective > 0 ? "#f8d44a" : "green",
              }}
            >
              {defective}
              <FormattedMessage id="DefectiveCount" />
            </Button>
          </div>
        </div>
      </div>

      <Dialog fullScreen open={modal === "staff"} onClose={closeModal}>
        <SelectStaffPage
          setStaffList={setStaffList}
          onClose={closeModal}
          setModal={setModal}
          data={currentOrder}
        />
      </Dialog>

      <Dialog fullScreen open={modal === "work"} onClose={closeModal}>
        <SelectWorkItemPage
          staffList={staffList}
          onClose={closeModal}
          setWorkItem={setWorkItem}
          data={currentOrder}
        />
      </Dialog>

      <OrderFinishModal
        show={modal === "finish"}
        submit={manualFinish}
        onHide={closeModal}
        data={orderData}
      />

      <OrderStartModal
        show={modal === "start"}
        submit={manualStart}
        onHide={closeModal}
        data={orderData}
      />

      <AddBadOrderModal
        show={modal === "defective"}
        machine={machine}
        closeModal={closeModal}
        order={currentOrder}
      />

      <Modal
        className="modal_style fade"
        size="lg"
        centered
        show={modal === "startWork"}
        onHide={closeModal}
      >
        <Modal.Header closeButton>
          <Modal.Title as="h5">
            <b>
              <FormattedMessage id="ConfirmationMessage" />
            </b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormattedMessage id="AreYouSureTo" />
          <FormattedMessage id="Start" />
          <FormattedMessage id="ThisWork" />
          <FormattedMessage id="QuestionMark" />
        </Modal.Body>

        <Modal.Footer className="d-flex justify-content-end">
          <Button className="btn-confirm" onClick={startWork}>
            <FormattedMessage id="OK" />
          </Button>
          <Button className="btn-cancel" onClick={closeModal}>
            <FormattedMessage id="Cancel" />
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        className="modal_style fade"
        size="lg"
        centered
        show={modal === "done"}
        onHide={closeModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id="Hint" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormattedMessage id="AreYouSureTo" />
          <FormattedMessage id="Complete" />
          <FormattedMessage id="ThisWork" />
          <FormattedMessage id="QuestionMark" />
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-end">
          <Button className="btn-confirm" onClick={done}>
            <FormattedMessage id="OK" />
          </Button>
          <Button className="btn-cancel" onClick={closeModal}>
            <FormattedMessage id="Cancel" />
          </Button>
        </Modal.Footer>
      </Modal>
      <ProductionSopModel
        setError={setError}
        error={error}
        show={SopModel}
        closeModal={close_modal}
        Data={documentData}
      />
      <QualitySipModel
        setError={setError}
        error={error}
        show={SipModel}
        closeModal={close_modal}
        Data={documentData}
      />
      <WaterwayModal
        setError={setError}
        error={error}
        show={WaterModel}
        closeModal={close_modal}
        Data={documentData}
      />

    </React.Fragment>
  );
}

function SelectPage({ name, data, submit, onClick, onClose, index }) {
  const listBlockRef = useRef(null);
  const scroll = (direction) => {
    listBlockRef.current.scrollTop +=
      (direction * (90 * window.innerHeight)) / 100;
  };

  let items;
  if (name === "Operator") {
    items = (
      <Grid container spacing={2}>
        {data.map((item, i) => (
          <StaffCard
            key={item.EmployeeId}
            data={item}
            index={i}
            onClick={onClick}
          />
        ))}
      </Grid>
    );
  } else if (name === "Work") {
    items = (
      <Grid container spacing={3}>
        {data.map(({ WorkItemId, WorkItemName }, i) => (
          <WorkItemCard
            key={WorkItemId}
            WorkItemName={WorkItemName}
            selected={i === index}
            index={i}
            onClick={onClick}
          />
        ))}
      </Grid>
    );
  }

  const classes = useStyles();
  return (
    <div className="h-100 flex-column ai-stretch">
      <div className="border-bottom p-4 w-100 ai-center jc-space-between">
        <b style={{ fontSize: "40px" }}>
          <FormattedMessage id="PleasePickThe" />
          <FormattedMessage id={name} />
        </b>
        <IconButton onClick={onClose}>
          <CloseIcon style={{ fontSize: "40px" }} />
        </IconButton>
      </div>
      <div style={{ height: "88%" }} className="p-4 ai-stretch">
        <div
          style={{ flex: 5 }}
          ref={listBlockRef}
          className={`${classes.listBlock} h-100 mr-4`}
        >
          {items}
        </div>
        <div className="flex-column flex-1">
          <div
            className={`${classes.arrow} center flex-1 mb-3`}
            onClick={() => scroll(-1)}
          >
            <ArrowDropUpIcon style={{ fontSize: "200px" }} />
          </div>
          <div
            className={`${classes.arrow} center flex-1 mb-3`}
            onClick={() => scroll(1)}
          >
            <ArrowDropDownIcon style={{ fontSize: "200px" }} />
          </div>
          <Button className={classes.submitButton} onClick={submit}>
            <FormattedMessage id="OK" />
          </Button>
        </div>
      </div>
    </div>
  );
}

//選擇操作人員頁面
function SelectStaffPage({ setStaffList, onClose, setModal, data }) {
  const [employeeList, setEmployeeList] = useState([]);
  const intl = useIntl();
  const { factory } = useContext(Context);

  useEffect(() => {
    ApiEmployee_GetEmployeeWorkList({
      factory_id: factory,
      order_num: data.order_num,
    }).then((res) => {
      setEmployeeList(
        res.data.map((item) => ({
          ...item,
          selected: false,
        }))
      );
    });
  }, []);

  const onClick = (i) => {
    let list = JSON.parse(JSON.stringify(employeeList));
    list[i].selected = !list[i].selected;
    setEmployeeList(list);
  };

  const submit = () => {
    let staffList = employeeList.filter((item) => item.selected);
    if (staffList.length > 0) {
      setStaffList(staffList);
      setModal("work");
    } else {
      alert(
        intl.formatMessage({ id: "PleasePickThe" }) +
        intl.formatMessage({ id: "Operator" })
      );
    }
  };

  return (
    <SelectPage
      name="Operator"
      data={employeeList}
      submit={submit}
      onClick={onClick}
      onClose={onClose}
    />
  );
}

//選擇操作項目
function SelectWorkItemPage({ staffList, setWorkItem, onClose, data }) {
  const [workList, setWorkList] = useState([]);
  const [index, setIndex] = useState();
  const intl = useIntl();
  const { factory } = useContext(Context);

  useEffect(() => {
    ApiEmployee_GetEmployeeWorkItem({
      factory_id: factory,
      employee_id: staffList.map(({ EmployeeId }) => EmployeeId).toString(),
      order_num: data.order_num
    })
      .then((res) => setWorkList(res.data))
      .catch((e) => handleError(e, intl));
  }, []);

  const submit = () => {
    if (index !== undefined) {
      setWorkItem(workList[index]);
      onClose();
    } else {
      alert(
        intl.formatMessage({ id: "PleasePickThe" }) +
        intl.formatMessage({ id: "Work" })
      );
    }
  };

  return (
    <SelectPage
      name="Work"
      data={workList}
      submit={submit}
      index={index}
      onClick={setIndex}
      onClose={onClose}
    />
  );
}

