import React, { useEffect, useState, useContext } from "react";
import { Modal, Button } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import moment from "moment";
import ViewModuleIcon from "@material-ui/icons/ViewModule";
import ListIcon from "@material-ui/icons/List";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import {
  ApiPes_bad_delete,
  ApiPes_bad_post,
  ApiEmployee_GetDefectiveByOrder,
  getManagementBadReason,
} from "../../../api";
import { handleError } from "../../common";
import Context from "../../../context";
import Carousel from "react-bootstrap/Carousel";
import Alert from "@material-ui/lab/Alert";

function OrderFinishModal({ show, onHide, submit, data }) {
  return (
    <Modal
      className="modal_style fade"
      size="lg"
      centered
      show={show}
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title as="h5">
          <b>
            <FormattedMessage id="ConfirmationMessage" />
          </b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>
          <FormattedMessage id="AreYouSureTo" />
          <FormattedMessage id="FinishManually" />
          <b>{` ${data?.order} `}</b>
          <FormattedMessage id="QuestionMark" />
        </span>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-end">
        <button className="btn btn-confirm" onClick={submit}>
          <FormattedMessage id="OK" />
        </button>
        <button className="btn btn-cancel" onClick={onHide}>
          <FormattedMessage id="Cancel" />
        </button>
      </Modal.Footer>
    </Modal>
  );
}

function OrderStartModal({ show, onHide, submit, data }) {
  const intl = useIntl();
  return (
    <Modal
      className="modal_style fade"
      size="lg"
      centered
      show={show}
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title as="h5">
          <b>
            <FormattedMessage id="ConfirmationMessage" />
          </b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h6>
          {intl.formatMessage(
            { id: "StartManuallyConfirm" },
            { name: data?.order }
          )}
        </h6>
        <span>
          <FormattedMessage id="StartManuallyRemark" />
        </span>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-end">
        <button className="btn btn-confirm" onClick={submit}>
          <FormattedMessage id="OK" />
        </button>
        <button className="btn btn-cancel" onClick={onHide}>
          <FormattedMessage id="Cancel" />
        </button>
      </Modal.Footer>
    </Modal>
  );
}

function AddBadOrderModal({ show, closeModal, machine, order }) {
  const [reasonList, setReasonList] = useState([]);
  const [page, setPage] = useState(0);
  const [badList, setBadList] = useState([]);
  const intl = useIntl();
  const { factory, locale } = useContext(Context);

  useEffect(() => {
    getManagementBadReason({
      factory_id: factory,
    }).then((res) => {
      res.data.forEach((item) => {
        item["zh-TW"] = item.description;
        item.en = item.english;
        item.vi = item.vietnamese;
      });
      setReasonList(res.data);
    });
  }, [factory]);

  useEffect(() => {
    if (show) {
      loadData(reasonList);
    }
  }, [machine.DeviceID, show, order.order_num]);

  const loadData = (reasonList) => {
    if (machine.DeviceID && order.order_num) {
      ApiEmployee_GetDefectiveByOrder({
        headers: { factory_id: factory },
        params: { order_num: order.order_num },
      }).then((res) => {
        let result = {};
        res.data.forEach(({ bad_reason, product_count }) => {
          if (result[bad_reason] === undefined) {
            result[bad_reason] = 1;
          } else {
            result[bad_reason] += product_count;
          }
        });
        reasonList.forEach(
          (item) => (item.amount = result[item.id] ? result[item.id] : 0)
        );
        setReasonList(reasonList);
        setBadList(res.data);
      });
    }
  };

  const addData = (Reason) => {
    ApiPes_bad_post({
      account: localStorage.getItem("account"),
      FactoryID: factory,
      DeviceID: machine.DeviceID,
      DatetimeStart: null,
      OrderID: order.id,
      ProductCount: 1,
      Reason,
    })
      .then(() => loadData(reasonList))
      .catch((err) => handleError(err, intl));
  };

  const deleteData = (reason) => {
    const result = badList.find(({ bad_reason }) => bad_reason === reason);
    if (result) {
      ApiPes_bad_delete({
        FactoryID: factory,
        account: localStorage.getItem("account"),
        id: result.id,
      })
        .then(() => loadData(reasonList))
        .catch((err) => handleError(err, intl));
    }
  };

  const clearData = async (reason) => {
    const arr = badList.filter(({ bad_reason }) => bad_reason === reason);
    for (let i = 0; i < arr.length; ++i) {
      try {
        await ApiPes_bad_delete({
          FactoryID: factory,
          account: localStorage.getItem("account"),
          id: arr[i].id,
        });
      } catch (e) {
        handleError(e, intl);
      }
    }
    loadData(reasonList);
  };

  let body;
  if (page === 0) {
    body = (
      <Grid container spacing={2}>
        {reasonList.map(({ id, amount, ...item }) => (
          <Grid key={id} item xs={4}>
            <Paper
              onClick={() => addData(id)}
              className="flex-column jc-space-between"
              style={{ height: "100px", padding: "10px", cursor: "pointer" }}
            >
              <b>{item[locale]}</b>
              <b
                style={{
                  textAlign: "end",
                  fontSize: "32px",
                  lineHeight: 1,
                }}
              >
                {amount}
              </b>
            </Paper>
          </Grid>
        ))}
      </Grid>
    );
  } else {
    body = (
      <ul className="bad-list" style={{ maxHeight: "65vh", overflow: "auto" }}>
        {reasonList.map(({ id, amount, ...item }) => (
          <li
            className="ai-center"
            style={{ paddingTop: "10px", paddingBottom: "10px" }}
          >
            <b style={{ width: "40%" }}>{item[locale]}</b>
            <div style={{ flex: 1 }} className="ai-center">
              <IconButton onClick={() => deleteData(id)}>
                <RemoveIcon fontSize="small" />
              </IconButton>
              <b style={{ fontSize: "18px", lineHeight: 1 }}>
                <FormattedMessage id="Amount" />：{amount}
              </b>
              <IconButton onClick={() => addData(id)}>
                <AddIcon fontSize="small" />
              </IconButton>
            </div>
            <IconButton onClick={() => clearData(id)}>
              <DeleteIcon />
            </IconButton>
          </li>
        ))}
      </ul>
    );
  }

  return (
    <Modal
      className="modal_style fade"
      size="lg"
      centered
      show={show}
      onHide={closeModal}
    >
      <Modal.Header closeButton>
        <Modal.Title as="h5">
          <b>
            <FormattedMessage id="EnterDefectiveProduct" />
          </b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="ai-stretch">
          <div style={{ flex: 1 }} className="pes-area">
            <div
              className="jc-space-between ai-center"
              style={{ marginBottom: "10px" }}
            >
              <span>
                {page === 0 ? <FormattedMessage id="ClickToAddQuantity" /> : ""}
              </span>
              <span>
                <ViewModuleIcon
                  style={{
                    backgroundColor: page === 0 ? "#d0d0d0" : "#f0f0f0",
                    marginRight: "10px",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                  fontSize="large"
                  onClick={() => setPage(0)}
                />
                <ListIcon
                  style={{
                    backgroundColor: page === 1 ? "#d0d0d0" : "#f0f0f0",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                  fontSize="large"
                  onClick={() => setPage(1)}
                />
              </span>
            </div>
            {body}
          </div>
          <div
            style={{
              borderLeft: "1px solid #d0d0d0",
              paddingLeft: "15px",
              marginLeft: "15px",
              fontWeight: "bold",
              maxWidth: "200px",
              wordWrap: "break-word",
            }}
          >
            <h5 style={{ color: "#b0b0b0" }}>
              <FormattedMessage id="DeviceNumber" />
            </h5>
            <h4>{machine.id}</h4>
            <h5 style={{ color: "#b0b0b0" }}>
              <FormattedMessage id="order_num_name" />
            </h5>
            <h4>{order?.order}</h4>
            <h5 style={{ color: "#b0b0b0" }}>
              <FormattedMessage id="CurrentOperationItem" />
            </h5>
            <h4>
              <FormattedMessage id="ChangeMold" />
            </h4>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

function QualitySipModel({ show, closeModal, Data, error, setError }) {
  const [video, setVideoModal] = useState(false);
  const [img, setImgModal] = useState(false);
  const [qualitymodal, setQualityModal] = useState(true);

  const [imgUrl, setImgUrl] = useState([]);
  const [videoUrl, setVideoUrl] = useState([]);
  const [refresh, setRefresh] = useState(true);
  const imgModalClick = () => {
    const sipFilter = Data.children[0].children.filter(
      (i) => i.name === "QualityInspection"
    );
    const imgFilter = sipFilter[0].children.find((x) => x.name === "Image");

    setImgUrl(imgFilter.children);
    setRefresh(!refresh);
    setQualityModal(false);
    setImgModal(true);
  };

  const videoModalClick = () => {
    const sipFilter = Data.children[0].children.filter(
      (i) => i.name === "QualityInspection"
    );
    const videoFilter = sipFilter[0].children.find((x) => x.name === "Video");

    setVideoUrl(videoFilter.children);
    setRefresh(!refresh);
    setQualityModal(false);
    setVideoModal(true);
  };
  const clearState = () => {
    setQualityModal(false);
    setVideoModal(false);
    setImgModal(false);
    setError(false);
  };
  //Go back
  const Back = () => {
    setImgModal(false);
    setVideoModal(false);
    setQualityModal(true);
  };
  React.useEffect(() => {
    if (show) {
      setQualityModal(show);
    } else {
      clearState();
    }
  }, [show]);

  return (
    <>
      <Modal
        className="modal_style fade"
        size="lg"
        centered
        show={qualitymodal}
        onHide={closeModal}
        style={{ zIndex: 2000 }}
      >
        <Modal.Header closeButton>
          <Modal.Title as="h5">
            <b>
              <FormattedMessage id="SelectType" />
            </b>
            {error ? <Alert severity="error">error</Alert> : null}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Button
            type="button"
            variant="outline-secondary"
            style={{ height: "15vh", width: "10vw", marginRight: "10px" }}
            className="btn_videoclick"
            onClick={videoModalClick}
          >
            <FormattedMessage id="Video" />
            {/*取消*/}
          </Button>
          <Button
            type="button"
            variant="outline-secondary"
            style={{ height: "15vh", width: "10vw" }}
            className="btn_imgClick"
            onClick={imgModalClick}
          >
            <FormattedMessage id="Image" />
            {/*取消*/}
          </Button>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn_common btn_cancel"
            onClick={closeModal}
          >
            <FormattedMessage id="Cancel" SOP />
            {/*取消*/}
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        className="modal_style fade"
        size="lg"
        centered
        style={{ zIndex: 2000 }}
        show={video}
        onHide={closeModal}
      >
        <Modal.Header closeButton>
          <Modal.Title as="h5">
            <FormattedMessage id="Video" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {videoUrl.map((i) => (
            <video width="720" height="480" controls>
              <source src={i.url} type="video/mp4" />
            </video>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn_common btn_cancel"
            onClick={Back}
          >
            <FormattedMessage id="Go Back" SOP />
            {/*取消*/}
          </button>

          <button
            type="button"
            className="btn btn_common btn_cancel"
            onClick={closeModal}
          >
            <FormattedMessage id="Cancel" SOP />
            {/*取消*/}
          </button>
        </Modal.Footer>
      </Modal>
      {/*img */}
      <Modal
        className="modal_style fade"
        size="lg"
        centered
        style={{ zIndex: 2000 }}
        show={img}
        onHide={closeModal}
      >
        <Modal.Header closeButton>
          <Modal.Title as="h5">
            <FormattedMessage id="Image" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Carousel>
            {imgUrl.map((i) => (
              <Carousel.Item>
                <img className="d-block w-100" src={i.url} alt="First slide" />
                <Carousel.Caption>
                  <h3>{i.name}</h3>
                </Carousel.Caption>
              </Carousel.Item>
            ))}
          </Carousel>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn_common btn_cancel"
            onClick={Back}
          >
            <FormattedMessage id="Go Back" SOP />
            {/*取消*/}
          </button>

          <button
            type="button"
            className="btn btn_common btn_cancel"
            onClick={closeModal}
          >
            <FormattedMessage id="Cancel" SOP />
            {/*取消*/}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

function ProductionSopModel({ show, closeModal, Data, error, setError }) {
  const [video, setVideoModal] = useState(false);
  const [img, setImgModal] = useState(false);
  const [modal, setModal] = useState(true);

  const [imgUrl, setImgUrl] = useState([]);
  const [videoUrl, setVideoUrl] = useState([]);
  const [refresh, setRefresh] = useState(true);

  const imgModalClick = () => {
    const sopFilter = Data.children[0].children.filter(
      (i) => i.name === "ProductionSop"
    );

    const imgFilter = sopFilter[0].children.find((x) => x.name === "Image");

    setImgUrl(imgFilter.children);
    setRefresh(!refresh);
    setModal(false);
    setImgModal(true);
  };

  const videoModalClick = () => {
    const sopFilter = Data.children[0].children.filter(
      (i) => i.name === "ProductionSop"
    );
    const videoFilter = sopFilter[0].children.find((x) => x.name === "Video");

    setVideoUrl(videoFilter.children);
    setRefresh(!refresh);
    setModal(false);
    setVideoModal(true);
  };
  const clearState = () => {
    setModal(false);
    setVideoModal(false);
    setImgModal(false);
    setError(false);
  };
  //Go back
  const Back = () => {
    setImgModal(false);
    setVideoModal(false);
    setModal(true);
  };

  React.useEffect(() => {
    if (show) {
      setModal(show);
    } else {
      clearState();
    }
  }, [show]);

  return (
    <>
      {
        //Modal select type
      }{" "}
      <Modal
        className="modal_style fade"
        size="lg"
        centered
        show={modal}
        onHide={closeModal}
        style={{ zIndex: 2000 }}
      >
        <Modal.Header closeButton>
          <Modal.Title as="h5">
            <b>
              <FormattedMessage id="SelectType" />
            </b>
            {error ? <Alert severity="error">error</Alert> : null}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Button
            type="button"
            variant="outline-secondary"
            style={{ height: "15vh", width: "10vw", marginRight: "10px" }}
            className="btn_videoclick"
            onClick={videoModalClick}
          >
            <FormattedMessage id="Video" />
            {/*取消*/}
          </Button>
          <Button
            type="button"
            variant="outline-secondary"
            style={{ height: "15vh", width: "10vw" }}
            className="btn_imgClick"
            onClick={imgModalClick}
          >
            <FormattedMessage id="Image" />
            {/*取消*/}
          </Button>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn_common btn_cancel"
            onClick={closeModal}
          >
            <FormattedMessage id="Cancel" SOP />
            {/*取消*/}
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        className="modal_style fade"
        size="lg"
        centered
        style={{ zIndex: 2000 }}
        show={video}
        onHide={closeModal}
      >
        <Modal.Header closeButton>
          <Modal.Title as="h5">
            <FormattedMessage id="Video" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {videoUrl.map((i) => (
            <video width="720" height="480" controls>
              <source src={i.url} type="video/mp4" />
            </video>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn_common btn_cancel"
            onClick={Back}
          >
            <FormattedMessage id="Go Back" SOP />
            {/*取消*/}
          </button>

          <button
            type="button"
            className="btn btn_common btn_cancel"
            onClick={closeModal}
          >
            <FormattedMessage id="Cancel" SOP />
            {/*取消*/}
          </button>
        </Modal.Footer>
      </Modal>
      {/*img */}
      <Modal
        className="modal_style fade"
        size="lg"
        centered
        style={{ zIndex: 2000 }}
        show={img}
        onHide={closeModal}
      >
        <Modal.Header closeButton>
          <Modal.Title as="h5">
            <FormattedMessage id="Image" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Carousel>
            {imgUrl.map((i) => (
              <Carousel.Item>
                <img className="d-block w-100" src={i.url} alt="First slide" />
                <Carousel.Caption>
                  <h3>{i.name}</h3>
                </Carousel.Caption>
              </Carousel.Item>
            ))}
          </Carousel>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn_common btn_cancel"
            onClick={Back}
          >
            <FormattedMessage id="Go Back" SOP />
            {/*取消*/}
          </button>

          <button
            type="button"
            className="btn btn_common btn_cancel"
            onClick={closeModal}
          >
            <FormattedMessage id="Cancel" SOP />
            {/*取消*/}
          </button>
        </Modal.Footer>
      </Modal>
    </>
    //Modal movie

    //Modal picture
  );
}

function WaterwayModal({ show, closeModal, Data, error, setError }) {
  const [img, setImgModal] = useState(false);
  const [modal, setModal] = useState(true);
  const [documentModal, setDocumentModal] = useState(false);
  const [showDoc, setShowDoc] = useState(false);
  const [pdf, setPdf] = useState([]);
  const [refresh, setRefresh] = useState(true);
  const [imgUrl, setImgUrl] = useState([]);
  const [numberDoc, setNumberDoc] = useState("");

  const documentModalClick = () => {
    const waterFilter = Data.children[0].children.filter(
      (i) => i.name === "MoldWaterWayConnection"
    );
    const documentFilter = waterFilter[0].children.find(
      (x) => x.name === "Document"
    );

    setPdf(documentFilter.children);
    setModal(false);
    setDocumentModal(true);
  };
  const imgModalClick = () => {
    const waterFilter = Data.children[0].children.filter(
      (i) => i.name === "MoldWaterWayConnection"
    );
    const imgFilter = waterFilter[0].children.find((x) => x.name === "Image");

    setImgUrl(imgFilter.children);
    setRefresh(!refresh);
    setModal(false);
    setImgModal(true);
  };
  const selectDoc = (e) => {
    setNumberDoc(e.target.value);
    setDocumentModal(false);
    setShowDoc(true);
  };

  //open all modal
  const clearState = () => {
    setModal(false);
    setDocumentModal(false);
    setImgModal(false);
    setShowDoc(false);
    setError(false);
  };
  //Go back
  const backToSelectPdf = () => {
    setShowDoc(false);
    setDocumentModal(true);
  };
  const Back = () => {
    setDocumentModal(false);
    setImgModal(false);
    setModal(true);
  };

  let setArr = [];
  for (let i in pdf) {
    setArr.push(i);
  }
  React.useEffect(() => {
    if (show) {
      setModal(show);
    } else {
      clearState();
    }
  }, [show]);

  return (
    <>
      {/* //Modal select type*/}
      <Modal
        className="modal_style fade"
        size="lg"
        centered
        show={modal}
        onHide={closeModal}
        style={{ zIndex: 2000 }}
      >
        <Modal.Header closeButton>
          <Modal.Title as="h5">
            <b>
              <FormattedMessage id="SelectType" />
            </b>
            {error ? <Alert severity="error">error</Alert> : null}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Button
            type="button"
            variant="outline-secondary"
            style={{ height: "15vh", width: "10vw", marginRight: "10px" }}
            className="btn_Documentclick"
            onClick={documentModalClick}
          >
            <FormattedMessage id="Document" />
            {/*取消*/}
          </Button>
          <Button
            type="button"
            variant="outline-secondary"
            style={{ height: "15vh", width: "10vw" }}
            className="btn_imgClick"
            onClick={imgModalClick}
          >
            <FormattedMessage id="Image" />
            {/*取消*/}
          </Button>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn_common btn_cancel"
            onClick={closeModal}
          >
            <FormattedMessage id="Cancel" SOP />
            {/*取消*/}
          </button>
        </Modal.Footer>
      </Modal>

      {/*img */}
      <Modal
        className="modal_style fade"
        size="lg"
        centered
        style={{ zIndex: 2000 }}
        show={img}
        onHide={closeModal}
      >
        <Modal.Header closeButton>
          <Modal.Title as="h5">
            <FormattedMessage id="Image" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Carousel>
            {imgUrl.map((i) => (
              <Carousel.Item>
                <img className="d-block w-100" src={i.url} alt="First slide" />
                <Carousel.Caption>
                  <h3>{i.name}</h3>
                </Carousel.Caption>
              </Carousel.Item>
            ))}
          </Carousel>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn_common btn_cancel"
            onClick={Back}
          >
            <FormattedMessage id="Go Back" SOP />
            {/*取消*/}
          </button>

          <button
            type="button"
            className="btn btn_common btn_cancel"
            onClick={closeModal}
          >
            <FormattedMessage id="Cancel" SOP />
            {/*取消*/}
          </button>
        </Modal.Footer>
      </Modal>
      {/*select Document */}

      <Modal
        className="modal_style fade"
        size="lg"
        centered
        show={documentModal}
        onHide={closeModal}
        style={{ zIndex: 2000 }}
      >
        <Modal.Header closeButton>
          <Modal.Title as="h5">
            <FormattedMessage id="SelectDocument" SOP />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul className="slt_times list-unstyled mb-0">
            {setArr.map((item, i) => (
              <button
                key={i}
                value={item}
                onClick={selectDoc}
                style={{ height: "10vh", width: "5vw", marginRight: "10px" }}
              >
                {item}
              </button>
            ))}
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn_common btn_cancel"
            onClick={Back}
          >
            <FormattedMessage id="Go Back" SOP />
            {/*go back*/}
          </button>

          <button
            type="button"
            className="btn btn_common btn_cancel"
            onClick={closeModal}
          >
            <FormattedMessage id="Cancel" SOP />
            {/*取消*/}
          </button>
        </Modal.Footer>
      </Modal>
      {/* show Document */}

      <Modal
        className="modal_style fade"
        size="lg"
        centered
        style={{ zIndex: 2000 }}
        show={showDoc}
        onHide={closeModal}
      >
        <Modal.Header closeButton>
          <Modal.Title as="h5">
            <FormattedMessage id="Document" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {showDoc ? (
            <iframe src={pdf[numberDoc].url} width="100%" height="500px" />
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn_common btn_cancel"
            onClick={backToSelectPdf}
          >
            <FormattedMessage id="Go Back" SOP />
          </button>

          <button
            type="button"
            className="btn btn_common btn_cancel"
            onClick={closeModal}
          >
            <FormattedMessage id="Cancel" SOP />
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export {
  OrderFinishModal,
  OrderStartModal,
  AddBadOrderModal,
  QualitySipModel,
  ProductionSopModel,
  WaterwayModal,
};
