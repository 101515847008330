import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { RootTabs, FactoryMenu } from "../../component/root/Root";
import { FactoryTable, InputModal } from "../../component/root/factory";
import { NewButton, DeleteModal, handleError } from "../../component/common";
import { deleteFactory } from "../../api.js";

export default function Factory({ checkEditAuth }) {
  const [modal, setModal] = useState("");

  const [select, setSelect] = useState({});
  const intl = useIntl();

  const showModal = (modal, item = {}) => {
    if (!checkEditAuth()) {
      return;
    }
    setSelect(item);
    setModal(modal);
  };

  const onHide = () => {
    setModal(null);
  };

  const deleteData = () => {
    deleteFactory(select.id)
      .then(onHide)
      .catch((err) => handleError(err, intl));
  };

  return (
    <div>
      <RootTabs tab="0" />
      <div className="page-content shadow-lg">
        <div className="jc-end">
          <FactoryMenu tab="0" />
        </div>
        <hr />
        <div className="jc-space-between ai-center">
          <h4 className="m-0">
            <b>
              <FormattedMessage id="FactoryList" />
            </b>
          </h4>
          <NewButton
            onClick={() => showModal("new")}
            text={intl.formatMessage(
              { id: "Create" },
              { name: intl.formatMessage({ id: "Factory" }) }
            )}
          />
        </div>
        <hr />
        <FactoryTable showModal={showModal} modal={modal} />
      </div>
      <InputModal modal={modal} data={select} onHide={onHide} />
      <DeleteModal
        show={modal === "delete"}
        onHide={onHide}
        name={select.name}
        type="Factory"
        callback={deleteData}
      />
    </div>
  );
}
