const pes = {
  Ideal: "未排定",
  Waiting: "待生產",
  Busy: "生產中",

  //生產執行系統-生產管理
  ProductionManagement: "生產管理",
  OrderRunning: "進行中工單",
  NoOrderRunning: "無工單運行中",
  NextOrder: "下一張工單",
  NoNextOrder: "目前無下一張工單",
  ClickToComplete: "按此完成",
  OrderDoneIn24Hours: "24小時內完成的工單",
  NoOrderDone: "目前無任何工單已完成",
  ClickToStart: "按此開始",
  FinishManually: "手動完成",
  FinishManuallyConfirm: "確定要手動完成工單{name}嗎？",
  StartManualy: "手動開始",
  StartManuallyConfirm: "確定要手動開始工單{name}嗎？",
  StartManuallyRemark: "提醒：工單將於送出確認後1分鐘開始生產",
  StartPauseConfirm: "確定要{action}工單{name}嗎？",
  ChangeStatus: "變更狀態",
  Split: "拆單",
  SplitThe: "拆分{name}",
  product_num: "產品編號",
  MoldNumberUsed: "使用模具編號",

  //生產執行系統-不良管理
  DefectiveManagement: "不良品管理",
  DefectiveList: "不良品列表",
  Defect: "不良原因",
  Defective: "不良品",
  date: "日期",
  product_number: "產品編號",
  time: "時間",
  order_id: "工單",
  product_count: "數量",
  bad_reason: "不良原因",

  //生產執行系統-停機管理
  DowntimeManagement: "停機管理",
  DowntimeList: "停機原因列表",
  DowntimeReason: "停機原因",
  DowntimeReasonClassification: "停機原因類別",
  BatchEditDowntimeReason: "批次更改停機原因",
  DowntimeReasonFirst: "請先至停機原因管理設立停機原因",
  Remark: "備註",
  Action: "動作",
  MergeThe: "合併{name}",
  Merge: "合併",
  Add: "新增",
  PleaseInsertTime: "請輸入時間",
  Fix: "修改",
  Mode: "模式",

  //生產執行系統-交接報告
  HandoverReport: "交接報告",
  shift: "班別",
  DeviceNumber: "機台編號",
  AverageOEE: "平均OEE",
  description: "說明",
  PersonInCharge: "負責人",
  Edit: "編輯",
  Availability: "稼動率",
  Performance: "效率",
  Yield: "良率",
  PlannedProductionQuantity: "計畫生產數量",
  actual_quantity: "實際生產數量",
  Factory: "廠區",
  date: "日期",
  deviceIDList: "機台編號",

  MoldDifferent: "使用不同模具",
  MaterialDifferent: "使用不同材料",
};

export default pes;
