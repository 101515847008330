import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { FormattedMessage, useIntl } from "react-intl";
import { Table } from '../common';

function BadChart({ data: { BadReasonDescriptionList, BadReasonPlatoDataList, BadReasonProductDataList } }) {
	const productList = Object.keys(BadReasonProductDataList);
	let series = [{
		name: '柏拉圖',
		type: 'line',
		color: '#e62F34',
		data: BadReasonPlatoDataList.map(val => val[1]),
		zIndex: 2,
	}];

	productList.forEach(product => {
		series.push({
			name: product,
			type: "column",
			data: BadReasonProductDataList[product].map((item, i) => ({ name: BadReasonDescriptionList[i], y: item })),
			yAxis: 1,
			dataLabels: {
				enabled: true,
				color: '#ffffff',
				align: 'center',
			},
		})
	});

	const options = {
		chart: { zoomType: 'xy' },
		title: null,
		exporting: { enabled: false },
		credits: { enabled: false },
		xAxis: {
			type: 'category',
			categories: BadReasonDescriptionList,
		},
		plotOptions: {
			column: {
				stacking: 'normal'
			}
		},
		yAxis: [
			{
				opposite: true,
				labels: {
					format: '{value} %',
					align: 'right',
					x: -3,
					y: 16
				},
				title: null
			},
			{
				labels: {
					formatter: function () {
						if (this.value >= 1000) return Highcharts.numberFormat(this.value / 1000, 1) + "K";  // maybe only switch if > 1000
						return Highcharts.numberFormat(this.value, 0);
					},
					align: 'left',
					x: 3,
					y: 16
				},
				title: null
			},
		],
		series: series
	}

	return (
		<HighchartsReact
			highcharts={Highcharts}
			options={options}
		/>
	)
}

function BadTable({ data }) {
	const intl = useIntl();
	const columns = [
		{
			Header: <FormattedMessage id="order_num_name" />/*工單編號*/,
			accessor: 'num',
			Filter: ''
		},
		{
			Header: <FormattedMessage id="product_num" />/*產品編號*/,
			accessor: 'ptnum',
			Filter: ''
		},
		{
			Header: <FormattedMessage id="product_name" />/*產品名稱*/,
			accessor: 'name',
			Filter: ''
		},
		{
			Header: <FormattedMessage id="Defect" />/*不良原因*/,
			accessor: 'reason',
			Filter: ''
		},
		{
			Header: intl.formatMessage({ id: 'Defective' }) + intl.formatMessage({ id: 'Quantity' }),
			accessor: 'count',
			Filter: ''
		},
		{
			Header: <FormattedMessage id="Percentage" />/*百分比*/,
			accessor: 'percent',
			Filter: ''
		}
	];

	const display = data.map(item => ({
		...item,
		percent: item.percent + '%'
	}))

	return (
		<Table columns={columns} data={display} />
	)
}

export { BadChart, BadTable }