import React, { useEffect, useState, useRef } from "react";
import { useIntl } from "react-intl";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Collapse from "@material-ui/core/Collapse";
import Chip from "@material-ui/core/Chip";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Divider from "@material-ui/core/Divider";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Alert from "@material-ui/lab/Alert";
import { MdFolderOpen } from "react-icons/md";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { useDrop, useDrag } from "react-dnd";
import { Tabs } from "../common";
import { showWarn } from "../../function";
import moment from "moment";

export function ManagementTabs({ tab }) {
  const list = [
    { to: "/pages/management/order", name: "OrderManagement" },
    { to: "/pages/management/schedule", name: "OrderScheduling" },
    { to: "/pages/management/material", name: "MaterialManagement" },
    { to: "/pages/management/mold", name: "MoldManagement" },
    { to: "/pages/management/product", name: "ProductManagement" },
    { to: "/pages/management/shift", name: "ShiftManagement" },
    { to: "/pages/management/holiday", name: "HolidayManagement" },
    { to: "/pages/management/defect", name: "DefectManagement" },
    { to: "/pages/management/downtime", name: "DowntimeReasonManagement" },
    {
      to: "/pages/management/unproductive",
      name: "UnproductiveOrderReasonManagement",
    },
  ];
  return <Tabs list={list} tab={tab} />;
}

export function SuggestionInput({
  options,
  value,
  disabled,
  name,
  onChange,
  id,
}) {
  const intl = useIntl();
  const remove = (i) => {
    value.splice(i, 1);
    onChange(value);
  };
  return (
    <Autocomplete
      multiple
      options={options.map(({ value }) => value)}
      getOptionLabel={(id) => options.find(({ value }) => id === value)?.label}
      value={value}
      onChange={(_, val) => onChange(val)}
      disabled={disabled}
      renderInput={(params) => (
        <React.Fragment>
          <div
            ref={params.InputProps.ref}
            id={id}
            className="form-control ai-center"
            disabled={disabled}
            style={{ paddingTop: 0, paddingBottom: 0 }}
          >
            {value.map((id, i) => {
              const result = options.find(({ value }) => id === value);
              if (result) {
                return (
                  <Chip
                    key={result.value}
                    label={result.label}
                    size="small"
                    className="mr-2"
                    onDelete={disabled ? undefined : () => remove(i)}
                  />
                );
              } else {
                return null;
              }
            })}
            <input
              {...params.inputProps}
              placeholder={
                value.length === 0
                  ? intl.formatMessage(
                      { id: "PickA" },
                      {
                        name: intl.formatMessage({
                          id: name,
                        }),
                      }
                    )
                  : ""
              }
              style={{ border: "none", backgroundColor: "transparent" }}
            />
          </div>
        </React.Fragment>
      )}
    />
  );
}

export function FileInput({ onChange, placeholder, label, id, accept }) {
  return (
    <div className="position-relative" id={id}>
      <div className="position-relative ai-center">
        <Form.File
          onChange={onChange}
          label={label ? label : placeholder}
          accept={accept}
          custom
        />
        <label className="position-absolute center m-0" htmlFor={id}>
          <MdFolderOpen size="20px" />
        </label>
      </div>
    </div>
  );
}

export const InputModalBase = ({
  show,
  onHide,
  title,
  columns,
  select,
  setSelect,
  submit,
  expand,
  setExpand,
  hasDelete,
  deleteData,
}) => {
  const intl = useIntl();
  const [error, setError] = useState("");

  useEffect(() => {
    if (!show) {
      setError("");
    }
  }, [show]);

  const onChange = (key, val) => {
    setSelect((prev) => ({ ...prev, [key]: val }));
  };

  const onCheck = (key, val) => {
    let list = select[key].slice();
    const index = list.indexOf(val);
    if (index > -1) {
      list.splice(index, 1);
    } else {
      list.push(val);
    }
    onChange(key, list);
  };

  const pickFile = (e) => {
    const { id, files } = e.target;
    if (files.length > 0) {
      const file = files[0];
      onChange(id, file);
    }
  };

  const check = () => {
    document.querySelectorAll(".warn").forEach((item) => (item.hidden = true));
    let pass = true;
    columns.forEach(({ id, type, required, extensions, max, min }) => {
      if (
        required &&
        (!select[id] || select[id] === "null" || select[id].length === 0)
      ) {
        showWarn(`${id}-warn`, "Required", intl);
        pass = false;
      } else if (
        select[id] &&
        (type === "date" || type === "datetime") &&
        !moment(select[id]).isValid()
      ) {
        showWarn(`${id}-warn`, "WrongFormat", intl);
        pass = false;
      } else if (
        type === "file" &&
        select[id]?.name &&
        extensions.indexOf(
          select[id].name.slice(
            ((select[id].name.lastIndexOf(".") - 1) >>> 0) + 2
          )
        ) === -1
      ) {
        showWarn(`${id}-warn`, "WrongFileType", intl);
        pass = false;
      } else if (min && select[id] < min) {
        showWarn(
          `${id}-warn`,
          intl.formatMessage(
            { id: "ShouldBeLargerThen" },
            { a: intl.formatMessage({ id }), b: min }
          )
        );
        pass = false;
      } else if (max && select[id] > max) {
        showWarn(
          `${id}-warn`,
          intl.formatMessage(
            { id: "ShouldBeLargerThen" },
            { a: intl.formatMessage({ id }), b: max }
          )
        );
        pass = false;
      }
    });
    if (pass) {
      submit()
        .then(onHide)
        .catch((e) => {
          if (e.response) {
            if (e.response.data.errors) {
              setError(e.response.data.errors[0]?.msg);
            } else {
              setError(
                intl.formatMessage({ id: e.response.status.toString() })
              );
            }
          } else {
            console.log(e);
          }
        });
    }
  };

  return (
    <Modal className="modal_style fade" size="lg" show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title as="h5">
          <b>{title}</b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {columns.map(
            ({
              id,
              type,
              options,
              disabled,
              remark,
              callback,
              visable,
              onClick,
              min,
              max,
            }) => {
              if (type === "text" || type === "number" || type === "password") {
                return (
                  <Form.Group key={id} as={Row} controlId={id}>
                    <Form.Label column lg={3} md={4}>
                      {intl.formatMessage({ id })}
                    </Form.Label>
                    <Col lg={9} md={8}>
                      <div className="ai-center">
                        <Form.Control
                          onChange={(e) =>
                            onChange(
                              id,
                              type === "number"
                                ? parseFloat(e.target.value)
                                : e.target.value
                            )
                          }
                          value={select[id] ? select[id] : ""}
                          placeholder={intl.formatMessage(
                            { id: "InputThe" },
                            { name: intl.formatMessage({ id }) }
                          )}
                          type={type === "password" && visable ? "text" : type}
                          disabled={disabled}
                          min={min}
                          max={max}
                        />
                        {type === "password" ? (
                          visable ? (
                            <VisibilityOffIcon
                              className="ml-2"
                              onClick={onClick}
                            />
                          ) : (
                            <VisibilityIcon
                              className="ml-2"
                              onClick={onClick}
                            />
                          )
                        ) : null}
                      </div>
                      {remark ? (
                        <Form.Text muted>
                          {intl.formatMessage({ id: remark })}
                        </Form.Text>
                      ) : null}
                      <span id={`${id}-warn`} className="warn" />
                    </Col>
                  </Form.Group>
                );
              }
              if (type === "select") {
                return (
                  <Form.Group key={id} as={Row} controlId={id}>
                    <Form.Label column lg={3} md={4}>
                      {intl.formatMessage({ id })}
                    </Form.Label>
                    <Col lg={9} md={8}>
                      <Form.Control
                        as="select"
                        onChange={(e) => onChange(id, e.target.value)}
                        value={select[id] ? select[id] : "null"}
                        disabled={disabled}
                      >
                        <option value="null">
                          {intl.formatMessage(
                            { id: "PickA" },
                            { name: intl.formatMessage({ id }) }
                          )}
                        </option>
                        {options.map(({ label, value }) => (
                          <option key={value} value={value}>
                            {label}
                          </option>
                        ))}
                      </Form.Control>
                      <span id={`${id}-warn`} className="warn" />
                    </Col>
                  </Form.Group>
                );
              }
              if (type === "multi_select") {
                return (
                  <Form.Group key={id} as={Row} controlId={id}>
                    <Form.Label column lg={3} md={4}>
                      {intl.formatMessage({ id })}
                    </Form.Label>
                    <Col lg={9} md={8}>
                      <SuggestionInput
                        id={id}
                        options={options}
                        value={select[id]}
                        onChange={(val) => onChange(id, val)}
                        name={id}
                      />
                      <span id={`${id}-warn`} className="warn" />
                    </Col>
                  </Form.Group>
                );
              }
              if (type === "check_box") {
                return (
                  <Form.Check
                    key={id}
                    id={id}
                    label={intl.formatMessage({ id })}
                    checked={select[id] ? select[id] : false}
                    onChange={() => onChange(id, !select[id])}
                  />
                );
              }
              if (type === "check") {
                return (
                  <Form.Group key={id} as={Row}>
                    <Form.Label column lg={3} md={4}>
                      {intl.formatMessage({ id })}
                    </Form.Label>
                    <Col lg={9} md={8}>
                      {options.map(({ label, value }) => (
                        <Form.Check
                          key={value}
                          label={label}
                          onChange={() => onChange(id, value)}
                          checked={select[id] === value}
                        />
                      ))}
                      <span id={`${id}-warn`} className="warn" />
                    </Col>
                  </Form.Group>
                );
              }
              if (type === "multi_check") {
                return (
                  <Form.Group key={id} as={Row}>
                    <Form.Label column lg={3} md={4}>
                      {intl.formatMessage({ id })}
                    </Form.Label>
                    <Col lg={9} md={8}>
                      {options.map(({ label, value }) => (
                        <Form.Check
                          key={value}
                          label={label}
                          onChange={() => onCheck(id, value)}
                          checked={select[id].indexOf(value) > -1}
                        />
                      ))}
                      <span className="warn" />
                    </Col>
                  </Form.Group>
                );
              }
              if (type === "date" || type === "datetime") {
                const format =
                  type === "datetime" ? "YYYY-MM-DD HH:mm" : "YYYY-MM-DD";
                return (
                  <Form.Group key={id} as={Row} controlId={id}>
                    <Form.Label column lg={3} md={4}>
                      {intl.formatMessage({ id })}
                    </Form.Label>
                    <Col lg={9} md={8}>
                      <DateRangePicker
                        initialSettings={{
                          singleDatePicker: true,
                          timePicker: type === "datetime",
                          autoApply: true,
                          startDate: select[id],
                          locale: { format },
                          parentEl: ".modal_style",
                          autoUpdateInput: false,
                        }}
                        onApply={(_, p) =>
                          onChange(id, p.startDate.format(format))
                        }
                      >
                        <Form.Control
                          onChange={(e) => onChange(id, e.target.id)}
                          value={select[id]}
                          placeholder={intl.formatMessage(
                            { id: "InputThe" },
                            {
                              name: intl.formatMessage({ id }),
                            }
                          )}
                          disabled={disabled}
                        />
                      </DateRangePicker>
                      <span id={`${id}-warn`} className="warn" />
                    </Col>
                  </Form.Group>
                );
              }
              if (type === "file") {
                return (
                  <Form.Group key={id} as={Row} controlId={id}>
                    <Form.Label column lg={3} md={4}>
                      {intl.formatMessage(
                        { id: "Upload" },
                        { name: intl.formatMessage({ id }) }
                      )}
                    </Form.Label>
                    <Col lg={9} md={8}>
                      <FileInput
                        label={select[id]?.name}
                        placeholder={intl.formatMessage(
                          { id: "PickA" },
                          { name: intl.formatMessage({ id: "File" }) }
                        )}
                        onChange={pickFile}
                      />
                      {remark ? (
                        <Form.Text muted>
                          {intl.formatMessage({ id: remark })}
                        </Form.Text>
                      ) : null}
                      <span id={`${id}-warn`} className="warn" />
                    </Col>
                  </Form.Group>
                );
              }
              if (type === "sort") {
                if (options.length > 0) {
                  return (
                    <Form.Group key={id} as={Row}>
                      <Form.Label column lg={3} md={4}>
                        {intl.formatMessage({ id: "Sort" })}
                      </Form.Label>
                      <Col lg={9} md={8}>
                        <List className="border">
                          {options.map(({ label, value }, i) => (
                            <DragCard
                              key={value}
                              id={value}
                              text={label}
                              index={i}
                              callback={callback}
                              devider={i !== options.length - 1}
                            />
                          ))}
                        </List>
                      </Col>
                    </Form.Group>
                  );
                } else {
                  return null;
                }
              }
            }
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer className="jc-space-between ai-center">
        <Collapse in={error !== ""}>
          <Alert severity="error">{error}</Alert>
        </Collapse>
        <div className="ai-center">
          {expand !== undefined ? (
            <button className="btn btn-more" onClick={() => setExpand(!expand)}>
              {intl.formatMessage({ id: "AdvancedSettings" })}
            </button>
          ) : null}
          {hasDelete ? (
            <button className="btn btn-delete" onClick={deleteData}>
              {intl.formatMessage({ id: "Delete" })}
            </button>
          ) : null}
          <button type="submit" className="btn btn-confirm" onClick={check}>
            {intl.formatMessage({ id: "Confirm" })}
          </button>
          <button className="btn btn-cancel" onClick={onHide}>
            {intl.formatMessage({ id: "Cancel" })}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

const DragCard = ({ id, text, index, callback, devider }) => {
  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: "card",
    collect: (monitor) => ({
      handlerId: monitor.getHandlerId(),
    }),
    hover: (item, monitor) => {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      callback(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: "card",
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  return (
    <React.Fragment>
      <ListItem
        ref={ref}
        style={{ cursor: "move", opacity }}
        data-handler-id={handlerId}
      >
        <ListItemIcon>
          <DragHandleIcon />
        </ListItemIcon>
        <ListItemText primary={text} />
      </ListItem>
      {devider ? <Divider /> : null}
    </React.Fragment>
  );
};
