const quality = {
  All: "全部",
  Item: "項目",
  Date: "日期",
  NumberofSample: "樣本數",
  NumberofGroup: "組數",
  CenterLineX: "平均中心線CLx",
  UpperControlLimitX: "平均上管制線UCLx",
  LowerControlLimitX: "平均下管制線LCLx",
  CenterLineR: "全距中心線CLr",
  UpperControlLimitR: "全距上管制線UCLr",
  LowerControlLimitR: "全距下管制線LCLr",
  Monitor: "監測",
  Edit: "編輯",
  ModifyParameters: "修改參數",
  ChooseMold: "選擇模具",
  ChooseColumn: "選擇欄位",
  Execute: "執行",
  CycleTime: "週期時間"
};

export default quality;
