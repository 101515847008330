import React, { useEffect, useState, useContext } from "react";
import moment from "moment";
import ClipLoader from "react-spinners/ClipLoader";
import { FormattedMessage, useIntl } from "react-intl";
import {
  DateRadio,
  SingleDatePicker,
  MultiDropdownPicker,
  RangeDatePicker,
} from "../../component/common";
import {
  AnalysisTabs,
  ParameterMenu,
  handleRequest,
} from "../../component/analysis/Analysis";
import { ParameterTable } from "../../component/analysis/Parameter";
import {
  apiGetAnalysisProcessDataList,
  apiDownloadAnalysisProcessExcel,
  baseURL,
  apiGetDeviceNameList,
  apiGetAnalysisProcessProductList,
} from "../../api.js";
import Context from "../../context";

export default function AnalysisProcess() {
  const today = moment().format("YYYY-MM-DD");
  const yesterday = moment().subtract(1, "d").format("YYYY-MM-DD");
  const [date, setDate] = useState({
    startDate: today,
  });
  const [deviceList, setDeviceList] = useState([]);
  const [checkedDevice, setCheckedDevice] = useState([]);
  const [productList, setProductList] = useState([]);
  const [checkedProduct, setCheckedProduct] = useState([]);
  const [sort, setSort] = useState("date");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const intl = useIntl();
  const { factory } = useContext(Context);

  useEffect(() => {
    apiGetDeviceNameList({
      FactoryID: factory,
    }).then((res) => setDeviceList(res.data));
  }, []);

  useEffect(() => {
    if (sort === "date") {
      setDate({
        startDate: today,
      });
    } else if (sort === "interval") {
      setDate({
        startDate: moment().format("YYYY-MM-DD HH:mm:ss"),
        endDate: moment().format("YYYY-MM-DD HH:mm:ss"),
      });
    }
  }, [sort]);

  useEffect(() => {
    if (checkedDevice.length !== 0) {
      let req = {
        FactoryID: factory,
        DeviceID: checkedDevice,
      };
      req = handleRequest(req, sort, date);
      apiGetAnalysisProcessProductList(req)
        .then((res) => setProductList(res.data.data))
        .catch(console.log);
    }
  }, [checkedDevice, date]);

  useEffect(() => {
    if (checkedDevice.length !== 0 && checkedProduct.length !== 0) {
      setLoading(true);
      loadData();
      const temp = setInterval(() => loadData, 30000);
      return () => clearInterval(temp);
    }
  }, [checkedDevice, checkedProduct, date]);

  const loadData = () => {
    let req = {
      FactoryID: factory,
      DeviceID: checkedDevice,
      CheckProduct: checkedProduct,
    };
    req = handleRequest(req, sort, date);
    apiGetAnalysisProcessDataList(req)
      .then((res) => {
        setData(res.data.data);
        setLoading(false);
      })
      .catch(console.log);
  };

  const pickDateRange = (startDate, endDate) => {
    setDate((prev) => ({ ...prev, startDate, endDate }));
  };

  const downloadExcel = () => {
    const language = localStorage.getItem("locale");
    let req = {
      FactoryID: factory,
      DeviceID: checkedDevice,
      CheckProduct: checkedProduct,
      Language: language ? language : "zh",
    };
    req = handleRequest(req, sort, date);
    apiDownloadAnalysisProcessExcel(req)
      .then((res) => window.open(`${baseURL}/${res.data}`))
      .catch(console.log);
  };

  let columns = [];
  if (data.length > 0) {
    const keys = Object.keys(data[0]);
    const typeArr = [
      "sp_forward",
      "sv_forward",
      "ss_forward",
      "sp_backward",
      "sv_backward",
      "ss_backward",
      "step_forward",
      "step_backward",
      "forward_count",
    ];
    const numberArr = ["blow_mode", "blow_position", "blow_delay", "blow_time"];
    const heads = ["a2", "b", "b2"];
    columns = keys.map((id) => {
      let name;
      const lastChar = id.substr(id.length - 1);
      const withNum = !isNaN(parseInt(lastChar));
      let key = id.replace("a2_", "").replace("b_", "").replace("b2_", "");
      if (withNum) {
        key = key.substr(0, key.length - 1);
      }
      if (
        keys.indexOf(`a2_${key}`) > -1 ||
        keys.indexOf(`b_${key}`) > -1 ||
        keys.indexOf(`b2_${key}`) > -1
      ) {
        const head =
          heads.indexOf(id.split("_")[0]) > -1 ? id.split("_")[0] : "a";
        if (typeArr.some((e) => key.includes(e))) {
          name = intl.formatMessage({ id: key }, { type: head.toUpperCase() });
        } else {
          name = intl.formatMessage(
            { id: head },
            { name: intl.formatMessage({ id: key }) }
          );
        }
        if (withNum) {
          name += lastChar;
        }
      } else if (numberArr.some((e) => key.startsWith(e))) {
        if (withNum) {
          name = intl.formatMessage({ id: key }, { number: lastChar });
        } else {
          name = intl.formatMessage({ id }, { number: "1" });
        }
      } else if (withNum) {
        name = intl.formatMessage({ id: key }) + lastChar;
      } else {
        name = intl.formatMessage({ id });
      }
      return { id, name };
    });
    const front = [
      "created_at",
      "device_name",
      "product_num",
      "product_name",
      "mold_num",
    ];
    columns = [
      ...front.map((id) => ({ id, name: intl.formatMessage({ id }) })),
      ...columns.filter(({ id }) => front.indexOf(id) === -1 && id !== "daily"),
    ];
  }

  return (
    <div>
      <AnalysisTabs tab="2" />
      <div className="page-content shadow-lg">
        <div className="jc-end">
          <ParameterMenu tab="1" />
        </div>
        <hr />
        <div className="ai-stretch">
          <DateRadio
            style={{ marginRight: "20px" }}
            list={[
              { label: <FormattedMessage id="Date" />, value: "date" },
              { label: <FormattedMessage id="Interval" />, value: "interval" },
            ]}
            value={sort}
            onChange={(e) => setSort(e.target.value)}
          />
          {sort === "date" ? (
            <React.Fragment>
              <DateRadio
                style={{ marginRight: "20px" }}
                list={[
                  { label: <FormattedMessage id="Today" />, value: today },
                  {
                    label: <FormattedMessage id="Yesterday" />,
                    value: yesterday,
                  },
                ]}
                value={date.startDate}
                onChange={(e) => setDate(e.target.value)}
              />
              <SingleDatePicker
                style={{ marginRight: "20px" }}
                date={date.startDate}
                onApply={(_, startDate) => setDate({ startDate })}
              />
            </React.Fragment>
          ) : (
            <RangeDatePicker
              startDate={date.startDate}
              endDate={date.endDate}
              timePicker={true}
              onApply={pickDateRange}
            />
          )}
        </div>
        <hr />
        <div className="jc-space-between ai-stretch">
          <button className="btn-gray" onClick={downloadExcel}>
            <FormattedMessage id="DownloadExcel" />
          </button>
          <div className="ai-stretch">
            <MultiDropdownPicker
              title={intl.formatMessage(
                { id: "PickA" },
                { name: intl.formatMessage({ id: "Device" }) }
              )}
              list={deviceList.map(({ name, id }) => ({
                label: name,
                value: id,
              }))}
              selected={checkedDevice}
              onApply={setCheckedDevice}
              style={{ marginRight: "20px" }}
            />
            <MultiDropdownPicker
              title={intl.formatMessage(
                { id: "PickA" },
                { name: intl.formatMessage({ id: "Product" }) }
              )}
              list={productList.map(({ name, id }) => ({
                label: name,
                value: id,
              }))}
              selected={checkedProduct}
              onApply={setCheckedProduct}
            />
          </div>
        </div>
        <hr />
        {loading ? (
          <div className="center" style={{ width: "100%" }}>
            <ClipLoader size={50} color="#123abc" loading={loading} />
          </div>
        ) : (
          <ParameterTable columns={columns} data={data} />
        )}
      </div>
    </div>
  );
}
