import React, { useState, useContext } from "react";
import { useIntl } from "react-intl";
import { NewButton, DeleteModal, handleError } from "../../component/common";
import { ManagementTabs } from "../../component/management/Management";
import {
  ImportModal,
  InputModal,
  MaterialTable,
} from "../../component/management/Material";
import {
  baseURL,
  ApiManagement_delete_material,
  ApiManagement_show_material,
  ApiManagement_export_material,
  ApiManagement_download_material_dataExample,
} from "../../api.js";
import Context from "../../context";

function ManagementMaterial({ checkEditAuth }) {
  const intl = useIntl();
  const { factory } = useContext(Context);
  const [modal, setModal] = useState();
  const [select, setSelect] = useState({});

  const showModal = (modal, item = {}) => {
    if (!checkEditAuth()) {
      return;
    }
    if (modal === "edit") {
      ApiManagement_show_material({
        id: item.id,
        factory_id: factory,
      })
        .then((res) => {
          setSelect(res.data[0]);
          setModal(modal);
        })
        .catch(console.log);
    } else {
      setSelect(item);
      setModal(modal);
    }
  };

  const onHide = () => {
    setModal();
  };

  const deleteData = () => {
    ApiManagement_delete_material({
      account: localStorage.getItem("account"),
      factory_id: factory,
      material_id: select.id,
    })
      .then(() => onHide())
      .catch((err) => handleError(err, intl));
  };

  const exportData = () => {
    ApiManagement_export_material({
      factory_id: factory,
    })
      .then((res) => window.open(`${baseURL}/${res.data}`))
      .catch((err) => handleError(err, intl));
  };

  const downloadDataExample = () => {
    ApiManagement_download_material_dataExample()
      .then((res) => window.open(`${baseURL}/${res.data}`))
      .catch((err) => handleError(err, intl));
  };

  const name = intl.formatMessage({ id: "Material" });

  return (
    <div>
      <ManagementTabs tab="2" />
      <div className="page-content shadow-lg">
        <div className="jc-end">
          <button
            className="center btn-gray"
            style={{ marginRight: "10px" }}
            onClick={downloadDataExample}
          >
            {intl.formatMessage({ id: "DownloadExample" })}
          </button>
          <button
            className="center btn-gray"
            style={{ marginRight: "10px" }}
            onClick={exportData}
          >
            {intl.formatMessage({ id: "Export" }, { name })}
          </button>
          <button
            className="center btn-gray"
            style={{ marginRight: "10px" }}
            onClick={() => showModal("import")}
          >
            {intl.formatMessage({ id: "Import" }, { name })}
          </button>
          <NewButton
            onClick={() => showModal("new")}
            text={intl.formatMessage(
              { id: "Create" },
              { name: intl.formatMessage({ id: "Material" }) }
            )}
          />
        </div>
        <hr />
        <MaterialTable showModal={showModal} modal={modal} />
      </div>

      <ImportModal modal={modal} onHide={onHide} />

      <InputModal modal={modal} onHide={onHide} data={select} />

      <DeleteModal
        show={modal === "delete"}
        onHide={onHide}
        callback={deleteData}
        name={select.material_num}
        type="Material"
      />
    </div>
  );
}

export default ManagementMaterial;
