const dashboard = {
  "1X1": "1X1",
  "2X2": "2X2",
  RealtimeStatus: "Realtime Status",
  Carousel: "Carousel",
  CapacityStatus: "Capacity Status",
  ProductionProgress: "Production Progress",
  ProductionPerHour: "Production Per Hour",
  orderNum: "Order Number",
  productNum: "Product Number",
  createTime: "Start Time",
  endTime: "Ideal End Time",
  expectEndTime: "Expected End Time",
  Manual: "Manual",
  Auto: "Auto",
  Semiautomatic: "Semiautomatic",
  Custom: " ",
  Ahead: "Ahead",
  Behind: "Behind",
  OnSchedule: "On Schedule",
  Pausing: "Pausing",
  Pause: "Pause",
  ProcessSettingTimes: "Process Setting Times",
  Fullscreen: "Fullscreen",
  KanbanList: "Kanban List",
  TopDieCycle: "Top Die Cycle",
  Unacceptable: "Unacceptable",
  NeedsImprovement: "Needs Improvement",
  Typical: "Typical",
  HighPerformance: "High Performance",
  WorldClass: "World Class",
};

export default dashboard;
