import React from 'react';
import { Tabs } from '../common';

function EmployeeTabs({ tab }) {
    const list = [
        { to: '/pages/employee/device', name: 'DeviceList' },
        { to: '/pages/employee/work', name: 'Work' },
        { to: '/pages/employee/setting', name: 'Worker' },
        { to: '/pages/employee/log', name: 'WorkerLog' },
    ];
    return (
        <Tabs
            list={list}
            tab={tab}
        />
    )
}
export { EmployeeTabs }