import React, { useState } from "react";
import { useIntl } from "react-intl";
import { RootTabs } from "../../component/root/Root";
import { InputModal, SqlTable } from "../../component/root/Sql";
import { NewButton, DeleteModal } from "../../component/common";
import { apiDeleteSql } from "../../api.js";

export default function Sql({ checkEditAuth }) {
  const [modal, setModal] = useState("");
  const [select, setSelect] = useState({});
  const intl = useIntl();

  const showModal = (modal, item = { connect_port: 3306 }) => {
    if (!checkEditAuth()) {
      return;
    }
    setSelect(item);
    setModal(modal);
  };

  const onHide = () => {
    setModal();
  };

  const deleteData = () => {
    apiDeleteSql({ id: select.id }).then(onHide);
  };

  return (
    <div>
      <RootTabs tab="2" />
      <div className="page-content shadow-lg">
        <div className="jc-end">
          <NewButton
            onClick={() => showModal("new")}
            text={intl.formatMessage(
              { id: "Create" },
              { name: intl.formatMessage({ id: "Database" }) }
            )}
          />
        </div>
        <hr />
        <SqlTable showModal={showModal} modal={modal} />
      </div>

      <InputModal modal={modal} onHide={onHide} data={select} />
      <DeleteModal
        show={modal === "delete"}
        onHide={onHide}
        name={select.database_name}
        type={intl.formatMessage({ id: "Database" })}
        callback={deleteData}
      />
    </div>
  );
}
