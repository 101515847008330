import React from "react";
import { Tabs, LinkBar } from "../common";

function IndexTabs({ tab }) {
  const list = [
    { to: "/pages/index/device", name: "DeviceOverview" },
    { to: "/pages/index/production", name: "ProductionOverview" },
  ];
  return <Tabs list={list} tab={tab} />;
}

function IndexLinks({ tab }) {
  const list = [
    { to: "/pages/index/production/activation", name: "OperationOverview" },
    { to: "/pages/index/production/oee", name: "OEEOverview" },
  ];
  return <LinkBar list={list} tab={tab} />;
}

export { IndexTabs, IndexLinks };
