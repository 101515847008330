const analysis = {
  OEEAnalysis: "OEE分析",
  QuantityAnalysis: "數量分析",
  MonitoringParameter: "製程參數",
  OrderSchedule: "工單時程",

  Overview: "總覽",
  ProductionAnalysis: "生產分析",

  DeviceAnalysis: "機台分析",

  TimeSection: "時間區段",
  DailyReport: "日報表",

  OEEOfDevices: "所選機台平均OEE",
  AvailabilityOfDevices: "所選機台平均稼動率",
  PerformanceOfDevices: "所選機台平均效率",
  QualityOfDevices: "所選機台平均良率",
  PickAnyDeviceAbove: "點選上方圖表任一機台",

  ProductionEfficiency: "生產效率",
  ProductionQuantity: "生產數量",
  ProductionCycleAnalysis: "生產週期分析",
  AverageCycle: "平均週期",
  AvgProductionCycle: "生產週期平均",
  Second: "秒",
  DowntimeTimeline: "停機時間分布",
  Downtime: "停機中",

  ProductAmount: "產品數量",
  Total: "總數",
  ModeCount: "模次",
  bad_total: "不良數",
  bad_percent: "不良率",

  PlannedProductionQuantity: "計畫生產數量",

  //生產分析報告-製程參數
  ParameterMonitoring: "工程監查",
  ProcessSetting: "工藝設置",
  created_at: "建立時間",
  cycle_time: "上模週期",
  mold_close_time: "關模時間",
  mold_open_time: "開模時間",
  inject_time: "射出時間",
  vp_time: "VP切換響應時間",
  hold_time: "保壓時間",
  compress_time: "壓縮時間",
  feed_time: "加料時間",
  delay_feed_time: "延遲加料時間",
  inj_peak_pressure: "射出系統壓力峰值",
  inj_melt_pressure: "射出熔體壓力峰值",
  cut_off_pressure: "射轉保系統壓力",
  cut_off_melt_pressure: "射轉保熔體壓力",
  inj_melt_integral: "射出熔體壓力積分值",
  compress_press: "壓縮模穴壓差",
  hold_avg_system_pressure: "保壓平均系統壓力",
  hold_avg_melt_pressure: "保壓平均熔體壓力",
  hold_velocity_pressure: "保壓系統壓力峰值",
  hold_melt_pressure: "保壓熔體壓力峰值",
  hold_pressure_integral: "保壓壓力積分值",
  feed_avg_pressure: "加料平均系統壓力",
  feed_pressure_velocity: "加料系統壓力峰值",
  melt_avg_back: "熔體平均背壓",
  melt_velocity_back: "熔體背壓峰值",
  vp_cavity: "VP切換點壓力值(模穴)",
  mold_integral_pressure: "模穴壓力積分值{number}",
  mold_velocity_pressure: "模穴壓力峰值{number}",
  pos_inj_start: "射出起始位置",
  vp_position: "VP切換響應位置",
  vp_itinerary: "VP切換響應行程",
  compression_stroke: "壓縮行程",
  pos_pressure_end: "保壓完位置",
  pos_bef_dos: "前鬆退位置",
  pos_dos_end: "加料完位置",
  pos_sb_end: "後鬆退位置",
  vp_volume: "VP切換響應容積",
  feed_volume: "加料容積",
  feed_before_volume: "加料前鬆退容積",
  feed_after_volume: "加料後鬆退容積",
  residual_volume: "殘量容積",
  inj_peak_velocity: "射出速度峰值",
  avg_inj_speed: "平均射出速度",
  feed_speed_velocity: "加料轉速峰值",
  max_plastic_speed: "最大塑料塑化線速度",
  avg_plastic_speed: "平均塑料塑化線速度",
  oil_temp: "機台油溫",
  material_temp: "第{number}段料溫",
  throat_temp: "入料喉部溫度",
  max_force: "單支柱最大受力",
  force_range: "大柱受立全距",
  cavity_temp: "母模模具溫度",
  core_temp: "公模模具溫度",
  mold_controller_temp: "模溫機溫度",
  mold_controller_flow: "模溫機水路流量",
  inlet_temp: "模具水路入水溫度{number}",
  outlet_temp: "模具水路出水溫度{number}",
  inlet_flow: "模具水路入水流量{number}",
  outlet_flow: "模具水路出水流量{number}",
  weight: "重量{number}",
  cooldown_time: "實際冷卻時間",
  ejector_fwd_time: "頂出時間",
  ejector_bwd_time: "頂退時間",
  ejector_shake_time: "震動時間",
  nozzle_bwd_time: "座退時間",
  nozzle_fwd_time: "座進時間",
  air_valve_time: "吹風時間",
  core_movein_act_time: "中子入",
  core_moveout_act_time: "中子出",
  robot_time: "機械手時間",
  zozzle_fwd_contactact: "座進保持時間",
  hold_max_pressure: "保壓壓力峰值",
  hold_max_velocity: "保壓速度峰值",
  lock_magic_power: "鎖模力{number}",
  b_inject_time: "B射射出時間",
  b_vp_time: "B射VP切換響應時間",
  b_compress_time: "B射壓縮時間",
  b_hold_time: "B射保壓時間",
  b_feed_time: "B射加料時間",
  b_delay_feed_time: "B射延遲加料時間",
  b_inj_peak_pressure: "B射射出系統壓力峰值",
  b_inj_melt_pressure: "B射射出熔體壓力峰值",
  b_cut_off_pressure: "B射射轉保系統壓力",
  b_cut_off_melt_pressure: "B射射轉保熔體壓力",
  b_inj_melt_integral: "B射射出熔體壓力積分值",
  b_hold_avg_system_pressure: "B射保壓平均系統壓力",
  b_hold_avg_melt_pressure: "B射保壓平均熔體壓力",
  b_hold_velocity_pressure: "B射保壓系統壓力峰值",
  b_hold_melt_pressure: "B射保壓熔體壓力峰值",
  b_hold_pressure_integral: "B射保壓壓力積分值",
  b_feed_avg_pressure: "B射加料平均系統壓力",
  b_feed_pressure_velocity: "B射加料系統壓力峰值",
  b_melt_avg_back: "B射熔體平均背壓",
  b_melt_velocity_back: "B射熔體背壓峰值",
  b_pos_inj_start: "B射射出起始位置",
  b_vp_position: "B射VP切換響應位置",
  b_vp_itinerary: "B射VP切換響應行程",
  b_compression_stroke: "B射壓縮行程",
  b_pos_pressure_end: "B射保壓完位置",
  b_pos_bef_dos: "B射前鬆退位置",
  b_pos_dos_end: "B射加料完位置",
  b_pos_sb_end: "B射後鬆退位置",
  b_vp_volume: "B射VP切換響應容積",
  b_feed_volume: "B射加料容積",
  b_feed_before_volume: "B射加料前鬆退容積",
  b_feed_after_volume: "B射加料後鬆退容積",
  b_residual_volume: "B射殘量容積",
  b_inj_peak_velocity: "B射射出速度峰值",
  b_avg_inj_speed: "B射平均射出速度",
  b_feed_speed_velocity: "B射加料轉速峰值",
  b_max_plastic_speed: "B射最大塑料塑化線速度",
  b_avg_plastic_speed: "B射平均塑料塑化線速度",
  b_material_temp: "B射第{number}段料溫",
  b_throat_temp: "B射入料喉部溫度",
  b_cooldown_time: "B射實際冷卻時間",

  //工藝設置
  a: "A射{name}",
  a2: "A2射{name}",
  b: "B射{name}",
  b2: "B2射{name}",
  mold_close_pressure: "關模壓力",
  mold_close_speed: "關模速度",
  mold_close_position: "關模位置",
  mold_open_pressure: "開模壓力",
  mold_open_speed: "開模速度",
  mold_open_position: "開模位置",
  mold_pressure: "模板壓力",
  mold_close_step: "關模段數",
  mold_open_step: "開模段數",
  mold_close_max_time: "最大關模時間",
  mold_open_max_time: "最大開模時間",
  mold_stocking: "儲料中開模",
  mold_insurance: "模保後開模",
  mold_clamp_tons: "設定鎖模力",
  forward_speed: "調模前進速度",
  backward_speed: "調模回退速度",
  mold_adjust: "調模模式",
  mold_adjust_by_time: "按時間調模",
  mold_adjust_single: "單齒調模",
  mold_thickness: "模厚",
  mold_adjust_time: "調模時間",

  sp_forward: "托模{type}進壓力",
  sv_forward: "托模{type}進速度",
  ss_forward: "托模{type}進位置",
  sp_backward: "托模{type}退壓力",
  sv_backward: "托模{type}退速度",
  ss_backward: "托模{type}退位置",
  step_forward: "托模{type}進段數",
  step_backward: "托模{type}退段數",
  ejector_rdistence: "頂出A行程",
  make_mould: "開模中托模",
  ejector_start_pos: "托進開始位置",
  forward_count: "托模{type}次數",
  blow_mode: "吹風模式{number}",
  blow_position: "吹風{number}開始位置",
  blow_delay: "吹風{number}延時",
  blow_time: "吹風{number}時間",
  inject_pressure: "射出壓力",
  inject_speed: "射出速度",
  inject_position: "射出位置",
  inject_pressure_segments: "射出壓力段數",
  hold_pressure: "保壓壓力",
  hold_speed: "保壓速度",
  hold_time: "保壓時間",
  hold_pressure_segments: "保壓壓力段數",
  vp_pressure: "VP切換壓力",
  vp_speed: "VP切換速度",
  restocking_back_pressure: "再儲料背壓",
  restocking_pressure: "再儲料壓力",
  restocking_speed: "再儲料速度",
  restocking_time: "再儲料時間",
  cold_material: "打冷料",
  cold_material_pressure: "打冷料壓力",
  cold_material_speed: "打冷料速度",
  cold_material_time: "打冷料時間",
  max_inject_time: "最大射出時間",
  colling_time: "冷卻時間",
  restocking_back: "儲料背壓",
  restocking_rotary_speed: "儲料轉速",
  restocking_position: "儲料位置",
  restocking_torque: "儲料力矩",
  restocking_segments: "儲料段數",
  restocking_delay: "儲料延時",
  max_restocking_time: "最大儲料時間",
  max_retreat_time: "最大鬆退時間",
  retreat_before_pressure: "前鬆壓力",
  retreat_before_speed: "前鬆速度",
  retreat_before_position: "前鬆位置",
  retreat_before_time: "前鬆時間",
  retreat_after_pressure: "後鬆壓力",
  retreat_after_speed: "後鬆速度",
  retreat_after_postition: "後鬆位置",
  retreat_after_time: "後鬆時間",
  seat_forward_pressure: "座台進壓力",
  seat_forward_speed: "座台進速度",
  seat_forward_position: "座台進位置",
  seat_backward_pressure: "座台退壓力",
  seat_backward_speed: "座台退速度",
  seat_backward_position: "座台退位置",
  seat_contactforce_pressure: "座台接觸力壓力",
  seat_contactforce_speed: "座台接觸力速度",
  seat_contactforce_setting: "座台接觸力時間設定值",
  max_inlet_time: "最大座進時間",
  max_outlet_time: "最大座退時間",
  max_clear_time: "最大清料時間",
  seat_control: "座台監控時間",
  seat_stocking: "儲料中座進",
  seat_injection: "射出時座進",
  inject_clear_pressure: "自動清料射出壓力",
  inject_clear_speed: "自動清料射出速度",
  inject_clear_time: "自動清料射出位置",
  unchuck_clear_pressure: "自動清料鬆退壓力",
  unchuck_clear_speed: "自動清料鬆退速度",
  unchuck_clear_time: "自動清料鬆退位置",
  stocking_clear_pressure: "自動清料儲料壓力",
  stocking_clear_speed: "自動清料儲料速度",
  stocking_clear_time: "自動清料儲料時間",
  heating_set: "料管設定溫度",
  heating_max: "上偏差",
  heating_min: "下偏差",
  standby_temp: "待機溫度",
  prevent_time: "防冷時間",
  hot_time: "最大預熱時間",

  // 工單時程
  ExpectedStartTime: "計劃開始時間",
  ActualStartTime: "實際開始時間",
  ExpectedEndTime: "計劃完成時間",
  ActualEndTime: "實際完成時間",

  // 生產總覽
  DeviceStatus: "機台狀態",
  TimeDuration: "總時長",
};

export default analysis;
