import React, { useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { StickyTable } from "../common";
import { getAnalysisReportOrders } from "../../api";
import Context from "../../context";

function OrderTable({ date, deviceID }) {
  const { factory } = useContext(Context);
  const [data, setData] = useState([]);
  useEffect(() => {
    if (deviceID) {
      getAnalysisReportOrders({
        FactoryID: factory,
        CheckDateStart: date.startDate,
        CheckDateEnd: date.endDate,
        DeviceID: deviceID,
      })
        .then((res) => setData(res.data))
        .catch(console.log);
    }
  }, [date, deviceID]);

  const columns = [
    {
      Header: <FormattedMessage id="order_num_name" />,
      accessor: "OrderNum",
    },
    {
      Header: <FormattedMessage id="DeviceNumber" />,
      accessor: "DeviceNum",
    },
    {
      Header: <FormattedMessage id="product_num" />,
      accessor: "ProductNum",
    },
    {
      Header: <FormattedMessage id="product_name" />,
      accessor: "ProductName",
    },
    {
      Header: <FormattedMessage id="mold_num" />,
      accessor: "MoldNum",
    },
    {
      Header: <FormattedMessage id="expect_quantity" />,
      accessor: "ExpectQuantity",
    },
    {
      Header: <FormattedMessage id="actual_quantity" />,
      accessor: "ActualQuantity",
    },
    {
      Header: <FormattedMessage id="ExpectedStartTime" />,
      accessor: "IdealScheduleDate",
    },
    {
      Header: <FormattedMessage id="ActualStartTime" />,
      accessor: "ActualScheduleDate",
    },
    {
      Header: <FormattedMessage id="ExpectedEndTime" />,
      accessor: "IdealEndTime",
    },
    {
      Header: <FormattedMessage id="ActualEndTime" />,
      accessor: "ActualEndTime",
    },
  ];

  return <StickyTable data={data} columns={columns} sticky={1} />;
}

export { OrderTable };
