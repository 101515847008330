const vi = {
    UserLogin: "Đăng nhập người dùng",
    Login: "Đăng nhập",
    KeepLogin: "Giữ trạng thái đăng nhập",
    ChangePwd: "đổi mật khẩu",
    Language: "Ngôn ngữ",
    Logout: "Đăng xuất",
    Hello: "xin chào",
    ProductionOverview: "Tổng quan về sản xuất",
    Monitoring: "Thực hiện sản xuất kanban",
    Operation: "Hệ thống thực hiện sản xuất",
    ProduceAnalysisReport: "Báo cáo phân tích sản xuất",
    ProductionAdmin: "Hệ thống quản lý sản xuất",
    SystemAdmin: "Quản lý hệ thống",
    Root: "Nguồn gốc",
    MachineOverview: "Tổng quan về máy",
    remainingDays: "Thời gian còn lại",
    PlannedQty: "Số lượng mục tiêu",
    OEETrend: "Xu hướng OEE",
    ScheduleStatus: "Xu hướng tiến bộ",
    MachineStatus: "Tổng quat",
    MachineNumber: "ID máy",
    Status: "trạng thái",
    OutputYesterday: "Sản lượng ngày hôm qua",
    OutputToday: "Đầu ra hôm nay",
    DaysDowntime: "Thời gian chết 2 ngày",
    RealTimeOEE: "Tổng quan về OEE",
    MachineTotal: "Tổng số máy",
    Unit: "ga tàu",
    NoWorkOrder: "Không có máy đơn",
    MachineStop: "Số lượng máy dừng",
    MachineOffline: "Số lượng máy được kết nối bất thường",
    OEEOnThHour: "Toàn bộ điểm OEE",
    OperationStatus: "Tình trạng hoạt động",
    OperationMode: "Chế độ hoạt động",
    RealtimeStatus: "Tình trạng máy",
    Carousel: "Băng chuyền",
    CapacityStatus: "Tình trạng năng lực",
    ProductionProgress: "tiến độ sản xuất",
    ProductionPerHour: "Số lượng sản xuất mỗi giờ",
    StartTime: "Thời gian bắt đầu",
    Stop: "Hoàn thành mục tiêu",
    EstStop: "Kỳ vọng vào sự hoàn thành",
    KanbanList: "Danh sách Kanban",
    Manual: "Hướng dẫn sử dụng",
    Auto: "tự động",
    Semiautomatic: "bán tự động",
    Custom: "Chế độ cài đặt",
    Ahead: "Sản xuất trước",
    Behind: "Sản xuất lạc hậu",
    ScheduleOnTime: "Sản xuất bình thường",
    LastThreeHour: "Ba giờ",
    LastTwoHour: "Hai giờ",
    LastOneHour: "Lúc nãy",
    Pausing: "Đình chỉ",
    Pause: "hết giờ",
    ProductionManagement: "Quản lý sản xuất",
    RunningOrderNum: "Công việc đang tiến triển",
    NoOrder: "Hiện tại không có đơn đặt hàng công việc đang tiến hành",
    ManualCompleted: "Nhấn vào đây để hoàn thành",
    UsedMoldNumber: "Sử dụng số khuôn",
    CompleteTasksInHours: "Đơn đặt hàng hoàn thành trong vòng 24 giờ",
    None: "Không có đơn đặt hàng làm việc hiện đang hoàn thành",
    CanOrder: "Không theo lịch trình",
    Waiting: "Được sản xuất",
    Onstream: "trong sản xuất",
    DefectManagement: "Quản lý tồi",
    DefectList: "Danh sách nguyên nhân xấu",
    CreateDefectLtem: "Hàng mới",
    PleaseEnterTheTimeOfTheDefectiveProduct: "Vui lòng nhập thời gian sản phẩm bị lỗi",
    PleaseKeyInDefectQuantity: "Vui lòng nhập số lượng sản phẩm bị lỗi",
    ChooseScarpReason: "Vui lòng nhập lý do cho sản phẩm bị lỗi",
    NextStep: "Bước tiếp theo",
    DeleteDefect: "Bạn có chắc chắn muốn xóa mục xấu này?",
    AddDefectiveProducts: "Vui lòng chọn thứ tự công việc để thêm các sản phẩm bị lỗi",
    DefectManagementAlert1: "Không có thứ tự công việc trong khoảng thời gian đã chọn, vui lòng chọn lại",
    DefectManagementAlert3: "Thời gian thêm vào không thể lớn hơn hôm nay, vui lòng nhập lại",
    DefectManagementAlert4: "Vui lòng chọn một đơn đặt hàng làm việc trước khi tiến hành bước tiếp theo",
    PleaseEnterQuantity: "Vui lòng nhập số lượng",
    StopManagement: "Quản lý thời gian chết",
    LatestStops: "Danh sách các lý do cho thời gian chết",
    OnlyUncategorized: "Chỉ hiển thị chưa được phân loại",
    Option: "Thực đơn",
    ModifyStopReason: "Chỉnh sửa lý do cho thời gian chết",
    MachineAnalysis: "Phân tích máy",
    ProduceAnalysis: "Phân tích sản xuất",
    ChooseMachine: "Chọn máy",
    ProductionEfficiency: "Hiệu quả sản xuất thiết bị",
    OverallEfficienc: "Hiệu quả thiết bị tổng thể",
    CycleTimeAnalysis: "Phân tích chu kỳ sản xuất",
    To: "đến",
    ScarpReason: "Lý do xấu",
    Overview: "Tổng quat",
    DefectCount: "Số lượng sản phẩm bị lỗi",
    Percentage: "tỷ lệ phần trăm (%)",
    StopTimeLine: "Phân phối thời gian chết",
    stop_time: "Thời gian chết",
    OEEAnalysis: "Phân tích OEE",
    TimeZone: "Múi giờ",
    Execute: "thực hiện",
    ChooseOrder: "Chọn thứ tự công việc",
    DownloadExcel: "Tải xuống Excel",
    QuantityAnalysis: "Phân tích định lượng",
    DailyReport: "Báo cáo hàng ngày",
    ProductAmount: "Định lượng",
    Total: "toàn bộ",
    ModeCount: "Chế độ",
    ChooseColumn: "Chọn trường",
    ChooseProduct: "Chọn sản phẩm",
    MonitoringParameter: "Thông số quá trình",
    ParameterMonitoring: "Kiểm tra kỹ thuật",
    ProcessSetting: "Cài đặt quy trình",
    CreateTime: "Thiết lập thời gian",
    MoldCloseTime: "Thời gian đóng khuôn",
    MoldOpenTime: "Thời gian mở khuôn",
    FillTime: "Thời gian tiêm",
    HoldTime: "thời gian nén",
    SuckBack: "Giờ cho ăn",
    ScrewDelayTime: "Thời gian cho ăn chậm",
    InjectionMaxPressure: "Hệ thống phun áp lực đỉnh",
    CutOffPressure: "Chụp để bảo vệ áp lực hệ thống",
    VelocityPressureTransferPoint: "VP chuyển đổi vị trí",
    ObtainedCushion_mm: "Sau khi giữ áp lực",
    PlastEndPosition_mm: "Vị trí điền",
    ObtainedCushion_cm3: "Khối lượng còn lại",
    InjectionMaxVelocity: "Tốc độ phun cực đại",
    OperatingOilTemperature: "Nhiệt độ dầu máy dầu",
    TemperatureInputZone1: "Nhiệt độ vật liệu đầu tiên",
    TemperatureInputZone2: "Nhiệt độ vật liệu giai đoạn hai",
    TemperatureInputZone3: "Nhiệt độ vật liệu giai đoạn ba",
    TemperatureInputZone4: "Nhiệt độ vật liệu giai đoạn thứ tư",
    TemperatureInputZone5: "Nhiệt độ vật liệu giai đoạn thứ năm",
    TemperatureInputZone6: "Nhiệt độ vật liệu giai đoạn sáu",
    Weight1: "Cân nặng 1",
    Weight2: "Cân nặng 2",
    ActualCooldownTime: "Thời gian làm mát thực tế",
    AdvancedSettings: "Cài đặt nâng cao",
    OrderManagement: "Quản lý đơn hàng",
    LastSevendays: "Gần bảy ngày",
    LastThirtydays: "Gần ba mươi ngày",
    ThisMonth: "Tháng này",
    LastMonth: "tháng trước",
    CTQ: "Xác minh chất lượng chính CTQ",
    OrderList: "Danh sách đặt hàng làm việc",
    IncreaseFabricationOrder: "Thêm lệnh làm việc",
    CreateDate: "Ngày thành lập",
    PleaseEnterTheOrderNumber: "Vui lòng nhập số thứ tự công việc",
    PleaseEnterTheOrderName: "Vui lòng nhập tên đơn đặt hàng công việc",
    PleaseEnterTheStatus: "Vui lòng nhập trạng thái",
    PleaseChooseTheOrderClass: "Vui lòng chọn loại đơn đặt hàng",
    PleaseChooseTheOrderReason: "Vui lòng chọn lý do đặt hàng",
    PleaseChooseTheProductNumber: "Vui lòng chọn một số sản phẩm",
    PleaseChooseTheMoldNumber: "Vui lòng chọn số khuôn",
    expect_quantity: "Số lượng sản xuất theo kế hoạch",
    PleaseEnterThePlannedQuantity: "Vui lòng nhập số lượng sản xuất theo kế hoạch",
    actual_quantity: "Số lượng sản xuất thực tế",
    PleaseEnterTheMachineNumber: "Vui lòng nhập số máy",
    PleaseEnterTimeDescription: "Vui lòng nhập một mô tả ngắn gọn về thời gian",
    expected_time: "Thời gian máy dự kiến",
    DescriptionPlannedDowntime: "Vui lòng tính bằng phút, nếu 2 giờ, vui lòng nhập 120",
    NumberOfCaptives: "Số lượng sâu răng",
    BestCycleTime: "Thời gian chu kỳ tốt nhất (giây)",
    normal_amount: "Số lượng khuôn bình thường liên tục vào chế độ sản xuất hàng loạt",
    PleaseEnterTheQualifiedShotCountToEnterMPMode: "Vui lòng nhập số lượng khuôn bình thường liên tiếp để vào chế độ sản xuất hàng loạt",
    QCFocus: "Điểm kiểm tra chất lượng",
    PleaseEnterQCFocus: "Vui lòng nhập các điểm kiểm tra chất lượng",
    mold_time: "Thời gian đúc (phút)",
    PleaseEnterPreparationTime: "Vui lòng nhập thời gian khuôn (phút)",
    CooldownTime: "Thời gian chết (phút)",
    PleaseEnterCooldownTime: "Vui lòng nhập thời gian chết (phút)",
    WorkOrderAutoFinish: "Lệnh công việc tự động hoàn thành",
    ApplyWorkOrderDueDate: "Áp dụng thời gian hết hạn đơn hàng công việc",
    WorkOrderDueDate: "Thời gian hết hạn làm việc",
    Schedule: "lịch trình",
    OrderSchedule: "Lịch trình làm việc",
    UnassignedOrders: "Vé chưa được chỉ định",
    ModifyOrderSchedule: "Điều chỉnh lịch trình công việc",
    ScheduleDates: "Thời gian biểu",
    ArrangementMachineNum: "Máy đơn hàng",
    CancelSchedule: "Hủy lịch trình",
    WrongDateFormat: "Lỗi định dạng thời gian",
    UsedBefore: "Máy tiêm được sử dụng để làm cùng một sản phẩm",
    MoldOutOfSide: "Kích thước của khuôn và khuôn phun là khác nhau",
    NozzleOutOfSide: "Khuôn không khớp với vòi máy",
    ShotWeightInsufficient: "Tổng trọng lượng của khuôn lớn hơn trọng lượng cho phép của máy tiêm",
    ClampingForceInsufficient: "Lực kẹp không đủ",
    MaterialManagement: "Quản lý vật liệu",
    Material: "vật chất",
    CreateMaterial: "Vật liệu mới",
    material_num: "Số nguyên liệu",
    PleaseChooseMaterialNumber: "Vui lòng chọn số nguyên liệu",
    PleaseEnterMaterialNumber: "Vui lòng nhập số tài liệu",
    MaterialType: "Mô hình vật liệu",
    PleaseEnterMaterialType: "Vui lòng nhập mô hình vật liệu",
    MaterialBase: "Vật liệu cơ bản",
    PleaseEnterMaterialBase: "Vui lòng nhập tài liệu cơ sở",
    supplier: "nhà cung cấp",
    PleaseEnterSupplier: "Vui lòng nhập nhà cung cấp",
    melt_density: "Mật độ nóng chảy",
    PleaseEnterMeltDensity: "Vui lòng nhập mật độ nóng chảy",
    soild_state_density: "Mật độ rắn",
    PleaseEnterSolidDensity: "Vui lòng nhập mật độ rắn",
    MeltingPointMin: "Nhiệt độ nóng chảy tối thiểu",
    PleaseEnterMeltingPointMin: "Vui lòng nhập nhiệt độ nóng chảy tối thiểu",
    MeltingPointMax: "Nhiệt độ nóng chảy tối đa",
    PleaseEnterMeltingPointMax: "Vui lòng nhập nhiệt độ nóng chảy tối đa",
    MeltingPointAvg: "Nhiệt độ nóng chảy trung bình",
    PleaseEnterMeltingPointAvg: "Vui lòng nhập nhiệt độ nóng chảy trung bình",
    MoldTemperatureMin: "Nhiệt độ khuôn tối thiểu",
    PleaseEnterMoldTemperatureMin: "Vui lòng nhập nhiệt độ khuôn tối thiểu",
    MoldTemperatureMax: "Nhiệt độ khuôn tối đa",
    PleaseEnterMoldTemperatureMax: "Vui lòng nhập nhiệt độ khuôn tối đa",
    MoldTemperatureAvg: "Nhiệt độ khuôn trung bình",
    PleaseEnterMoldTemperatureAvg: "Vui lòng nhập nhiệt độ khuôn trung bình",
    aridity: "Nhiệt độ sấy khuyến nghị",
    PleaseEnterDryingTemperature: "Vui lòng nhập nhiệt độ sấy được đề nghị",
    DryingTimeSec: "Thời gian sấy đề nghị",
    PleaseEnterDryingTimeSec: "Vui lòng nhập thời gian sấy được đề nghị",
    FiberRatio: "Hàm lượng chất xơ",
    PleaseEnterFiberRatio: "Vui lòng nhập nội dung sợi",
    melting_index: "Tan chỉ số",
    PleaseEnterMeltFlowIndex: "Vui lòng nhập chỉ số tan chảy",
    Weight: "Trọng lượng vật liệu chứng khoán",
    PleaseEnterWeight: "Vui lòng nhập trọng lượng của vật liệu chứng khoán",
    MoldManagement: "Quản lý khuôn",
    Mold: "Khuôn",
    CreateMold: "Khuôn mới",
    mold_num: "Số khuôn",
    PleaseEnterTheMoldNumber: "Vui lòng nhập số khuôn",
    PleaseEnterNumberOfCaptives: "Vui lòng nhập số lượng khoang khuôn",
    PleaseEnterBestCycleTime: "Vui lòng nhập thời gian chu kỳ tốt nhất (giây)",
    PleaseEnterStopTime: "Vui lòng nhập thời gian xuống (giây)",
    PlatenOrientation: "hình thức",
    PleaseEnterPlatenOrientation: "Vui lòng nhập vào mẫu",
    AllowableTime: "Thời gian chu kỳ cho phép (giây)",
    PleaseEnterAllowableTime: "Vui lòng nhập thời gian chu kỳ cho phép (giây)",
    MaximumAllowableTime: "Thời gian chu kỳ tối đa được phép (giây)",
    PleaseEnterMaximumAllowableTime: "Vui lòng nhập thời gian chu kỳ tối đa cho phép (giây)",
    CountMaintenance: "Thời gian bảo trì khuôn",
    PleaseEnterCountMaintenance: "Vui lòng nhập số lượng khuôn bảo trì",
    StackHeight: "Độ dày khuôn",
    PleaseEnterStackHeight: "Vui lòng nhập độ dày khuôn",
    Width: "Chiều rộng khuôn",
    PleaseEnterWidth: "Vui lòng nhập chiều rộng khuôn",
    VerticalHeight: "Chiều dài khuôn",
    PleaseEnterVerticalHeight: "Vui lòng nhập chiều dài khuôn",
    SprueAperture1: "Khẩu độ dọc 1",
    PleaseEnterSprueAperture1: "Vui lòng nhập khẩu độ chạy dọc 1",
    SprueAperture2: "Khẩu độ dọc 2",
    PleaseEnterSprueAperture2: "Vui lòng nhập khẩu độ dọc 2",
    SprueAngleR1: "Đường thẳng đứng R góc 1",
    PleaseEnterSprueAngleR1: "Vui lòng nhập đường vòng R dọc 1",
    SprueAngleR2: "Đường thẳng đứng R góc 2",
    PleaseEnterSprueAngleR2: "Vui lòng nhập đường vòng R dọc 2",
    ReqMoldOpenStroke: "Khuôn mở khuôn",
    PleaseEnterReqMoldOpenStroke: "Vui lòng nhập đột quỵ mở khuôn",
    HotRunnerVolume: "Số lượng đường vòng nóng",
    PleaseEnterHotRunnerVolume: "Vui lòng nhập số lượng bỏ qua nóng",
    NumberOfCorePull: "Số nơtron",
    PleaseEnterNumberOfCorePull: "Vui lòng nhập số lượng neutron",
    AcquisitionDate: "Ngày mua",
    PleaseEnterAcquisitionDate: "Vui lòng nhập ngày mua",
    DeactivationDate: "Ngày hủy kích hoạt",
    PleaseEnterDeactivationDate: "Vui lòng nhập ngày đình chỉ",
    NumberOfBases: "Số lượng khuôn",
    PleaseEnterNumberOfBases: "Vui lòng nhập số lượng khuôn",
    PleaseEnterRegion: "Vui lòng vào khu vực",
    MaintenanceCycle: "Chu kỳ bảo trì (ngày)",
    PleaseEnterMaintenanceCycle: "Vui lòng nhập khoảng thời gian bảo trì (ngày)",
    MaintenanceCount: "Thời gian bảo trì",
    PleaseEnterMaintenanceCount: "Vui lòng nhập số lượng bảo trì",
    LastMaintenance: "Thời gian bảo trì lần cuối",
    PleaseEnterLastMaintenance: "Vui lòng nhập thời gian bảo trì cuối cùng",
    MoldProduceCount: "Mô-đun sản xuất",
    PleaseEnterMoldProduceCount: "Vui lòng nhập mô-đun sản xuất",
    ring1: "Định vị đường kính vòng 1",
    PleaseEnterLocatingRingDiameter1: "Vui lòng nhập đường kính vòng 1",
    ring2: "Định vị đường kính vòng 2",
    PleaseEnterLocatingRingDiameter2: "Vui lòng nhập đường kính vòng 2",
    ClampingForce: "Lực kẹp",
    PleaseEnterClampingForce: "Vui lòng nhập lực kẹp",
    CenterDistance: "Khoảng cách trung tâm",
    PleaseEnterCenterDistance: "Vui lòng nhập khoảng cách trung tâm",
    core_temp: "Nhiệt độ khuôn chung",
    PleaseEnterCoreTemperature: "Vui lòng nhập nhiệt độ khuôn nam",
    cavity_temp: "Nhiệt độ khuôn chủ",
    PleaseEnterCavityTemperature: "Vui lòng nhập nhiệt độ khuôn chủ",
    Customer: "khách hàng",
    PleaseEnterCustomer: "Vui lòng nhập khách hàng",
    PhotoRemark: "Vui lòng tải lên một hình ảnh với kích thước hình ảnh 200 * 200. Phần mở rộng tập tin phải là chữ thường.",
    ProductManagement: "Quản lý sản phẩm",
    Product: "sản phẩm",
    CreateProduct: "Sản phẩm mới",
    product_num: "ID sản phẩm",
    PleaseEnterTheProductNumber: "Vui lòng nhập số sản phẩm",
    ProductDescription: "tên sản phẩm",
    PleaseEnterProductDescription: "vui lòng nhập tên sản phẩm",
    PleaseChooseEnterMaterialNumber: "Vui lòng chọn / nhập số nguyên liệu",
    PleaseChooseEnterMoldNumber: "Vui lòng chọn / nhập số khuôn",
    product_type: "Loại sản phẩm",
    PleaseEnterProductType: "Vui lòng nhập loại sản phẩm",
    part_weight: "trọng lượng sản phẩm",
    PleaseEnterProductWeight: "Vui lòng nhập trọng lượng sản phẩm",
    runner_weight: "Trọng lượng Sprue",
    PleaseEnterRunnerWeight: "Vui lòng nhập trọng lượng spue",
    MoldWeight: "Trọng lượng khuôn",
    PleaseEnterMoldWeight: "Vui lòng nhập trọng lượng chết",
    CostPerPiece: "Chi phí sản xuất",
    PleaseEnterCostPerPiece: "Vui lòng nhập chi phí sản xuất",
    ShiftManagement: "Quản lý lớp học",
    WorkdaySetting: "Đặt ngày làm việc",
    WorkHours1: "Giờ làm việc 1",
    WorkHours2: "Giờ làm việc 2",
    WorkHours3: "Giờ làm việc 3",
    WorkHours4: "Giờ làm việc 4",
    Repeat: "nói lại",
    EveryWeekday_MondayToFriday: "Mỗi thứ Hai đến thứ Sáu",
    EveryWeekday_MondayToSaturday: "Mỗi thứ Hai đến thứ Bảy",
    Everyday: "Hằng ngày",
    Type: "Kiểu",
    AppliedMachines: "Máy áp dụng",
    HolidayManagement: "Quản lý kỳ nghỉ",
    CreateHolidayOrWorkday: "Thêm ngày lễ hoặc ngày làm việc",
    is_holiday: "Ngày lễ hoặc ngày làm việc",
    Holiday: "ngày lễ",
    Workday: "Ngày làm việc",
    PleaseEnterHolidayOrWorkday: "Vui lòng chọn một ngày lễ hoặc ngày làm việc",
    PleaseEnterDate: "Vui lòng nhập một ngày",
    ScarpReasonManagement: "Quản lý nguyên nhân xấu",
    ScarpReasonInputMode: "Chế độ chèn lấp nguyên nhân xấu",
    EditScarpReasonInputMode: "Chế độ chèn lấp để chỉnh sửa kém",
    CreateScarpReasonOption: "Lý do mới cho sự xấu",
    IPQC: "Kiểm tra phía máy IPQC: Có thanh tra chất lượng phía máy bên cạnh máy của nhà máy và thanh tra chất lượng có khả năng xác định nguyên nhân gây ra lỗi",
    TwoTierInspection: "Đánh giá kiểm soát chất lượng: Có một thanh tra chất lượng phía máy bên cạnh máy của nhà máy, chỉ có thể đánh giá lỗi ngay từ đầu và kiểm soát chất lượng sẽ điền vào nguyên nhân của từng khuôn sau đó theo thời gian.",
    FQC: "Kiểm soát chất lượng số lượng tích lũy: Không có thanh tra chất lượng phía máy bên cạnh máy sản xuất, một số lượng nhất định được tích lũy hoặc kiểm tra chất lượng kiểm tra trước khi chuyển đổi và xác định xem nó có tệ không, bạn cần nhập thời gian đầu ra gần đúng",
    StopReasonManagement: "Quản lý thời gian chết",
    CreateStopReasonOption: "Lý do mới cho thời gian chết",
    UnproductiveOrderReasonManagement: "Quản lý lý do đơn hàng không hiệu quả",
    CreateUnproductiveOrderReason: "Tạo lý do đơn hàng không hiệu quả",
    UsersManagement: "Quản lý người dùng",
    Users: "người dùng",
    IncreaseUsers: "Thêm người dùng",
    UserList: "Danh sách người dùng",
    ProfilePicture: "hình đại diện",
    Name: "Tên",
    Role: "Vai trò",
    PleaseChooseRole: "Vui lòng chọn một vai trò",
    Email: "e-mail",
    Group: "Nhóm",
    PleaseChooseGroup: "Vui lòng chọn một nhóm",
    Pwd: "mật khẩu",
    PermissionSetting: "Cài đặt quyền",
    All: "Tất cả",
    ViewPermissions: "Xem quyền",
    EditPermissions: "Chỉnh sửa quyền",
    ClickAndUploadYourProfilePicture: "Nhấn vào đây để tải lên một bức ảnh.Phần mở rộng tập tin phải là chữ thường.",
    PleaseEnterTheEmail: "Vui lòng nhập email của bạn",
    PleaseEnterTheName: "Vui lòng nhập tên của bạn",
    PleaseEnterRemark: "Vui lòng nhập một mô tả",
    KeyInToChangePassword: "Nhập để thay đổi mật khẩu",
    PleaseKeyInPassword: "Xin vui lòng nhập mật khẩu",
    PanelManagement: "Quản lý Kanban",
    Panel: "Kanban",
    CreatePanelMenu: "Thêm Kanban",
    Title: "tiêu đề",
    PleaseEnterTitle: "vui lòng nhập tiêu đề",
    PleaseEnterType: "Vui lòng chọn loại",
    MachineClassificationManagement: "Quản lý nhóm máy",
    MachineClassificationName: "Tên nhóm máy",
    MachineClassification: "Nhóm máy",
    IncreaseMachineClassification: "Thêm nhóm máy",
    PleaseEnterMachineClassificationName: "Vui lòng nhập tên nhóm máy",
    FactoryManagement: "Quản lý nhà máy",
    FactoryList: "Danh sách nhà máy",
    Factory: "nhà máy",
    IncreaseFactory: "Nhà máy mới",
    FactoryNumber: "Mã nhà máy",
    FactoryName: "Tên nhà máy",
    PleaseChooseFactoryName: "Vui lòng chọn tên nhà máy",
    Address: "Địa chỉ",
    TimeZone1: "Múi giờ",
    PleaseChooseRegion: "Vui lòng chọn một khu vực",
    PleaseEnterFactoryNumber: "Vui lòng nhập mã nhà máy",
    PleaseEnterFactoryName: "Vui lòng nhập tên nhà máy",
    PleaseEnterAddress: "Vui lòng nhập địa chỉ",
    PleaseChooseTimeZone1: "Vui lòng chọn múi giờ",
    MachineList: "Danh sách máy",
    Machine: "Máy móc",
    IncreaseMachine: "Máy mới",
    MachineNumbers: "Tên may moc",
    PleaseEnterMachineNumbers: "Vui lòng nhập tên máy",
    Manufacturer: "nhà chế tạo",
    PleaseEnterManufacturer: "Vui lòng nhập nhà sản xuất",
    Tonnes: "Tấn",
    PleaseEnterTonnes: "Vui lòng nhập trọng tải",
    Connection: "Phương thức kết nối",
    PleaseChooseConnection: "Vui lòng chọn một phương thức kết nối",
    MachinePhotos: "Tải ảnh máy lên",
    PleaseChoosePhotos: "Vui lòng chọn một hình ảnh",
    ScrewDiameter: "Đường kính trục vít",
    PleaseEnterScrewDiameter: "Vui lòng nhập đường kính vít",
    NozzleAngleR1: "Vòi R góc 1",
    PleaseEnterNozzleAngleR1: "Vui lòng nhập vòi R góc 1",
    NozzleAngleR2: "Vòi R góc 2",
    PleaseEnterNozzleAngleR2: "Vui lòng nhập vòi R góc 2",
    NozzleAperture1: "Khẩu độ vòi phun 1",
    PleaseEnterNozzleAperture1: "Vui lòng nhập khẩu độ vòi phun 1",
    NozzleAperture2: "Khẩu độ vòi phun 2",
    PleaseEnterNozzleAperture2: "Vui lòng nhập khẩu độ vòi phun 2",
    ShotWeight: "Trọng lượng tiêm tối đa cho phép (PS)",
    PleaseEnterShotWeight: "Vui lòng nhập trọng lượng tiêm tối đa cho phép (PS)",
    ShotSchedule: "Đột quỵ tiêm tối đa cho phép",
    PleaseEnterShotSchedule: "Vui lòng nhập đột quỵ tiêm tối đa cho phép",
    IntensificationRatio: "Tỷ lệ tăng",
    PleaseEnterIntensificationRatio: "Vui lòng nhập tỷ lệ tăng",
    InjectionPressure: "Áp suất phun tối đa cho phép",
    PleaseEnterInjectionPressure: "Vui lòng nhập áp suất phun tối đa cho phép",
    InjectionVelocity: "Tốc độ tiêm tối đa cho phép",
    PleaseEnterInjectionVelocity: "Vui lòng nhập tốc độ tiêm tối đa cho phép",
    MoldStackWidthMaxH: "Chiều dài khuôn tối đa (H)",
    PleaseEnterMoldStackWidthMaxH: "Vui lòng nhập chiều dài dung lượng tối đa (H)",
    MoldStackHeightMaxV: "Dung sai tối đa (V)",
    PleaseEnterMoldStackHeightMaxV: "Vui lòng nhập dung sai tối đa (V)",
    MoldStackWidthMinH: "Chiều dài khuôn tối thiểu (H)",
    PleaseEnterMoldStackWidthMinH: "Vui lòng nhập chiều dài dung lượng tối thiểu (H)",
    MoldStackHeightMinV: "Dung sai tối thiểu (V)",
    PleaseEnterMoldStackHeightMinV: "Vui lòng nhập dung sai tối thiểu (V)",
    MoldOpenDaylightMax: "Khoảng cách mở khuôn tối đa",
    PleaseEnterMoldOpenDaylightMax: "Vui lòng nhập khoảng cách mở tối đa",
    TiebarClearanceWidth: "Khoảng cách nội bộ cột lớn",
    PleaseEnterTiebarClearanceWidth: "Vui lòng nhập chiều rộng của cột lớn",
    TiebarClearanceLength: "Chiều dài khoảng cách bên trong cột lớn",
    PleaseEnterTiebarClearanceLength: "Vui lòng nhập chiều dài của cột lớn",
    MoldThicknessMax: "Độ dày khuôn tối đa",
    PleaseEnterMoldThicknessMax: "Vui lòng nhập độ dày tối đa",
    MoldThicknessMin: "Độ dày khuôn tối thiểu",
    PleaseEnterMoldThicknessMin: "Vui lòng nhập độ dày khuôn tối thiểu",
    MoldWidthMinH: "Kích thước khuôn tối thiểu",
    MoldHeightMinV: "Kích thước và chiều dài khuôn tối thiểu",
    ChooseFactory: "Chọn nhà máy",
    GroupManagement: "Quản lý nhóm",
    CreateGroup: "Thêm nhóm",
    GroupName: "Tên nhóm",
    Trademark: "Hình ảnh thương hiệu",
    PleaseEnterGroupName: "Vui lòng nhập tên nhóm",
    Required: "Các mặt hàng cần thiết",
    Increase: "Thêm vào",
    AddFailed: "Thêm thất bại",
    Modify: "sửa đổi",
    ModifyFailed: "Chỉnh sửa thất bại",
    Copy: "bản sao",
    Delete: "xóa bỏ",
    DeleteFailed: "không thể xóa",
    TryAgain: "Yêu cầu lỗi dữ liệu, vui lòng thử lại",
    Yes: "Đúng",
    No: "Không",
    DeleteConfirmation: "Xác nhận xóa",
    ConfirmationMessage: "thông báo xác nhận",
    description: "Giải trình",
    PleaseEnterDescription: "Vui lòng nhập mô tả",
    OrderClass: "Hạng đặt hàng",
    order_num_name: "Số đơn hàng công việc",
    OrderName: "Tên đơn hàng",
    Order: "Trình tự công việc",
    Defective: "Sản phẩm bị lỗi",
    DefectReason: "Nguyên nhân khiếm khuyết",
    CycleTime: "Chu kỳ khuôn",
    LatestCycleTime: "Thời gian chu kỳ",
    StopReason: "Lý do cho thời gian chết",
    order_reason: "Lý do đặt hàng",
    UnproductiveOrderReason: "Lý do đặt hàng không hiệu quả",
    EndTime: "Thời gian kết thúc",
    Utilization: "Tỷ lệ sử dụng",
    Performance: "hiệu quả",
    Quality: "Năng suất",
    region: "khu vực",
    Edit: "biên tập",
    Photo: "hình chụp",
    OK: "mục đích",
    Cancel: "hủy bỏ",
    The: "Đầu tiên",
    Page: "trang",
    PreviousPage: "Trước",
    NextPage: "Trang tiếp theo",
    DoYouWantToDeleteThis: "Bạn có chắc chắn muốn xóa",
    ThisUser: "Người dùng này?",
    ThisPanel: "Cái kanban này",
    ThisFactory: "Nhà máy này?",
    ThisMachine: "Cái máy này phải không",
    ThisGroup: "Nhóm này?",
    ThisOrder: "Trình tự công việc này",
    ThisMaterial: "Vật liệu này?",
    ThisMold: "Khuôn này",
    ThisProduct: "Sản phẩm này",
    ThisShift: "Bạn có muốn rời khỏi lớp học này không",
    ThisDay: "Ngày của ngày này",
    ThisDefectScarp: "Đây có phải là nguyên nhân xấu được mô tả",
    ThisyStopReason: "Điều này có dừng mô tả nguyên nhân không",
    ThisUnproductiveOrderReason: "Có phải lý do làm việc phi hiệu quả này không",
    ThisMachineClassification: "Đây có phải là nhóm máy không",
    Sec: "thứ hai",
    Amount: "Định lượng",
    Quantity: "Số lượng sản xuất",
    Days: "ngày",
    Today: "hôm nay",
    Yesterday: "Hôm qua",
    Tomorrow: "Ngày mai",
    Date: "ngày",
    Dates: "Khoảng thời gian hàng ngày",
    DatesTimeQuantum: "Khoảng thời gian hàng ngày",
    Year: "năm",
    Month: "tháng",
    Day: "ngày",
    Time: "thời gian",
    Hours: "Thời gian",
    Minutes: "Phút",
    Week: "Tuần",
    January: "tháng Giêng",
    February: "Tháng hai",
    March: "tháng Ba",
    April: "Tháng 4",
    May: "có thể",
    June: "Tháng 6",
    July: "Tháng 7",
    August: "tháng Tám",
    Septemper: "Tháng Chín",
    October: "Tháng Mười",
    November: "Tháng 11",
    December: "Tháng 12",
    Shutdown: "Không bật",
    Alert: "Thông báo",
    Downtime: "Thời gian chết",
    Running: "Đang chạy",
    NoOrderRunning: "Không có lệnh làm việc",
    MachineIsDowntime: "Thời gian chết máy",
    ProductionPause: "Đình chỉ sản xuất",
    MicroStop: "Dừng sản xuất",
    MachineIsOffline: "Máy ngoại tuyến",
    AreYouSureToCompleteTheWorkOrder: "Bạn có chắc chắn muốn hoàn thành trình tự công việc?",
    WouldYouWantToStartTheOder: "Bạn có muốn bắt đầu mua hàng không?",
    OrderAutomaticRunNotifify: "Đơn đặt hàng tiếp theo sẽ bắt đầu được sản xuất sau 1 phút sau khi nhấp vào xác nhận"
}

export default vi;