import React, { useEffect, useState, useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Modal from "react-bootstrap/Modal";
import { MdDone, MdTimer } from "react-icons/md";
import Alert from "@material-ui/lab/Alert";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";
import {
  baseURL,
  ApiPes_GetOrderIn24HourFinishList,
  postOrderSplit,
  ApiPes_OrderManualFinish,
  ApiPes_OrderManualStart,
  ApiOrder_UpdateOrderStatus,
} from "../../api.js";
import Context from "../../context";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function OrderCard({
  data: {
    id,
    order_group_name,
    photo_path,
    order,
    product,
    mold,
    starttime,
    endtime,
    order_status,
    isMaterialDifferent,
    isMoldDifferent,
  },
  buttonType,
  showModal,
  clickable,
  className,
}) {
  const intl = useIntl();
  const { auth } = useContext(Context);
  const data = { order_status, id, order };
  return (
    <div className={`order jc-space-between ${className}`}>
      <div className="ai-stretch">
        <img src={photo_path ? `${baseURL}/${photo_path}` : ""} />
        <div className="flex-column mr-5">
          <h4>
            <b>{order}</b>
          </h4>
          <h6>
            <FormattedMessage id="order_group_name" />：
            <b>{order_group_name || "-"}</b>
          </h6>
          <h6>
            <FormattedMessage id="product_num" />：<b>{product}</b>
          </h6>
          <h6>
            <FormattedMessage id="MoldNumberUsed" />：<b>{mold}</b>
          </h6>
          {starttime ? (
            <h6>
              <FormattedMessage id="StartTime" />：<b>{starttime}</b>
            </h6>
          ) : (
            <h6>
              <FormattedMessage id="EndTime" />：<b>{endtime}</b>
            </h6>
          )}
        </div>
        <div className="flex-column jc-space-between p-2">
          {isMoldDifferent === 1 ? (
            <Alert severity="warning">
              {intl.formatMessage({ id: "MoldDifferent" })}
            </Alert>
          ) : (
            <div />
          )}
          {isMaterialDifferent === 1 ? (
            <Alert severity="warning">
              {intl.formatMessage({ id: "MaterialDifferent" })}
            </Alert>
          ) : (
            <div />
          )}
        </div>
      </div>

      <div className="ai-center">
        {order_status === 2 && (
          <div className="flex" style={{ marginRight: "10px" }}>
            <button
              className="btn_finish center flex-column"
              onClick={() => showModal("split", data)}
            >
              <span>
                <FormattedMessage id="Split" />
              </span>
            </button>
          </div>
        )}
        {buttonType === "finish" && (
          <div className="flex" style={{ marginRight: "10px" }}>
            <button
              className="btn_finish center flex-column"
              onClick={() => showModal("pause", data)}
            >
              {order_status === 2 ? <PauseIcon /> : <PlayArrowIcon />}
              <span>
                {order_status === 2 ? (
                  <FormattedMessage id="Pause" />
                ) : (
                  <FormattedMessage id="Start" />
                )}
              </span>
            </button>
          </div>
        )}
        {clickable && (
          <div className="flex">
            <button
              className="btn_finish center flex-column"
              onClick={() => showModal(buttonType, data)}
            >
              {buttonType === "finish" ? (
                <MdDone size="30px" />
              ) : (
                <MdTimer size="30px" />
              )}
              <span>
                <FormattedMessage
                  id={
                    buttonType === "finish" ? "ClickToComplete" : "ClickToStart"
                  }
                />
              </span>
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

function RunningOrderList({ data, showModal }) {
  if (data.length === 0) {
    return (
      <div className="order">
        <span className="no-order center">
          <FormattedMessage id="NoOrderRunning" />
        </span>
      </div>
    );
  } else {
    return (
      <ul className="list-unstyled m-0 flex-column ai-stretch order-list border" >
        {data.map((item, i) => (
          <OrderCard
            data={item}
            showModal={showModal}
            buttonType="finish"
            clickable={true}
            className={i < data.length - 1 ? "border-bottom" : ""}
          />
        ))}
      </ul>
    );
  }
}

function WaitingOrderList({ showModal, data, busy, style }) {
  if (data.length === 0) {
    return (
      <div className="order">
        <span className="no-order center">
          <FormattedMessage id="NoNextOrder" />
        </span>
      </div>
    );
  } else {
    return (
      <ul
        className="list-unstyled m-0 flex-column ai-stretch order-list border"
        style={style}
      >
        {data.map((item, i) => (
          <OrderCard
            key={item.id}
            data={item}
            showModal={showModal}
            buttonType="start"
            clickable={!busy && i === 0 && item.manual_start === 1}
            className={i < data.length - 1 ? "border-bottom" : ""}
          />
        ))}
      </ul>
    );
  }
}

function CompletedOrderList({ deviceID, modal }) {
  const [orderList, setOrderList] = useState([]);
  const { factory } = useContext(Context);

  useEffect(() => {
    if (!modal) {
      loadData();
    }
  }, [deviceID, modal]);

  const loadData = () => {
    if (deviceID) {
      ApiPes_GetOrderIn24HourFinishList({
        FactoryID: factory,
        DeviceID: deviceID,
      })
        .then((res) => setOrderList(res.data))
        .catch(console.log);
    }
  };

  if (orderList.length == 0) {
    return (
      <div className="order">
        <span className="no-order center">
          <FormattedMessage id="NoOrderDone" />
        </span>
      </div>
    );
  } else {
    return (
      <ul className="list-unstyled m-0 flex-column ai-stretch order-list border">
        {orderList.map((item, i) => (
          <OrderCard
            key={item.id}
            data={item}
            className={i < orderList.length - 1 ? "border-bottom" : ""}
          />
        ))}
      </ul>
    );
  }
}

export const FinishModal = ({
  modal,
  onHide,
  data,
  showModal,
  orderList,
  setOrderList,
}) => {
  const intl = useIntl();
  const { factory } = useContext(Context);
  const submit = () => {
    ApiPes_OrderManualFinish({
      FactoryID: factory,
      OrderID: data.id,
      account: localStorage.getItem("account"),
    })
      .then(() => {
        setOrderList((prev) => [null, ...prev.slice(1)]);
        if (orderList.length > 1) {
          showModal("start", orderList[1]);
        } else {
          onHide();
        }
      })
      .catch(console.log);
  };

  return (
    <Modal
      className="modal_style fade"
      size="lg"
      centered
      show={modal === "finish"}
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title as="h5">
          <b>
            <FormattedMessage id="ConfirmationMessage" />
          </b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h6 className="mb-0">
          {intl.formatMessage(
            { id: "FinishManuallyConfirm" },
            { name: data?.order }
          )}
        </h6>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-end">
        <button className="btn btn-confirm" onClick={submit}>
          <FormattedMessage id="OK" />
        </button>
        <button className="btn btn-cancel" onClick={onHide}>
          <FormattedMessage id="Cancel" />
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export const StartModal = ({ modal, onHide, data, deviceID }) => {
  const intl = useIntl();
  const { factory } = useContext(Context);
  const submit = () => {
    ApiPes_OrderManualStart({
      account: localStorage.getItem("account"),
      FactoryID: factory,
      DeviceID: deviceID,
      OrderID: data.id,
    })
      .then(onHide)
      .catch(console.log);
  };

  return (
    <Modal
      className="modal_style fade"
      size="lg"
      centered
      show={modal === "start"}
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title as="h5">
          <b>
            <FormattedMessage id="ConfirmationMessage" />
          </b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h6 className="mb-0">
          {intl.formatMessage(
            { id: "StartManuallyConfirm" },
            { name: data?.order }
          )}
        </h6>
        <small className="form-text text-muted">
          <FormattedMessage id="StartManuallyRemark" />
        </small>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-end">
        <button className="btn btn-confirm" onClick={submit}>
          <FormattedMessage id="OK" />
        </button>
        <button className="btn btn-cancel" onClick={onHide}>
          <FormattedMessage id="Cancel" />
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export const PauseModal = ({ modal, onHide, data }) => {
  const intl = useIntl();
  const { factory } = useContext(Context);
  const submit = () => {
    ApiOrder_UpdateOrderStatus(data.id, {
      factoryID: factory,
      orderStatus: data.order_status === 2 ? "stop" : "start",
    }).then(onHide);
  };
  return (
    <Modal
      className="modal_style fade"
      size="lg"
      centered
      show={modal === "pause"}
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title as="h5">
          <b>
            <FormattedMessage id="ConfirmationMessage" />
          </b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h6>
          {intl.formatMessage(
            { id: "StartPauseConfirm" },
            {
              action: intl.formatMessage({
                id: data.order_status === 2 ? "Pause" : "Start",
              }),
              name: data?.order,
            }
          )}
        </h6>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-end">
        <button className="btn btn-confirm" onClick={submit}>
          <FormattedMessage id="OK" />
        </button>
        <button className="btn btn-cancel" onClick={onHide}>
          <FormattedMessage id="Cancel" />
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export const SplitModal = ({ modal, onHide, data, showModal }) => {
  const intl = useIntl();
  const { factory } = useContext(Context);
  const [select, setSelect] = useState({});
  const [alert, setAlert] = useState(false);
  const [messageNumber, setMessageNumber] = useState(0);

  const columns = [{ id: "expect_quantity", type: "text", required: true }];

  const quantityOnChange = (e) => {
    setSelect({ ...data, expect_quantity: e.target.value });
  };
  console.log(select);

  let alertMessage = [
    "QuantityCantBeZero",
    "PleaseInputPlannedQuantity",
    "PleaseInputNumber",
  ];

  const checkInput = () => {
    let re = /^[0-9\b]+$/;
    if (!re.test(select.expect_quantity)) {
      setAlert(true);
      setMessageNumber(2);
    } else if (select.expect_quantity === undefined) {
      setAlert(true);
      setMessageNumber(1);
    } else if (select.expect_quantity === "0") {
      setAlert(true);
      setMessageNumber(0);
    } else {
      showModal("check", select);
    }
  };

  return (
    <Modal
      className="modal_style fade"
      size="lg"
      centered
      show={modal === "split"}
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title as="b">
          <FormattedMessage id="Split" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group as={Row} controlId="expect_quantity">
          <Form.Label column lg={3} md={4}>
            <FormattedMessage id="PlannedQuantity" />
          </Form.Label>
          <Col lg={9} md={8}>
            <Form.Control
              onChange={quantityOnChange}
              // value={select.expect_quantity}
              type="number"
              placeholder={intl.formatMessage(
                { id: "InputThe" },
                { name: intl.formatMessage({ id: "PlannedQuantity" }) }
              )}
            />
            <span className="warn" />
          </Col>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-end">
        {alert ? (
          <Alert severity="warning">
            <FormattedMessage id={alertMessage[messageNumber]} />
          </Alert>
        ) : (
          <></>
        )}

        <button className="btn btn-confirm" onClick={checkInput}>
          <FormattedMessage id="OK" />
        </button>
        <button className="btn btn-cancel" onClick={onHide}>
          <FormattedMessage id="Cancel" />
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export const CheckModal = ({ modal, onHide, data }) => {
  const intl = useIntl();
  const { factory } = useContext(Context);

  const submit = () => {
    return new Promise((resolve, reject) => {
      postOrderSplit({
        expect_quantity: data.expect_quantity,
        order_id: data.id,
        factory_id: factory,
      })
        .then(resolve, onHide)
        .catch(reject);
    });
  };
  return (
    <Modal
      className="modal_style fade"
      size="lg"
      centered
      show={modal === "check"}
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title as="b">
          <FormattedMessage id="Split" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group as={Row} controlId="expect_quantity">
          <Form.Label column lg={3} md={4}>
            <FormattedMessage id="PlannedQuantity" />
          </Form.Label>
          <Form.Label column lg={3} md={4}>
            <b style={{ fontSize: 28 }}>{data.expect_quantity}</b>
          </Form.Label>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-end">
        <button className="btn btn-confirm" onClick={submit}>
          <FormattedMessage id="OK" />
        </button>
        <button className="btn btn-cancel" onClick={onHide}>
          <FormattedMessage id="Cancel" />
        </button>
      </Modal.Footer>
    </Modal>
  );
};
export { RunningOrderList, WaitingOrderList, CompletedOrderList };
