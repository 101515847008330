import React, { useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { MdCreate, MdDelete } from "react-icons/md";
import { InputModalBase } from "../management/Management";
import { Table } from "../common";
import { apiGetSql, apiPostSql, apiPutSql } from "../../api.js";

export function SqlTable({ modal, showModal }) {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (!modal) {
      apiGetSql().then((res) => setData(res.data));
    }
  }, [modal]);

  const columns = [
    {
      Header: <FormattedMessage id="account" /> /*帳戶名稱*/,
      accessor: "account",
      Filter: "",
    },
    {
      Header: <FormattedMessage id="connect_ip" /> /*port*/,
      accessor: "connect_ip",
      Filter: "",
    },
    {
      Header: <FormattedMessage id="connect_port" /> /*port*/,
      accessor: "connect_port",
      Filter: "",
    },
    {
      Header: <FormattedMessage id="database_name" /> /*資料庫*/,
      accessor: "database_name",
      Filter: "",
    },
    {
      Header: <FormattedMessage id="Edit" />,
      accessor: "editor",
      Filter: "",
    },
  ];

  const display = data.map((item) => ({
    ...item,
    editor: (
      <span>
        <MdCreate
          className="icon-btn"
          size="20px"
          onClick={() => showModal("edit", item)}
        />
        <MdDelete
          style={{ marginLeft: "10px" }}
          className="icon-btn"
          size="20px"
          onClick={() => showModal("delete", item)}
        />
      </span>
    ),
  }));

  return <Table columns={columns} data={display} />;
}

export const InputModal = ({ modal, onHide, data }) => {
  const intl = useIntl();
  const [select, setSelect] = useState({});
  const [visable, setVisable] = useState(false);

  useEffect(() => {
    setSelect(JSON.parse(JSON.stringify(data)));
  }, [data]);

  const submit = async () => {
    return new Promise((resolve, reject) => {
      if (modal === "new") {
        apiPostSql(select).then(resolve).catch(reject);
      } else {
        apiPutSql(select.id, select).then(resolve).catch(reject);
      }
    });
  };

  const columns = [
    { id: "account", type: "text", required: true },
    {
      id: "password",
      type: "password",
      required: true,
      visable,
      onClick: () => setVisable(!visable),
    },
    { id: "database_name", type: "text", required: true },
    { id: "connect_ip", type: "text", required: true },
    { id: "connect_port", type: "number", required: true, min: 1, max:65535 },
  ];

  const title = intl.formatMessage(
    { id: modal === "new" ? "Create" : "EditThe" },
    { name: intl.formatMessage({ id: "Database" }) }
  );

  return (
    <InputModalBase
      show={modal === "new" || modal === "edit"}
      onHide={onHide}
      title={title}
      columns={columns}
      select={select}
      setSelect={setSelect}
      submit={submit}
    />
  );
};
