import React, { useEffect, useState, useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { MdCreate, MdDelete } from "react-icons/md";
import { InputModalBase } from "../management/Management";
import { Table } from "../common";
import {
  ApiSysGetMachineGroupDataList,
  getDevice,
  ApiSysAddMachineGroupData,
  ApiSysEditMachineGroupData,
} from "../../api";
import Context from "../../context";

function DeviceGroupTable({ showModal, modal }) {
  const [data, setData] = useState([]);
  const { factory } = useContext(Context);

  useEffect(() => {
    if (!modal) {
      loadData();
    }
  }, [modal]);

  const loadData = () => {
    ApiSysGetMachineGroupDataList({
      factory_id: factory,
    }).then((res) => setData(res.data));
  };

  const columns = [
    {
      Header: <FormattedMessage id="DeviceGroupName" /> /*機台群組名稱*/,
      accessor: "Title",
      Filter: "",
    },
    {
      Header: <FormattedMessage id="DeviceName" /> /*機台名稱*/,
      accessor: "deviceName",
      Filter: "",
    },
    {
      Header: <FormattedMessage id="Edit" />,
      accessor: "editor",
      Filter: "",
    },
  ];

  const display = data.map((item) => ({
    ...item,
    deviceName: item.DeviceList.map(({ DeviceName }) => DeviceName).toString(),
    editor: (
      <span>
        <MdCreate
          className="icon-btn"
          size="20px"
          onClick={() => showModal("edit", item)}
        />
        <MdDelete
          style={{ marginLeft: "10px" }}
          className="icon-btn"
          size="20px"
          onClick={() => showModal("delete", item)}
        />
      </span>
    ),
  }));

  return <Table columns={columns} data={display} />;
}

const InputModal = ({ modal, onHide, data }) => {
  const intl = useIntl();
  const { factory } = useContext(Context);
  const [deviceList, setDeviceList] = useState([]);
  const [select, setSelect] = useState({ device_id: [] });

  useEffect(() => {
    getDevice({ factory_id: [factory] }).then((res) => setDeviceList(res.data));
  }, []);

  useEffect(() => {
    if (modal) {
      setSelect(data);
    } else {
      setSelect({ device_id: [] });
    }
  }, [modal, data]);

  const submit = () => {
    return new Promise((resolve, reject) => {
      let req = {
        ...select,
        account: localStorage.getItem("account"),
        factory_id: factory,
      };
      if (modal === "new") {
        ApiSysAddMachineGroupData(req).then(resolve).catch(reject);
      } else {
        ApiSysEditMachineGroupData(req).then(resolve).catch(reject);
      }
    });
  };

  const title = intl.formatMessage(
    { id: modal === "new" ? "Create" : "EditThe" },
    { name: intl.formatMessage({ id: "DeviceGroup" }) }
  );

  const columns = [
    { id: "title", type: "text", required: true },
    {
      id: "device_id",
      type: "multi_check",
      required: true,
      options: deviceList.map(({ id, device_name }) => ({
        label: device_name,
        value: id,
      })),
    },
  ];

  return (
    <InputModalBase
      show={modal === "new" || modal === "edit"}
      onHide={onHide}
      title={title}
      columns={columns}
      select={select}
      setSelect={setSelect}
      submit={submit}
    />
  );
};
export { DeviceGroupTable, InputModal };
