const document = {
      //document 
  ProductionSop: "生產操作SOP",
  QualityInspection: "品質檢驗SIP",
  MoldWaterWayConnection: '模具水路接法圖',
  SelectType:'選擇類型',
  Video:'影片',
  Image:'圖片',
  Document:'文件',
  SelectDocument:'選擇文件',
  SureToDelete:'你確定你要刪除 ?',
  Confirm:'確認',
  NoData:'沒有資料',
  MoldingConditions:"成型條件"

}

export default document;