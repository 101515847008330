import React, { useEffect, useState, useContext } from "react";
import moment from "moment";
import { FormattedMessage, useIntl } from "react-intl";
import {
  DateRadio,
  SingleDatePicker,
  SingleDropdownPicker,
} from "../../component/common";
import { AnalysisTabs, AmountMenu } from "../../component/analysis/Analysis";
import {
  ReportAmountTable,
  ReportCycleTable,
} from "../../component/analysis/amountDaily";
import {
  baseURL,
  apiGetAnalysisDailyReport,
  apiDownloadAnalysisProductQuantityDailyReportExcel,
  apiDownloadAnalysisMachineCycleDailyReportExcel,
} from "../../api.js";
import zh_TW from "../../i18n/zh-tw";
import en from "../../i18n/en";
import Context from "../../context";

export default function AnalysisReport() {
  const today = moment().format("YYYY-MM-DD");
  const yesterday = moment().subtract(1, "d").format("YYYY-MM-DD");
  const intl = useIntl();
  const [date, setDate] = useState(today);
  const [dataInterval, setDataInterval] = useState(2);
  const [data, setData] = useState({
    DailyReportProductQuantityData: [],
    DailyReportMachineCycleData: [],
  });
  const { factory, locale } = useContext(Context);
  let lang = {};
  if (locale === "zh-TW") {
    lang = zh_TW;
  } else {
    lang = en;
  }

  useEffect(() => {
    apiGetAnalysisDailyReport({
      FactoryID: factory,
      CheckDateStart: date,
      CheckTimeInterval: dataInterval,
    })
      .then((res) => setData(res.data))
      .catch(console.log);
  }, [date, dataInterval]);

  const downloadPQReport = () => {
    apiDownloadAnalysisProductQuantityDailyReportExcel({
      FactoryID: factory,
      CheckDateStart: date,
      CheckTimeInterval: dataInterval,
      DataTitle: [
        lang.order_num_name,
        lang.DeviceNumber,
        lang.product_num,
        lang.product_name,
        lang.Total,
      ],
    })
      .then((res) => window.open(`${baseURL}/${res.data}`))
      .catch(console.log);
  };

  const downloadMCReport = () => {
    apiDownloadAnalysisMachineCycleDailyReportExcel({
      FactoryID: factory,
      CheckDateStart: date,
      CheckTimeInterval: dataInterval,
      DataTitle: [lang.DeviceNumber, lang.Total],
    })
      .then((res) => window.open(`${baseURL}/${res.data}`))
      .catch(console.log);
  };

  return (
    <div>
      <AnalysisTabs tab="1" />
      <div className="page-content shadow-lg">
        <div className="ai-center jc-end">
          <AmountMenu tab="1" />
        </div>
        <hr />
        <div className="jc-space-between ai-stretch">
          <div className="ai-stretch">
            <DateRadio
              style={{ marginRight: "20px" }}
              list={[{ label: <FormattedMessage id="Date" />, value: "date" }]}
              value="date"
            />
            <DateRadio
              style={{ marginRight: "20px" }}
              list={[
                { label: <FormattedMessage id="Today" />, value: today },
                {
                  label: <FormattedMessage id="Yesterday" />,
                  value: yesterday,
                },
              ]}
              value={date}
              onChange={(e) => setDate(e.target.value)}
            />
            <SingleDatePicker
              style={{ marginRight: "20px" }}
              date={date}
              onApply={(name, date) => setDate(date)}
            />
          </div>
          <div className="ai-stretch">
            <SingleDropdownPicker
              value={dataInterval}
              list={[
                { label: 1 + intl.formatMessage({ id: "Hour" }), value: 1 },
                { label: 2 + intl.formatMessage({ id: "Hour" }), value: 2 },
                { label: 4 + intl.formatMessage({ id: "Hour" }), value: 4 },
                { label: 8 + intl.formatMessage({ id: "Hour" }), value: 8 },
              ]}
              onApply={setDataInterval}
              style={{ marginRight: "20px" }}
            />
            <button className="btn-gray" onClick={downloadPQReport}>
              <FormattedMessage id="DownloadExcel" />
            </button>
          </div>
        </div>
        <hr />
        <h4 className="m-0">
          <b>
            <FormattedMessage id="ProductAmount" />
          </b>
        </h4>
        <hr />
        <ReportAmountTable
          data={data.DailyReportProductQuantityData}
          totalData={data.DailyReportTotalProductQuantityData}
        />
        <hr />
        <div className="ai-center jc-space-between">
          <h4 className="m-0">
            <b>
              <FormattedMessage id="ModeCount" />
            </b>
          </h4>
          <button className="btn-gray" onClick={downloadMCReport}>
            <FormattedMessage id="DownloadExcel" />
          </button>
        </div>
        <hr />
        <ReportCycleTable data={data.DailyReportMachineCycleData} />
      </div>
    </div>
  );
}
