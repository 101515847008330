import React, { useState, useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import moment from "moment";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { MdPlaylistAdd } from "react-icons/md";
import { PesTabs, DeviceList } from "../../component/mes/mes";
import { BadList, InputModal } from "../../component/mes/defective";
import { ApiPes_bad_delete } from "../../api.js";
import {
  SingleDatePicker,
  DateRadio,
  NewButton,
  DeleteModal,
} from "../../component/common";
import { handleError } from "../../function";
import Context from "../../context";

const today = moment().format("YYYY-MM-DD");

export default function Defective({ checkEditAuth }) {
  const [deviceID, setDeviceID] = useState();
  const [select, setSelect] = useState({});
  const [modal, setModal] = useState();
  const [date, setDate] = useState(today);
  const intl = useIntl();
  const { factory } = useContext(Context);
  const [warn, setWarn] = useState("");

  const showModal = (modal, item = {}) => {
    if (checkEditAuth()) {
      setSelect(item);
      setModal(modal);
    }
  };

  const onHide = () => {
    setModal();
    setWarn("");
    setSelect({});
  };

  const deleteData = () => {
    ApiPes_bad_delete({
      FactoryID: factory,
      account: localStorage.getItem("account"),
      id: select.id,
    })
      .then(onHide)
      .catch((err) => handleError(err, intl));
  };

  return (
    <div>
      <PesTabs tab="1" />
      <div className="page-content shadow-lg pes-area">
        <div className="top-bar ai-stretch">
          <DateRadio
            list={[{ label: <FormattedMessage id="Date" />, value: "date" }]}
          />
          <DateRadio
            list={[
              {
                label: <FormattedMessage id="Yesterday" />,
                value: moment().subtract(1, "d").format("YYYY-MM-DD"),
              },
              { label: <FormattedMessage id="Today" />, value: today },
            ]}
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
          <SingleDatePicker
            name="date"
            date={date}
            onApply={(_, date) => setDate(date)}
          />
        </div>
        <hr />
        <Row>
          <Col lg={4}>
            <DeviceList
              pickDevice={setDeviceID}
              deviceID={deviceID}
              modal={modal}
            />
          </Col>
          <Col lg={8} className="bad-area">
            <div
              className="jc-space-between ai-center"
              style={{ marginBottom: "15px" }}
            >
              <h5 className="m-0 ai-center">
                <MdPlaylistAdd size="30px" style={{ marginRight: "10px" }} />
                <FormattedMessage id="DefectiveList" />
              </h5>
              <NewButton
                onClick={() => showModal("new")}
                text={intl.formatMessage(
                  { id: "Create" },
                  { name: intl.formatMessage({ id: "Defective" }) }
                )}
              />
            </div>
            <BadList
              showModal={showModal}
              deviceID={deviceID}
              date={date}
              modal={modal}
            />
          </Col>
        </Row>
      </div>
      <InputModal
        modal={modal}
        onHide={onHide}
        deviceID={deviceID}
        data={select}
        warn={warn}
        setWarn={setWarn}
      />

      <DeleteModal
        show={modal === "delete"}
        onHide={onHide}
        callback={deleteData}
        name=""
        type="Defective"
      />
    </div>
  );
}
