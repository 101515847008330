import React, { useState, useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Switch from "@material-ui/core/Switch";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import { withStyles } from "@material-ui/core/styles";
import { DeleteModal } from "../component/common";
import {
  QualitySortBar,
  QualityTabs,
  Chart,
} from "../component/quality/Quality";
import {
  QualityAnalysisSortBar,
  ParameterTable,
} from "../component/quality/QualityAnalysis";
import { ApiQuality_AddStandard, ApiQuality_DeleteStandard } from "../api.js";
import Context from "../context";

const columns = [
  { id: "cycle_time", name: "CycleTime" /*週期時間*/ },
  { id: "mold_close_time", name: "MoldCloseTime" /*關模時間*/ },
  { id: "mold_open_time", name: "MoldOpenTime" /*開模時間*/ },
  { id: "inject_time", name: "FillTime" /*射出時間*/ },
  { id: "vp_time", name: "VP切換響應時間" },
  { id: "compress_time", name: "PackTime" /*壓縮時間*/ },
  { id: "hold_time", name: "HoldTime" /*保壓時間*/ },
  { id: "feed_time", name: "SuckBack" /*加料時間*/ },
  { id: "delay_feed_time", name: "ScrewDelayTime" /*延遲加料時間*/ },
  {
    id: "inj_peak_pressure",
    name: "InjectionMaxPressure" /*射出系統壓力峰值*/,
  },
  { id: "inj_melt_pressure", name: "射出熔體壓力峰值" },
  { id: "cut_off_pressure", name: "CutOffPressure" /*射轉保系統壓力*/ },
  { id: "cut_off_melt_pressure", name: "射轉保熔體壓力" },
  { id: "inj_melt_integral", name: "射出熔體壓力積分值" },
  { id: "compress_press", name: "壓縮模穴壓差" },
  { id: "hold_avg_system_pressure", name: "保壓平均系統壓力" },
  { id: "hold_avg_melt_pressure", name: "保壓平均熔體壓力" },
  { id: "hold_velocity_pressure", name: "保壓系統壓力峰值" },
  { id: "hold_melt_pressure", name: "保壓熔體壓力峰值" },
  { id: "hold_pressure_integral", name: "保壓壓力積分值" },
  { id: "feed_avg_pressure", name: "加料平均系統壓力" },
  { id: "feed_pressure_velocity", name: "加料系統壓力峰值" },
  { id: "melt_avg_back", name: "熔體平均背壓" },
  { id: "melt_velocity_back", name: "熔體背壓峰值" },
  { id: "vp_cavity", name: "VP切換點壓力值(模穴)" },
  {
    id: "mold_integral_pressure1",
    name: "CavityPressureIntegral1" /*模穴壓力積分值1*/,
  },
  {
    id: "mold_integral_pressure2",
    name: "CavityPressureIntegral2" /*模穴壓力積分值2*/,
  },
  {
    id: "mold_integral_pressure3",
    name: "CavityPressureIntegral3" /*模穴壓力積分值3*/,
  },
  {
    id: "mold_integral_pressure4",
    name: "CavityPressureIntegral4" /*模穴壓力積分值4*/,
  },
  {
    id: "mold_integral_pressure5",
    name: "CavityPressureIntegral5" /*模穴壓力積分值5*/,
  },
  {
    id: "mold_integral_pressure6",
    name: "CavityPressureIntegral6" /*模穴壓力積分值6*/,
  },
  {
    id: "mold_integral_pressure7",
    name: "CavityPressureIntegral7" /*模穴壓力積分值7*/,
  },
  {
    id: "mold_integral_pressure8",
    name: "CavityPressureIntegral8" /*模穴壓力積分值8*/,
  },
  {
    id: "mold_velocity_pressure1",
    name: "CavityPressurePeak1" /*模穴壓力峰值1*/,
  },
  {
    id: "mold_velocity_pressure2",
    name: "CavityPressurePeak2" /*模穴壓力峰值2*/,
  },
  {
    id: "mold_velocity_pressure3",
    name: "CavityPressurePeak3" /*模穴壓力峰值3*/,
  },
  {
    id: "mold_velocity_pressure4",
    name: "CavityPressurePeak4" /*模穴壓力峰值4*/,
  },
  {
    id: "mold_velocity_pressure5",
    name: "CavityPressurePeak5" /*模穴壓力峰值5*/,
  },
  {
    id: "mold_velocity_pressure6",
    name: "CavityPressurePeak6" /*模穴壓力峰值6*/,
  },
  {
    id: "mold_velocity_pressure7",
    name: "CavityPressurePeak7" /*模穴壓力峰值7*/,
  },
  {
    id: "mold_velocity_pressure8",
    name: "CavityPressurePeak8" /*模穴壓力峰值8*/,
  },
  { id: "pos_inj_start", name: "射出起始位置" },
  {
    id: "vp_position",
    name: "VelocityPressureTransferPoint" /*VP切換響應位置*/,
  },
  { id: "vp_itinerary", name: "VP切換響應行程" },
  { id: "compression_stroke", name: "壓縮行程" },
  { id: "pos_pressure_end", name: "ObtainedCushion_mm" /*保壓完位置*/ },
  {
    id: "pos_bef_dos",
    name: "DecompressionBeforePlasticizePosition" /*前鬆退位置*/,
  },
  { id: "pos_dos_end", name: "PlastEndPosition_mm" /*加料完位置*/ },
  {
    id: "pos_sb_end",
    name: "DecompressionAfterPlasticizePosition" /*後鬆退位置*/,
  },
  { id: "vp_volume", name: "VP切換響應容積" },
  { id: "feed_volume", name: "加料容積" },
  { id: "feed_before_volume", name: "加料前鬆退容積" },
  { id: "feed_after_volume", name: "加料後鬆退容積" },
  { id: "residual_volume", name: "ObtainedCushion_cm3" /*殘量容積*/ },
  { id: "inj_peak_velocity", name: "InjectionMaxVelocity" /*射出速度峰值*/ },
  { id: "avg_inj_speed", name: "平均射出速度" },
  { id: "feed_speed_velocity", name: "加料轉速峰值" },
  { id: "max_plastic_speed", name: "最大塑料塑化線速度" },
  { id: "avg_plastic_speed", name: "平均塑料塑化線速度" },
  { id: "oil_temp", name: "OperatingOilTemperature" /*機台油溫*/ },
  { id: "material_temp1", name: "TemperatureInputZone1" /*第一段料溫*/ },
  { id: "material_temp2", name: "TemperatureInputZone2" /*第二段料溫*/ },
  { id: "material_temp3", name: "TemperatureInputZone3" /*第三段料溫*/ },
  { id: "material_temp4", name: "TemperatureInputZone4" /*第四段料溫*/ },
  { id: "material_temp5", name: "TemperatureInputZone5" /*第五段料溫*/ },
  { id: "material_temp6", name: "TemperatureInputZone6" /*第六段料溫*/ },
  { id: "material_temp7", name: "TemperatureInputZone7" /*第七段料溫*/ },
  { id: "material_temp8", name: "TemperatureInputZone8" /*第八段料溫*/ },
  { id: "throat_temp", name: "HopperTemp" /*入料喉部溫度*/ },
  { id: "max_force", name: "單支柱最大受力" },
  { id: "force_range", name: "大柱受立全距" },
  { id: "cavity_temp", name: "母模模具溫度" },
  { id: "core_temp", name: "公模模具溫度" },
  { id: "mold_controller_temp", name: "模溫機溫度" },
  { id: "mold_controller_flow", name: "模溫機水路流量" },
  {
    id: "inlet_temp1",
    name: "FlowTempModulesActualTempIn1" /*模具水路入水溫度1*/,
  },
  {
    id: "inlet_temp2",
    name: "FlowTempModulesActualTempIn2" /*模具水路入水溫度2*/,
  },
  {
    id: "inlet_temp3",
    name: "FlowTempModulesActualTempIn3" /*模具水路入水溫度3*/,
  },
  {
    id: "inlet_temp4",
    name: "FlowTempModulesActualTempIn4" /*模具水路入水溫度4*/,
  },
  {
    id: "outlet_temp1",
    name: "FlowTempModulesActualTempOut1" /*模具水路出水溫度1*/,
  },
  {
    id: "outlet_temp2",
    name: "FlowTempModulesActualTempOut2" /*模具水路出水溫度2*/,
  },
  {
    id: "outlet_temp3",
    name: "FlowTempModulesActualTempOut3" /*模具水路出水溫度3*/,
  },
  {
    id: "outlet_temp4",
    name: "FlowTempModulesActualTempOut4" /*模具水路出水溫度4*/,
  },
  {
    id: "inlet_flow1",
    name: "FlowTempModulesActualFlowIn1" /*模具水路入水流量1*/,
  },
  {
    id: "inlet_flow2",
    name: "FlowTempModulesActualFlowIn2" /*模具水路入水流量2*/,
  },
  {
    id: "inlet_flow3",
    name: "FlowTempModulesActualFlowIn3" /*模具水路入水流量3*/,
  },
  {
    id: "inlet_flow4",
    name: "FlowTempModulesActualFlowIn4" /*模具水路入水流量4*/,
  },
  {
    id: "outlet_flow1",
    name: "FlowTempModulesActualFlowOut1" /*模具水路出水流量1*/,
  },
  {
    id: "outlet_flow2",
    name: "FlowTempModulesActualFlowOut2" /*模具水路出水流量2*/,
  },
  {
    id: "outlet_flow3",
    name: "FlowTempModulesActualFlowOut3" /*模具水路出水流量3*/,
  },
  {
    id: "outlet_flow4",
    name: "FlowTempModulesActualFlowOut4" /*模具水路出水流量4*/,
  },
  { id: "weight1", name: "Weight1" /*重量1*/ },
  { id: "weight2", name: "Weight2" /*重量2*/ },
  { id: "cooldown_time", name: "ActualCooldownTime" /*實際冷卻時間*/ },
  { id: "ejector_fwd_time", name: "EjectorFwdTime" /*頂出時間*/ },
  { id: "ejector_bwd_time", name: "EjectorBwdTime" /*頂退時間*/ },
  { id: "ejector_shake_time", name: "EjectorShakeTime" /*震動時間*/ },
  { id: "nozzle_bwd_time", name: "NozzleBwdTime" /*座退時間*/ },
  { id: "nozzle_fwd_time", name: "NozzleFwdTime" /*座進時間*/ },
  { id: "air_valve_time", name: "AirValveTime" /*吹風時間*/ },
  { id: "core_movein_act_time", name: "CoreMoveInActTime" /*中子入*/ },
  { id: "core_moveout_act_time", name: "CoreMoveOutActTime" /*中子出*/ },
  { id: "robot_time", name: "RobotTime" /*機械手時間*/ },
  {
    id: "zozzle_fwd_contactact",
    name: "NozzleContactHoldTime" /*座進保持時間*/,
  },
  { id: "hold_max_pressure", name: "HoldMaxPressure" /*保壓壓力峰值*/ },
  { id: "hold_max_velocity", name: "HoldMaxVelocity" /*保壓速度峰值*/ },
  { id: "lock_magic_power1", name: "TieBarPressure1" /*鎖模力1*/ },
  { id: "lock_magic_power2", name: "TieBarPressure2" /*鎖模力2*/ },
  { id: "lock_magic_power3", name: "TieBarPressure3" /*鎖模力3*/ },
  { id: "lock_magic_power4", name: "TieBarPressure4" /*鎖模力4*/ },
  { id: "b_inject_time", name: "InjBFillTime" /*B射射出時間*/ },
  { id: "b_vp_time", name: "B射VP切換響應時間" },
  { id: "b_compress_time", name: "InjBPackTime" /*B射壓縮時間*/ },
  { id: "b_hold_time", name: "InjBHoldTime" /*B射保壓時間*/ },
  { id: "b_feed_time", name: "InjBPlastTime" /*B射加料時間*/ },
  { id: "b_delay_feed_time", name: "InjBDelayPastTime" /*B射延遲加料時間*/ },
  {
    id: "b_inj_peak_pressure",
    name: "InjBInjectionMaxPressure" /*B射射出系統壓力峰值*/,
  },
  { id: "b_inj_melt_pressure", name: "B射射出熔體壓力峰值" },
  {
    id: "b_cut_off_pressure",
    name: "InjBCutOffPressure" /*B射射轉保系統壓力*/,
  },
  { id: "b_cut_off_melt_pressure", name: "B射射轉保熔體壓力" },
  { id: "b_inj_melt_integral", name: "B射射出熔體壓力積分值" },
  { id: "b_hold_avg_system_pressure", name: "B射保壓平均系統壓力" },
  { id: "b_hold_avg_melt_pressure", name: "B射保壓平均熔體壓力" },
  { id: "b_hold_velocity_pressure", name: "B射保壓系統壓力峰值" },
  { id: "b_hold_melt_pressure", name: "B射保壓熔體壓力峰值" },
  { id: "b_hold_pressure_integral", name: "B射保壓壓力積分值" },
  { id: "b_feed_avg_pressure", name: "B射加料平均系統壓力" },
  { id: "b_feed_pressure_velocity", name: "B射加料系統壓力峰值" },
  { id: "b_melt_avg_back", name: "B射熔體平均背壓" },
  { id: "b_melt_velocity_back", name: "B射熔體背壓峰值" },
  { id: "b_pos_inj_start", name: "B射射出起始位置" },
  { id: "b_vp_position", name: "InjBCutOffPosition" /*B射VP切換響應位置*/ },
  { id: "b_vp_itinerary", name: "B射VP切換響應行程" },
  { id: "b_compression_stroke", name: "B射壓縮行程" },
  { id: "b_pos_pressure_end", name: "InjBHoldEndPosition" /*B射保壓完位置*/ },
  { id: "b_pos_bef_dos", name: "B射前鬆退位置" },
  { id: "b_pos_dos_end", name: "InjBPlastEndPosition" /*B射加料完位置*/ },
  { id: "b_pos_sb_end", name: "B射後鬆退位置" },
  { id: "b_vp_volume", name: "B射VP切換響應容積" },
  { id: "b_feed_volume", name: "B射加料容積" },
  { id: "b_feed_before_volume", name: "B射加料前鬆退容積" },
  { id: "b_feed_after_volume", name: "B射加料後鬆退容積" },
  { id: "b_residual_volume", name: "InjBCushion" /*B射殘量容積*/ },
  {
    id: "b_inj_peak_velocity",
    name: "InjBInjectionMaxVelocity" /*B射射出速度峰值*/,
  },
  { id: "b_avg_inj_speed", name: "B射平均射出速度" },
  { id: "b_feed_speed_velocity", name: "B射加料轉速峰值" },
  { id: "b_max_plastic_speed", name: "B射最大塑料塑化線速度" },
  { id: "b_avg_plastic_speed", name: "B射平均塑料塑化線速度" },
  {
    id: "b_material_temp1",
    name: "InjBTemperatureInputZone1" /*B射第一段料溫*/,
  },
  {
    id: "b_material_temp2",
    name: "InjBTemperatureInputZone2" /*B射第二段料溫*/,
  },
  {
    id: "b_material_temp3",
    name: "InjBTemperatureInputZone3" /*B射第三段料溫*/,
  },
  {
    id: "b_material_temp4",
    name: "InjBTemperatureInputZone4" /*B射第四段料溫*/,
  },
  {
    id: "b_material_temp5",
    name: "InjBTemperatureInputZone5" /*B射第五段料溫*/,
  },
  {
    id: "b_material_temp6",
    name: "InjBTemperatureInputZone6" /*B射第六段料溫*/,
  },
  { id: "b_throat_temp", name: "InjBThroatTemperature" /*B射入料喉部溫度*/ },
  { id: "b_cooldown_time", name: "InjBActualCoolingTime" /*B射實際冷卻時間*/ },
];

function Quality({}) {
  const [columnName, setColumnName] = useState("test");
  const [control_limit, setLimit] = useState({});
  const [data, setData] = useState([]);
  const intl = useIntl();
  const { factory } = useContext(Context);

  const loadData = (resData, col_id) => {
    setData(resData.data);
    setLimit({
      CLx: resData.CLx,
      UCLx: resData.UCLx,
      LCLx: resData.LCLx,
      CLr: resData.CLr,
      UCLr: resData.UCLr,
      LCLr: resData.LCLr,
    });
    let col_name = columns.filter((obj) => obj.id == col_id);
    if (col_name.length > 0) col_name = col_name[0].name;
    setColumnName(intl.formatMessage({ id: col_name }));
  };

  const clearData = () => {
    setData([]);
    setLimit({});
  };

  const putIn = (mold_id, column_id, data) => {
    let dataObj = {
      factory_id: factory,
      mold_id: mold_id,
      parameter_best_name: column_id,
      date: data.date + " " + data.hour,
      set: Math.floor(data.total / data.point),
      point: data.point,
      CLx: data.CLx,
      UCLx: data.UCLx,
      LCLx: data.LCLx,
      CLr: data.CLr,
      UCLr: data.UCLr,
      LCLr: data.LCLr,
      enable: false,
    };

    ApiQuality_AddStandard({
      dataObject: dataObj,
    })
      .then(() => {
        alert("填入成功");
      })
      .catch(() => {
        alert("填入失敗");
      });
  };

  const collapse = (e) => {
    if (
      !(
        e.target.classList.contains("dropdown-toggle") ||
        e.target.classList.contains("dropdown-item") ||
        e.target.classList.contains("form-check-input") ||
        e.target.classList.contains("form-check-label")
      )
    ) {
      document
        .querySelectorAll(".dropdown-menu")
        .forEach((item) => item.classList.remove("show"));
    }
  };

  return (
    <React.Fragment>
      <div className="analysis_area clearfix" onClick={collapse}>
        <QualityTabs tab="1" />
        <div
          className="tab-content tabs_content shadow-lg clearfix"
          style={{ overflow: "unset" }}
        >
          <QualitySortBar
            loadData={loadData}
            clearData={clearData}
            columns={columns}
            putIn={putIn}
          />
          <div>
            <span
              style={{
                fontSize: "24px",
                fontWeight: "bold",
                fontFamily: "Roboto",
                color: "#333333",
              }}
            >
              平均值管制圖
            </span>
          </div>
          <hr />
          <Chart
            column={columnName}
            CL={control_limit.CLx}
            UCL={control_limit.UCLx}
            LCL={control_limit.LCLx}
            data={data}
            tail="x"
          />
          <div>
            <span
              style={{
                fontSize: "24px",
                fontWeight: "bold",
                fontFamily: "Roboto",
                color: "#333333",
              }}
            >
              全域管制圖
            </span>
          </div>
          <hr />
          <Chart
            column={columnName}
            CL={control_limit.CLr}
            UCL={control_limit.UCLr}
            LCL={control_limit.LCLr}
            data={data}
            tail="r"
          />
        </div>
      </div>
    </React.Fragment>
  );
}

function QualityAnalysis({}) {
  const tableColumns = [
    {
      Header: <FormattedMessage id="Item" /> /*項目*/,
      accessor: "item",
      Filter: "",
      name: "Item",
    },
    {
      Header: <FormattedMessage id="Date" /> /*日期*/,
      accessor: "date",
      Filter: "",
      name: "Date",
    },
    {
      Header: <FormattedMessage id="NumberofSample" /> /*樣本數*/,
      accessor: "sample_num",
      Filter: "",
      name: "NumberofSample",
    },
    {
      Header: <FormattedMessage id="NumberofGroup" /> /*組數*/,
      accessor: "group_num",
      Filter: "",
      name: "NumberofGroup",
    },
    {
      Header: <FormattedMessage id="CenterLineX" /> /*平均中心線CLx*/,
      accessor: "clx",
      Filter: "",
      name: "CenterLineX",
    },
    {
      Header: <FormattedMessage id="UpperControlLimitX" /> /*平均上管制線UCLx*/,
      accessor: "uclx",
      Filter: "",
      name: "UpperControlLimitX",
    },
    {
      Header: <FormattedMessage id="LowerControlLimitX" /> /*平均下管制線LCLx*/,
      accessor: "lclx",
      Filter: "",
      name: "LowerControlLimitX",
    },
    {
      Header: <FormattedMessage id="CenterLineR" /> /*全距中心線CLr*/,
      accessor: "clr",
      Filter: "",
      name: "CenterLineR",
    },
    {
      Header: <FormattedMessage id="UpperControlLimitR" /> /*全距上管制線UCLr*/,
      accessor: "uclr",
      Filter: "",
      name: "UpperControlLimitR",
    },
    {
      Header: <FormattedMessage id="LowerControlLimitR" /> /*全距下管制線LCLr*/,
      accessor: "lclr",
      Filter: "",
      name: "LowerControlLimitR",
    },
  ];
  const [mold, setMold] = useState();
  const [column, setColumn] = useState({});
  const [reload, setReload] = useState(false);
  const [modal, setModal] = useState();
  const [select, setSelect] = useState({});
  const intl = useIntl();
  const { factory } = useContext(Context);

  const IOSSwitch = withStyles((theme) => ({
    root: {
      width: 70,
      height: 34,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 2,
      "&$checked": {
        transform: "translateX(36px)",
        color: theme.palette.common.white,
        "& + $track": {
          backgroundColor: "#2196F3",
          opacity: 1,
          border: "none",
        },
      },
      "&$focusVisible $thumb": {
        color: "#2196F3",
        border: "6px solid #fff",
      },
    },
    thumb: {
      width: 30,
      height: 30,
    },
    track: {
      borderRadius: 34 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: "#CCC",
      opacity: 1,
      transition: theme.transitions.create(["background-color", "border"]),
    },
    checked: {},
    focusVisible: {},
  }))(({ classes, ...props }) => {
    return (
      <Switch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        {...props}
      />
    );
  });

  const showModal = async (modal, item = {}) => {
    setModal(modal);
    setSelect(item);
  };

  const closeModal = () => {
    setModal(null);
  };

  const loadData = (selectedMold, selectedColumn) => {
    let selectedColObject = columns.filter(
      (col) => col.id == selectedColumn
    )[0];
    selectedColObject.name = intl.formatMessage({ id: selectedColObject.name });
    setMold(selectedMold);
    setColumn(selectedColObject);
    setReload(!reload);
  };

  const switchToggle = (e) => {
    setSelect((prev) => ({ ...prev, monitor: e.target.checked }));
  };

  const onChange = (e) => {
    const { id, value } = e.currentTarget;
    setSelect((prev) => ({ ...prev, [id]: value }));
  };

  const pickDate = (e, picker) => {
    setSelect((prev) => ({
      ...prev,
      [e.currentTarget.firstChild.id]: picker.startDate.format("YYYY-MM-DD"),
    }));
  };

  const editParameters = async () => {
    console.log(select);
    let dataObj = {
      factory_id: factory,
      id: select.id,
      CLx: parseFloat(select.clx),
      UCLx: parseFloat(select.uclx),
      LCLx: parseFloat(select.lclx),
      CLr: parseFloat(select.clr),
      UCLr: parseFloat(select.uclr),
      LCLr: parseFloat(select.lclr),
      enable: select.monitor,
    };

    try {
      await ApiQuality_AddStandard({
        dataObject: dataObj,
      });
      alert("修改完成！");
      setReload(!reload);
    } catch (e) {
      console.log(e);
      alert("修改失敗！");
    }
    setModal(null);
  };

  const deleteData = async () => {
    try {
      await ApiQuality_DeleteStandard({
        id: select.id,
        factory_id: factory,
      });
      setReload(!reload);
    } catch (e) {
      console.log(e);
    }
    setModal(null);
  };

  const collapse = (e) => {
    if (
      !(
        e.target.classList.contains("dropdown-toggle") ||
        e.target.classList.contains("dropdown-item") ||
        e.target.classList.contains("form-check-input") ||
        e.target.classList.contains("form-check-label")
      )
    ) {
      document
        .querySelectorAll(".dropdown-menu")
        .forEach((item) => item.classList.remove("show"));
    }
  };

  return (
    <React.Fragment>
      <div className="analysis_area clearfix" onClick={collapse}>
        <QualityTabs tab="2" />
        <div
          className="tab-content tabs_content shadow-lg clearfix"
          style={{ overflow: "unset" }}
        >
          <QualityAnalysisSortBar loadData={loadData} columns={columns} />
          <div>
            <span
              style={{
                fontSize: "24px",
                fontWeight: "bold",
                fontFamily: "Roboto",
                color: "#333333",
              }}
            >
              參數表
            </span>
          </div>
          <div className="work_area no_scroll clearfix">
            <ParameterTable
              tableColumns={tableColumns}
              selectedColumn={column}
              selectedMold={mold}
              showModal={showModal}
              reload={reload}
            />
          </div>
        </div>
      </div>

      <Modal
        className="modal_style fade"
        size="lg"
        show={modal === "edit"}
        onHide={closeModal}
      >
        <Modal.Header closeButton>
          <Modal.Title as="h5">
            <b>
              <FormattedMessage id="ModifyParameters" />
            </b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {tableColumns.map((item) =>
              item.accessor.includes("date") ? (
                <Form.Group as={Row} controlId={item.accessor}>
                  <Form.Label column lg={3} md={4}>
                    <FormattedMessage id={item.name} />
                  </Form.Label>
                  <Col lg={9} md={8}>
                    <DateRangePicker
                      initialSettings={{
                        singleDatePicker: true,
                        autoApply: true,
                        startDate: select[item.accessor],
                        locale: { format: "YYYY-MM-DD" },
                        autoUpdateInput: false,
                      }}
                      onApply={pickDate}
                    >
                      <Form.Control
                        placeholder={
                          intl.formatMessage({ id: "PleaseEnterThe" }) +
                          intl.formatMessage({ id: "Date" })
                        }
                        onChange={onChange}
                        value={select[[item.accessor]]}
                        autoComplete="off"
                      />
                    </DateRangePicker>
                    <span className="warn" />
                  </Col>
                </Form.Group>
              ) : (
                <Form.Group as={Row} controlId={item.accessor}>
                  <Form.Label column lg={3} md={4}>
                    <FormattedMessage id={item.name} />
                  </Form.Label>
                  <Col lg={9} md={8}>
                    <Form.Control
                      placeholder={
                        intl.formatMessage({ id: "PleaseEnterThe" }) +
                        intl.formatMessage({ id: item.name })
                      }
                      onChange={onChange}
                      value={select[item.accessor]}
                    />
                  </Col>
                  <span className="warn" />
                </Form.Group>
              )
            )}
            {
              <Form.Group as={Row} controlId="monitor">
                <Form.Label column lg={3} md={4}>
                  <FormattedMessage id="Monitor" />
                </Form.Label>
                <Col lg={9} md={8}>
                  <IOSSwitch
                    checked={select["monitor"]}
                    name="monitorSwitch"
                    onChange={switchToggle}
                  />
                  <span className="warn" />
                </Col>
              </Form.Group>
            }
          </Form>
        </Modal.Body>
        <Modal.Footer className="justify-content-end">
          <div className="d-flex">
            <button className="btn btn-confirm" onClick={editParameters}>
              <FormattedMessage id="Modify" />
            </button>
            <button className="btn btn-cancel" onClick={closeModal}>
              <FormattedMessage id="Cancel" />
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      <DeleteModal
        show={modal === "delete"}
        onHide={closeModal}
        callback={deleteData}
        name={select.item}
        type="Mold"
      />
    </React.Fragment>
  );
}

export { Quality, QualityAnalysis };
