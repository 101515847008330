import React, { useEffect, useState, useContext } from "react";
import { FormattedMessage } from "react-intl";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { MdPlayCircleFilled, MdEventAvailable } from "react-icons/md";
import { PesTabs, DeviceList } from "../../component/mes/mes";
import {
  RunningOrderList,
  WaitingOrderList,
  CompletedOrderList,
  SplitModal,
  StartModal,
  FinishModal,
  PauseModal,
  CheckModal,
} from "../../component/mes/production";
import {
  ApiPes_GetDeviceCurrentOrderData,
} from "../../api.js";
import Context from "../../context";

export default function Pes({ checkEditAuth }) {
  const { factory } = useContext(Context);
  const [modal, setModal] = useState();
  const [deviceID, setDeviceID] = useState();
  const [orderList, setOrderList] = useState([]);
  const [select, setSelect] = useState({});

  useEffect(() => {
    loadData();
    const interval = setInterval(loadData, 10000);
    return () => clearInterval(interval);
  }, [deviceID, factory]);

  useEffect(() => {
    if (!modal) {
      loadData();
    }
  }, [modal]);

  const loadData = () => {
    if (deviceID) {
      ApiPes_GetDeviceCurrentOrderData({
        FactoryID: factory,
        DeviceID: deviceID,
      })
        .then((res) =>
          setOrderList(
            res.data.sort((a, b) => {
              if (a.order_group_name > b.order_group_name) {
                return 1;
              }
              if (a.order_group_name < b.order_group_name) {
                return -1;
              }
              return 0;
            })
          )
        )
        .catch(console.log);
    }
  };

  const showModal = (modal, order) => {
    if (!checkEditAuth()) {
      return;
    }
    setSelect(order);
    setModal(modal);
  };

  const onHide = () => {
    setModal();
  };

  return (
    <div>
      <PesTabs />
      <div className="page-content shadow-lg pes-area">
        <Row>
          <Col lg={4}>
            <DeviceList
              pickDevice={setDeviceID}
              deviceID={deviceID}
              modal={modal}
            />
          </Col>
          <Col lg={8} className="order-area flex-column jc-space-between">
            <div className="mb-3">
              <h5 className="ai-center">
                <MdPlayCircleFilled style={{ marginRight: "10px" }} />
                <FormattedMessage id="OrderRunning" />
              </h5>
              <RunningOrderList showModal={showModal} data={orderList.filter((item) => item.order_status == 2 || item.order_status == 5)} />
            </div>
            <div className="mb-3">
              <h5 className="ai-center">
                <MdPlayCircleFilled style={{ marginRight: "10px" }} />
                <FormattedMessage id="NextOrder" />
              </h5>
              <WaitingOrderList
                showModal={showModal}
                data={orderList.filter((item) => item.order_status == 1)}
                busy={orderList[0]}
              />
            </div>
            <div>
              <h5 className="ai-center">
                <MdEventAvailable style={{ marginRight: "10px" }} />
                <FormattedMessage id="OrderDoneIn24Hours" />
              </h5>
              <CompletedOrderList showModal={showModal} deviceID={deviceID} />
            </div>
          </Col>
        </Row>
      </div>

      <PauseModal modal={modal} onHide={onHide} data={select} />
      <FinishModal
        modal={modal}
        onHide={onHide}
        data={select}
        showModal={showModal}
        orderList={orderList}
        setOrderList={setOrderList}
      />
      <StartModal
        modal={modal}
        onHide={onHide}
        data={select}
        deviceID={deviceID}
      />
      <SplitModal
        modal={modal}
        onHide={onHide}
        data={select}
        showModal={showModal}
      />
      <CheckModal
        modal={modal}
        onHide={onHide}
        data={select}
        showModal={showModal}
      />
    </div>
  );
}
