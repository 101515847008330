import React, { useContext, useEffect, useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { MdCreate, MdDelete } from "react-icons/md";
import { InputModalBase } from "./Management";
import { ColumnFilter, Table } from "../common";
import { showWarn } from "../../function";
import {
  ApiManagement_new_holiday,
  ApiManagement_update_holiday,
} from "../../api";
import Context from "../../context";

export function HolidayTable({ data, showModal }) {
  const intl = useIntl();
  const columns = [
    {
      Header: <FormattedMessage id="is_holiday" /> /*描述*/,
      accessor: "is_holiday",
      Filter: ({ column }) => ColumnFilter(column, "is_holiday"),
    },
    {
      Header: <FormattedMessage id="Date" />,
      accessor: "holiday",
      Filter: ({ column }) => ColumnFilter(column, "Date"),
    },
    {
      Header: <FormattedMessage id="Edit" />,
      accessor: "editor",
      Filter: "",
      defaultCanSort: false,
    },
  ];

  const display = data.map((item) => ({
    ...item,
    is_holiday:
      item.is_holiday === 1
        ? intl.formatMessage({ id: "Workday" })
        : intl.formatMessage({ id: "Holiday" }),
    editor: (
      <span>
        <MdCreate
          className="icon-btn"
          size="20px"
          onClick={() => showModal("edit", item)}
        />
        <MdDelete
          style={{ marginLeft: "10px" }}
          className="icon-btn"
          size="20px"
          onClick={() => showModal("delete", item)}
        />
      </span>
    ),
  }));

  return <Table columns={columns} data={display} />;
}

export const InputModal = ({ modal, onHide, data, list }) => {
  const intl = useIntl();
  const { factory } = useContext(Context);
  const [select, setSelect] = useState({});
  const columns = [
    {
      id: "is_holiday",
      type: "select",
      required: true,
      options: [
        { label: intl.formatMessage({ id: "Workday" }), value: 1 },
        { label: intl.formatMessage({ id: "Holiday" }), value: 2 },
      ],
    },
    { id: "holiday", type: "date", required: true },
  ];

  useEffect(() => {
    setSelect(JSON.parse(JSON.stringify(data)));
  }, [data]);

  const submit = () => {
    return new Promise((resolve, reject) => {
      let pass = true;
      if (
        list.findIndex(
          ({ id, holiday }) => holiday === select.holiday && id !== select.id
        ) > -1
      ) {
        showWarn("holiday-warn", "Existed", intl);
        pass = false;
      }
      if (pass) {
        let req = {
          account: localStorage.getItem("account"),
          factory_id: factory,
          isHoliday: select.is_holiday,
          date: select.holiday,
        };
        if (modal === "new") {
          ApiManagement_new_holiday(req).then(resolve).catch(reject);
        } else {
          req.holiday_id = select.id;
          ApiManagement_update_holiday(req).then(resolve).catch(reject);
        }
      }
    });
  };

  const title = intl.formatMessage(
    { id: modal === "new" ? "Create" : "EditThe" },
    { name: intl.formatMessage({ id: "is_holiday" }) }
  );

  return (
    <InputModalBase
      show={modal === "new" || modal === "edit"}
      onHide={onHide}
      title={title}
      columns={columns}
      select={select}
      setSelect={setSelect}
      submit={submit}
    />
  );
};
