import React, { useState, useContext } from "react";
import { useIntl } from "react-intl";
import moment from "moment";
import { PesTabs } from "../../component/mes/mes";
import { HandoverTable, InputModal } from "../../component/mes/Handover";
import { ApiPes_deleteHandoverReport } from "../../api.js";
import {
  RangeDatePicker,
  NewButton,
  DeleteModal,
  handleError,
} from "../../component/common";
import Context from "../../context";

const today = moment().format("YYYY-MM-DD");

function Handover({ checkEditAuth }) {
  const { factory } = useContext(Context);
  const intl = useIntl();
  const [date, setDate] = useState({ startDate: today, endDate: today });
  const [modal, setModal] = useState();
  const [select, setSelect] = useState({ deviceIDList: [] });

  const showModal = (
    modal,
    item = { deviceIDList: [] }
  ) => {
    if (!checkEditAuth()) {
      return;
    }
    // if (modal === "edit") {
    //   const arr = item.shift.split(" ");
    //   item.date = arr[1];
    //   item.shift = `${arr[0]}_${arr[2]}`;
    // }
    setSelect(item);
    setModal(modal);
  };
  const onHide = () => {
    setModal();
  };

  const deleteData = () => {
    ApiPes_deleteHandoverReport({
      ReportID: select.reportIDList,
      FactoryID: factory,
    }).then(onHide).catch(e => handleError(e, intl));
  };

  return (
    <div>
      <PesTabs tab="3" />
      <div className="page-content shadow-lg pes-area">
        <div className="d-flex justify-content-between">
          <RangeDatePicker
            startDate={date.startDate}
            endDate={date.endDate}
            onApply={(startDate, endDate) => setDate({ startDate, endDate })}
          />
          <NewButton
            onClick={() => showModal("new")}
            text={intl.formatMessage(
              { id: "Create" },
              { name: intl.formatMessage({ id: "HandoverReport" }) }
            )}
          />
        </div>
        <hr />
        <HandoverTable showModal={showModal} modal={modal} date={date} />
      </div>
      <InputModal modal={modal} onHide={onHide} data={select} />

      <DeleteModal
        show={modal === "delete"}
        onHide={onHide}
        callback={deleteData}
        name={select.shift}
        type="HandoverReport"
      />
    </div>
  );
}

export default Handover;