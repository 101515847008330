import React, { useEffect, useMemo, useState, useContext } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment';
import { FormattedMessage, useIntl } from "react-intl";
import { ApiSysGetMachineGroupDataList } from '../../api';
import Context from "../../context";

function SingleDeviceList({ handleSelect, checked, style }) {
	const [groupList, setGroupList] = useState([]);
	const [group, setGroup] = useState({ DeviceList: [] });
	const { DeviceList } = group;
	const { factory } = useContext(Context);

	useEffect(() => {
		ApiSysGetMachineGroupDataList({ factory_id: factory }).then(res => {
			setGroupList(res.data);
			if ("analysisDeviceGroup" in localStorage) {
				const group = res.data.find((item) => item.GroupID === parseInt(localStorage.getItem("analysisDeviceGroup")))
				if (group) {
					setGroup(group);
					if ("analysisDevice" in localStorage) {
						const device = group.DeviceList.find((item) => item.DeviceID === parseInt(localStorage.getItem("analysisDevice")))
						if (device) {
							handleSelect(device.DeviceID);
						} else {
							localStorage.removeItem("analysisDevice");
						}
					}
				} else {
					localStorage.removeItem("analysisDeviceGroup");
				}
			}
		});
	}, []);

	const _handleSelect = (deviceID) => {
		localStorage.setItem("analysisDevice", deviceID);
		handleSelect(deviceID);
	}

	const pickGroup = (group) => {
		localStorage.setItem("analysisDeviceGroup", group.GroupID);
		localStorage.removeItem("analysisDevice");
		setGroup(group);
	}

	const back = () => {
		localStorage.removeItem("analysisDeviceGroup");
		localStorage.removeItem("analysisDevice");
		setGroup({ DeviceList: [] });
	}

	return (
		<div className="analysis-list" style={style}>
			<ul className="list-group list-unstyled mb-0">
				{DeviceList.length === 0 ?
					groupList.map(row => (
						<li className="list-group-item" key={row.GroupID} onClick={() => pickGroup(row)}>
							<span>{row.Title}</span>
						</li>
					)) :
					DeviceList.map(row => (
						<li className={`list-group-item ${row.DeviceID === checked ? "checked" : ""}`} key={row.DeviceID} onClick={() => _handleSelect(row.DeviceID)}>
							<span>{row.DeviceName}</span>
						</li>
					))
				}
				{DeviceList.length === 0 ?
					null :
					<li className="list-group-item" onClick={back}>
						<span>上一層</span>
					</li>
				}
			</ul>
		</div>
	)
}

function AnalysisPie({ data }) {
	const title = ['OEE', <FormattedMessage id="Utilization" />/*稼動率*/, <FormattedMessage id="Performance" />/*效率*/, <FormattedMessage id="Yield" />/*良率*/];
	const keys = ['oee', 'utilization', 'performance', 'yield'];
	return (
		<ul className="pie_inner list-unstyled mb-0 jc-space-between">
			{keys.map((key, index) => {
				return (
					<li key={keys[index]}>
						<div className="d-flex justify-content-center">
							<div
								className={"pie_chart rounded js_number d-flex justify-content-center align-items-center rounded-circle pct_" + (Math.min(data[key], 100))}>
								<div className="pie_info">
									<h4 className="text-center mt-0"><span><b className="js_count">{data[key]}</b><small>%</small></span></h4>
									<h6 className="text-center m-0"><span>{title[index]}</span></h6>
								</div>
							</div>
						</div>
					</li>
				)
			})}
		</ul>
	)
}

function AnalysisPtqtyChart({ data, date, sort }) {
	const intl = useIntl();
	const startTime = moment(sort === 'dateTime' ? `${date.startDate} ${date.startTime}` : date.startDate);
	const options = {
		chart: { type: 'column', height: 250 },
		colors: ['#97B9FF'],
		credits: { enabled: false },
		title: null,
		xAxis: {
			type: 'datetime',
		},
		yAxis: { visible: false },
		legend: { enabled: false },
		exporting: { enabled: false },
		series: [{
			type: 'column',
			name: intl.formatMessage({ id: 'ProductionQuantity' }),
			data: data,
			pointStart: Date.UTC(...startTime.toArray()),
			pointInterval: 1000 * 60 * 30,
			dataLabels: {
				enabled: true,
				rotation: 0,
				color: '#4F86F2',
				align: 'center',
				style: {
					fontSize: '12px',
					textOutline: 'none'
				}
			}
		}]
	};
	return (
		<HighchartsReact
			highcharts={Highcharts}
			options={options}
		/>
	)
}

function AnalysisDeviceChart({ data, date, sort, name }) {
	const startTime = moment(sort === 'dateTime' ? `${date.startDate} ${date.startTime}` : date.startDate);
	const options = useMemo(() => ({
		chart: { height: 250 },
		colors: ['#9D8AFC'],
		credits: { enabled: false },
		title: null,
		xAxis: {
			type: 'datetime',
		},
		yAxis: { enabled: false },
		legend: { enabled: false },
		plotOptions: {
			area: { fillColor: '#E7E2FD' },
		},
		exporting: { enabled: false },
		series: [{
			type: 'area',
			name: name,
			data: data,
			pointStart: Date.UTC(...startTime.toArray()),
			pointInterval: 1000 * 60 * 30,
			dataLabels: {
				enabled: true,
				rotation: 0,
				color: '#6B54DE',
				align: 'center',
				style: {
					color: '#6B54DE',
					fontSize: '12px',
					textOutline: 'none'
				}
			}
		}]
	}), [data]);

	return (
		<HighchartsReact
			highcharts={Highcharts}
			options={options}
		/>
	)
}

function AnalysisCycleChart({ data, date, sort }) {
	const startTime = moment(sort === 'dateTime' ? `${date.startDate} ${date.startTime}` : date.startDate);
	const intl = useIntl();
	const options = {
		chart: { height: 250 },
		colors: ['#EE3554'],
		credits: { enabled: false },
		title: null,
		xAxis: {
			type: 'datetime',
		},
		yAxis: { enabled: false },
		legend: { enabled: false },
		exporting: { enabled: false },
		series: [{
			type: 'line',
			name: intl.formatMessage({ id: 'AverageCycle' }),
			data: data,
			pointStart: Date.UTC(...startTime.toArray()),
			pointInterval: 1000 * 60 * 10,
		}]
	};

	return (
		<HighchartsReact
			highcharts={Highcharts}
			options={options}
		/>
	)
}
export {
	SingleDeviceList,
	AnalysisPie,
	AnalysisPtqtyChart,
	AnalysisDeviceChart,
	AnalysisCycleChart,
};
