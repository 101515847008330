import React, { useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { MdCreate, MdDelete } from "react-icons/md";
import { Table } from "../common";
import {
  getDevice,
  postDeviceImage,
  postFactoryDevice,
  putFactoryDevice,
  getDeviceImage,
  baseURL,
} from "../../api.js";
import { InputModalBase } from "../management/Management";

function DeviceTable({ modal, showModal, factory_id, connectionList }) {
  const [data, setData] = useState([]);
  useEffect(() => {
    if (!modal && factory_id.length !== 0) {
      getDevice({ factory_id })
        .then(async (res) => {
          res.data.forEach((item) => {
            item.connection_name =
              connectionList[item.connection_id - 1]?.connection_name;
          });
          for (let i = 0; i < res.data.length; ++i) {
            if (res.data[i].photo_path) {
              try {
                const { data } = await getDeviceImage(res.data[i].photo_path);
                res.data[i].photo = data;
              } catch (e) {
                res.data[i].photo = `${baseURL}/${res.data[
                  i
                ].photo_path.replace("web/", "")}`;
              }
            }
          }
          setData(res.data);
        })
        .catch(console.log);
    } else if (factory_id.length === 0) {
      setData([]);
    }
  }, [modal, factory_id]);

  const columns = [
    {
      Header: <FormattedMessage id="Photo" /> /*照片*/,
      accessor: "photo",
      Cell: ({ cell: { value } }) => (
        <img src={value} className="fit-contain" style={{ width: "100px" }} />
      ),
      Filter: "",
    },
    {
      Header: <FormattedMessage id="device_num" /> /*機台編號*/,
      accessor: "device_num",
      Filter: "",
    },
    {
      Header: <FormattedMessage id="FactoryName" /> /*工廠名稱*/,
      accessor: "factory_name",
      Filter: "",
    },
    {
      Header: <FormattedMessage id="manufacturer" />,
      accessor: "manufacturer",
      Filter: "",
    },
    {
      Header: <FormattedMessage id="device_name" /> /*機台名稱*/,
      accessor: "device_name",
      Filter: "",
    },
    {
      Header: <FormattedMessage id="tonnage" /> /*噸數*/,
      accessor: "tonnage",
      Filter: "",
    },
    {
      Header: <FormattedMessage id="connection_id" /> /*連線方式*/,
      accessor: "connection_name",
      Filter: "",
    },
    {
      Header: <FormattedMessage id="Edit" />,
      accessor: "editor",
      Filter: "",
    },
  ];

  const display = data.map((item) => ({
    ...item,
    editor: (
      <span>
        <MdCreate
          className="icon-btn"
          size="20px"
          onClick={() => showModal("edit", item)}
        />
        <MdDelete
          style={{ marginLeft: "10px" }}
          className="icon-btn"
          size="20px"
          onClick={() => showModal("delete", item)}
        />
      </span>
    ),
  }));

  return <Table columns={columns} data={display} />;
}

const InputModal = ({ modal, onHide, data, factoryList, connectionList }) => {
  const intl = useIntl();
  const [expand, setExpand] = useState(false);
  const [select, setSelect] = useState({});

  useEffect(() => {
    if (!modal) {
      setExpand(false);
    }
    setSelect(JSON.parse(JSON.stringify(data)));
  }, [modal, data]);

  const submit = () => {
    return new Promise(async (resolve, reject) => {
      let req = {
        ...select,
        photo_path: select.photo_path ? select.photo_path : "",
      };
      if (select.photo?.size) {
        const data = new FormData();
        data.append("image", select.photo);
        try {
          const res = await postDeviceImage(data);
          req.photo_path = res.data;
        } catch (e) {
          console.log(e);
        }
      }

      if (modal === "new") {
        postFactoryDevice(select.factory_id, req).then(resolve).catch(reject);
      } else if (modal === "edit") {
        putFactoryDevice(select.factory_id, req.id, req)
          .then(resolve)
          .catch(reject);
      }
    });
  };

  const title = intl.formatMessage(
    { id: modal === "new" ? "Create" : "EditThe" },
    { name: intl.formatMessage({ id: "Device" }) }
  );

  const columns = [
    { id: "device_num", type: "text", required: true },
    {
      id: "factory_id",
      type: "select",
      required: true,
      options: factoryList.map(({ id, name }) => ({ label: name, value: id })),
    },
    { id: "manufacturer", type: "text", required: true },
    { id: "device_name", type: "text", required: true },
    { id: "tonnage", type: "number", required: true },
    {
      id: "connection_id",
      type: "select",
      required: true,
      options: connectionList.map(({ id, connection_name }) => ({
        label: connection_name,
        value: id,
      })),
    },
    { id: "screw", type: "number" },
    { id: "location_ring", type: "number" },
    { id: "location_ring2", type: "number" },
    { id: "nozzle_r", type: "number" },
    { id: "nozzle_r2", type: "number" },
    { id: "nozzle_aperture", type: "number" },
    { id: "nozzle_aperture2", type: "number" },
    { id: "max_injection_weight", type: "number" },
    { id: "max_injection_stroke", type: "number" },
    { id: "charge_rate", type: "number" },
    { id: "max_injection_pressure", type: "number" },
    { id: "max_injection_speed", type: "number" },
    { id: "max_mold_height", type: "number" },
    { id: "max_mold_width", type: "number" },
    { id: "min_mold_height", type: "number" },
    { id: "min_mold_width", type: "number" },
    { id: "max_mold_pitch", type: "number" },
    { id: "clearance_width", type: "number" },
    { id: "clearance_height", type: "number" },
    { id: "max_mold_thickness", type: "number" },
    { id: "min_mold_thickness", type: "number" },
    { id: "mold_width", type: "number" },
    { id: "mold_height", type: "number" },
    {
      id: "photo",
      type: "file",
      extensions: ["webp", "jpg", "png", "jpeg", "bmp", "gif"],
      remark: "PhotoRemark",
    },
  ];

  return (
    <InputModalBase
      show={modal === "new" || modal === "edit"}
      onHide={onHide}
      title={title}
      columns={columns.filter((_, i) => expand || i < 6)}
      select={select}
      setSelect={setSelect}
      submit={submit}
      expand={expand}
      setExpand={setExpand}
    />
  );
};

export { DeviceTable, InputModal };
