import React, { useContext, useEffect, useState } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { useTable, useSortBy, useFilters, usePagination } from "react-table";
import { FaCaretDown, FaSort, FaSortUp, FaSortDown } from "react-icons/fa";
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdAdd,
} from "react-icons/md";
import { Link, useHistory } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import "./common.css";
import Context from "../context";

function DatePicker({ name, style, date, onApply, maxDate }) {
  const _onApply = (event, picker) => {
    onApply(name, picker.startDate.format("YYYY-MM-DD"));
  };

  return (
    <div style={style}>
      <DateRangePicker
        initialSettings={{
          opens: "center",
          singleDatePicker: true,
          autoApply: true,
          startDate: date,
          locale: { format: "YYYY-MM-DD" },
          autoUpdateInput: false,
          maxDate,
        }}
        onApply={_onApply}
      >
        <span className="picker ai-center">
          <span>{date}</span>
          <FaCaretDown />
        </span>
      </DateRangePicker>
    </div>
  );
}

function SingleDatePicker({ name, style, date, onApply, maxDate }) {
  const onClick = (type) => {
    if (type === "left") {
      onApply(name, moment(date).subtract(1, "d").format("YYYY-MM-DD"));
    } else {
      if (moment(date).isBefore(maxDate)) {
        onApply(name, moment(date).add(1, "d").format("YYYY-MM-DD"));
      }
    }
  };

  return (
    <div className="ai-stretch" style={style}>
      <button
        className="picker"
        onClick={() => onClick("left")}
        style={{ borderRight: "none" }}
      >
        <MdKeyboardArrowLeft />
      </button>
      <DatePicker name={name} date={date} onApply={onApply} maxDate={maxDate} />
      <button
        className="picker"
        onClick={() => onClick("right")}
        style={{ borderLeft: "none" }}
      >
        <MdKeyboardArrowRight />
      </button>
    </div>
  );
}

function RangeDatePicker({ style, startDate, endDate, timePicker, onApply }) {
  const format = timePicker ? "YYYY-MM-DD HH:mm" : "YYYY-MM-DD";

  const _onApply = (event, picker) => {
    onApply(picker.startDate.format(format), picker.endDate.format(format));
  };

  return (
    <div style={style}>
      <DateRangePicker
        initialSettings={{
          opens: "center",
          autoApply: true,
          timePicker,
          locale: { format },
          startDate,
          endDate,
          parentEl: ".modal_style",
          timePicker24Hour: true,
          autoUpdateInput: false,
        }}
        onApply={_onApply}
      >
        <div className="ai-center" style={{ height: "100%" }}>
          <span className="picker ai-center">
            <span>{startDate}</span>
            <FaCaretDown />
          </span>
          <span style={{ margin: "0 10px" }}>~</span>
          <span className="picker ai-center">
            <span>{endDate}</span>
            <FaCaretDown />
          </span>
        </div>
      </DateRangePicker>
    </div>
  );
}

function DateRadio({ list, value, onChange, style }) {
  return (
    <div className="ai-center date-radio" style={style}>
      {list.map((item) => (
        <div key={item.value}>
          <input
            id={item.value}
            type="radio"
            value={item.value}
            onChange={onChange ? onChange : () => {}}
            checked={item.value === value}
          />
          <label className="center" htmlFor={item.value}>
            {item.label}
          </label>
        </div>
      ))}
    </div>
  );
}

function SingleDropdownPicker({ style, list, onApply, value }) {
  return (
    <Dropdown style={style}>
      <Dropdown.Toggle
        as="span"
        className="ai-center picker-small jc-space-between"
        style={{ height: "100%" }}
      >
        {list.find((e) => e.value === value)?.label}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <div style={{ maxHeight: "30vh", overflowY: "auto" }}>
          {list.map(({ value, label }) => (
            <Dropdown.Item key={value} onClick={() => onApply(value)}>
              <span className="ai-center">{label}</span>
            </Dropdown.Item>
          ))}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}

function MultiDropdownPicker({ style, title, list, selected, onApply }) {
  const checkedAll = list.every((item) => selected.indexOf(item.value) > -1);
  const onClick = (val) => {
    let newArr = selected.slice();
    if (val === "all") {
      if (checkedAll) {
        newArr = [];
      } else {
        newArr = list.map((item) => item.value);
      }
    } else {
      if (selected.indexOf(val) > -1) {
        newArr = selected.filter((item) => item !== val);
      } else {
        newArr.push(val);
      }
    }
    onApply(newArr);
  };

  return (
    <Dropdown style={style}>
      <Dropdown.Toggle
        as="span"
        className="ai-center picker-small jc-space-between"
        style={{ height: "100%" }}
      >
        {title}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <div style={{ maxHeight: "40vh", overflowY: "auto" }}>
          <Dropdown.Item onClick={() => onClick("all")}>
            <span className="ai-center">
              <input
                type="checkbox"
                style={{ marginRight: "10px" }}
                checked={checkedAll}
                readOnly
              />
              <FormattedMessage id="PickAll" />
            </span>
          </Dropdown.Item>
          {list.map(({ value, label }) => (
            <Dropdown.Item key={value} onClick={() => onClick(value)}>
              <span className="ai-center">
                <input
                  type="checkbox"
                  style={{ marginRight: "10px" }}
                  checked={selected.indexOf(value) > -1}
                  readOnly
                />
                {label}
              </span>
            </Dropdown.Item>
          ))}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}

function Tabs({ list }) {
  const history = useHistory();
  const { auth } = useContext(Context);
  return (
    <ul className="nav nav-tabs tabs_area clearfix">
      {list
        .filter(
          ({ to }) => auth[to.replace("/pages/", "").split("/").join("_")] > 0
        )
        .map(({ name, to }) => (
          <li key={name} className="nav-item">
            <Link
              to={to}
              className={`nav-link ${
                history.location.pathname.startsWith(to) ? "active" : ""
              }`}
            >
              <FormattedMessage id={name} />
            </Link>
          </li>
        ))}
    </ul>
  );
}

function LinkBar({ list, tab }) {
  return (
    <ul className="linkbar d-flex">
      {list.map((item, i) => (
        <li key={item.name}>
          <Link
            to={item.to}
            className={`nav-link ${tab === i.toString() ? "active" : ""}`}
          >
            <FormattedMessage id={item.name} />
          </Link>
        </li>
      ))}
    </ul>
  );
}

function RectangleButton({
  backgroundColor,
  color,
  fontSize,
  onClick,
  children,
  style = {},
}) {
  const _style = Object.assign(
    {
      padding: "5px 20px",
      fontSize: fontSize,
      backgroundColor: backgroundColor,
      color: color,
    },
    style
  );
  return (
    <button style={_style} onClick={onClick}>
      {children}
    </button>
  );
}

function CustomRadio({ id, value, onChange, checked, children }) {
  return (
    <li style={{ listStyle: "none" }}>
      <input
        id={id}
        value={value}
        className="custom-radio-ipt"
        onChange={onChange}
        type="radio"
        checked={checked}
        name="radio"
      />
      <label className="custom-radio-label center flex-column" htmlFor={id}>
        {children}
      </label>
    </li>
  );
}

function Pagination({
  pageIndex,
  canPreviousPage,
  previousPage,
  canNextPage,
  nextPage,
  pageCount,
  gotoPage,
}) {
  const [inputString, setInputString] = useState(pageIndex + 1);

  useEffect(() => {
    setInputString(pageIndex + 1);
  }, [pageIndex]);

  useEffect(() => {
    if (pageIndex >= pageCount) {
      gotoPage(0);
    }
  }, [pageCount]);

  const onKeyDown = (e) => {
    if (e.key === "Enter") {
      const p = parseInt(inputString);
      if (p) {
        gotoPage(p - 1);
      }
    }
  };

  return (
    <nav className="pagination-area ai-stretch">
      <button
        id="previousPage"
        onClick={previousPage}
        disabled={!canPreviousPage}
      >
        <FormattedMessage id="PreviousPage" />
      </button>
      <span className="center">
        <FormattedMessage id="The" />
        <input
          style={{ width: "40px", margin: "0 5px" }}
          value={inputString}
          onChange={(e) => setInputString(e.currentTarget.value)}
          onKeyDown={onKeyDown}
        />
        {`/${pageCount}`}
        <FormattedMessage id="Page" />
      </span>
      <button id="nextPage" onClick={nextPage} disabled={!canNextPage}>
        <FormattedMessage id="NextPage" />
      </button>
    </nav>
  );
}

function ColumnFilter({ filterValue, setFilter }, placeholder) {
  const intl = useIntl();
  return (
    <input
      value={filterValue || ""}
      onChange={(e) => setFilter(e.target.value || undefined)}
      placeholder={intl.formatMessage(
        { id: "InputThe" },
        { name: intl.formatMessage({ id: placeholder }) }
      )}
      className="text-center"
      style={{ maxWidth: "170px" }}
    />
  );
}

function Table({ columns, data, className, options = {}, clickable, onClick }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      autoResetPage: false,
      pageSize: options.pageSize,
    },
    useFilters,
    useSortBy,
    usePagination
  );

  return (
    <div className="table-area flex-column ai-end">
      <table {...getTableProps()} className={`strike-table w-100 ${className}`}>
        {headerGroups.map((headerGroup) => (
          <thead {...headerGroup.getHeaderGroupProps()}>
            <tr>
              {headerGroup.headers.map((column, i) => {
                const noSort =
                  column.id === "editor" ||
                  column.id === "photo" ||
                  column.id === "is_notify";
                let className = "";
                if (column.id === "editor") {
                  className = "text-right";
                } else if (i !== 0) {
                  className = "text-center";
                }
                return (
                  <th
                    {...column.getHeaderProps(
                      noSort ? undefined : column.getSortByToggleProps()
                    )}
                    className={className}
                  >
                    <span>
                      <span>{column.render("Header")}</span>
                      {noSort ? null : (
                        <span style={{ marginLeft: "5px", fontSize: "14px" }}>
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <FaSortDown />
                            ) : (
                              <FaSortUp />
                            )
                          ) : (
                            <FaSort />
                          )}
                        </span>
                      )}
                    </span>
                  </th>
                );
              })}
            </tr>
            <tr>
              {headerGroup.headers.map((column, i) => {
                if (i !== 0 || column.id !== "editor") {
                  className = "center";
                }
                return (
                  <th {...column.getHeaderProps()}>
                    <div style={{ width: "100%" }} className={className}>
                      {column.canFilter ? column.render("Filter") : null}
                    </div>
                  </th>
                );
              })}
            </tr>
          </thead>
        ))}
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <CollapseRow
                key={i}
                row={row}
                clickable={clickable}
                onClick={onClick}
              />
            );
          })}
        </tbody>
      </table>
      <Pagination
        pageIndex={pageIndex}
        canPreviousPage={canPreviousPage}
        previousPage={previousPage}
        canNextPage={canNextPage}
        nextPage={nextPage}
        pageCount={pageCount}
        gotoPage={gotoPage}
      />
    </div>
  );
}

export const CollapseRow = ({
  row,
  clickable,
  sticky,
  prevRow,
  showDifference,
  onClick,
}) => {
  const [open, setOpen] = useState(false);

  const _onClick = (data) => {
    if (clickable) {
      setOpen(!open);
    }
    onClick(data);
  };

  return (
    <>
      <tr {...row.getRowProps()}>
        {row.cells.map((cell, i) => {
          let className = "";
          if (cell.column.id === "editor") {
            className = "text-right";
          } else if (i !== 0) {
            className = "text-center";
          }
          if (i < sticky) {
            className += " sticky ";
          }

          if (
            showDifference &&
            cell.column.id !== "order_num" &&
            cell.column.id !== "created_at" &&
            cell.column.id !== "device_name" &&
            cell.column.id !== "product_num" &&
            cell.column.id !== "product_name" &&
            cell.column.id !== "mold_num" &&
            prevRow?.original[cell.column.id] !== cell.value
          ) {
            className += " warn ";
          }
          return (
            <td
              {...cell.getCellProps()}
              className={className}
              onClick={() => _onClick(row.original)}
              style={{
                left: i < sticky ? `${200 * i}px` : undefined,
                zIndex: i < sticky ? 1 : 0,
                backgroundColor: row.original.backgroundColor,
              }}
            >
              <div style={{ minWidth: i < sticky ? "200px" : undefined }}>
                {cell.render("Cell")}
              </div>
            </td>
          );
        })}
      </tr>
      {open && (
        <tr>
          <td colSpan="6">{row.original.children}</td>
        </tr>
      )}
    </>
  );
};

function StickyTable({
  className,
  columns,
  data,
  sticky,
  clickable,
  showDifference,
}) {
  const [onScroll, setScroll] = useState(false);
  useEffect(() => {
    document.addEventListener("keydown", keydown);
    return () => document.removeEventListener("keydown", keydown);
  }, []);

  const mouseDown = () => {
    setScroll(true);
  };

  const mouseUp = () => {
    setScroll(false);
  };

  const mouseMove = (e) => {
    if (onScroll) {
      const ele = document.getElementById("scroll-area");
      ele.scrollLeft -= e.movementX;
    }
  };

  const keydown = (e) => {
    const ele = document.getElementById("scroll-area");
    if (e.key === "ArrowRight") {
      ele.scrollLeft += 30;
    } else if (e.key === "ArrowLeft") {
      ele.scrollLeft -= 30;
    }
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    gotoPage,
    canPreviousPage,
    previousPage,
    canNextPage,
    nextPage,
    pageCount,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      autoResetPage: false,
      initialState: { pageSize: 100 },
    },
    useFilters,
    useSortBy,
    usePagination
  );

  return (
    <div className="flex-column ai-end">
      <div id="scroll-area" className="table-area w-100">
        <table
          {...getTableProps()}
          className={`strike-table ${className}`}
          onMouseDown={mouseDown}
          onMouseUp={mouseUp}
          onMouseMove={mouseMove}
          style={{
            minWidth: "100%",
          }}
        >
          {headerGroups.map((headerGroup) => (
            <thead {...headerGroup.getHeaderGroupProps()}>
              <tr>
                {headerGroup.headers.map((column, i) => (
                  <th
                    key={i}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={`${i !== 0 ? "text-center" : ""} sticky`}
                    style={{
                      left: i < sticky ? `${200 * i}px` : undefined,
                      zIndex: i < sticky ? 2 : 1,
                    }}
                  >
                    <div style={{ minWidth: i < sticky ? "200px" : undefined }}>
                      <span>{column.render("Header")}</span>
                      <span style={{ marginLeft: "5px", fontSize: "14px" }}>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <FaSortDown />
                          ) : (
                            <FaSortUp />
                          )
                        ) : (
                          <FaSort />
                        )}
                      </span>
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
          ))}
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <CollapseRow
                  key={i}
                  row={row}
                  clickable={clickable}
                  sticky={sticky}
                  prevRow={page[i + 1]}
                  showDifference={showDifference}
                />
              );
            })}
          </tbody>
        </table>
      </div>
      <Pagination
        pageIndex={pageIndex}
        canPreviousPage={canPreviousPage}
        previousPage={previousPage}
        canNextPage={canNextPage}
        nextPage={nextPage}
        pageCount={pageCount}
        gotoPage={gotoPage}
      />
    </div>
  );
}

function DeleteModal({ show, onHide, callback, name, type }) {
  const intl = useIntl();
  return (
    <Modal className="modal_style fade" size="lg" show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title as="h5">
          <b>
            <FormattedMessage id="ConfirmationMessage" />
          </b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h6 className="m-0">
          {intl.formatMessage(
            { id: "DeleteConfirm" },
            { name: `${intl.formatMessage({ id: type })} ${name}` }
          )}
        </h6>
      </Modal.Body>
      <Modal.Footer className="jc-end">
        <button type="button" className="btn btn-delete" onClick={callback}>
          <FormattedMessage id="Delete" />
        </button>
        <button type="button" className="btn btn-cancel" onClick={onHide}>
          <FormattedMessage id="Cancel" />
        </button>
      </Modal.Footer>
    </Modal>
  );
}

function NewButton({ onClick, text, style }) {
  return (
    <button className="center btn-gray" onClick={onClick} style={style}>
      <MdAdd />
      {text}
    </button>
  );
}

function handleError(err, intl) {
  if (err.response && err.response.status === 400) {
    alert(intl.formatMessage({ id: "400" }));
  } else if (err.response && err.response.status === 404) {
    alert(intl.formatMessage({ id: "404" }));
  } else if (err.response && err.response.status === 409) {
    alert(intl.formatMessage({ id: "409" }));
  } else {
    alert(intl.formatMessage({ id: "Failed" }));
    console.log(err);
  }
}

export {
  DateRadio,
  DatePicker,
  SingleDatePicker,
  RangeDatePicker,
  Tabs,
  LinkBar,
  RectangleButton,
  SingleDropdownPicker,
  MultiDropdownPicker,
  CustomRadio,
  Pagination,
  ColumnFilter,
  Table,
  StickyTable,
  DeleteModal,
  NewButton,
  handleError,
};
