import React, { useState, useEffect, useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { MdCreate, MdDelete } from "react-icons/md";
import { Table } from "../common";
import { InputModalBase } from "../management/Management";
import { ProductionTable } from "../index/activation";
import {
  ApiPes_GetHandoverReport,
  ApiPes_GetHandoverReportShift,
  ApiPes_GetHandoverReportDeviceList,
  getUserSelf,
  ApiPes_newHandoverReport,
  ApiPes_editHandoverReport,
} from "../../api.js";
import { lampStatus } from "../../function";
import Context from "../../context";

function HandoverTable({ showModal, modal, date }) {
  const [data, setData] = useState([]);
  const { factory } = useContext(Context);
  useEffect(() => {
    if (!modal) {
      ApiPes_GetHandoverReport({
        FactoryID: factory,
        CheckDateStart: date.startDate,
        CheckDateEnd: date.endDate,
      })
        .then((res) => {
          const data = res.data.map((item) => {
            const arr = item.shift.split(" ");
            const date = arr[1];
            const times = arr[2].split("-");
            return {
              ...item,
              device_number: item.deviceNumList.join(", "),
              oee: `${item.oee}%`,
              date,
              deviceDataList: item.deviceDataList.map((item) => {
                item.oee = `${item.oee}%`;
                item.utilization = `${item.utilization}%`;
                item.performance = `${item.performance}%`;
                item.yield = `${item.yield}%`;
                item.produceLampList.forEach((item) => {
                  const { status, color } = lampStatus(item.state);
                  item.status = status;
                  item.color = color;
                  item.width = item.time / (60 * 60 * 24);
                  item.duration = item.time;
                });
                item.timeline = item.produceLampList;
                return item;
              }),
              start_at: `${date} ${times[0]}`,
              end_at: `${date} ${times[1]}`,
            };
          });
          setData(data);
        })
        .catch(console.log);
    }
  }, [modal, date]);

  const columns = [
    {
      Header: <FormattedMessage id="shift" /> /*班別*/,
      accessor: "shift",
      Filter: "",
    },
    {
      Header: <FormattedMessage id="DeviceNumber" /> /*機台編號*/,
      accessor: "device_number",
      Filter: "",
    },
    {
      Header: <FormattedMessage id="AverageOEE" /> /*平均OEE*/,
      accessor: "oee",
      Filter: "",
    },
    {
      Header: <FormattedMessage id="description" /> /*說明*/,
      accessor: "description",
      Filter: "",
    },
    {
      Header: <FormattedMessage id="PersonInCharge" /> /*負責人*/,
      accessor: "user",
      Filter: "",
    },
    {
      Header: <FormattedMessage id="Edit" />,
      accessor: "editor",
      Filter: "",
    },
  ];

  const subColumns = [
    {
      Header: <FormattedMessage id="DeviceNumber" /> /*機台編號*/,
      accessor: "deviceNum",
      Filter: "",
    },
    {
      Header: "OEE" /*OEE*/,
      accessor: "oee",
      Filter: "",
    },
    {
      Header: <FormattedMessage id="Utilization" /> /*稼動率*/,
      accessor: "utilization",
      Filter: "",
    },
    {
      Header: <FormattedMessage id="Performance" /> /*效率*/,
      accessor: "performance",
      Filter: "",
    },
    {
      Header: <FormattedMessage id="Yield" /> /*良率*/,
      accessor: "yield",
      Filter: "",
    },
    {
      Header: (
        <FormattedMessage id="PlannedProductionQuantity" />
      ) /*計畫生產數量*/,
      accessor: "expectedProduceQuantity",
      Filter: "",
    },
    {
      Header: <FormattedMessage id="actual_quantity" /> /*實際生產數量*/,
      accessor: "actualProduceQuantity",
      Filter: "",
    },
  ];

  const display = data.map((item) => ({
    ...item,
    shift: `${item.date} ${item.shift.split(" ")[2]}`,
    editor: (
      <span>
        <MdCreate
          className="icon-btn"
          size="20px"
          onClick={() => showModal("edit", item)}
        />
        <MdDelete
          style={{ marginLeft: "10px" }}
          className="icon-btn"
          size="20px"
          onClick={() => showModal("delete", item)}
        />
      </span>
    ),
    children: (
      <ProductionTable
        data={item.deviceDataList}
        start_at={item.start_at}
        end_at={item.end_at}
        columns={subColumns}
      />
    ),
  }));

  return <Table columns={columns} data={display} clickable={true} />;
}

const InputModal = ({ modal, onHide, data }) => {
  const intl = useIntl();
  const { factory } = useContext(Context);
  const [shiftList, setShiftList] = useState([]);
  const [deviceList, setDeviceList] = useState([]);
  const [select, setSelect] = useState({ deviceIDList: [] });

  useEffect(() => {
    setSelect(JSON.parse(JSON.stringify(data)));
  }, [data]);

  useEffect(() => {
    if (select.date) {
      ApiPes_GetHandoverReportShift({
        FactoryID: factory,
        ShiftDate: select.date,
      }).then((res) =>
        setShiftList(
          res.data.map(
            ({ workday_shift, additional_shift, time_start, time_end }) => {
              const head = workday_shift
                ? workday_shift
                : additional_shift === 0
                ? "O"
                : "S";
              return {
                value: `${head}_${time_start.replace(
                  ":00",
                  ""
                )}-${time_end.replace(":00", "")}`,
                label: `${head} (${time_start.replace(
                  ":00",
                  ""
                )}-${time_end.replace(":00", "")})`,
              };
            }
          )
        )
      );
    }
  }, [factory, select.date]);

  useEffect(() => {
    if (select.date && select.shift) {
      const arr = select.shift.split("_");
      let req = { FactoryID: factory, ShiftDate: select.date };
      if (arr[0] === "O" || arr[0] === "S") {
        req.AdditionalShift = arr[0] === "O" ? 0 : 1;
        req.AdditionalTimeStart = arr[1];
        req.AdditionalTimeEnd = arr[2];
      } else {
        req.WorkdayShift = parseInt(arr[0]);
      }
      ApiPes_GetHandoverReportDeviceList(req).then((res) =>
        setDeviceList(
          res.data.map(({ id, device_num }) => ({
            label: device_num,
            value: id,
          }))
        )
      );
    }
  }, [factory, select.date, select.shift]);

  const submit = () => {
    return new Promise((resolve, reject) => {
      getUserSelf().then((res) => {
        const arr = select.shift.split("_");
        let req = {
          ReportID: select.reportIDList,
          FactoryID: factory,
          DeviceID: select.deviceIDList,
          ShiftDate: select.date,
          Description: select.description ? select.description : "",
          UserID: res.data.id,
        };
        if (arr[0] === "O" || arr[0] === "S") {
          req.AdditionalShift = arr[0] === "O" ? 0 : 1;
          req.AdditionalTimeStart = arr[1];
          req.AdditionalTimeEnd = arr[2];
        } else {
          req.WorkdayShift = parseInt(arr[0]);
        }

        if (modal === "new") {
          ApiPes_newHandoverReport(req).then(resolve).catch(reject);
        }
        if (modal === "edit") {
          ApiPes_editHandoverReport(req).then(resolve).catch(reject);
        }
      });
    });
  };

  const title = intl.formatMessage(
    { id: modal === "new" ? "Create" : "EditThe" },
    { name: intl.formatMessage({ id: "HandoverReport" }) }
  );

  const columns = [
    { id: "date", type: "date", require: true },
    { id: "shift", type: "select", options: shiftList, require: true },
    {
      id: "deviceIDList",
      type: "multi_select",
      options: deviceList,
      require: true,
    },
    { id: "description", type: "text" },
  ];

  return (
    <InputModalBase
      show={modal === "new" || modal === "edit"}
      onHide={onHide}
      title={title}
      columns={columns}
      select={select}
      setSelect={setSelect}
      submit={submit}
    />
  );
};

export { HandoverTable, InputModal };
