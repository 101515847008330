import React, { useContext, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { RootTabs, FactoryMenu } from "../../component/root/Root";
import { DeviceTable, InputModal } from "../../component/root/Device";
import {
  NewButton,
  DeleteModal,
  handleError,
  MultiDropdownPicker,
} from "../../component/common";
import { getFactory, getConnection, deleteFactoryDevice } from "../../api.js";

export default function Device({ checkEditAuth }) {
  const [modal, setModal] = useState("");
  const [connectionList, setConnectionList] = useState([]);
  const [factoryList, setFactoryList] = useState([]);
  const [factory_id, setFactoryID] = useState([]);
  const [select, setSelect] = useState({});
  const intl = useIntl();

  useEffect(() => {
    getFactory().then((res) => {
      setFactoryList(res.data);
      setFactoryID(res.data.map(({ id }) => id));
    });
    getConnection().then((res) => setConnectionList(res.data));
  }, []);

  const showModal = (modal, item = {}) => {
    if (!checkEditAuth()) {
      return;
    }
    let photo = {};
    if (item.photo_path) {
      const arr = item.photo_path.split("/");
      photo.name = arr[arr.length - 1];
    }
    setSelect({ ...item, photo });
    setModal(modal);
  };

  const onHide = () => {
    setModal();
    setSelect({});
  };

  const deleteData = () => {
    deleteFactoryDevice(select.factory_id, select.id)
      .then(onHide)
      .catch((err) => handleError(err, intl));
  };

  return (
    <React.Fragment>
      <div>
        <RootTabs tab="0" />
        <div className="page-content shadow-lg">
          <div className="jc-end">
            <FactoryMenu tab="1" />
          </div>
          <hr />
          <div className="jc-space-between ai-center">
            <h4 className="m-0">
              <b>
                <FormattedMessage id="DeviceList" />
              </b>
            </h4>
            <div className="ai-stretch">
              <MultiDropdownPicker
                title={intl.formatMessage(
                  { id: "PickA" },
                  { name: intl.formatMessage({ id: "Factory" }) }
                )}
                list={factoryList.map(({ id, name }) => ({
                  value: id,
                  label: name,
                }))}
                selected={factory_id}
                onApply={setFactoryID}
                style={{ marginRight: "20px" }}
              />
              <NewButton
                onClick={() => showModal("new")}
                text={intl.formatMessage(
                  { id: "Create" },
                  { name: intl.formatMessage({ id: "Device" }) }
                )}
              />
            </div>
          </div>
          <hr />
          <DeviceTable
            showModal={showModal}
            modal={modal}
            factory_id={factory_id}
            connectionList={connectionList}
          />
        </div>
      </div>

      <InputModal
        modal={modal}
        onHide={onHide}
        data={select}
        factoryList={factoryList}
        connectionList={connectionList}
      />

      <DeleteModal
        show={modal === "delete"}
        onHide={onHide}
        name={select.device_num}
        type={"Device"}
        callback={deleteData}
      />
    </React.Fragment>
  );
}
