import React, { useMemo, useState, useContext, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {
  MdAccountCircle,
  MdLockOpen,
  MdLock,
  MdCreate,
  MdDelete,
  MdLockOutline,
} from "react-icons/md";
import { Table, RectangleButton } from "../common";
import {
  postUserImage,
  postUserCreate,
  putUserUpdate,
  ApiSysUpdateUserAuth,
  getUserImage,
  getGroup,
} from "../../api.js";
import { showWarn, handleError } from "../../function";
import Context from "../../context";
import Alert from '@material-ui/lab/Alert';

const UserTable = ({ data, showModal, searchString }) => {
  const filterData = useMemo(() => {
    if (searchString) {
      return data.filter((user) =>
        JSON.stringify(Object.values(user))
          .toLowerCase()
          .includes(searchString.toLowerCase())
      );
    }
    return data;
  }, [data, searchString]);

  const columns = [
    {
      Header: <FormattedMessage id="ProfilePicture" /> /*頭像*/,
      accessor: "photo",
      Filter: "",
      Cell: ({ cell: { value } }) => {
        return (
          <img
            src={value ? value : ""}
            className="fit-contain"
            style={{ width: "100px" }}
          />
        );
      },
    },
    {
      Header: <FormattedMessage id="Name" /> /*姓名*/,
      accessor: "name",
      Filter: "",
    },
    {
      Header: <FormattedMessage id="FactoryName" /> /*工廠名稱*/,
      accessor: "factory_name",
      Filter: "",
    },
    {
      Header: <FormattedMessage id="Role" /> /*角色*/,
      accessor: "role",
      Filter: "",
    },
    {
      Header: <FormattedMessage id="Email" /> /*電子信箱*/,
      accessor: "email",
      Filter: "",
    },
    {
      Header: <FormattedMessage id="Group" /> /*群組*/,
      accessor: "group_name",
      Filter: "",
    },
    {
      Header: <FormattedMessage id="description" /> /*描述*/,
      accessor: "remark",
      Filter: "",
    },
    {
      Header: <FormattedMessage id="Edit" />,
      accessor: "editor",
      Filter: "",
    },
  ];

  const display = filterData.map((item) => ({
    ...item,
    editor: (
      <span>
        <MdCreate
          className="icon-btn"
          size="20px"
          onClick={() => showModal("edit", item)}
        />
        <MdLockOutline
          style={{ marginLeft: "10px" }}
          className="icon-btn"
          size="20px"
          onClick={() => showModal("auth", item)}
        />
        <MdDelete
          style={{ marginLeft: "10px" }}
          className="icon-btn"
          size="20px"
          onClick={() => showModal("delete", item)}
        />
      </span>
    ),
  }));

  return <Table columns={columns} data={display} />;
};

const InputModal = ({ modal, closeModal, data, roleList }) => {
  const intl = useIntl();
  const { setName, setPhotoPath } = useContext(Context);
  const [select, setSelect] = useState({});
  const [file, setFile] = useState();
  const [groupList, setGroupList] = useState([]);
  const [preview, setPreview] = useState();
  const [alert, setAlert] = useState(false);
  useState(() => {
    getGroup().then((res) => setGroupList(res.data));
  }, []);

  useEffect(() => {
    setAlert(false);

    if (modal) {
      setSelect(data);
      if (data.fileName) {
        setPreview(getUserImage(select.fileName));
      }
    } else {
      setSelect({});
      setFile();
    }
  }, [modal, data]);

  const onChange = (e) => {
    const { value, id } = e.target;
    setSelect((prev) => ({ ...prev, [id]: value }));
  };

  const selectFile = (e) => {
    if (e.currentTarget.files.length > 0) {
      const file = e.target.files[0];
      if (
        [".webp", ".jpg", ".png", ".jpeg", ".bmp", ".gif"].indexOf(
          file.name.substring(file.name.lastIndexOf("."))
        ) > -1
      ) {
        setFile(file);
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreview(reader.result);
        };
        reader.readAsDataURL(file);
      } else {
        showWarn("photo_path", "WrongFileType", intl);
      }
    }
  };

  const submit = async () => {
    document.querySelectorAll(".warn").forEach((ele) => (ele.hidden = true));
    let pass = true;
    const { email, password, name, photo_path } = select;
    const require = ["email", "name", "group_id", "role_id"];
    require.forEach((key) => {
      if (!select[key]) {
        showWarn(key, "Required", intl);
        pass = false;
      }
    });
    if (modal === "new" && !password) {
      showWarn("password", "Required", intl);
      pass = false;
    }

    if (email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(email)) {
        showWarn("email", intl.formatMessage({ id: "EmailWarning" }));
        pass = false;
      }
    }

    if (pass) {
      let photo_path;
      const account = localStorage.getItem("account");
      if (file) {
        const data = new FormData();
        data.append("image", file);
        try {
          const res = await postUserImage(data);
          photo_path = res.data;
          if (account === email) {
            localStorage.setItem("photoPath", photo_path);
            setPhotoPath(photo_path);
          }
        } catch (e) {
          console.log(e);
        }
      }
      let req = {
        ...select,
        user_id: select.id,
        photo_path: photo_path
          ? photo_path
          : modal === "new"
          ? ""
          : select.photo_path,
      };

      if (modal === "new") {
        postUserCreate(req)
          .then(()=>closeModal())
          .catch((err) => {
            if(err.response.data.errors[0].msg === "EMAIL_ALREADY_FOUND"){
              setAlert(true);
            }
          }
            
          );
      } else if (modal === "edit") {
        putUserUpdate(req)
          .then(() => {
            if (account === email) {
              localStorage.setItem("name", name);
              setName(name);
            }
            closeModal();
          })
          .catch((err) => handleError(err, intl));
      }
    }
  };

  const title = intl.formatMessage(
    { id: modal === "new" ? "Create" : "EditThe" },
    { name: intl.formatMessage({ id: "User" }) }
  );

  return (
    <Modal
      className="modal_style fade"
      size="lg"
      centered
      show={modal === "new" || modal === "edit"}
      onHide={closeModal}
    >
      <Modal.Header closeButton>
        <Modal.Title as="h5">
          <b>{title}</b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div className="ai-stretch">
            <div
              className="flex-1 center flex-column"
              style={{ marginRight: "2rem", marginLeft: "1rem" }}
            >
              <input
                className="d-none"
                id="file"
                onChange={selectFile}
                type="file"
              />
              <label id="photo_path" htmlFor="file" style={{ width: "90%" }}>
                <img
                  src={preview ? preview : "./images/img_upload.svg"}
                  className="fit-contain"
                  style={{ width: "100%", borderRadius: "50%" }}
                />
              </label>
              <span className="warn" />
              <span style={{ color: "#999" }} className="text-center">
                <FormattedMessage id="ClickAndUploadYourProfilePicture" />
              </span>
            </div>
            <div style={{ flex: 2 }}>
              <Form.Group as={Row} controlId="group_id">
                <Form.Label column lg={3} md={4}>
                  <FormattedMessage id="Group" />
                </Form.Label>
                <Col lg={9} md={8}>
                  <Form.Control
                    as="select"
                    onChange={onChange}
                    value={select.group_id}
                  >
                    <option value="null">
                      {intl.formatMessage(
                        { id: "PickA" },
                        { name: intl.formatMessage({ id: "Group" }) }
                      )}
                    </option>
                    {groupList.map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </Form.Control>
                  <span className="warn" />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="email">
                <Form.Label column lg={3} md={4}>
                  <FormattedMessage id="Email" />
                </Form.Label>
                <Col lg={9} md={8}>
                  <Form.Control
                    placeholder={intl.formatMessage(
                      { id: "InputThe" },
                      { name: intl.formatMessage({ id: "Email" }) }
                    )}
                    onChange={onChange}
                    value={select.email ? select.email : ""}
                  />
                  <span className="warn" />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="name">
                <Form.Label column lg={3} md={4}>
                  <FormattedMessage id="Name" />
                </Form.Label>
                <Col lg={9} md={8}>
                  <Form.Control
                    placeholder={intl.formatMessage(
                      { id: "InputThe" },
                      { name: intl.formatMessage({ id: "Name" }) }
                    )}
                    onChange={onChange}
                    value={select.name ? select.name : ""}
                  />
                  <span className="warn" />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="role_id">
                <Form.Label column lg={3} md={4}>
                  <FormattedMessage id="Role" />
                </Form.Label>
                <Col lg={9} md={8}>
                  <Form.Control
                    as="select"
                    onChange={onChange}
                    value={select.role_id}
                  >
                    <option value="null">
                      {intl.formatMessage(
                        { id: "PickA" },
                        { name: intl.formatMessage({ id: "Role" }) }
                      )}
                    </option>
                    {roleList.map(({ id, role }) => (
                      <option key={id} value={id}>
                        {role}
                      </option>
                    ))}
                  </Form.Control>
                  <span className="warn" />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="password">
                <Form.Label column lg={3} md={4}>
                  <FormattedMessage id="Pwd" />
                </Form.Label>
                <Col lg={9} md={8}>
                  <Form.Control
                    type="password"
                    placeholder={
                      modal === "new"
                        ? intl.formatMessage(
                            { id: "InputThe" },
                            { name: intl.formatMessage({ id: "Password" }) }
                          )
                        : intl.formatMessage({ id: "KeyInToChangePassword" })
                    }
                    onChange={onChange}
                    value={select.password}
                  />
                  <span className="warn" />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="remark">
                <Form.Label column lg={3} md={4}>
                  <FormattedMessage id="Remark" />
                </Form.Label>
                <Col lg={9} md={8}>
                  <Form.Control
                    as="textarea"
                    placeholder={intl.formatMessage(
                      { id: "InputThe" },
                      { name: intl.formatMessage({ id: "Remark" }) }
                    )}
                    onChange={onChange}
                    value={select.remark}
                  />
                  <span className="warn" />
                </Col>
              </Form.Group>
            </div>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-end">
        {alert ? <Alert severity="error"><FormattedMessage id="EmailAlreadyFound" /></Alert>:<></>}
        <button className="btn btn-confirm" onClick={submit}>
          <FormattedMessage id="Confirm" />
        </button>
        <button className="btn btn-cancel" onClick={closeModal}>
          <FormattedMessage id="Cancel" />
        </button>
      </Modal.Footer>
    </Modal>
  );
};

const AuthModal = ({ modal, closeModal, data }) => {
  const intl = useIntl();
  const [select, setSelect] = useState({ auth: {} });
  const [page, setPage] = useState(0);

  useEffect(() => {
    if (modal) {
      setSelect(data);
    } else {
      setSelect({ auth: {} });
    }
  }, [modal, data]);

  const submit = () => {
    ApiSysUpdateUserAuth({
      account: localStorage.getItem("account"),
      email: select.email,
      auth: select.auth,
    })
      .then(closeModal)
      .catch((err) => handleError(err, intl));
  };

  return (
    <Modal
      className="modal_style fade"
      size="lg"
      centered
      show={modal === "auth"}
      onHide={closeModal}
    >
      <Modal.Header closeButton>
        <Modal.Title as="h5">
          <b>
            <FormattedMessage id="PermissionSetting" />
          </b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="ai-center jc-space-between">
          <span className="ai-center" style={{ color: "#808080" }}>
            <MdAccountCircle size="30px" style={{ marginRight: "5px" }} />
            <b>{select.email}</b>
          </span>
          <div>
            <RectangleButton
              backgroundColor={page === 0 ? "#8fc31f" : "#efefef"}
              color={page === 0 ? "white" : "#808080"}
              onClick={() => setPage(0)}
            >
              <FormattedMessage id="Website" />
            </RectangleButton>
            <RectangleButton
              backgroundColor={page === 1 ? "#8fc31f" : "#efefef"}
              color={page === 1 ? "white" : "#808080"}
              onClick={() => setPage(1)}
            >
              APP
            </RectangleButton>
          </div>
        </div>
        <hr />
        <AuthTable page={page} select={select} setSelect={setSelect} />
      </Modal.Body>
      <Modal.Footer className="jc-end">
        <button className="btn btn-confirm" onClick={submit}>
          <FormattedMessage id="Confirm" />
        </button>
        <button className="btn btn-cancel" onClick={closeModal}>
          <FormattedMessage id="Cancel" />
        </button>
      </Modal.Footer>
    </Modal>
  );
};

const AuthTable = ({ page, select, setSelect }) => {
  const intl = useIntl();
  const { auth } = useContext(Context);

  const onChange = (e) => {
    const { id, checked } = e.target;
    const key = id.replace("_edit", "");
    let value = 0;
    if (id.includes("_edit")) {
      if (checked) {
        value = 2;
      } else {
        value = 1;
      }
    } else if (checked) {
      value = 1;
    }
    setSelect((prev) => ({ ...prev, auth: { ...prev.auth, [key]: value } }));
  };

  let webColumns = [
    {
      key: "index",
      name: "ProductionOverview",
      children: [
        { key: "index_device", name: "DeviceOverview" },
        { key: "index_production", name: "ProductionOverview" },
      ],
    },
    {
      key: "dashboard",
      name: "Monitoring",
      children: [
        { key: "dashboard_1x1", name: "1x1" },
        { key: "dashboard_2x2", name: "2x2" },
      ],
    },
    {
      key: "mes",
      name: "Operation",
      children: [
        { key: "mes_production", name: "ProductionManagement" },
        { key: "mes_defective", name: "DefectiveManagement" },
        { key: "mes_downtime", name: "DowntimeReasonManagement" },
        { key: "mes_handover", name: "HandoverReport" },
      ],
    },
    {
      key: "analysis",
      name: "ProduceAnalysisReport",
      children: [
        { key: "analysis_oee", name: "OEEAnalysis" },
        { key: "analysis_amount", name: "QuantityAnalysis" },
        { key: "analysis_parameter", name: "MonitoringParameter" },
        { key: "analysis_schedule", name: "OrderSchedule" },
        { key: "analysis_production", name: "ProductionOverview" },
      ],
    },
    {
      key: "management",
      name: "ProductionAdmin",
      children: [
        { key: "management_order", name: "OrderManagement" },
        { key: "management_schedule", name: "OrderScheduling" },
        { key: "management_material", name: "MaterialManagement" },
        { key: "management_mold", name: "MoldManagement" },
        { key: "management_product", name: "ProductManagement" },
        { key: "management_shift", name: "ShiftManagement" },
        { key: "management_holiday", name: "HolidayManagement" },
        { key: "management_defect", name: "DefectManagement" },
        { key: "management_downtime", name: "DowntimeReasonManagement" },
        {
          key: "management_unproductive",
          name: "UnproductiveOrderReasonManagement",
        },
      ],
    },
    {
      key: "system",
      name: "SystemAdmin",
      children: [
        { key: "system_user", name: "UsersManagement" },
        { key: "system_dashboard", name: "PanelManagement" },
        { key: "system_devicegroup", name: "DeviceClassificationManagement" },
        { key: "system_factory", name: "FactoryManagement" },
        { key: "system_notify", name: "Notify" },
        { key: "system_document", name: "Document" },
        { key: "system_log", name: "Diary" },
        { key: "system_role", name: "RoleManagement" },
      ],
    },
    {
      key: "root",
      name: "Root",
      children: [
        { key: "root_factory", name: "FactoryManagement" },
        { key: "root_group", name: "GroupManagement" },
      ],
    },
    {
      key: "employee",
      name: "EmployeeEfficiency",
      children: [
        { key: "employee_device", name: "DeviceList" },
        { key: "employee_work", name: "Work" },
        { key: "employee_setting", name: "Worker" },
        { key: "employee_log", name: "WorkerLog" },
      ],
    },
    {
      key: "quality",
      name: "Quality",
      children: [
        { key: "quality", name: "監測點" },
        { key: "quality_analysis", name: "散佈圖" },
      ],
    },
  ];

  const groupAuth = [
    "root",
    "employee",
    "quality",
    "mes_handover",
    "analysis_schedule",
    "system_notify",
    "system_document",
    "system_log",
    "system_role",
  ];

  groupAuth.forEach((key) => {
    if (!auth[key]) {
      const index = webColumns.findIndex((e) => e.key === key);
      if (index > -1) {
        webColumns.splice(index, 1);
      } else {
        const arr = key.split("_");
        const i = webColumns.findIndex((e) => e.key === arr[0]);
        const j = webColumns[i].children.findIndex((e) => e.key === key);
        if (i > -1 && j > -1) {
          webColumns[i].children.splice(j, 1);
        }
      }
    }
  });

  const appColumns = [
    {
      key: "overview",
      name: "ProductionOverview",
      children: [
        { key: "app_mahcine", name: "DeviceOverview" },
        { key: "app_utilization", name: "OperationOverview" },
        { key: "app_analysis", name: "DeviceAnalysis" },
      ],
    },
    {
      key: "alert",
      name: "IntelligentAlarm",
      children: [{ key: "app_alert", name: "IntelligentAlarm" }],
    },
    {
      key: "management",
      name: "ProductionManagement",
      children: [
        {
          key: "app_order",
          name: intl.formatMessage(
            { id: "Create" },
            { name: intl.formatMessage({ id: "Order" }) }
          ),
        },
        { key: "app_schedule", name: "OrderScheduling" },
        { key: "app_not_schedule", name: "UnassignedOrders" },
        { key: "app_material", name: "MaterialManagement" },
        { key: "app_mold", name: "MoldManagement" },
        { key: "app_product", name: "ProductManagement" },
      ],
    },
    {
      key: "defective",
      name: "DefectiveManagement",
      children: [{ key: "app_bad_product", name: "DefectiveManagement" }],
    },
    {
      key: "downtime",
      name: "DowntimeReasonManagement",
      children: [{ key: "app_closing", name: "DowntimeReasonManagement" }],
    },
  ];

  return (
    <div style={{ overflowY: "auto", height: "50vh" }}>
      <div className="auth-table">
        <div className="ai-center">
          <span className="flex-1" />
          <b className="flex-1 text-center">
            <FormattedMessage id="ViewPermissions" />
          </b>
          <b className="flex-1 text-center">
            <FormattedMessage id="EditPermissions" />
          </b>
        </div>
        {(page === 0 ? webColumns : appColumns).map(
          ({ key, name, children }) => (
            <div key={key}>
              <div>
                <b>
                  <FormattedMessage id={name} />
                </b>
              </div>
              {children.map(({ key, name }) => (
                <div key={key} className="ai-center">
                  <b className="flex-1">
                    <FormattedMessage id={name} />
                  </b>
                  <span className="flex-1 center">
                    <input
                      className="d-none"
                      type="checkbox"
                      id={key}
                      onChange={onChange}
                      checked={select.auth[key] > 0}
                    />
                    <label htmlFor={key} className="icon-btn">
                      {select.auth[key] > 0 ? (
                        <MdLockOpen size="20px" />
                      ) : (
                        <MdLock size="20px" />
                      )}
                    </label>
                  </span>
                  <span className="flex-1 center">
                    <input
                      className="d-none"
                      type="checkbox"
                      id={`${key}_edit`}
                      onChange={onChange}
                      checked={select.auth[key] === 2}
                    />
                    <label htmlFor={`${key}_edit`} className="icon-btn">
                      {select.auth[key] === 2 ? (
                        <MdLockOpen size="20px" />
                      ) : (
                        <MdLock size="20px" />
                      )}
                    </label>
                  </span>
                </div>
              ))}
            </div>
          )
        )}
      </div>
    </div>
  );
};

export { UserTable, InputModal, AuthModal, AuthTable };
