import React, { useState, useEffect, useContext } from 'react';
import { FormattedMessage } from "react-intl";
import { MdCreate, MdDelete } from 'react-icons/md'
import { ColumnFilter, Table } from '../common';
import { ApiEmployee_GetEmployeeWorkList } from '../../api'
import Context from "../../context";

function SettingTable({ showModal, modal }) {
    const [data, setData] = useState([]);
	const { factory } = useContext(Context);

    useEffect(() => {
        if (!modal) {
            ApiEmployee_GetEmployeeWorkList({ factory_id: factory }).then(res => setData(res.data));
        }
    }, [modal]);

    const columns = [
        {
            Header: <FormattedMessage id="WorkerNumber" />,
            accessor: 'EmployeeNum',
            Filter: ({ column }) => ColumnFilter(column, 'WorkerNumber')
        },
        {
            Header: <FormattedMessage id="WorkerName" />,
            accessor: 'EmployeeName',
            Filter: ({ column }) => ColumnFilter(column, 'WorkerName')
        },
        {
            Header: <FormattedMessage id="Work" />,
            accessor: 'work',
            Filter: ({ column }) => ColumnFilter(column, 'Work')
        },
        {
            Header: <FormattedMessage id="Edit" />,
            accessor: 'editor',
            Filter: ''
        }
    ];

    const display = data.map(item => ({
        ...item,
        work: item.WorkItemList.map(e => e.WorkItemName).toString(),
        editor:
            <span>
                <MdCreate className="icon-btn" size="20px" onClick={() => showModal('edit', item)} />
                <MdDelete style={{ marginLeft: '10px' }} className="icon-btn" size="20px" onClick={() => showModal('delete', item)} />
            </span>
    }));

    return (
        <Table columns={columns} data={display} />
    );
}

export { SettingTable }