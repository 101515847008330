const management = {
  OrderManagement: "Order Management",
  OrderScheduling: "Order Scheduling",
  MaterialManagement: "Material Management",
  MoldManagement: "Mold Management",
  ProductManagement: "Product Management",
  ShiftManagement: "Shift Management",
  HolidayManagement: "Holiday Management",
  DefectManagement: "Defect Management",
  DowntimeReasonManagement: "Downtime Reason Management",
  UnproductiveOrderReasonManagement: "Unproductive Order Reason Management",
  AdvancedSettings: "Advanced Settings",

  // 工單管理
  Order: "Order",
  LastSevenDays: "Last Seven Days",
  LastThirtyDays: "Last Thirty Days",
  ThisMonth: "This Month",
  LastMonth: "Last Month",
  OrderList: "Order List",
  CreateDate: "Create Date",
  actual_quantity: "Actual Quantity",
  DeviceNumber: "Device Number",
  order_num: "Order Number",
  order_num_name: "Order Number",
  product_id: "Product Number",
  material_id: "Material NumberUsed",
  mold_id: "Mold Number Used",
  cavity_amount: "Cavity Quantity",
  best_cycle: "Best Cycle",
  expect_quantity: "Planned Quantity",
  normal_amount: "Qualified Shot Count To Enter MP Mode",
  qc_point: "QC Point",
  mold_time: "mold_time(min)",
  die_time: "die_time(min)",
  ApplyOrderExpirationDate: "Apply Order Expiration Date",
  auto_finish: "Order Auto Finish",
  OrderExpirationDate: "Order Expiration Date",
  UnproductiveOrder: "Unproductive Order",
  order_reason: "Order Reason",
  expected_time: "Planned Downtime",
  ExpextedTimeRemark: "The unit is minute.",
  Separate: "Separate {name}",
  DownloadExample: "Download Example",
  Unscheduled: "Unscheduled",
  Waiting: "Waiting",
  Producing: "Producing",
  FinishedManually: "Finished Manually",
  FinishedAutomatically: "Finished Automatically",
  OrderImportRemark:
    "Please split products, materials or molds by comma. ex: Mold001,Mold002",
  SplitConfirm: "Are you sure to split order {name}?",

  //工單排程
  Schedule: "Schedule",
  UnassignedOrders: "Unassigned Orders",
  ModifyOrderSchedule: "Modify Order Schedule",
  start_time: "Start Time",
  device_id: "Device Number",
  ScheduleDates: "Schedule Dates",
  ArrangementMachineNum: "Arrangement Machine Num",
  CancelSchedule: "Cancel Schedule",
  WrongDateFormat: "Wrong Date Format",
  UsedBefore: "Used Before",
  MoldOutOfSide: "Mold Out Of Side",
  NozzleOutOfSide: "Nozzle Out Of Side",
  ShotWeightInsufficient: "Shot Weight Insufficient",
  ClampingForceInsufficient: "Clamping Force Insufficient",
  InsertMode: "Insert Mode",

  //材料管理
  Material: "Material",
  material_num: "Material Number",
  material_model: "Material Model",
  base_material: "Base Material",
  supplier: "supplier",
  melt_density: "Melt Density",
  soild_state_density: "Solid Density",
  lowest_melt: "Lowest Melt Temperature",
  hightest_melt: "Hightest Melt Temperature",
  average_melt: "Average Melt Temperature",
  lowest_mold: "Lowest Mold Temperature",
  highest_mold: "Highest Mold Temperature",
  average_mold: "Average Mold Temperature",
  aridity: "Drying Temperature",
  drying_time: "Drying Time",
  fiber_content: "Fiber Content",
  melting_index: "Melt Flow Index",
  weight: "Weight In Stock",
  is_use: "Display",

  //模具管理
  Mold: "Mold",
  mold_num: "Mold Number",
  stop_time: "Stop Time",
  modus: "modus",
  allowed_cycle: "Allowed Cycle",
  max_cycle: "Max Allowed Cycle",
  maintainence_mold: "Mold Maintained Times",
  mold_height: "Mold Height",
  mold_width: "Mold Width",
  mold_length: "Mold Length",
  sprue_aperture: "Down Spure Aperture 1",
  sprue_aperture2: "Down Spure Aperture 2",
  sprue_r: "Down Spure Radius 1",
  sprue_r2: "Down Spure Radius 2",
  mold_stroke: "Opening Stroke",
  hot_runner_amount: "Hot Runner Quantity",
  molds: "Core Pulling Quantity",
  ring1: "Locating Ring Diameter 1",
  ring2: "Locating Ring Diameter 2",
  locking: "Mold Clamping Force",
  center_distance: "Centre To Centre Distance",
  purchase_date: "Centre To Centre Distance",
  disable_date: "Disable Date",
  Quantity: "Quantity",
  region: "region",
  maintainence_cycle: "Maintainence Cycle",
  maintainence_count: "Maintainence Times",
  maintainence_last: "Last Maintainence",
  modulus: "modulus",
  core_temp: "Core Temperature",
  cavity_temp: "Cavity Temperature",
  customer: "customer",
  PhotoRemark: "Please pick a picture of 200*200.",
  MoldImportRemark: "Please split molds by comma. ex: Mold001,Mold002",

  //產品管理
  Product: "Product",
  product_name: "Product Name",
  product_type: "Product Type",
  part_weight: "Product Weight",
  runner_weight: "Runner Weight",
  pcs_weight: "Weight Per Pcs",
  product_cost: "Production Cost",
  OrderImportRemark:
    "Please split materials or molds by comma. ex: Mold001,Mold002",

  //班別管理
  WorkdaySetting: "Workday Setting",
  WorkHours1: "WorkHours 1",
  WorkHours2: "WorkHours 2",
  WorkHours3: "WorkHours 3",
  WorkHours4: "WorkHours 4",
  To: "To",
  Repeat: "Repeat",
  EveryWeekday_MondayToFriday: "Every Monday To Friday",
  EveryWeekday_MondayToSaturday: "Every Monday To Saturday",
  Everyday: "Everyday",
  Type: "Type",
  AppliedMachines: "Applied Machines",
  WorkOvertime: "Work Overtime",
  ScheduledMaintenance: "Scheduled Maintenance",
  ShiftImportRemark:
    "Please import a *.ics, the events will be transform to holiday.",
  ShiftRequired: "There should be at least one shift",

  //假日管理
  is_holiday: "Holiday Or Workday",
  Holiday: "Holiday",
  Workday: "Workday",
  holiday: "Date",

  //不良原因管理
  DefectGroupList: "Defect Group List",
  DefectList: "Defect List",
  DefectGroup: "Defect Group",
  bad_reason_id: "Defect",
  PushNotification: "Push Notification",
  controlLimit: "Control Limit",
  vietnamese: "vietnamese",
  english: "english",

  //停機原因管理
  DowntimeReason: "Downtime Reason",
  DowntimeReasonClassification: "Downtime Reason Classification",

  //非生產性工單原因管理
  UnproductiveOrderReason: "Unproductive Order Reason",
  order_class: "Order Classification",

  ModifyField: "Modify field",
  DeleteField: "Delete field",
  InsertField: "Insert field",

  Switch: "Switch",
  Open: "Open",
  Close: "Close",
  PlannedQuantity:"Planned Quantity",
  QuantityCantBeZero:"Quantity Can't be 0",
  PleaseInputPlannedQuantity:"Please Input Planned Quantity",
  PleaseInputNumber:"Please Input Number",
  CreateTime:"Creation Time",

};

export default management;
