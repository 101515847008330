import React, { useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { MdSearch } from "react-icons/md";
import { NewButton, DeleteModal, handleError } from "../../component/common";
import { InputModal, UserTable, AuthModal } from "../../component/root/user";
import { SysTabs } from "../../component/system/system";
import {
  ApiSys,
  ApiSysDeleteUser,
  getFactoryRole,
  getUserImage,
  baseURL,
} from "../../api.js";
import Context from "../../context";

export default function User({ checkEditAuth }) {
  const [userList, setUserList] = useState([]);
  const [modal, setModal] = useState();
  const [select, setSelect] = useState({ auth: {} });
  const [auth, setAuth] = useState();
  const [roleList, setRoleList] = useState([]);
  const [searchString, setSearchString] = useState("");
  const intl = useIntl();
  const { factory } = useContext(Context);

  useEffect(() => {
    getFactoryRole({ factoryID: factory }).then((res) =>
      setRoleList(res.data)
    );
  }, []);

  useEffect(() => {
    if (!modal) {
      ApiSys({
        account: localStorage.getItem("account"),
        factory_id: factory,
      }).then(async (res) => {
        for (let i = 0; i < res.data.UserDataList.length; ++i) {
          if (res.data.UserDataList[i].photo_path) {
            try {
              const { data } = await getUserImage(
                res.data.UserDataList[i].photo_path
              );
              res.data.UserDataList[i].photo = data;
            } catch (e) {
              console.log(e);
              res.data.UserDataList[
                i
              ].photo = `${baseURL}/${res.data.UserDataList[i].photo_path}`;
            }
          }
        }
        setUserList(
          res.data.UserDataList.sort((a, b) => a.role_id - b.role_id)
        );
        setAuth(res.data.AuthLevelCheck);
      });
    }
  }, [modal]);

  const showModal = (modal, item = { auth: {} }) => {
    if (!checkEditAuth()) {
      return;
    }

    if (modal === "edit" && item.photo_path) {
      const arr = item.photo_path.split("/");
      item.fileName = arr[arr.length - 1];
    }
    setSelect(item);
    setModal(modal);
  };

  const closeModal = () => {
    setModal();
    setSelect({ auth: {} });
  };

  const deleteData = () => {
    ApiSysDeleteUser({
      account: localStorage.getItem("account"),
      Email: select.email,
    })
      .then(closeModal)
      .catch((err) => handleError(err, intl));
  };

  return (
    <div>
      <SysTabs tab="0" />
      <div className="page-content shadow-lg">
        <div className="jc-end ai-stretch">
          <div className="ai-stretch" style={{ marginRight: "10px" }}>
            <input
              className="text-input"
              style={{ borderRight: "none", width: "150px" }}
              value={searchString}
              onChange={(e) => setSearchString(e.currentTarget.value)}
              placeholder={intl.formatMessage(
                { id: "InputThe" },
                { name: intl.formatMessage({ id: "Keywords" }) }
              )}
            />
            <button className="input-button center">
              <MdSearch />
            </button>
          </div>
          <NewButton
            onClick={() => showModal("new")}
            text={intl.formatMessage(
              { id: "Create" },
              { name: intl.formatMessage({ id: "User" }) }
            )}
          />
        </div>
        <hr />
        <UserTable
          showModal={showModal}
          data={userList}
          searchString={searchString}
        />
      </div>

      <InputModal
        modal={modal}
        closeModal={closeModal}
        data={select}
        roleList={roleList}
      />

      <AuthModal
        modal={modal}
        closeModal={closeModal}
        data={select}
        auth={auth}
      />

      <DeleteModal
        show={modal === "delete"}
        onHide={closeModal}
        callback={deleteData}
        name={select.name}
        type="User"
      />
    </div>
  );
}
