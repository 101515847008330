const index = {
  //生產總覽-機台總覽
  DeviceOverview: "機台總覽",
  remainingDays: "剩餘時間",
  expect_quantity: "目標數量",
  OEETrend: "OEE趨勢",
  ProgressTrend: "進度趨勢",

  //生產總覽-生產總覽-稼動總覽
  OperationOverview: "稼動總覽",
  DeviceName: "機台名稱",
  Status: "狀態",
  ProductionQuantity: "生產數量",
  TodayQuantity: "生產數量",
  StartTime: "開始時間",

  //生產總覽-生產總覽-OEE總覽
  OEEOverview: "OEE總覽",
  DeviceQuantity: "總機台數",
  Unit: "台",
  DeviceNoOrder: "無工單機台數",
  DeviceStop: "停止中機台數",
  DeviceOffline: "連線異常機台數",
  OEEOnTheHour: "整點OEE",
  OperationStatus: "運行狀態",
  OperationMode: "運行模式",
  LastNinetyMin: "前90分",
  LastSixtyMin: "前60分",
  LastThirtyMin: "前30分",
};

export default index;
