const dashboard = {
  "1x1": "1x1",
  "2x2": "2x2",
  title: "標題",
  type: "型態",
  boardCycleTime: "輪播間隔時間",
  deviceList: "機台",
  RealtimeStatus: "機台即時狀況",
  Carousel: "輪播",
  CapacityStatus: "產能狀況",
  ProductionProgress: "生產進度",
  ProductionPerHour: "每小時生產數量",
  orderNum: "工單編號",
  productNum: "產品編號",
  createTime: "開始時間",
  endTime: "目標完成",
  expectEndTime: "預計完成",
  Manual: "手動",
  Auto: "自動",
  Semiautomatic: "半自動",
  Custom: "",
  Ahead: "生產超前",
  Behind: "生產落後",
  OnSchedule: "生產正常",
  Pausing: "暫停中",
  Pause: "暫停",
  ProcessSettingTimes: "工藝設置次數",
  Fullscreen: "全螢幕",
  KanbanList: "看板清單",
  TopDieCycle: "上模週期",
  Unacceptable: "不合格",
  NeedsImprovement: "需改進",
  Typical: "正常標準",
  HighPerformance: "高積效",
  WorldClass: "標竿績效",
};

export default dashboard;
