import React, { useState, useContext } from "react";
import { useIntl } from "react-intl";
import { NewButton, DeleteModal } from "../../component/common";
import { SysTabs } from "../../component/system/system";
import { InputModal, FileTable } from "../../component/system/file";
import { deleteSysDocument } from "../../api";
import Context from "../../context";

export default function File({ checkEditAuth }) {
  const intl = useIntl();
  const { factory } = useContext(Context);
  const [modal, setModal] = useState("");
  const [path, setPath] = useState();
  const [select, setSelect] = useState({});

  const showModal = (modal, item) => {
    if (!checkEditAuth()) {
      return;
    }
    setSelect(item);
    setModal(modal);
  };

  const closeModal = () => {
    setModal();
    setSelect({});
  };

  const deleteData = () => {
    deleteSysDocument(factory, { object: select.path }).then(closeModal);
  };

  return (
    <div>
      <SysTabs tab="5" />
      <div className="page-content shadow-lg">
        <div className="jc-end">
          <NewButton
            onClick={() => showModal("new")}
            text={intl.formatMessage(
              { id: "Upload" },
              { name: intl.formatMessage({ id: "File" }) }
            )}
          />
        </div>
        <hr />
        <FileTable modal={modal} showModal={showModal} setPath={setPath} />
      </div>

      <InputModal modal={modal} closeModal={closeModal} path={path} />

      <DeleteModal
        show={modal === "delete"}
        onHide={closeModal}
        name={select?.name}
        type="File"
        callback={deleteData}
      />
    </div>
  );
}
