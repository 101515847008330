import React, { useContext, useState, useEffect } from "react";
import moment from "moment";
import { FormattedMessage } from "react-intl";
import {
  SingleDropdownPicker,
  DateRadio,
  RangeDatePicker,
  SingleDatePicker,
} from "../../component/common";
import { AnalysisTabs } from "../../component/analysis/Analysis";
import { SingleDeviceList } from "../../component/analysis/Production";
import { OrderTable } from "../../component/analysis/Schedule";
import { getAnalysisReportOrdersExcel, baseURL } from "../../api.js";
import Context from "../../context";

const today = moment().format("YYYY-MM-DD");

export default function AnalysisSchedule() {
  const [deviceID, setDeviceID] = useState();
  const [date, setDate] = useState({
    startDate: today,
    endDate: today,
  });
  const [month, setMonth] = useState(0);
  const [sort, setSort] = useState("date");
  const { factory, locale } = useContext(Context);

  useEffect(() => {
    if (sort === "date") {
      setDate({ startDate: today });
    } else if (sort === "interval") {
      setDate({
        startDate: today,
        endDate: today,
      });
    } else if (sort === "month") {
      setDate({
        startDate: moment().startOf("month").format("YYYY-MM-DD"),
        endDate: moment().endOf("month").format("YYYY-MM-DD"),
      });
    }
  }, [sort]);

  const pickDate = (name, date) => {
    setDate((prev) => ({ ...prev, [name]: date }));
  };

  const pickDateRange = (startDate, endDate) => {
    setDate((prev) => ({ ...prev, startDate, endDate }));
  };

  const pickMonth = (value) => {
    setMonth(value);
    setDate({
      startDate: moment().month(value).startOf("month").format("YYYY-MM-DD"),
      endDate: moment().month(value).endOf("month").format("YYYY-MM-DD"),
    });
  };

  const handleDownloadExcel = () => {
    getAnalysisReportOrdersExcel({
      FactoryID: factory,
      CheckDateStart: date.startDate,
      CheckDateEnd: date.endDate,
      Language: locale,
      DeviceID: deviceID,
    })
      .then((res) => window.open(baseURL + res.data, "_blank"))
      .catch(console.log);
  };

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return (
    <div>
      <AnalysisTabs tab="3" />
      <div className="page-content shadow-lg">
        <hr />
        <div className="jc-space-between">
          <div className="ai-stretch">
            <DateRadio
              list={[
                { label: <FormattedMessage id="Date" />, value: "date" },
                {
                  label: <FormattedMessage id="Interval" />,
                  value: "interval",
                },
                { label: <FormattedMessage id="Month" />, value: "month" },
              ]}
              value={sort}
              onChange={(e) => setSort(e.currentTarget.value)}
              style={{ marginRight: "20px" }}
            />
            {sort === "date" ? (
              <SingleDatePicker
                name="startDate"
                date={date.startDate}
                onApply={pickDate}
              />
            ) : sort === "interval" ? (

              
              <RangeDatePicker
                startDate={date.startDate}
                endDate={date.endDate}
                timePicker={false}
                onApply={pickDateRange}
              />
            ) : (
              <SingleDropdownPicker
                style={{ width: "100px" }}
                value={month}
                list={months.map((item, i) => ({
                  label: <FormattedMessage id={item} />,
                  value: i,
                }))}
                onApply={pickMonth}
              />
            )}
          </div>
          <button className="center btn-gray" onClick={handleDownloadExcel}>
            <FormattedMessage id="DownloadExcel" />
          </button>
        </div>
        <hr />
        <div className="row">
          <div className="col-md-4 col-lg-3 col-xl-2">
            <h4>
              <b>
                <FormattedMessage id="PleasePickThe" />
                <FormattedMessage id="Device" />
              </b>
            </h4>
            <SingleDeviceList handleSelect={setDeviceID} checked={deviceID} />
          </div>
          <div className="col-md-8 col-lg-9 col-xl-10">
            <OrderTable date={date} deviceID={deviceID} />
          </div>
        </div>
      </div>
    </div>
  );
}
