import React, { useState, useEffect, useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link, useParams, useHistory } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import { BiErrorCircle } from "react-icons/bi";
import { MdLock, MdAndroid } from "react-icons/md";
import { FaMobileAlt, FaApple } from "react-icons/fa";
import ClipLoader from "react-spinners/ClipLoader";
import sha512 from "crypto-js/sha512";
import {
  postUserLogin,
  postUserGetResetToken,
  postUserReset,
  getUserResetVerify,
  request,
  getUserSelf,
} from "../api";
import Context from "../context";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [modal, setModal] = useState(false);
  const [rememberMe, setKeepLogin] = useState(false);
  const history = useHistory();
  const intl = useIntl();
  const { token, setToken } = useContext(Context);

  useEffect(() => {
    if (token) {
      history.push("/pages/index/device");
    }
  }, [token]);

  useEffect(() => {
    setEmail("");
    setPassword("");
  }, [modal]);

  const login = (e) => {
    e.preventDefault();
    if (!email || !password) {
      setError(intl.formatMessage({ id: "EmptyUnable" }));
      return;
    }
    postUserLogin({
      email,
      password,
      rememberMe,
    })
      .then((res) => {
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("account", email);
        request.defaults.headers.common["Authorization"] = res.data.token;
        setToken(res.data.token);
      })
      .catch((e) =>
        setError(
          intl.formatMessage({
            id: e.response ? e.response.data.errors[0].msg : "Failed",
          })
        )
      );
  };

  return (
    <div className="wrapper_area login_area">
      <main className="main_area clearfix">
        <div className="login_content clearfix">
          <div className="login_bg d-flex">
            <div className="login_left_logo d-flex align-items-center justify-content-center">
              <img
                className="login_logo"
                src="./images/logo/logo.svg"
                height="50"
              />
            </div>
            <div className="login_inner clearfix">
              <div className="login_form_bg d-flex align-items-center justify-content-center">
                <div className="login_form_block w-100">
                  <Form className="login-form" action="/">
                    <h3>
                      <FormattedMessage id="UserLogin" />
                    </h3>
                    <Form.Group controlId="email">
                      <Form.Label className="lab_title">
                        <FormattedMessage id="Email" />
                      </Form.Label>
                      <Form.Control
                        className="ipt_data"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        placeholder={
                          intl.formatMessage({ id: "PleaseEnterThe" }) +
                          intl.formatMessage({ id: "Email" })
                        }
                      />
                    </Form.Group>
                    <Form.Group controlId="password">
                      <Form.Label className="lab_title">
                        <FormattedMessage id="Pwd" />
                      </Form.Label>
                      <Form.Control
                        type="password"
                        className="ipt_data"
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                        placeholder={
                          intl.formatMessage({ id: "PleaseEnterThe" }) +
                          intl.formatMessage({ id: "Pwd" })
                        }
                      />
                    </Form.Group>
                    <div className="jc-space-between ai-center form-group">
                      <div className="checkbox_area clearfix">
                        <input
                          type="checkbox"
                          className="form-check-input d-none"
                          id="keepLogin"
                          onChange={(e) => setKeepLogin(e.target.checked)}
                        />
                        <label className="form-check-label" htmlFor="keepLogin">
                          <b>
                            <FormattedMessage id="KeepLogin" />
                          </b>
                        </label>
                      </div>
                      <span
                        style={{ cursor: "pointer", color: "#cccccc" }}
                        onClick={() => setModal(true)}
                      >
                        <FormattedMessage id="ForgetPassword" />
                      </span>
                    </div>
                    {error && (
                      <Form.Group>
                        <div className="warn">
                          <BiErrorCircle
                            style={{ marginRight: "5px", fontSize: "20px" }}
                          />
                          <span>{error}</span>
                        </div>
                      </Form.Group>
                    )}
                    <Form.Group>
                      <button
                        onClick={login}
                        className="btn btn_submit text-center w-100 center"
                        type="submit"
                      >
                        <MdLock size="24px" style={{ marginRight: "5px" }} />
                        <span>
                          <FormattedMessage id="Login" />
                        </span>
                      </button>
                    </Form.Group>
                    <Form.Group className="text-center">
                      <span className="lab_title">OR</span>
                    </Form.Group>
                    <Form.Group className="text-center">
                      <Link to="/mobile">
                        <button className="btn btn_submit text-center w-100 center">
                          <FaMobileAlt
                            size="24px"
                            style={{ marginRight: "5px" }}
                          />
                          <span>
                            <FormattedMessage id="ToMobile" />
                          </span>
                        </button>
                      </Link>
                    </Form.Group>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <ResetModal modal={modal} setModal={setModal} />
    </div>
  );
}

function ResetModal({ modal, setModal, token }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [code, setCode] = useState("");
  const [send, setSend] = useState(false);
  const [verified, setVerified] = useState(false);
  const [loading, setLoading] = useState(false);
  const [_token, setToken] = useState(token);
  const [hint, setHint] = useState("");
  const [error, setError] = useState(false);
  const intl = useIntl();
  const history = useHistory();

  useEffect(() => {
    if (token) {
      verify();
    }
  }, []);

  useEffect(() => {
    if (!modal) {
      setSend(false);
      setVerified(false);
      setLoading(false);
      setEmail("");
      setPassword("");
      setConfirmPassword("");
      setCode("");
      setHint("");
      setError(false);
    }
  }, [modal]);

  const showWarn = (dom, msg) => {
    const ele = document.getElementById(dom).nextElementSibling;
    ele.textContent = intl.formatMessage({ id: msg });
    ele.hidden = false;
  };

  const sendToken = () => {
    document.querySelectorAll(".warn").forEach((item) => (item.hidden = true));
    if (email) {
      setLoading(true);
      postUserGetResetToken({
        email,
        host: window.location.origin + "/#",
      })
        .then(() => {
          setError(false);
          setHint(intl.formatMessage({ id: "VerificationCodeSendToEmail" }));
          setLoading(false);
          setSend(true);
        })
        .catch((e) => {
          setError(true);
          setHint(
            intl.formatMessage({
              id: e.response ? e.response.data.errors[0].msg : "Failed",
            })
          );
          setLoading(false);
        });
    } else {
      showWarn("resetEmail", "Required");
    }
  };

  const verify = () => {
    document.querySelectorAll(".warn").forEach((item) => (item.hidden = true));
    if (_token || code) {
      getUserResetVerify(_token ? _token : sha512(code))
        .then((res) => {
          setVerified(res.data.status);
          if (res.data.email) {
            setEmail(res.data.email);
            setHint("");
          }
        })
        .catch((e) => {
          setError(true);
          setHint(
            intl.formatMessage({ id: e.response ? "VerifyFailed" : "Failed" })
          );
          setSend(true);
          setToken();
        });
    } else {
      showWarn("code", "Required");
    }
  };

  const reset = () => {
    document.querySelectorAll(".warn").forEach((item) => (item.hidden = true));
    if (password) {
      if (password === confirmPassword) {
        let req = {
          token: _token ? _token : sha512(code),
          password,
        };
        postUserReset(req)
          .then(() => {
            setModal(false);
            history.push("/");
          })
          .catch((e) => {
            setError(true);
            setHint(
              intl.formatMessage({
                id: e.response ? e.response.data.errors[0].msg : "Failed",
              })
            );
          });
      } else {
        showWarn("confirmPassword", "ConfrimPasswordWrong");
      }
    } else {
      showWarn("newPassword", "Required");
    }
  };

  let submit;
  if (!loading) {
    if (verified) {
      submit = reset;
    } else if (send) {
      submit = verify;
    } else {
      submit = sendToken;
    }
  }

  return (
    <Modal
      className="modal_style fade"
      size="lg"
      show={modal}
      onHide={() => setModal(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title as="h5">
          <b>
            <FormattedMessage id="ForgetPassword" />
          </b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group as={Row} controlId="resetEmail">
          <Form.Label column lg={3} md={4}>
            <FormattedMessage id="Email" />
          </Form.Label>
          <Col lg={9} md={8}>
            <Form.Control
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              disabled={token ? send && verified : send}
              placeholder={
                intl.formatMessage({ id: "PleaseEnterThe" }) +
                intl.formatMessage({ id: "Email" })
              }
            />
            <span className="warn" />
          </Col>
        </Form.Group>
        {send ? (
          <Form.Group as={Row} controlId="code">
            <Form.Label column lg={3} md={4}>
              <FormattedMessage id="VerificationCode" />
            </Form.Label>
            <Col lg={9} md={8}>
              <Form.Control
                onChange={(e) => setCode(e.target.value)}
                value={code}
                disabled={verified}
                placeholder={
                  intl.formatMessage({ id: "PleaseEnterThe" }) +
                  intl.formatMessage({ id: "VerificationCode" })
                }
              />
              <span className="warn" />
            </Col>
          </Form.Group>
        ) : null}
        {verified ? (
          <React.Fragment>
            <Form.Group as={Row} controlId="newPassword">
              <Form.Label column lg={3} md={4}>
                <FormattedMessage id="NewPassword" />
              </Form.Label>
              <Col lg={9} md={8}>
                <Form.Control
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  placeholder={
                    intl.formatMessage({ id: "PleaseEnterThe" }) +
                    intl.formatMessage({ id: "NewPassword" })
                  }
                />
                <span className="warn" />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="confirmPassword">
              <Form.Label column lg={3} md={4}>
                <FormattedMessage id="ConfirmPassword" />
              </Form.Label>
              <Col lg={9} md={8}>
                <Form.Control
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  value={confirmPassword}
                  placeholder={intl.formatMessage({
                    id: "PleaseEnterThePasswordAgain",
                  })}
                />
                <span className="warn" />
              </Col>
            </Form.Group>
          </React.Fragment>
        ) : null}
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-end ai-cneter">
        {loading ? (
          <div style={{ marginRight: "10px" }}>
            <ClipLoader color="blue" loading={loading} size={40} />
          </div>
        ) : (
          <span style={{ marginRight: "10px", color: error ? "red" : "green" }}>
            {hint}
          </span>
        )}
        <button className="btn btn-delete " onClick={submit}>
          <FormattedMessage id="OK" />
        </button>
        <button className="btn btn-cancel" onClick={() => setModal(false)}>
          <FormattedMessage id="Cancel" />
        </button>
      </Modal.Footer>
    </Modal>
  );
}

function Mobile() {
  return (
    <div className="mobile-area">
      <div>
        <div className="mobile-describe">
          <div>
            <h2>即時遠端監控</h2>
            <h6>－隨時掌握機台狀態增加機台10-15效率</h6>
          </div>
          <div>
            <h2>AI數據洞察</h2>
            <h6>－iSPC分析最加上油時機</h6>
          </div>
          <div>
            <h2>分析、改善</h2>
            <h6>－稼動、效率、良率生產數量、週期時間</h6>
          </div>
        </div>
        <div className="d-flex">
          <div className="qr-background" style={{ marginRight: "60px" }}>
            <span className="ai-center">
              <FaApple style={{ marginRight: "5px", fontSize: "45px" }} />
              iOS
            </span>
            <img
              src="./images/mobile/ios-qr-code.png"
              className="fit-contain"
            />
          </div>
          <div className="qr-background">
            <span className="ai-center">
              <MdAndroid style={{ marginRight: "5px", fontSize: "45px" }} />
              Android
            </span>
            <img
              src="./images/mobile/android-qr-code.png"
              className="fit-contain"
            />
          </div>
        </div>
      </div>
      <div>
        <img src="./images/mobile/device.png" />
      </div>
    </div>
  );
}

function Reset() {
  const { token } = useParams();
  return (
    <div className="login_area">
      <div className="login_bg">
        <ResetModal modal={true} setModal={() => {}} token={token} />
      </div>
    </div>
  );
}

export { Login, Reset, Mobile };
