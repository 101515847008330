import React, { useEffect, useRef, useState, useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { FaCircle, FaRegClock } from "react-icons/fa";
import { MdArrowDownward, MdArrowUpward, MdRemove } from "react-icons/md";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartsMore from "highcharts/highcharts-more.js";
import highchartsExport from "highcharts/modules/exporting.js";
import {
  ProgressBar,
  ProduceQuantityChart,
  loadData,
  gaugeOption,
} from "./Dashboard";
import { baseURL } from "../../api.js";
import Context from "../../context";
highchartsMore(Highcharts);
highchartsExport(Highcharts);

function SmallDashboard({ deviceID, fullscreen }) {
  const chartComponent = useRef([]);
  const [data, setData] = useState([]);
  const [dataInterval, setDataInterval] = useState(1);
  const intl = useIntl();
  const { factory } = useContext(Context);

  useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  useEffect(() => updateDimensions(), [fullscreen, data.deviceName]);

  useEffect(() => {
    if (deviceID) {
      _loadData();
      const temp = setInterval(_loadData, 10000);
      return () => clearInterval(temp);
    }
  }, [deviceID, dataInterval]);

  const _loadData = () => {
    loadData({
      FactoryID: factory,
      DashboardQuantity: 1,
      DeviceID: deviceID,
      CheckTimeInterval: dataInterval,
    })
      .then((data) => {
        for (let i = 0; i < data.length; i++) {
          if (data[i].remainingDays) {
            data[i].remainingDays = data[i].remainingDays
              .replace("天", intl.formatMessage({ id: "Days" }))
              .replace("時", intl.formatMessage({ id: "Hours" }))
              .replace("分", intl.formatMessage({ id: "Minutes" }));
          }
        }
        setData(data);
        updateDimensions();
      })
      .catch(console.log);
  };

  const updateDimensions = () => {
    chartComponent.current.forEach(item => {
      const container = item?.container.current;

      if (container) {
        const chart = document.getElementById("chart");
        container.style.height = chart.clientHeight + "px";
        container.style.width = chart.clientWidth + "px";
        item.chart.reflow();
      }
    });
  };

  for (let i = 0; i < data.length; i++) {
    let arrow;

    if (data[i].oeeStatus == -1) {
      arrow = <MdArrowDownward size="30px" />;
    } else if (data[i].oeeStatus == 1) {
      arrow = <MdArrowUpward size="30px" />;
    } else {
      arrow = <MdRemove size="30px" />;
    }

    let options = gaugeOption({
      color: data[i].oeeColor,
      dial: {
        radius: "50%",
        baseWidth: 10,
        topWidth: 0,
        baseLength: "30%",
        rearLength: "10%",
      },
      tickLength: 70,
      tickWidth: 5,
      oee: data[i].oee,
    });

    let columns = [
      "orderNum",
      "productNum",
      "createTime",
      "endTime",
      "expectEndTime",
      "remainingDays",
    ];

    data[i].arrow = arrow;
    data[i].options = options;
    data[i].columns = columns;
  }

  if (data.length > 0) {
    if (!data[0].deviceName) {
      return <div />;
    }
  }

  return (
    data.map((row, index) => (
      <div className="jc-space-between ai-stretch dashboard-small">
        <div className="flex-column main-left">
          <div className="ai-stretch" style={{ marginBottom: "10px" }}>
            <div
              className="center border-right flex-1 bar-left"
              style={{ color: row.statusColor }}
            >
              <div className="flex-column">
                <b style={{ fontSize: "18px" }}>{row.deviceName}</b>
                <span style={{ fontSize: "12px" }}>
                  {row.deviceStatus}
                  {row.deviceMachineMode ? "：" : ""}
                  {row.deviceMachineMode}
                </span>
              </div>
              <FaCircle size="35px" style={{ margin: "0 10px" }} />
            </div>
            <div className="ai-stretch jc-space-between bar-right">
              <div
                className="center"
                style={{
                  backgroundColor:
                    row.oeeStatus === -1
                      ? "#e40026"
                      : row.oeeStatus === 1
                        ? "#2dc76d"
                        : "#0080FF",
                }}
              >
                <b>
                  <FormattedMessage id="OEETrend" />
                </b>
                {row.arrow}
              </div>
              <div
                className="center"
                style={{
                  backgroundColor:
                    row.produceStatus.statusCode === -1
                      ? "#e40026"
                      : row.produceStatus.statusCode === 1
                        ? "#2dc76d"
                        : "#0080FF",
                }}
              >
                <FaRegClock size="24px" style={{ marginRight: "5px" }} />
                <span className="flex-column al-center">
                  <span>{row.produceStatus.time}</span>
                  <span>{row.produceStatus.status}</span>
                </span>
              </div>
              <div
                className="center flex-column"
                style={{
                  backgroundColor:
                    row.badProductQuantity >= 1 ? "#f8d44a" : "#cccccc",
                }}
              >
                <span>{row.badProductQuantity}</span>
                <span>
                  <b>
                    <FormattedMessage id="Defective" />
                  </b>
                </span>
              </div>
            </div>
          </div>
          <div
            className="ai-center progress-area"
            style={{ marginBottom: "10px" }}
          >
            <span className="flex-1 text-center">
              <FormattedMessage id="ProductionProgress" />
            </span>
            <b className="flex-1 text-center">{row.produceProgress}%</b>
            <ProgressBar
              value={row.produceProgress}
              label={`${row.actualProduceQuantity}/${row.expectProduceQuantity}`}
            />
          </div>
          <div
            className="ai-center  progress-area"
            style={{ marginBottom: "20px" }}
          >
            <span className="flex-1 text-center">
              <FormattedMessage id="TopDieCycle" />
            </span>
            <b className="flex-1 text-center">
              {row.cycleTime ? row.cycleTime : 0}
              <FormattedMessage id="Sec" />
            </b>
            <ProgressBar
              value={(row.cycleTime / (row.bestCycleTime * 2.5)) * 100}
              max={row.bestCycleTime * 2.5}
              isIdeal={row.cycleTime < row.bestCycleTime}
              ideal={row.bestCycleTime}
            />
          </div>
          <div className="jc-space-between ai-stretch flex-1">
            <div className="flex-column" style={{ marginRight: "10px" }}>
              <ProduceQuantityChart
                pickDataInterval={(e) => setDataInterval(e.target.value)}
                dataInterval={row.dataInterval}
                max={row.produceQuantityMaximum}
                ideal={row.idealProduceQuantity}
                least={row.leastProduceQuantity}
                list={row.produceQuantityByTime}
              />
              <div className="list-block">
                <div className="text-center">
                  <b>
                    <FormattedMessage id="Defective" />
                  </b>
                </div>
                <div>
                  <marquee scrollamount="1">
                    {row.badReason.map(({ description, quantity }, i) => (
                      <span key={i}>
                        {`${i + 1}. ${description} ${intl.formatMessage(
                          { id: "Count" },
                          { count: quantity }
                        )} `}
                      </span>
                    ))}
                  </marquee>
                </div>
              </div>
              <div className="list-block">
                <div className="text-center">
                  <b>
                    <FormattedMessage id="DowntimeReason" />
                  </b>
                </div>
                <div>
                  <marquee scrollamount="1">
                    {row.closingReason.map((item, i) => (
                      <span key={i}>{`${i + 1}. ${item} `}</span>
                    ))}
                  </marquee>
                </div>
              </div>
            </div>
            <div
              className="position-relative flex-column"
              style={{
                width: "24vw",
                border: "1px solid rgba(0, 0, 0, 0.125)",
                padding: "20px",
              }}
            >
              <span>
                <b>OEE</b>
              </span>
              <div
                className="position-relative"
                id="chart"
                style={{ width: "100%", height: "24vh" }}
              >
                <HighchartsReact
                  highcharts={Highcharts}
                  options={row.options}
                  ref={(element) => { chartComponent.current[index] = element; }}
                />
                <span
                  className="position-absolute text-center"
                  style={{ top: "60%", width: "100%" }}
                >
                  <b>{row.oeeTitle}</b>
                </span>
                <div
                  className="position-absolute jc-center"
                  style={{ width: "100%", top: "85%" }}
                >
                  <span
                    className="jc-space-between"
                    style={{ width: "73%", fontSize: "28px" }}
                  >
                    <b> 0</b>
                    <b>100</b>
                  </span>
                </div>
              </div>
              <span
                className="position-absolute"
                style={{
                  top: "25px",
                  right: "25px",
                  color: row.oeeColor,
                  fontSize: "36px",
                }}
              >
                <b>{row.oee}%</b>
              </span>
            </div>
          </div>
        </div>
        <div className="flex-1 border-left flex-column jc space-between main-right">
          <div>
            {row.columns.map((id, i) => (
              <div
                key={id}
                className={`flex-column ${i < row.columns.length - 1 ? "border-bottom" : ""
                  }`}
              >
                {intl.formatMessage({ id })}
                <h5>
                  <b>{row[id]}</b>
                </h5>
              </div>
            ))}
          </div>
          {row.producePhotoPath ? (
            <img
              src={baseURL + row.producePhotoPath}
              className="fit-contain"
              style={{ width: "100%" }}
            />
          ) : null}
        </div>
      </div>
    ))
  );
}
export { SmallDashboard };
