import { FormattedMessage } from "react-intl";

export const lampStatus = (code) => {
  const status = [
    { status: <FormattedMessage id="Shutdown" />, color: "#617DE8" },
    { status: <FormattedMessage id="Alert" />, color: "#DB4954" },
    { status: <FormattedMessage id="Standby" />, color: "#F3A43B" },
    { status: <FormattedMessage id="Running" />, color: "#5FB946" },
    {},
    { status: <FormattedMessage id="NoOrderRunning" />, color: "#5FB946" },
    { status: <FormattedMessage id="MachineIsDowntime" />, color: "#F3A43B" },
    { status: <FormattedMessage id="ProductionPause" />, color: "#5FB946" },
    { status: <FormattedMessage id="MicroStop" />, color: "#5FB946" },
    { status: <FormattedMessage id="MachineIsOffline" />, color: "#cccccc" },
  ];
  return status[code] ? status[code] : {};
};

export const machineMode = (code) => {
  const mode = [
    null,
    <FormattedMessage id="Manual" />,
    <FormattedMessage id="Semiautomatic" />,
    <FormattedMessage id="Auto" />,
  ];
  return mode[code];
};

export const machineStatus = (code) => {
  const status = [
    <FormattedMessage id="Ideal" />,
    <FormattedMessage id="Waiting" />,
    <FormattedMessage id="Busy" />,
  ];
  return status[code];
};

export const orderStatus = (code) => {
  const status = [
    <FormattedMessage id="Unscheduled" />,
    <FormattedMessage id="Waiting" />,
    <FormattedMessage id="Producing" />,
    <FormattedMessage id="FinishedManually" />,
    <FormattedMessage id="FinishedAutomatically" />,
    <FormattedMessage id="Pause" />,
  ];
  return status[code];
};

export const showWarn = (dom, msg, intl) => {
  const ele = document.getElementById(dom);
  if (ele) {
    if (intl) {
      ele.textContent = intl.formatMessage({ id: msg });
    } else {
      ele.textContent = msg;
    }
    ele.hidden = false;
  }
};

export const handleError = (err, intl) => {
  if (err.response && err.response.status === 400) {
    alert(intl.formatMessage({ id: "400" }));
  } else if (err.response && err.response.status === 404) {
    alert(intl.formatMessage({ id: "404" }));
  } else if (err.response && err.response.status === 409) {
    alert(intl.formatMessage({ id: "409" }));
  } else {
    alert(intl.formatMessage({ id: "Failed" }));
    console.log(err);
  }
};
