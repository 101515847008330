import React, { useEffect, useState, useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Typography from "@material-ui/core/Typography";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import DeleteIcon from "@material-ui/icons/Delete";
import FolderIcon from "@material-ui/icons/Folder";
import DescriptionIcon from "@material-ui/icons/Description";
import ClipLoader from "react-spinners/ClipLoader";
import Alert from "@material-ui/lab/Alert";
import { FileInput } from "../management/Management";
import {
  getSysDocument,
  postSysDocument,
  ApiManagement_ProductList,
} from "../../api";
import Context from "../../context";

function FileTable({ showModal, setPath, modal }) {
  const intl = useIntl();
  const { factory } = useContext(Context);
  const [data, setData] = useState({});
  const [errors, setErrors] = useState([]);
  const [productList, setProductList] = useState([]);

  useEffect(() => {
    ApiManagement_ProductList({ factory_id: factory }).then((res) =>
      setProductList(res.data.map(({ ProductNum }) => ProductNum))
    );
  }, []);

  useEffect(() => {
    const findMaxNodeId = (nodes, max) => {
      for (let i = 0; i < nodes.length; ++i) {
        if (nodes[i].nodeId > max) {
          max = nodes[i].nodeId;
        }
        if (nodes[i].children) {
          max = findMaxNodeId(nodes[i].children, max);
        }
      }
      return max;
    };

    if (!modal && productList.length > 0) {
      getSysDocument(factory)
        .then((res) => {
          let nodeId = findMaxNodeId(res.data.children, res.data.nodeId);
          const columns = [
            { name: "MoldWaterWayConnection", children: ["Document", "Image"] },
            { name: "ProductionSop", children: ["Image", "Video"] },
            { name: "QualityInspection", children: ["Image", "Video"] },
          ];
          res.data.children = res.data.children.filter(
            ({ name }) => productList.indexOf(name) > -1
          );
          productList.forEach((product_number) => {
            if (
              !res.data.children.find(({ name }) => product_number === name)
            ) {
              res.data.children.push({
                path: `${res.data.path}/${product_number}`,
                name: product_number,
                type: "directory",
                nodeId: ++nodeId,
                children: [],
              });
            }
          });
          res.data.children.forEach((product) => {
            columns.forEach(({ name, children }) => {
              let directory = product.children.find((e) => e.name === name);
              if (!directory) {
                product.children.push({
                  path: `${product.path}/${name}`,
                  name,
                  type: "directory",
                  nodeId: ++nodeId,
                  children: [],
                });
                directory = product.children[product.children.length - 1];
              }
              children.forEach((name) => {
                if (!directory.children.find((e) => e.name === name)) {
                  directory.children.push({
                    path: `${directory.path}/${name}`,
                    name,
                    type: "directory",
                    nodeId: ++nodeId,
                    children: [],
                  });
                }
              });
            });
          });
          setData(res.data);
        })
        .catch((e) => {
          if (e.response) {
            console.log(e.response.data);
            setErrors(e.response.data.errors.map(({ msg }) => msg));
          }
        });
    }
  }, [modal, productList]);

  const onNodeSelect = (event, id) => {
    const findNode = (nodes, id) => {
      let result;
      for (let i = 0; i < nodes.length; ++i) {
        if (id === nodes[i].nodeId) {
          result = nodes[i];
        } else if (nodes[i].children) {
          result = findNode(nodes[i].children, id);
        }
        if (result) {
          break;
        }
      }
      return result;
    };

    const result =
      parseInt(id) === data.nodeId
        ? data
        : findNode(data.children, parseInt(id));
    setPath(result?.path);
  };

  const renderTree = ({ nodeId, name, type, children, path }) => (
    <TreeItem
      key={nodeId.toString()}
      nodeId={nodeId.toString()}
      label={
        <div className="ai-center p-3">
          {type === "directory" ? (
            <FolderIcon style={{ color: "#9893a6" }} />
          ) : (
            <DescriptionIcon style={{ color: "#9893a6" }} />
          )}
          <Typography className="mr-3 ml-3 flex-1">
            {intl.formatMessage({ id: name })}
          </Typography>
          {type !== "directory" ? (
            <DeleteIcon
              className="icon-btn"
              onClick={() => showModal("delete", { path, name })}
            />
          ) : null}
        </div>
      }
    >
      {children ? children.map((item) => renderTree(item)) : null}
    </TreeItem>
  );

  return data.nodeId ? (
    <TreeView className="w-100" onNodeSelect={onNodeSelect}>
      {renderTree(data)}
    </TreeView>
  ) : (
    <div className="center" style={{ width: "100%" }}>
      {errors.map((id, i) => (
        <Alert key={i} style={{ width: "100%" }} severity="error">
          {intl.formatMessage({ id })}
        </Alert>
      ))}
      <ClipLoader size={50} color="#123abc" loading={errors.length === 0} />
    </div>
  );
}

const InputModal = ({ modal, closeModal, path }) => {
  const intl = useIntl();
  const [file, setFile] = useState();
  const [warn, setWarn] = useState("");

  useEffect(() => {
    if (!modal) {
      setFile();
      setWarn("");
    }
  }, [modal]);

  const submit = () => {
    if (path) {
      const data = new FormData();
      data.append("file", file);
      postSysDocument(data, { path })
        .then(closeModal)
        .catch((e) => {
          if (e.response) {
            setWarn(e.response.data.errors[0]?.msg);
          }
        });
    } else {
      setWarn(
        intl.formatMessage(
          { id: "PickA" },
          { name: intl.formatMessage({ id: "Directory" }) }
        )
      );
    }
  };

  const title = intl.formatMessage(
    { id: "Upload" },
    { name: intl.formatMessage({ id: "File" }) }
  );

  return (
    <Modal
      className="modal_style fade"
      size="lg"
      show={modal === "new"}
      onHide={closeModal}
    >
      <Modal.Header closeButton>
        <Modal.Title as="h5">
          <b>{title}</b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group as={Row} controlId="title">
          <Form.Label column lg={3} md={4}>
            <FormattedMessage id="File" />
          </Form.Label>
          <Col lg={9} md={8}>
            <FileInput
              label={file?.name}
              placeholder={intl.formatMessage(
                { id: "PickA" },
                { name: intl.formatMessage({ id: "File" }) }
              )}
              onChange={(e) => setFile(e.target.files[0])}
            />
            <span className="warn" />
          </Col>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer className="jc-space-between ai-center">
        <span className="warn">{warn}</span>
        <div className="ai-center">
          <button className="btn btn-confirm" onClick={submit}>
            <FormattedMessage id="Confirm" />
          </button>
          <button className="btn btn-cancel" onClick={closeModal}>
            <FormattedMessage id="Cancel" />
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export { FileTable, InputModal };
