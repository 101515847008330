import React, { useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import moment from "moment";
import { lampStatus } from "../../function";
import { apiAnalysisUtilizationRate } from "../../api.js";
import { ProductionBar, ProductionTable } from "../index/activation";

function AnalysisProductionTable({ date, hourInterval }) {
  const intl = useIntl();
  const [data, setData] = useState({});
  const [devices, setDevices] = useState([]);
  const [initPeriod, setInitPeriod] = useState([]);

  const defaultFilterMode = "greater";
  const defaultFilterTime = 1;
  const defaultFilterUnit = "minute";
  const secsPerHour = 3600;
  const hoursPerDay = 24;
  const totalTime = secsPerHour * hoursPerDay / 100;
  const getDurationWidth = (duration, hourIterval) => (duration / (secsPerHour * hourIterval)) / (hoursPerDay / hourInterval) * 100
  useEffect(() => {
    loadData();
  }, [date]);

  const loadData = () => {
    apiAnalysisUtilizationRate({
      FactoryID: localStorage.getItem("factory"),
      CheckDateStart: date,
      FilterMode: defaultFilterMode,
      FilterTime: defaultFilterTime,
      FilterUnit: defaultFilterUnit,
    })
      .then((res) => {
        const { startTime } = res.data;
        let initTime = `${moment(startTime).format("YYYY-MM-DD")} 08:00:00`;
        setInitPeriod(initTime)
        const start = moment(startTime);
        res.data.OperationStatusData.forEach((item) => {
          const { status, color } = lampStatus(item.State);
          item.state = item.State;
          item.status = status;
          item.color = color;
          item.OEE = `${item.OEE}%`;
          item.Performance = `${item.Performance}%`;
          item.Utilization = `${item.Utilization}%`;
          item.Yield = `${item.Yield}%`;
          item.TodayQuantity = item.Quantity;
          item.StateLog.forEach((item) => {
            const { status, color } = lampStatus(item.state);
            item.status = status;
            item.color = color;
            item.left = Math.max(
              moment(item.startTime).diff(initTime, "s") / totalTime,
              0
            );
            item.width = getDurationWidth(item.duration, hourInterval);
            start.add(item.duration, "s");
            const h = Math.floor(item.duration / 3600);
            const m = Math.floor((item.duration - h * 3600) / 60);
            const s = item.duration - h * 3600 - m * 60;
            item.durationString =
              h +
              intl.formatMessage({ id: "Hours" }) +
              m +
              intl.formatMessage({ id: "Minutes" }) +
              s +
              intl.formatMessage({ id: "Seconds" });
          });
          item.timeline = item.StateLog;
        });
        setDevices(res.data.OperationStatusData);
        setData({
          OEE: `${res.data.OEE}%`,
          Performance: `${res.data.Performance}%`,
          Utilization: `${res.data.Utilization}%`,
          Yield: `${res.data.Yield}%`,
          TodayQuantity: res.data.TotalQuantity,
          start_at: startTime,
          end_at: start.format("YYYY-MM-DD HH:mm:ss"),
        });
      })
      .catch(console.log);
  };
  
  useEffect(() => {
    if (devices.length > 0) {
      devices.forEach((item) => {
        item.StateLog.forEach((item) => {
          item.left = Math.max(
            (moment(item.startTime).diff(initPeriod, "s")) / totalTime, 0
          );
          item.width = getDurationWidth(item.duration, hourInterval);
        });
      });
    };
  setDevices(devices)
}, [hourInterval])
  const columns = [
    {
      Header: <FormattedMessage id="DeviceName" />,
      accessor: "DeviceName",
    },
    {
      Header: <FormattedMessage id="Status" />,
      accessor: "status",
    },
    {
      Header: <FormattedMessage id="OEE" />,
      accessor: "OEE",
    },
    {
      Header: <FormattedMessage id="Utilization" />,
      accessor: "Utilization",
    },
    {
      Header: <FormattedMessage id="Performance" />,
      accessor: "Performance",
    },
    {
      Header: <FormattedMessage id="Yield" />,
      accessor: "Yield",
    },
    {
      Header: <FormattedMessage id="TodayQuantity" />,
      accessor: "TodayQuantity",
    },
  ];

  return (
    <React.Fragment>
      <ProductionBar data={data} />
      <hr />
      <ProductionTable
        data={devices}
        tooltip={true}
        start_at={data.start_at}
        end_at={data.end_at}
        columns={columns}
        hourInterval={hourInterval}
      />
    </React.Fragment>
  );
}

export { AnalysisProductionTable };
