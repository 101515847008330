import React, { useEffect, useState, useContext } from "react";
import moment from "moment";
import { FormattedMessage, useIntl } from "react-intl";
import {
  DateRadio,
  RangeDatePicker,
  MultiDropdownPicker,
} from "../../component/common";
import { AnalysisTabs, AmountMenu } from "../../component/analysis/Analysis";
import {
  AmountTable,
  CycleTable,
} from "../../component/analysis/amountInterval";
import {
  baseURL,
  apiGetAnalysisDateReport,
  apiGetAnalysisOrderProductList,
  apiDownloadAnalysisProductQuantityDateReportExcel,
  apiDownloadAnalysisMachineCycleDateReportExcel,
} from "../../api.js";
import zh_TW from "../../i18n/zh-tw";
import en from "../../i18n/en";
import Context from "../../context";

export default function AnalysisAmount() {
  const today = moment().format("YYYY-MM-DD");
  const [date, setDate] = useState({
    startDate: today,
    endDate: today,
  });
  const [orderList, setOrderList] = useState([]);
  const [checkedOrder, setCheckedOrder] = useState([]);
  const [productList, setProductList] = useState([]);
  const [checkedProduct, setCheckedProduct] = useState([]);
  const [data, setData] = useState({
    DateReportProductQuantityData: [],
    DateReportMachineCycleData: [],
  });
  const intl = useIntl();
  const { factory, locale } = useContext(Context);
  let lang = {};
  if (locale === "zh-TW") {
    lang = zh_TW;
  } else {
    lang = en;
  }

  useEffect(() => {
    apiGetAnalysisOrderProductList({
      FactoryID: factory,
      CheckDateStart: date.startDate,
      CheckDateEnd: date.endDate,
    })
      .then((res) => {
        setOrderList(res.data.OrderList);
        setProductList(res.data.ProductList);
      })
      .catch(console.log);
  }, [date, factory]);

  useEffect(() => {
    apiGetAnalysisDateReport({
      FactoryID: factory,
      CheckDateStart: date.startDate,
      CheckDateEnd: date.endDate,
      CheckOrderList: checkedOrder,
      CheckProductList: checkedProduct,
    })
      .then((res) => setData(res.data))
      .catch(console.log);
  }, [date, factory, checkedProduct, checkedOrder]);

  const pickDateRange = (startDate, endDate) => {
    setDate((prev) => ({ ...prev, startDate, endDate }));
  };

  const downloadPQReport = () => {
    apiDownloadAnalysisProductQuantityDateReportExcel({
      FactoryID: factory,
      CheckDateStart: date.startDate,
      CheckDateEnd: date.endDate,
      DataTitle: [
        lang.order_num_name,
        lang.DeviceNumber,
        lang.product_num,
        lang.product_name,
        lang.Total,
      ],
      CheckOrderList: checkedOrder,
      CheckProductList: checkedProduct,
    })
      .then((res) => window.open(`${baseURL}/${res.data}`))
      .catch(console.log);
  };

  const downloadMCReport = () => {
    apiDownloadAnalysisMachineCycleDateReportExcel({
      FactoryID: factory,
      CheckDateStart: date.startDate,
      CheckDateEnd: date.endDate,
      DataTitle: [lang.DeviceNumber, lang.Total],
      CheckOrderList: checkedOrder,
      CheckProductList: checkedProduct,
    })
      .then((res) => window.open(`${baseURL}/${res.data}`))
      .catch(console.log);
  };

  return (
    <div>
      <AnalysisTabs tab="1" />
      <div className="page-content shadow-lg">
        <div className="jc-end">
          <AmountMenu tab="0" />
        </div>
        <hr />
        <div className="jc-space-between ai-stretch">
          <div className="ai-stretch">
            <DateRadio
              style={{ marginRight: "20px" }}
              list={[
                {
                  label: <FormattedMessage id="Interval" />,
                  value: "interval",
                },
              ]}
              value="interval"
            />
            <RangeDatePicker
              style={{ marginRight: "20px" }}
              startDate={date.startDate}
              endDate={date.endDate}
              timePicker={false}
              onApply={pickDateRange}
            />
            <MultiDropdownPicker
              title={intl.formatMessage(
                { id: "PickA" },
                { name: intl.formatMessage({ id: "Product" }) }
              )}
              list={productList.map((item) => ({ label: item, value: item }))}
              selected={checkedProduct}
              onApply={setCheckedProduct}
              style={{ marginRight: "20px" }}
            />
            <MultiDropdownPicker
              title={intl.formatMessage(
                { id: "PickA" },
                { name: intl.formatMessage({ id: "Order" }) }
              )}
              list={orderList.map((item) => ({ label: item, value: item }))}
              selected={checkedOrder}
              onApply={setCheckedOrder}
            />
          </div>
          <button className="btn-gray" onClick={downloadPQReport}>
            <FormattedMessage id="DownloadExcel" />
          </button>
        </div>
        <hr />
        <h4 className="m-0">
          <b>
            <FormattedMessage id="ProductAmount" />
          </b>
        </h4>
        <hr />
        <AmountTable
          data={data.DateReportProductQuantityData}
          totalData={data.DateReportTotalProductQuantityData}
        />
        <hr />
        <div className="ai-center jc-space-between">
          <h4 className="m-0">
            <b>
              <FormattedMessage id="ModeCount" />
            </b>
          </h4>
          <button className="btn-gray" onClick={downloadMCReport}>
            <FormattedMessage id="DownloadExcel" />
          </button>
        </div>
        <hr />
        <CycleTable data={data.DateReportMachineCycleData} />
      </div>
    </div>
  );
}
