import React, { useEffect, useState, useContext } from "react";
import moment from "moment";
import { FormattedMessage, useIntl } from "react-intl";
import {
  SingleDropdownPicker,
  MultiDropdownPicker,
} from "../../component/common";
import {
  AnalysisTabs,
  AnalysisMenu,
  DateBar,
  handleRequest,
} from "../../component/analysis/Analysis";
import {
  OrderListSelector,
  OrderChart,
  OrderTable,
} from "../../component/analysis/Order";
import {
  SingleDeviceList,
  AnalysisPie,
  AnalysisPtqtyChart,
  AnalysisDeviceChart,
  AnalysisCycleChart,
} from "../../component/analysis/Production";
import { BadChart, BadTable } from "../../component/analysis/Bad";
import {
  DowntimeDistribution,
  DowntimeChart,
  DowntimeTable,
} from "../../component/analysis/Downtime";
import {
  apiGetOEEAnalysisData,
  apiDownloadOEEAnalysisDataExcel,
  ApiAnalysis_GetAnalysisData,
  ApiAnalysis_GetDeviceBadReasonData,
  ApiAnalysis_GetDeviceDowntimeReasonData,
  ApiAnalysis_GetDeviceDowntimeReasonList,
  getBadReasonClassList,
  getBadReasonList,
  getDowntimeReasonClassList,
  baseURL,
} from "../../api.js";
import Context from "../../context";

function AnalysisOrder() {
  const [order, setOrder] = useState("");
  const [chartData, setChartData] = useState({});
  const [tableData, setTableData] = useState([]);
  const [deviceID, setDeviceID] = useState();
  const intl = useIntl();
  const { factory } = useContext(Context);

  const pickOrder = (orderNum) => {
    apiGetOEEAnalysisData({
      FactoryID: factory,
      OrderNum: orderNum,
      DeviceID: deviceID,
    }).then((res) => {
      const { chartData, fetchdata } = res.data;
      setChartData(chartData);
      setTableData(fetchdata);
      setOrder(orderNum);
    });
  };

  const handleDownloadExcel = () => {
    const idArr = [
      "Time",
      "MachineNumber",
      "Factory",
      "region",
      "OEE",
      "Utilization",
      "Performance",
      "Yield",
    ];
    apiDownloadOEEAnalysisDataExcel({
      FactoryID: factory,
      OrderNum: order,
      DataTitle: idArr.map((id) => intl.formatMessage({ id: id })),
    })
      .then((res) => window.open(baseURL + res.data, "_blank"))
      .catch((err) => console.log(err));
  };

  return (
    <React.Fragment>
      <div>
        <AnalysisTabs tab="0" />
        <div className="page-content shadow-lg">
          <div className="jc-space-between">
            <h4 className="m-0">
              <b>
                <FormattedMessage id="OEEAnalysis" />
                {/*OEE分析*/}
              </b>
            </h4>
            <AnalysisMenu tab="1" />
          </div>
          <hr />
          <div className="row">
            <div className="col-md-4 col-lg-3 col-xl-2">
              <SingleDeviceList handleSelect={setDeviceID} checked={deviceID} />
            </div>
            <div className="col-md-8 col-lg-9 col-xl-10">
              <OrderListSelector pickOrder={pickOrder} deviceID={deviceID} />
              <OrderChart data={chartData} />
              <hr />
              <div className="jc-end">
                <button
                  className="center btn-gray"
                  onClick={handleDownloadExcel}
                >
                  <FormattedMessage id="DownloadExcel" />
                  {/*下載Excel*/}
                </button>
              </div>
              <hr />
              <OrderTable data={tableData} />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

function AnalysisProduction() {
  const [deviceID, setDeviceID] = useState();
  const [dataType, setDataType] = useState("oee");
  const [data, setData] = useState({
    DeviceOverallDeviceData: [],
    DeviceProductionEfficiency: {},
    DeviceProductionQuantity: [],
    ProductionCycleAnalysis: [],
  });
  const [date, setDate] = useState({
    startDate: moment().format("YYYY-MM-DD"),
  });
  const [sort, setSort] = useState("date");
  const intl = useIntl();
  const { factory } = useContext(Context);

  useEffect(() => {
    if (deviceID) {
      let req = {
        FactoryID: factory,
        DeviceID: deviceID,
        DataTitle: dataType,
      };
      req = handleRequest(req, sort, date);
      ApiAnalysis_GetAnalysisData(req)
        .then((res) => setData(res.data))
        .catch((err) => console.log(err));
    }
  }, [deviceID, date, dataType]);

  const AvgProductionCycle = data.ProductionCycleAnalysis;
  let sum = 0;
  for (let i in AvgProductionCycle) {
    sum += AvgProductionCycle[i];
  }

  const list = [
    { label: "OEE", value: "oee" },
    {
      label: intl.formatMessage({ id: "Utilization" }),
      value: "utilization",
    },
    {
      label: intl.formatMessage({ id: "Performance" }),
      value: "performance",
    },
    {
      label: intl.formatMessage({ id: "Yield" }),
      value: "yield",
    },
  ];

  return (
    <div>
      <AnalysisTabs tab="0" />
      <div className="page-content shadow-lg">
        <div className="jc-end">
          <AnalysisMenu tab="2" />
        </div>
        <hr />
        <DateBar sort={sort} date={date} setSort={setSort} setDate={setDate} />
        <hr />
        <div className="row">
          <div className="col-md-4 col-lg-3 col-xl-2">
            <h4>
              <b>
                <FormattedMessage id="PleasePickThe" />
                <FormattedMessage id="Device" />
              </b>
            </h4>
            <SingleDeviceList handleSelect={setDeviceID} checked={deviceID} />
          </div>
          <div className="col-md-8 col-lg-9 col-xl-10">
            <div className="analysis_pie analysis-block border">
              <h5>
                <b>
                  <FormattedMessage id="ProductionEfficiency" />
                  {/*設備生產效率*/}
                </b>
              </h5>
              <AnalysisPie data={data.DeviceProductionEfficiency} />
            </div>
            <div className="analysis-block border">
              <h5>
                <b>
                  <FormattedMessage id="ProductionQuantity" />
                  {/*生產數量*/}
                </b>
              </h5>
              <AnalysisPtqtyChart
                date={date}
                sort={sort}
                data={data.DeviceProductionQuantity}
              />
            </div>
            <div className="analysis-block border">
              <div className="jc-space-between">
                <h5 className="m-0">
                  <b>
                    <FormattedMessage id="Device" />
                    {list.find(({ value }) => value === dataType)?.label}
                  </b>
                </h5>
                <SingleDropdownPicker
                  value={dataType}
                  list={list}
                  onApply={setDataType}
                />
              </div>
              <AnalysisDeviceChart
                date={date}
                sort={sort}
                data={data.DeviceOverallDeviceData}
                name={dataType.label}
              />
            </div>
            <div className="analysis-block border">
              <h5 className="m-0">
                <b>
                  <FormattedMessage id="ProductionCycleAnalysis" />
                  {/*生產週期分析*/}
                </b>
              </h5>
              <div className="d-flex justify-content-center">
                <h3>
                  {data.ProductionCycleAverage}
                  <FormattedMessage id="Second" />{" "}
                </h3>
              </div>
              <div className="d-flex justify-content-center">
                <h7 style={{ color: "gray" }}>
                  <FormattedMessage id="AvgProductionCycle" />
                </h7>
              </div>

              <AnalysisCycleChart
                date={date}
                sort={sort}
                data={data.ProductionCycleAnalysis}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function AnalysisBad() {
  const [deviceID, setDeviceID] = useState();
  const [date, setDate] = useState({
    startDate: moment().format("YYYY-MM-DD"),
  });
  const [sort, setSort] = useState("date");
  const [classificationList, setClassificationList] = useState([]);
  const [checkedClassification, setCheckedClassification] = useState([]);
  const [badReasonList, setBadReasonList] = useState([]);
  const [checkedBadReason, setCheckedBadReason] = useState([]);
  const [data, setData] = useState({
    BadReasonChartData: {
      BadReasonDescriptionList: [],
      BadReasonPlatoDataList: [],
      BadReasonProductDataList: {},
    },
    BadReasonOverviewDataList: [],
  });
  const intl = useIntl();
  const { factory } = useContext(Context);

  useEffect(() => {
    if (deviceID) {
      let req = {
        FactoryID: factory,
        DeviceID: deviceID,
      };
      req = handleRequest(req, sort, date);
      getBadReasonClassList(req)
        .then((res) => {
          setCheckedClassification([]);
          setBadReasonList([]);
          setCheckedBadReason([]);
          setData({
            BadReasonChartData: {
              BadReasonDescriptionList: [],
              BadReasonPlatoDataList: [],
              BadReasonProductDataList: {},
            },
            BadReasonOverviewDataList: [],
          });
          setClassificationList(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [deviceID, date]);

  useEffect(() => {
    if (deviceID && checkedClassification.length > 0) {
      let req = {
        FactoryID: factory,
        DeviceID: deviceID,
        ClassifyID: checkedClassification,
      };
      req = handleRequest(req, sort, date);
      getBadReasonList(req)
        .then((res) => setBadReasonList(res.data))
        .catch((err) => console.log(err));
    }
  }, [checkedClassification]);

  useEffect(() => {
    if (deviceID && checkedBadReason.length > 0) {
      let req = {
        FactoryID: factory,
        DeviceID: deviceID,
        BadReasonList: checkedBadReason,
      };
      req = handleRequest(req, sort, date);
      ApiAnalysis_GetDeviceBadReasonData(req)
        .then((res) => setData(res.data))
        .catch((err) => console.log(err));
    }
  }, [checkedBadReason]);

  return (
    <React.Fragment>
      <AnalysisTabs tab="0" />
      <div className="page-content shadow-lg">
        <div className="jc-space-between ai-center">
          <h4>
            <b>
              <FormattedMessage id="Defect" />
            </b>
          </h4>
          <AnalysisMenu tab="3" />
        </div>
        <hr />
        <div className="ai-center jc-space-between">
          <DateBar
            sort={sort}
            date={date}
            setSort={setSort}
            setDate={setDate}
          />
          <div className="ai-center">
            <MultiDropdownPicker
              title={intl.formatMessage(
                { id: "PickA" },
                { name: intl.formatMessage({ id: "DefectGroup" }) }
              )}
              list={classificationList.map((item) => ({
                label: item.title,
                value: item.group_id,
              }))}
              selected={checkedClassification}
              onApply={setCheckedClassification}
              style={{ marginRight: "20px" }}
            />
            <MultiDropdownPicker
              title={intl.formatMessage(
                { id: "PickA" },
                { name: intl.formatMessage({ id: "Defect" }) }
              )}
              list={badReasonList.map((item) => ({
                label: item.description,
                value: item.id,
              }))}
              selected={checkedBadReason}
              onApply={setCheckedBadReason}
            />
          </div>
        </div>
        <hr />
        <div className="ai-end" style={{ marginBottom: "20px" }}>
          <div className="col-md-4 col-lg-3 col-xl-2">
            <h4>
              <b>
                {intl.formatMessage(
                  { id: "PickA" },
                  { name: intl.formatMessage({ id: "Device" }) }
                )}
              </b>
            </h4>
            <SingleDeviceList
              handleSelect={setDeviceID}
              checked={deviceID}
              style={{ height: "50vh" }}
            />
          </div>
          <div className="col-md-8 col-lg-9 col-xl-10">
            <BadChart data={data.BadReasonChartData} />
          </div>
        </div>
        <hr />
        <h4 className="m-0">
          <b>
            <FormattedMessage id="Overview" />
          </b>
        </h4>
        <hr />
        <BadTable data={data.BadReasonOverviewDataList} />
      </div>
    </React.Fragment>
  );
}

function AnalysisDowntime() {
  const [deviceID, setDeviceID] = useState();
  const [date, setDate] = useState({
    startDate: moment().format("YYYY-MM-DD"),
  });
  const [sort, setSort] = useState("date");
  const [classificationList, setClassificationList] = useState([]);
  const [checkedClassification, setCheckedClassification] = useState([]);
  const [downtimeReasonList, setDowntimReasonList] = useState([]);
  const [checkedDownTimeReason, setCheckedDowntimeReason] = useState([]);
  const [data, setData] = useState({
    OperationStatusDataList: [],
    DowntimeReasonChartData: {
      DowntimeReasonDataList: [],
      DowntimeReasonPlatoDataList: [],
      DowntimeReasonDescriptionList: [],
    },
    DowntimeOverviewDataList: [],
  });
  const intl = useIntl();
  const { factory } = useContext(Context);

  useEffect(() => {
    if (deviceID) {
      let req = {
        FactoryID: factory,
        DeviceID: deviceID,
      };
      req = handleRequest(req, sort, date);
      getDowntimeReasonClassList(req)
        .then((res) => {
          setCheckedClassification(res.data.map(({ id }) => id));
          setClassificationList(res.data);
          setDowntimReasonList([]);
          setCheckedDowntimeReason([]);
          setData({
            OperationStatusDataList: [],
            DowntimeReasonChartData: {
              DowntimeReasonDataList: [],
              DowntimeReasonPlatoDataList: [],
              DowntimeReasonDescriptionList: [],
            },
            DowntimeOverviewDataList: [],
          });
        })
        .catch((err) => console.log(err));
    }
  }, [deviceID, date]);

  useEffect(() => {
    if (checkedClassification.length > 0) {
      let req = {
        FactoryID: factory,
        DeviceID: deviceID,
        ClassifyID: checkedClassification,
      };
      req = handleRequest(req, sort, date);
      ApiAnalysis_GetDeviceDowntimeReasonList(req)
        .then((res) => {
          setCheckedDowntimeReason(res.data.map(({ id }) => id));
          setDowntimReasonList(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [checkedClassification]);

  useEffect(() => {
    if (checkedDownTimeReason.length > 0) {
      let req = {
        FactoryID: factory,
        DeviceID: deviceID,
        ClosingReasonList: checkedDownTimeReason,
      };
      req = handleRequest(req, sort, date);
      ApiAnalysis_GetDeviceDowntimeReasonData(req)
        .then((res) => setData(res.data))
        .catch((err) => console.log(err));
    }
  }, [checkedDownTimeReason]);

  return (
    <React.Fragment>
      <div className="analysis_area clearfix">
        <AnalysisTabs tab="0" />
        <div className="page-content shadow-lg">
          <div className="ai-center jc-end">
            <AnalysisMenu tab="4" />
          </div>
          <hr />
          <div className="ai-center jc-space-between">
            <DateBar
              sort={sort}
              date={date}
              setSort={setSort}
              setDate={setDate}
            />
            <div className="ai-center">
              <MultiDropdownPicker
                title={
                  intl.formatMessage({ id: "PleasePickThe" }) +
                  intl.formatMessage({ id: "DowntimeReasonClassification" })
                }
                list={classificationList.map((item) => ({
                  label: item.classify,
                  value: item.id,
                }))}
                selected={checkedClassification}
                onApply={setCheckedClassification}
                style={{ marginRight: "20px" }}
              />
              <MultiDropdownPicker
                title={
                  intl.formatMessage({ id: "PleasePickThe" }) +
                  intl.formatMessage({ id: "DowntimeReason" })
                }
                list={downtimeReasonList.map((item) => ({
                  label: item.description,
                  value: item.id,
                }))}
                selected={checkedDownTimeReason}
                onApply={setCheckedDowntimeReason}
              />
            </div>
          </div>
          <hr />
          <h4>
            <b>
              <FormattedMessage id="DowntimeTimeline" />
              {/*停機時間分布*/}
            </b>
          </h4>
          <hr />
          <DowntimeDistribution
            data={data.OperationStatusDataList}
            startDate={date.startDate}
            endDate={date.endDate}
          />
          <hr />
          <h4 className="m-0">
            <b>
              <FormattedMessage id="DowntimeReason" />
              {/*停機原因*/}
            </b>
          </h4>
          <hr />
          <div className="ai-end">
            <div className="col-md-4 col-lg-3 col-xl-2">
              <h4>
                <b>
                  <FormattedMessage id="PleasePickThe" />
                  <FormattedMessage id="Device" />
                </b>
              </h4>
              <SingleDeviceList
                handleSelect={setDeviceID}
                checked={deviceID}
                style={{ height: "50vh" }}
              />
            </div>
            <div className="col-md-8 col-lg-9 col-xl-10">
              <DowntimeChart data={data.DowntimeReasonChartData} />
            </div>
          </div>
          <hr />
          <h4 className="m-0">
            <b>
              <FormattedMessage id="Overview" />
              {/*總覽*/}
            </b>
          </h4>
          <hr />
          <DowntimeTable data={data.DowntimeOverviewDataList} />
        </div>
      </div>
    </React.Fragment>
  );
}

export { AnalysisOrder, AnalysisProduction, AnalysisBad, AnalysisDowntime };
