import React, { useEffect, useState, useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Collapse from "@material-ui/core/Collapse";
import Alert from "@material-ui/lab/Alert";
import ICAL from "ical.js";
import moment from "moment";
import { InputModalBase } from "./Management";
import {
  ApiManagement_class,
  ApiManagement_classGetSetWorkDay,
  ApiManagement_classGetDeviceList,
  ApiManagement_classGetScheduleNewList,
  ApiManagement_classGetScheduleChangeList,
  ApiManagement_classScheduleChange,
  ApiManagement_classUpdateScheduleNew,
  ApiManagement_classScheduleNew,
  ApiManagement_new_holiday,
} from "../../api";
import Context from "../../context";

let timeOption = [];
for (let i = 0; i <= 48; ++i) {
  const hour = Math.floor(i / 2);
  const min = (i % 2) * 30;
  timeOption.push(
    <option key={i}>{`${hour < 10 ? `0${hour}` : `${hour}`}:${
      min === 0 ? "00" : "30"
    }`}</option>
  );
}
let timeOptimeEnd = []
for (let i = 1; i <= 48; ++i) {
  const hour = Math.floor(i / 2);
  const min = (i % 2) * 30;
  timeOptimeEnd.push(
    <option key={i}>{`${hour < 10 ? `0${hour}` : `${hour}`}:${
      min === 0 ? "00" : "30"
    }`}</option>
  );
}

export const InputModal = ({ modal, onHide, data, setModal }) => {
  const intl = useIntl();
  const { factory } = useContext(Context);
  const [deviceList, setDeviceList] = useState([]);
  const [select, setSelect] = useState({ DeviceList: [] });
  const [error, setError] = useState("");
  useEffect(() => {
    if (modal && data.date) {
      ApiManagement_classGetDeviceList({
        FactoryID: factory,
        ScheduleDate: data.date,
      })
        .then((res) => {
          setDeviceList(res.data.DeviceListData);
          if (modal === "new") {
            setSelect({
              date: data.date,
              status: "0",
              workday_start: "00:00",
              workday_end: "00:00",
              DeviceList: res.data.DeviceListData.map(({ id }) => id),
            });
          } else {
            if (data.status) {
              ApiManagement_classGetScheduleNewList({
                NewScheduleID: data.id,
                FactoryID: factory,
                ScheduleDate: data.date,
              })
                .then((res) =>
                  setSelect({ ...data, DeviceList: res.data.DeviceList })
                )
                .catch(console.log);
            } else {
              ApiManagement_classGetScheduleChangeList({
                ShiftID: data.id,
                FactoryID: factory,
                ScheduleDate: data.date,
              })
                .then((res) =>
                  setSelect({ ...data, DeviceList: res.data.DeviceList })
                )
                .catch(console.log);
            }
          }
        })
        .catch(console.log);
    } else {
      setSelect({ DeviceList: [] });
      setError("");
    }
  }, [modal, data]);

  const onChange = (e) => {
    const { id, value } = e.target;
    setSelect((prev) => ({ ...prev, [id]: value }));
  };

  const OnCheckChange = (e) => {
    const { id, checked } = e.target;
    const arr = id.split("_");
    if (arr[1] === "all") {
      if (checked) {
        setSelect((prev) => ({
          ...prev,
          DeviceList: deviceList.map(({ id }) => id),
        }));
      } else {
        setSelect((prev) => ({ ...prev, DeviceList: [] }));
      }
    } else {
      let DeviceList = select.DeviceList.slice();
      if (checked) {
        DeviceList.push(parseInt(arr[1]));
      } else {
        DeviceList.splice(DeviceList.indexOf(parseInt(arr[1])), 1);
      }
      setSelect((prev) => ({ ...prev, DeviceList }));
    }
  };

  const submit = () => {
    const { id, date, status, workday_start, workday_end, DeviceList } = select;
    let req = {
      account: localStorage.getItem("account"),
      FactoryID: factory,
      ConfirmDate: date,
      Status: status,
      StartTime: workday_start,
      EndTime: workday_end,
      Device: DeviceList,
    };
    if (modal === "new") {
      ApiManagement_classScheduleNew(req)
        .then(onHide)
        .catch((e) =>
          setError(intl.formatMessage({ id: e.response?.status.toString() }))
        );
    } else {
      if (status !== undefined) {
        req.NewScheduleID = id;
        ApiManagement_classUpdateScheduleNew(req)
          .then(onHide)
          .catch((e) =>
            setError(intl.formatMessage({ id: e.response?.status.toString() }))
          );
      } else {
        req.ShiftID = id;
        req.IsHoliday = 0;
        ApiManagement_classScheduleChange(req)
          .then(onHide)
          .catch((e) =>
            setError(intl.formatMessage({ id: e.response?.status.toString() }))
          );
      }
    }
  };

  const title = intl.formatMessage(
    { id: modal === "new" ? "Create" : "Edit" },
    { name: intl.formatMessage({ id: "Shift" }) }
  );

  return (
    <Modal
      className="modal_style fade"
      size="lg"
      show={modal === "new" || modal === "edit"}
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title as="h5">
          <b>{title}</b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {modal === "new" || select.status !== undefined ? (
            <Form.Group as={Row} controlId="status">
              <Form.Label column lg={3} md={4}>
                <FormattedMessage id="Type" />
              </Form.Label>
              <Col lg={9} md={8}>
                <Form.Control
                  as="select"
                  onChange={onChange}
                  value={select.status}
                >
                  <option value="0">
                    {intl.formatMessage({ id: "WorkOvertime" })}
                  </option>
                  <option value="1">
                    {intl.formatMessage({ id: "ScheduledMaintenance" })}
                  </option>
                </Form.Control>
                <span className="warn" />
              </Col>
            </Form.Group>
          ) : null}
          <Form.Group as={Row}>
            <Form.Label column lg={3} md={4}>
              <FormattedMessage id="Schedule" />
            </Form.Label>
            <Col lg={9} md={8}>
              <div className="ai-center">
                <Form.Control
                  as="select"
                  id="workday_start"
                  onChange={onChange}
                  value={select.workday_start}
                >
                  {timeOption.map((opt) => opt)}
                </Form.Control>
                <span style={{ margin: "0 20px" }}>
                  {intl.formatMessage({ id: "To" })}
                </span>
                <Form.Control
                  as="select"
                  id="workday_end"
                  onChange={onChange}
                  value={select.workday_end}
                >
                  {timeOption.map((opt) => opt)}
                </Form.Control>
              </div>
              <span className="warn" />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column lg={3} md={4}>
              <FormattedMessage id="AppliedMachines" />
            </Form.Label>
            <Col lg={9} md={8}>
              <Form.Check
                label={intl.formatMessage({ id: "PickAll" })}
                id="device_all"
                onChange={OnCheckChange}
                checked={deviceList.every(
                  ({ id }) => select.DeviceList.indexOf(id) > -1
                )}
              />
              {deviceList.map(({ id, device_num }) => (
                <Form.Check
                  key={id}
                  label={device_num}
                  id={`device_${id}`}
                  onChange={OnCheckChange}
                  checked={
                    select.DeviceList && select.DeviceList.indexOf(id) > -1
                  }
                />
              ))}
              <span className="warn" />
            </Col>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer className="jc-space-between ai-center">
        <Collapse in={error !== ""}>
          <Alert severity="error">{error}</Alert>
        </Collapse>
        <div className="ai-center">
          {modal === "edit" ? (
            <button
              className="btn btn-delete"
              onClick={() => setModal("delete")}
            >
              <FormattedMessage id="Delete" />
            </button>
          ) : null}
          <button className="btn btn-confirm" onClick={submit}>
            <FormattedMessage id="Confirm" />
          </button>

          <button className="btn btn-cancel" onClick={onHide}>
            <FormattedMessage id="Cancel" />
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export const SetModal = ({ modal, onHide }) => {
  const intl = useIntl();
  const { factory } = useContext(Context);
  const [select, setSelect] = useState({ WorkSettingDataList: [] });
  const [error, setError] = useState("");

  useEffect(() => {
    if (modal === "set") {
      ApiManagement_classGetSetWorkDay({
        FactoryID: factory,
      }).then((res) => {
        res.data.WorkSettingDataList.forEach((item) => {
          item[1] = item[1].replace(":00", "");
          item[2] = item[2].replace(":00", "");
        });
        setSelect(res.data);
      });
    } else {
      setSelect({ WorkSettingDataList: [] });
    }
  }, [modal]);

  const onCheckChange = (e) => {
    const { id, checked } = e.target;
    const arr = id.split("_");
    let WorkSettingDataList = select.WorkSettingDataList.slice();
    WorkSettingDataList[parseInt(arr[1])][0] = checked ? 1 : 0;
    setSelect((prev) => ({
      ...prev,
      WorkSettingDataList,
    }));
  };
  const onChange = (e) => {
    const { id, value } = e.target;
    if (id.startsWith("time_")) {
      const arr = id.split("_");
      let WorkSettingDataList = select.WorkSettingDataList.slice();
      WorkSettingDataList[parseInt(arr[1])][arr[2] === "start" ? 1 : 2] = value;
      setSelect((prev) => ({
        ...prev,
        WorkSettingDataList,
      }));
    } else {
      setSelect((prev) => ({ ...prev, [id]: value }));
    }
  };
  const submit = () => {
    const work = select.WorkSettingDataList.map((item, i) => ({
      id: i,
      is_workday: item[0],
      workday_start: `${item[1]}:00`,
      workday_end: `${item[2]}:00`,
    }));
    if (work.every(({ is_workday }) => is_workday === 0)) {
      setError(intl.formatMessage({ id: "ShiftRequired" }));
    } else {
      ApiManagement_class({
        account: localStorage.getItem("account"),
        factoryID: factory,
        work,
        repeat: parseInt(select.Repeat),
      })
        .then(onHide)
        .catch((e) =>
          setError(intl.formatMessage({ id: e.response?.status.toString() }))
        );
    }
  };
  return (
    <Modal
      className="modal_style fade"
      size="lg"
      show={modal === "set"}
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title as="h5">
          <b>
            <FormattedMessage id="WorkdaySetting" />
          </b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {select.WorkSettingDataList.map((_, i) => (
            <Form.Group as={Row} key={i}>
              <Form.Label column lg={3} md={4}>
                <FormattedMessage id={`WorkHours${i + 1}`} />
              </Form.Label>
              <Col lg={9} md={8}>
                <div className="ai-center">
                  <Form.Check
                    id={`time_${i}`}
                    style={{ marginRight: "20px" }}
                    onChange={onCheckChange}
                    checked={select.WorkSettingDataList[i][0] === 1}
                  />
                  <Form.Control
                    as="select"
                    id={`time_${i}_start`}
                    onChange={onChange}
                    disabled={select.WorkSettingDataList[i][0] !== 1}
                    value={select.WorkSettingDataList[i][1]}
                  >
                    {timeOption.map((opt) => opt)}
                  </Form.Control>
                  <span style={{ margin: "0 20px" }}>
                    {intl.formatMessage({ id: "To" })}
                  </span>
                  <Form.Control
                    as="select"
                    id={`time_${i}_end`}
                    onChange={onChange}
                    disabled={select.WorkSettingDataList[i][0] !== 1}
                    value={select.WorkSettingDataList[i][2]}
                  >
                    {timeOptimeEnd.map((opt) => opt)}
                  </Form.Control>
                </div>
              </Col>
            </Form.Group>
          ))}
          <Form.Group as={Row} controlId="Repeat">
            <Form.Label column lg={3} md={4}>
              <FormattedMessage id="Repeat" />
            </Form.Label>
            <Col lg={9} md={8}>
              <Form.Control
                as="select"
                onChange={onChange}
                value={select.Repeat}
              >
                <option value="0">
                  {intl.formatMessage({ id: "EveryWeekday_MondayToFriday" })}
                </option>
                <option value="1">
                  {intl.formatMessage({
                    id: "EveryWeekday_MondayToSaturday",
                  })}
                </option>
                <option value="2">
                  {intl.formatMessage({ id: "Everyday" })}
                </option>
              </Form.Control>
            </Col>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer className="jc-space-between ai-center">
        <Collapse in={error !== ""}>
          <Alert severity="error">{error}</Alert>
        </Collapse>
        <div className="ai-center">
          <button className="btn btn-confirm" onClick={submit}>
            <FormattedMessage id="Confirm" />
          </button>
          <button className="btn btn-cancel" onClick={onHide}>
            <FormattedMessage id="Cancel" />
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export const ImportModal = ({ modal, onHide }) => {
  const intl = useIntl();
  const { factory } = useContext(Context);
  const [select, setSelect] = useState({});

  useEffect(() => {
    if (!modal) {
      setSelect({});
    }
  }, [modal]);

  const submit = () => {
    const reader = new FileReader();
    reader.addEventListener("load", async (e) => {
      const jcalData = ICAL.parse(e.target.result);
      const comp = new ICAL.Component(jcalData);
      const vevents = comp.getAllSubcomponents("vevent");
      let holidays = [];
      vevents.forEach((item) => {
        const event = new ICAL.Event(item);
        if (event.duration.days >= 1) {
          const start = moment(event.startDate.toString());
          for (let i = event.duration.days; i > 0; --i) {
            holidays.push(start.format("YYYY-MM-DD"));
            start.add(1, "d");
          }
        }
      });
      for (let i = 0; i < holidays.length; ++i) {
        try {
          await ApiManagement_new_holiday({
            account: localStorage.getItem("account"),
            factory_id: factory,
            isHoliday: 0,
            date: holidays[i],
          });
        } catch (err) {
          console.log(err);
        }
      }
      onHide();
    });
    reader.readAsBinaryString(select.iCal);
  };

  const columns = [
    {
      id: "iCal",
      type: "file",
      required: true,
      remark: "ShiftImportRemark",
      extensions: ["ics"],
    },
  ];

  return (
    <InputModalBase
      show={modal === "import"}
      onHide={onHide}
      title={intl.formatMessage(
        { id: "Import" },
        { name: intl.formatMessage({ id: "iCal" }) }
      )}
      columns={columns}
      select={select}
      setSelect={setSelect}
      submit={submit}
    />
  );
};
