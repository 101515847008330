import React from "react";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import { DropdownBtn } from "./Quality";
import { Table } from "../Component";
import { ApiOrder_GetProduceMold, ApiQuality_GetStandard } from "../../api";

const $ = require("jquery");
$.DataTable = require("datatables.net");

class QualityAnalysisSortBar extends React.Component {
  constructor() {
    super();
    this.state = {
      moldList: [],
      columnList: [],
      selectedMold: [],
      selectedColumn: [],
      close: false,
    };
  }

  componentDidMount() {
    ApiOrder_GetProduceMold({
      factory_id: localStorage.getItem("factory"),
    }).then((res) =>
      this.setState({
        moldList: res.data.map((item) => {
          return {
            id: String(item.id),
            name: item.mold_num,
          };
        }),
      })
    );
  }

  loadData = () => {
    if (
      this.state.selectedMold[0] === undefined ||
      this.state.selectedColumn[0] === undefined
    ) {
      alert("請選擇模具和欄位！");
    } else {
      this.props.loadData(
        this.state.selectedMold[0],
        this.state.selectedColumn[0]
      );
    }
    document
      .querySelectorAll(".dropdown-menu")
      .forEach((item) => item.classList.remove("show"));
  };

  handleChecked = (key, checked) => {
    // multi-selection
    /*
    let col = this.state[key];
    if (col.includes(checked)) {
      if (checked === "all") col = [];
      else col = col.filter((item) => item !== checked);
    } 
    else {
      if (checked === "all") {
        col = [];
        for (const obj of this.state.columns) {
          col.push(obj.id);
        }
      } 
      else col.push(checked);
    }
    */
    if (key === "selectedMold") {
      ApiQuality_GetStandard({
        factory_id: localStorage.getItem("factory"),
        mold_id: checked,
      })
        .then((res) => {
          let cols = res.data.map((obj) => obj.parameter_best_name);
          let tempList = this.props.columns.filter((obj) =>
            cols.includes(obj.id)
          );
          this.setState({
            columnList: tempList,
          });
        })
        .catch(console.log);
    }

    this.setState({
      [key]: [checked],
    });
  };

  render() {
    return (
      <React.Fragment>
        <div
          className="btn_download list_group clear_both"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div className="d-flex justify-content-between justify-content-lg-end">
            <div
              className="dropdown slt_dropdown"
              style={{ marginRight: "10px" }}
            >
              <FormattedMessage id="ChooseMold">
                {(e) => (
                  <DropdownBtn
                    name={e}
                    /*選擇模具*/ list={this.state.moldList}
                    menuRight="true"
                    checked={this.state.selectedMold}
                    handleChange={(checked) =>
                      this.handleChecked("selectedMold", checked)
                    }
                  />
                )}
              </FormattedMessage>
            </div>
            <div
              className="dropdown slt_dropdown"
              style={{ marginRight: "10px" }}
            >
              <FormattedMessage id="ChooseColumn">
                {(e) => (
                  <DropdownBtn
                    name={e}
                    /*選擇欄位*/ list={this.state.columnList}
                    menuRight="true"
                    checked={this.state.selectedColumn}
                    handleChange={(checked) =>
                      this.handleChecked("selectedColumn", checked)
                    }
                  />
                )}
              </FormattedMessage>
            </div>
            <button
              className="dropdown slt_dropdown btn_common"
              onClick={this.loadData}
              style={{ marginRight: "10px" }}
            >
              <span style={{ color: "#75498d" }}>
                <FormattedMessage id="Execute" />
                {/*執行*/}
              </span>
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

class ParameterTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }

  componentDidMount() {
    $(this.refs.main).DataTable({
      dom: '<"data-table-wrapper">',
      ordering: true,
      paging: false,
      info: false,
      visible: false,
      searching: false,
      columnDefs: [
        {
          targets: [6],
          orderable: false,
        },
      ],
    });
    this.loadData();
  }

  componentWillUnmount() {
    $(".data-table-wrapper").find("table").DataTable().destroy(true);
  }

  componentDidUpdate(prevProps) {
    if (this.props.reload != prevProps.reload) {
      this.loadData();
    }
  }

  loadData = () => {
    let data = [];
    ApiQuality_GetStandard({
      factory_id: localStorage.getItem("factory"),
      mold_id: this.props.selectedMold,
    }).then((res) => {
      console.log(res.data);
      let temp = res.data.filter(
        (item) => item.parameter_best_name == this.props.selectedColumn.id
      );
      for (let item of temp) {
        data.push({
          id: item.id,
          mold_id: this.props.selectedMold,
          col_id: this.props.selectedColumn.id,
          item: this.props.selectedColumn.name,
          date: moment().format("YYYY-MM-DD"), //item.date
          sample_num: item.point,
          group_num: item.set,
          clx: item.CLx,
          uclx: item.UCLx,
          lclx: item.LCLx,
          clr: item.CLr,
          uclr: item.UCLr,
          lclr: item.LCLr,
          monitor: item.enable,
        });
      }
      this.setState({ data: data });
    }).catch(console.log);;
  };

  iconClicked = (e) => {
    var modal = e.target.getAttribute("modaltoshow");
    var id =
      e.currentTarget.parentNode.parentNode.previousSibling.previousSibling.id;
    var dataObject = this.state.data.filter((row) => row.id == id)[0];
    this.props.showModal(modal, dataObject);
  };

  render() {
    const tableOption = {
      editorHtml: [
        <th className="th_style text-center">
          <span>
            <FormattedMessage id="Edit" />
            {/*編輯*/}
          </span>
        </th>,
        <td className="management_editor text-center">
          <div className="d-flex justify-content-between">
            <button
              className="btn_tool"
              modaltoshow="edit"
              onClick={this.iconClicked}
            >
              <i className="icon-create"></i>
            </button>
            <button
              className="btn_tool"
              modaltoshow="delete"
              onClick={this.iconClicked}
            >
              <i className="icon-delete_outline"></i>
            </button>
          </div>
        </td>,
      ],
      monitor: [
        <th className="th_style text-center">
          <span>
            <FormattedMessage id="Monitor" />
          </span>
        </th>,
      ],
      monitor_render: (is_checked) => {
        return (
          <td className="text-center">
            <input
              type="checkbox"
              readOnly
              checked={is_checked}
              style={{ height: "25px", width: "25px" }}
            />
          </td>
        );
      },
    };

    return (
      <Table
        columns={this.props.tableColumns}
        data={this.state.data}
        tableOption={tableOption}
      />
    );
  }
}

export { QualityAnalysisSortBar, ParameterTable };
