import index from "./zh-tw/index";
import dashboard from "./zh-tw/dashboard";
import pes from "./zh-tw/pes";
import analysis from "./zh-tw/analysis";
import management from "./zh-tw/management";
import sys from "./zh-tw/sys";
import root from "./zh-tw/root";
import employee from "./zh-tw/employee";
import quality from "./zh-tw/quality";
import errors from "./zh-tw/errors";
import document from "./zh-tw/document";
const obj = {
  //登入頁面
  UserLogin: "使用者登入",
  Login: "登入",
  ForgetPassword: "忘記密碼",
  VerificationCode: "驗證碼",
  NewPassword: "新密碼",
  ConfirmPassword: "確認密碼",
  PleaseEnterThePasswordAgain: "請再次輸入密碼",
  ToMobile: "前往下載手機版",
  WRONG_PASSWORD: "密碼錯誤！",
  VerificationCodeSendToEmail: "驗證碼已寄至信箱！",
  EMAIL_NOT_FOUND: "使用者不存在",
  WrongVerificationCode: "驗證碼錯誤",
  VerifyFailed: "驗證失敗",
  ConfrimPasswordWrong: "密碼二次輸入錯誤",
  KeepLogin: "保持登入",
  Password: "密碼",

  //Header
  ChangePassword: "修改密碼",
  Language: "語言",
  Logout: "登出",
  Hello: "您好",

  //Footer
  // Footer: `Copyright © FCS - v ${process.env.REACT_APP_VERSION} ALL RIGHTS RESERVED`,

  //leftmenu
  ProductionOverview: "生產總覽",
  Monitoring: "生產執行看板",
  Operation: "生產執行系統",
  ProduceAnalysisReport: "生產分析報告",
  ProductionAdmin: "生產管理系統",
  SystemAdmin: "系統管理",
  Root: "Root",
  EmployeeEfficiency: "人員效率",
  QualityManagement: "品質管理",

  OEE: "OEE",
  Utilization: "稼動率",
  Performance: "效率",
  Yield: "良率",

  Percentage: "百分比",
  DownloadExcel: "下載Excel",

  //常出現
  New: "新增",
  Increase: "新增",
  Modify: "修改",
  Edit: "編輯",
  Copy: "複製{name}",
  Delete: "刪除",
  Yes: "是",
  No: "否",
  OK: "確定",
  Cancel: "取消",
  Prev: "上一步",
  PreviousPage: "上一頁",
  NextPage: "下一頁",
  PickAll: "全選",
  ConfirmationMessage: "確認訊息",
  AreYouSureToDelete: "你確定要刪除",
  DeleteConfirm: "你確定要刪除{name}嗎？",
  AreYouSureTo: "你確定要",
  The: "第",
  Page: "頁",
  Required: "必填項目",
  NeedSame: "需要相同",
  Latest: "最近模次",
  EndTime: "結束時間",
  description: "說明",
  Photo: "照片",
  File: "檔案",
  Column: "欄位",
  NoData: "無資料",
  Time: "時間",
  Quantity: "數量",
  CannotBeSmallerThan1: "不可小於1",
  And: "及",
  QuestionMark: "嗎？",
  LargerThan: "大於",
  SmallerThan: "小於",
  Import: "匯入{name}",
  Upload: "上傳{name}",
  PickA: "請選擇{name}",
  Create: "新增{name}",
  EditThe: "編輯{name}",
  InputThe: "請輸入{name}",
  ToSmall: "不可小於{number}",
  Export: "匯出{name}",
  To: "至",
  Count: "{count}個",

  //單位
  Sec: "秒",
  Amount: "數量",
  Days: "天",
  Today: "今日",
  Yesterday: "昨日",
  Tomorrow: "明天",
  Date: "日期",
  Interval: "日區間",
  DatesTimeQuantum: "日區間-時間區間",
  Year: "年",
  Month: "月",
  Day: "日",
  Time: "時間",
  Hour: "小時",
  Hours: "時",
  Minutes: "分",
  Seconds: "秒",
  Week: "週",

  //月份
  January: "一月",
  February: "二月",
  March: "三月",
  April: "四月",
  May: "五月",
  June: "六月",
  July: "七月",
  August: "八月",
  September: "九月",
  October: "十月",
  November: "十一月",
  December: "十二月",

  //燈號
  LampStatus: "燈號",
  Shutdown: "未開機",
  Alert: "警報中",
  Standby: "待機中",
  Running: "運行中",
  NoOrderRunning: "無工單運行",
  MachineIsDowntime: "機台停機",
  ProductionPause: "生產暫停",
  MicroStop: "生產微停",
  MachineIsOffline: "機台離線",

  PleaseEnterThe: "請輸入",
  PleasePickThe: "請選擇",
  PleasePickOrEnterThe: "請輸入或選擇",
  400: "欄位有缺漏或錯誤，請重新嘗試",
  404: "該資料不存在，請重新嘗試",
  409: "同樣物件已存在，請重新嘗試",
  Succeed: "成功",
  Failed: "動作失敗",
  WrongFileType: "檔案類型錯誤，請重新選擇",
  ShouldBeLargerThen: "{a}需大於{b}",
  ShouldBeSmallerThen: "{a}需小於{b}",
  Existed: "已存在同樣資料",
  WrongFormat: "格式錯誤",
  EmptyUnable: "欄位不可為空",
  NoAuth: "沒有權限",
  SureToRepair:'你確定要修改？',
  classify:'分類',
  StartTime:"開始時間",

};

const zh_TW = Object.assign(
  obj,
  index,
  dashboard,
  pes,
  analysis,
  management,
  sys,
  root,
  employee,
  quality,
  errors,
  document
);
export default zh_TW;
