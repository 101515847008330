import React, { useState, useEffect, useContext } from "react";
import { useIntl } from "react-intl";
import { DeleteModal, NewButton, handleError } from "../../component/common";
import { DowntimeTable, InputModal } from "../../component/management/Downtime";
import { ManagementTabs } from "../../component/management/Management";
import {
  deleteManagementClosingReason,
  ApiManagement_downtime_getClassify,
} from "../../api.js";
import Context from "../../context";

function ManagementDowntime({ checkEditAuth }) {
  const [modal, setModal] = useState();
  const [select, setSelect] = useState({});
  const [groupList, setGroupList] = useState([]);
  const intl = useIntl();
  const { factory } = useContext(Context);

  useEffect(() => {
    ApiManagement_downtime_getClassify()
      .then((res) => setGroupList(res.data))
      .catch(console.log);
  }, []);

  const showModal = (modal, item = {}) => {
    if (!checkEditAuth()) {
      return;
    }
    setSelect(item);
    setModal(modal);
  };

  const onHide = () => {
    setModal();
  };

  const deleteData = () => {
    deleteManagementClosingReason(select.id, {
      factory_id: factory,
    })
      .then(onHide)
      .catch((err) => handleError(err, intl));
  };

  return (
    <div>
      <ManagementTabs tab="8" />
      <div className="page-content shadow-lg">
        <div className="jc-end">
          <NewButton
            onClick={() => showModal("new")}
            text={intl.formatMessage(
              { id: "Create" },
              { name: intl.formatMessage({ id: "DowntimeReason" }) }
            )}
          />
        </div>
        <hr />
        <DowntimeTable
          groupList={groupList}
          modal={modal}
          showModal={showModal}
        />
      </div>
      <InputModal
        modal={modal}
        onHide={onHide}
        data={select}
        groupList={groupList}
      />

      <DeleteModal
        show={modal === "delete"}
        onHide={onHide}
        name={select.description}
        type="DowntimeReason"
        callback={deleteData}
      />
    </div>
  );
}

export default ManagementDowntime;
