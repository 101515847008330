import React, { useEffect, useState, useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { MdCreate, MdDelete } from "react-icons/md";
import { InputModalBase } from "./Management";
import { ColumnFilter, Table } from "../common";
import {
  baseURL,
  ApiManagement_mold,
  ApiManagement_new_mold,
  ApiManagement_update_mold,
  ApiManagement_mold_photo,
  ApiManagement_upload_mold,
} from "../../api.js";
import { showWarn } from "../../function";
import Context from "../../context";
import Switch from "@material-ui/core/Switch";

export function MoldTable({ showModal, modal }) {
  const { factory } = useContext(Context);
  const [data, setData] = useState([]);
  const [trigger, setTrigger] = useState(false);
  useEffect(() => {
    if (!modal) {
      ApiManagement_mold({
        factory_id: factory,
      })
        .then((res) => {
          res.data.forEach((item) => {
            if (item.photo_path) {
              item.photo = `${baseURL}/${item.photo_path}`;
            }
          });
          setData(res.data);
        })
        .catch(console.log);
    }
  }, [modal, trigger]);

  const onChange = (item) => {
    ApiManagement_update_mold({
      ...item,
      mold_id: item.id,
      account: localStorage.getItem("account"),
      factory_id: factory,
      is_use: !item.is_use ? 1 : 0,
    })
      .then(() => setTrigger(!trigger))
      .catch(console.log);
  };

  const columns = [
    {
      Header: <FormattedMessage id="Photo" /> /*照片*/,
      accessor: "photo",
      Cell: ({ cell: { value } }) => {
        return (
          <img src={value} className="fit-contain" style={{ width: "100px" }} />
        );
      },
      Filter: "",
    },
    {
      Header: <FormattedMessage id="mold_num" /> /*模具編號*/,
      accessor: "mold_num",
      Filter: ({ column }) => ColumnFilter(column, "mold_num"),
    },
    {
      Header: <FormattedMessage id="cavity_amount" /> /*模穴數*/,
      accessor: "cavity_amount",
      Filter: "",
    },
    {
      Header: <FormattedMessage id="modulus" /> /*modulus*/,
      accessor: "modulus",
      Filter: "",
    },
    {
      Header: <FormattedMessage id="modus" /> /*形式*/,
      accessor: "modus",
      Filter: "",
    },

    {
      Header: <FormattedMessage id="mold_length" /> /*模具長度*/,
      accessor: "mold_length",
      Filter: "",
    },
    {
      Header: <FormattedMessage id="mold_width" /> /*模具寬度*/,
      accessor: "mold_width",
      Filter: "",
    },
    {
      Header: <FormattedMessage id="mold_height" /> /*模具厚度*/,
      accessor: "mold_height",
      Filter: "",
    },
    {
      Header: <FormattedMessage id="is_use" /> /*模具厚度*/,
      accessor: "is_use",
      Filter: "",
    },
    {
      Header: <FormattedMessage id="Edit" />,
      accessor: "editor",
      Filter: "",
    },
  ];

  const display = data.map((item) => ({
    ...item,
    is_use: (
      <Switch
        checked={item.is_use}
        onChange={() => onChange(item)}
        color="primary"
      />
    ),
    editor: (
      <span>
        <MdCreate
          className="icon-btn"
          size="20px"
          onClick={() => showModal("edit", item)}
        />
        <MdDelete
          style={{ marginLeft: "10px" }}
          className="icon-btn"
          size="20px"
          onClick={() => showModal("delete", item)}
        />
      </span>
    ),
  }));

  return <Table columns={columns} data={display} />;
}

export const InputModal = ({ modal, onHide, data }) => {
  const { factory } = useContext(Context);
  const intl = useIntl();
  const [select, setSelect] = useState({});
  const [expand, setExpand] = useState(false);

  const columns = [
    { id: "mold_num", type: "text", required: true },
    { id: "cavity_amount", type: "number", required: true },
    { id: "best_cycle", type: "number", required: true },
    { id: "stop_time", type: "number", required: true },
    { id: "modus", type: "text" },
    { id: "allowed_cycle", type: "number" },
    { id: "max_cycle", type: "number" },
    { id: "maintainence_mold", type: "number" },
    { id: "mold_height", type: "number" },
    { id: "mold_width", type: "number" },
    { id: "mold_length", type: "number" },
    { id: "sprue_aperture", type: "number" },
    { id: "sprue_aperture2", type: "number" },
    { id: "sprue_r", type: "number" },
    { id: "sprue_r2", type: "number" },
    { id: "mold_stroke", type: "number" },
    { id: "hot_runner_amount", type: "number" },
    { id: "molds", type: "number" },
    { id: "ring1", type: "number" },
    { id: "ring2", type: "number" },
    { id: "locking", type: "number" },
    { id: "center_distance", type: "number" },
    { id: "purchase_date", type: "date" },
    { id: "disable_date", type: "date" },
    { id: "mold_amount", type: "number" },
    { id: "region", type: "text" },
    { id: "maintainence_cycle", type: "number" },
    { id: "maintainence_count", type: "number" },
    { id: "maintainence_last", type: "date" },
    { id: "modulus", type: "number" },
    { id: "core_temp", type: "number" },
    { id: "cavity_temp", type: "number" },
    { id: "customer", type: "text" },
    {
      id: "photo",
      type: "file",
      extensions: ["webp", "jpg", "png", "jpeg", "bmp", "gif"],
      remark: "PhotoRemark",
    },
    {
      id: "is_use",
      type: "check_box",
    },
  ];

  useEffect(() => {
    if (!modal) {
      setExpand(false);
    }
    setSelect(JSON.parse(JSON.stringify(data)));
  }, [modal, data]);

  const submit = async () => {
    return new Promise(async (resolve, reject) => {
      let pass = true;
      if (select.best_cycle >= select.stop_time) {
        showWarn(
          "stop_time-warn",
          intl.formatMessage(
            { id: "ShouldBeLargerThen" },
            {
              a: intl.formatMessage({ id: "stop_time" }),
              b: intl.formatMessage({ id: "best_cycle" }),
            }
          )
        );
        pass = false;
      }
      if (select.max_cycle && !select.allowed_cycle) {
        showWarn("allowed_cycle-warn", "Required", intl);
        pass = false;
      } else if (!select.max_cycle && select.allowed_cycle) {
        showWarn("max_cycle-warn", "Required", intl);
        pass = false;
      }

      if (pass) {
        let req = {
          ...select,
          account: localStorage.getItem("account"),
          factory_id: factory,
          photo_path: select.photo_path ? select.photo_path : "",
          is_use: select.is_use ? 1 : 0,
        };
        if (select.photo?.size) {
          const data = new FormData();
          data.append("avast", select.photo);
          try {
            const res = await ApiManagement_mold_photo(data);
            req.photo_path = res.data.message;
          } catch (e) {
            reject(e);
          }
        }
        columns.forEach(({ id, type }) => {
          if (req[id] === undefined) {
            if (type === "number") {
              req[id] = 0;
            } else {
              req[id] = "";
            }
          }
        });
        if (modal === "new") {
          ApiManagement_new_mold(req).then(resolve).catch(reject);
        } else if (modal === "edit") {
          req.mold_id = select.id;
          ApiManagement_update_mold(req).then(resolve).catch(reject);
        }
      }
    });
  };

  const title = intl.formatMessage(
    { id: modal === "new" ? "Create" : "EditThe" },
    { name: intl.formatMessage({ id: "Mold" }) }
  );

  return (
    <InputModalBase
      show={modal === "new" || modal === "edit"}
      onHide={onHide}
      title={title}
      columns={columns.filter((_, i) => expand || i < 4)}
      select={select}
      setSelect={setSelect}
      submit={submit}
      expand={expand}
      setExpand={setExpand}
    />
  );
};

export const ImportModal = ({ modal, onHide }) => {
  const { factory } = useContext(Context);
  const intl = useIntl();
  const [select, setSelect] = useState({});

  useEffect(() => {
    if (!modal) {
      setSelect({});
    }
  }, [modal]);

  const submit = () => {
    return new Promise((resolve, reject) => {
      const data = new FormData();
      data.append("file", select.xls);
      data.append("factory_id", factory);
      ApiManagement_upload_mold(data).then(resolve).catch(reject);
    });
  };

  const columns = [
    {
      id: "xls",
      type: "file",
      required: true,
      remark: "MoldImportRemark",
      extensions: ["xls", "xlsx"],
    },
  ];

  return (
    <InputModalBase
      show={modal === "import"}
      onHide={onHide}
      title={intl.formatMessage(
        { id: "Import" },
        { name: intl.formatMessage({ id: "Mold" }) }
      )}
      columns={columns}
      select={select}
      setSelect={setSelect}
      submit={submit}
    />
  );
};
