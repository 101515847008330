import React, { useEffect, useState, useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { FormattedMessage, useIntl } from "react-intl";

import { EmployeeTabs } from '../../component/employee/Employee';
import { WorkTable } from "../../component/employee/Work";
import { SettingTable } from '../../component/employee/Setting';
import { LogTable } from '../../component/employee/Log';
import { handleError, NewButton, DeleteModal } from '../../component/common';
import {
    ApiEmployee_GetWorkList,
    ApiEmployee_GetGroupList,
    ApiEmployee_PostWork,
    ApiEmployee_PutWork,
    ApiEmployee_DeleteWork,
    ApiEmployee_PostEmployeeWorkList,
    ApiEmployee_PutEmployeeWorkList,
    ApiEmployee_DeleteEmployeeWorkList
} from '../../api.js';
import Context from "../../context";

function EmployeeWork({ checkEditAuth }) {
    const [modal, setModal] = useState();
    const [groupList, setGroupList] = useState([]);
    const [select, setSelect] = useState({});
    const intl = useIntl();
	const { factory } = useContext(Context);

    useEffect(() => {
        ApiEmployee_GetGroupList().then(res => setGroupList(res.data));
    }, [])

    const showModal = (modal, item = {}) => {
        if (!checkEditAuth()) {
            return;
        }
        if (modal === 'edit') {
            const obj = groupList.find(e => e.classify === item.classify);
            if (obj) {
                item.classify_id = obj.id;
            }
        }
        setSelect(item);
        setModal(modal);
    }

    const closeModal = () => {
        setModal(null);
    }

    const onChange = (e) => {
        const { id, value, tagName } = e.currentTarget;
        if (tagName === 'SELECT' && value === 'null') {
            return;
        }
        setSelect(prev => ({ ...prev, [id]: value }));
    }

    const showWarn = (dom, msg) => {
        const ele = document.getElementById(dom).nextElementSibling;
        ele.textContent = intl.formatMessage({ id: msg })/*必填項目*/;
        ele.hidden = false;
    }

    const submit = () => {
        document.querySelectorAll(".warn").forEach(item => item.hidden = true);
        let pass = true;
        const { id, classify_id, work_item_name } = select;
        if (!classify_id || classify_id === 'null') {
            pass = false;
            showWarn('classify_id', 'Required');
        }
        if (!work_item_name) {
            pass = false;
            showWarn('work_item_name', 'Required');
        }

        if (pass) {
            let req = {
                account: localStorage.getItem('account'),
                factory_id: factory,
                classify_id: classify_id,
                work_item_name: work_item_name
            }
            if (modal === 'new') {
                ApiEmployee_PostWork(req)
                    .then(() => closeModal())
                    .catch(err => handleError(err, intl));
            }
            else if (modal === 'edit') {
                req.work_item_id = id;
                ApiEmployee_PutWork(req)
                    .then(() => closeModal())
                    .catch(err => handleError(err, intl));
            }
        }
    }

    const deleteData = () => {
        ApiEmployee_DeleteWork({
            account: localStorage.getItem('account'),
            work_item_id: select.id,
            factory_id: factory,

        })
            .then(() => closeModal())
            .catch(err => handleError(err, intl));
    }

    const title = modal === 'new' ? <FormattedMessage id="New" /> : <FormattedMessage id="Modify" />;

    return (
        <React.Fragment>
            <div>
                <EmployeeTabs tab='1' />
                <div className="page-content shadow-lg">
                    <div className="jc-end">
                        <NewButton
                            onClick={() => showModal('new')}
                            text={intl.formatMessage({ id: 'New' }) + intl.formatMessage({ id: 'Work' })}
                        />
                    </div>
                    <hr />
                    <WorkTable showModal={showModal} modal={modal} />
                </div>
            </div>

            <Modal className="modal_style fade" size="lg" show={modal === 'new' || modal === 'edit'} onHide={closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title as="h5"><b>{title} <FormattedMessage id="Work" /></b></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group as={Row} controlId="classify_id">
                            <Form.Label column lg={3} md={4}><FormattedMessage id="Classification" /></Form.Label>
                            <Col lg={9} md={8}>
                                <Form.Control
                                    onChange={onChange}
                                    value={select.classify_id}
                                    as="select"
                                >
                                    <option value="null">{intl.formatMessage({ id: 'PleasePickThe' }) + intl.formatMessage({ id: 'Classification' })}</option>
                                    {groupList.map(item => <option value={item.id}>{item.classify}</option>)}
                                </Form.Control>
                                <span className="warn" />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="work_item_name">
                            <Form.Label column lg={3} md={4}><FormattedMessage id="WorkName" /></Form.Label>
                            <Col lg={9} md={8}>
                                <Form.Control
                                    placeholder={intl.formatMessage({ id: 'PleaseEnterThe' }) + intl.formatMessage({ id: 'WorkName' })}
                                    onChange={onChange}
                                    value={select.work_item_name}
                                />
                                <span className="warn" />
                            </Col>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer className="jc-end">
                    <button className="btn btn-confirm" onClick={submit}>{title}</button>
                    <button className="btn btn-cancel" onClick={closeModal}><FormattedMessage id="Cancel" /></button>
                </Modal.Footer>
            </Modal>

            <DeleteModal
                show={modal === 'delete'}
                onHide={closeModal}
                name={select.work_item_name}
                type="Work"
                callback={deleteData}
            />
        </React.Fragment>
    );
}

function EmployeeSetting({ checkEditAuth }) {
    const [modal, setModal] = useState();
    const [workList, setWorkList] = useState([]);
    const [select, setSelect] = useState({});
    const intl = useIntl();
	const { factory } = useContext(Context);

    useEffect(() => {
        ApiEmployee_GetWorkList({ factory_id: factory })
            .then(res => setWorkList(res.data));
    }, []);

    const showModal = (modal, item = { work_item: [] }) => {
        if (!checkEditAuth()) {
            return;
        }
        if (modal === 'edit') {
            item.work_item = item.WorkItemList.map(e => {
                const obj = workList.find(work => work.classify === e.ClassifyName && work.work_item_name === e.WorkItemName);
                if (obj) {
                    return obj.id;
                }
            });
            item.work_item = item.work_item.filter(e => e);
        }
        setSelect(item);
        setModal(modal);
    }

    const closeModal = () => {
        setModal(null);
    }

    const onChange = (e) => {
        const { id, value, tagName } = e.currentTarget;
        if (tagName === 'SELECT' && value === 'null') {
            return;
        }
        setSelect(prev => ({ ...prev, [id]: value }));
    }

    const checkOnChange = (e) => {
        const { id, checked } = e.currentTarget;
        const arr = id.split('_');
        let list = select.work_item.slice();
        if (checked) {
            list.push(parseInt(arr[1]));
        }
        else {
            list = list.filter(item => item !== parseInt(arr[1]));
        }
        setSelect(prev => ({ ...prev, work_item: list }));
    }

    const showWarn = (dom, msg) => {
        const ele = document.getElementById(dom).nextElementSibling;
        ele.textContent = intl.formatMessage({ id: msg })/*必填項目*/;
        ele.hidden = false;
    }

    const submit = () => {
        document.querySelectorAll(".warn").forEach(item => item.hidden = true);
        let pass = true;
        const { EmployeeId, EmployeeName, EmployeeNum, work_item } = select;
        if (!EmployeeName) {
            showWarn('EmployeeName', 'Required');
            pass = false;
        }
        if (!EmployeeNum) {
            showWarn('EmployeeNum', 'Required');
            pass = false;
        }
        if (work_item.length === 0) {
            showWarn('work_item', 'Required');
            pass = false;
        }

        if (pass) {
            let req = {
                account: localStorage.getItem('account'),
                factory_id: factory,
                employee_num: EmployeeNum,
                employee_name: EmployeeName,
                work_item: work_item
            }

            if (modal === 'new') {
                ApiEmployee_PostEmployeeWorkList(req)
                    .then(() => closeModal())
                    .catch(err => handleError(err, intl));
            }
            else if (modal === 'edit') {
                req.employee_id = EmployeeId;
                ApiEmployee_PutEmployeeWorkList(req)
                    .then(() => closeModal())
                    .catch(err => handleError(err, intl));
            }
        }
    }

    const deleteData = () => {
        ApiEmployee_DeleteEmployeeWorkList({
            account: localStorage.getItem('account'),
            employee_id: select.EmployeeId,
            factory_id: factory,
        })
            .then(() => closeModal())
            .catch(err => handleError(err, intl));
    }

    const title = modal === 'new' ? <FormattedMessage id="New" /> : <FormattedMessage id="Modify" />;
    return (
        <React.Fragment>
            <div>
                <EmployeeTabs tab='2' />
                <div className="page-content shadow-lg">
                    <div className="jc-end">
                        <NewButton
                            onClick={() => showModal('new')}
                            text={intl.formatMessage({ id: 'New' }) + intl.formatMessage({ id: 'Worker' })}
                        />
                    </div>
                    <hr />
                    <SettingTable showModal={showModal} modal={modal} />
                </div>
            </div>

            <Modal className="modal_style fade" size="lg" show={modal === 'new' || modal === 'edit'} onHide={closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title as="h5"><b>{title} <FormattedMessage id="Worker" /></b></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group as={Row} controlId="EmployeeNum">
                            <Form.Label column lg={3} md={4}><FormattedMessage id="WorkerNumber" /></Form.Label>
                            <Col lg={9} md={8}>
                                <Form.Control
                                    placeholder={intl.formatMessage({ id: 'PleaseEnterThe' }) + intl.formatMessage({ id: 'WorkerNumber' })}
                                    onChange={onChange}
                                    value={select.EmployeeNum}
                                />
                                <span className="warn" />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="EmployeeName">
                            <Form.Label column lg={3} md={4}><FormattedMessage id="WorkerName" /></Form.Label>
                            <Col lg={9} md={8}>
                                <Form.Control
                                    placeholder={intl.formatMessage({ id: 'PleaseEnterThe' }) + intl.formatMessage({ id: 'WorkerName' })}
                                    onChange={onChange}
                                    value={select.EmployeeName}
                                />
                                <span className="warn" />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="work_item">
                            <Form.Label column lg={3} md={4}><FormattedMessage id="Work" /></Form.Label>
                            <Col lg={9} md={8}>
                                {workList.map(item =>
                                    <Form.Check
                                        key={item.id}
                                        label={item.work_item_name}
                                        id={`work_${item.id}`}
                                        onChange={checkOnChange}
                                        checked={select.work_item && select.work_item.indexOf(item.id) > -1}
                                    />
                                )}
                                <span className="warn" />
                            </Col>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer className="jc-end">
                    <button className="btn btn-confirm" onClick={submit}>{title}</button>
                    <button className="btn btn-cancel" onClick={closeModal}><FormattedMessage id="Cancel" /></button>
                </Modal.Footer>
            </Modal>

            <DeleteModal
                show={modal === 'delete'}
                onHide={closeModal}
                name={select.EmployeeNum}
                type="Worker"
                callback={deleteData}
            />
        </React.Fragment>
    );
}

function EmployeeLog() {
    return (
        <React.Fragment>
            <div>
                <EmployeeTabs tab='3' />
                <div className="page-content shadow-lg">
                    <hr />
                    <LogTable />
                </div>
            </div>

        </React.Fragment>
    );
}

export { EmployeeWork, EmployeeSetting, EmployeeLog };