import React, { useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { MdCreate, MdDelete } from "react-icons/md";
import { InputModalBase } from "../management/Management";
import { Table } from "../common";
import {
  baseURL,
  getGroup,
  postGroup,
  putGroup,
  ApiGroup_UploadImage,
} from "../../api.js";

function GroupTable({ modal, showModal }) {
  const [data, setData] = useState([]);
  useEffect(() => {
    if (!modal) {
      getGroup().then((res) => {
        res.data.forEach((item) => {
          if (item.photo_path) {
            item.photo = `${baseURL}/${item.photo_path.replace("web/", "")}`;
          }
        });
        setData(res.data);
      });
    }
  }, [modal]);

  const columns = [
    {
      Header: <FormattedMessage id="GroupName" /> /*群組名稱*/,
      accessor: "name",
      Filter: "",
    },
    {
      Header: <FormattedMessage id="description" /> /*說明*/,
      accessor: "description",
      Filter: "",
    },
    {
      Header: <FormattedMessage id="Trademark" /> /*商標圖片*/,
      accessor: "photo",
      disableSortBy: true,
      Cell: ({ cell: { value } }) => {
        return (
          <img src={value} className="fit-contain" style={{ width: "50px" }} />
        );
      },
      Filter: "",
    },
    {
      Header: <FormattedMessage id="Edit" />,
      accessor: "editor",
      Filter: "",
    },
  ];
  const display = data.map((item) => ({
    ...item,
    editor: (
      <span>
        <MdCreate
          className="icon-btn"
          size="20px"
          onClick={() => showModal("edit", item)}
        />
        <MdDelete
          style={{ marginLeft: "10px" }}
          className="icon-btn"
          size="20px"
          onClick={() => showModal("delete", item)}
        />
      </span>
    ),
  }));

  return <Table columns={columns} data={display} />;
}

const InputModal = ({ modal, onHide, data }) => {
  const intl = useIntl();
  const [select, setSelect] = useState({});

  useEffect(() => {
    setSelect(JSON.parse(JSON.stringify(data)));
  }, [data]);

  const submit = () => {
    return new Promise(async (resolve, reject) => {
      let req = {
        ...select,
        photo_path: select.photo_path ? select.photo_path : "",
      };
      if (select.photo?.size) {
        const data = new FormData();
        data.append("file", select.photo);
        try {
          const res = await ApiGroup_UploadImage(data);
          req.photo_path = res.data.path;
        } catch (e) {
          reject(e);
        }
      }
      if (modal === "new") {
        postGroup(req).then(resolve).catch(reject);
      } else if (modal === "edit") {
        putGroup(select.id, req).then(resolve).catch(reject);
      }
    });
  };

  const title = intl.formatMessage(
    { id: modal === "new" ? "Create" : "EditThe" },
    { name: intl.formatMessage({ id: "Group" }) }
  );

  const columns = [
    { id: "name", type: "text", required: true },
    { id: "description", type: "text", required: true },
    {
      id: "photo",
      type: "file",
      extensions: ["webp", "jpg", "png", "jpeg", "bmp", "gif"],
      remark: "PhotoRemark",
    },
  ];

  return (
    <InputModalBase
      show={modal === "new" || modal === "edit"}
      onHide={onHide}
      title={title}
      columns={columns}
      select={select}
      setSelect={setSelect}
      submit={submit}
    />
  );
};

export { GroupTable, InputModal };
