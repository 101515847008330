import React, { useState } from "react";
import { FaSquareFull } from "react-icons/fa";
import { FormattedMessage, useIntl } from "react-intl";
import moment from "moment";
import { AnalysisTabs } from "../../component/analysis/Analysis";
import { AnalysisProductionTable } from "../../component/analysis/ProductionOverview";
import { SingleDatePicker, SingleDropdownPicker } from "../../component/common";

const state = [
  {
    color: "#617DE8",
    describe: <FormattedMessage id="Shutdown" /> /*未開機*/,
  },
  { color: "#DB4954", describe: <FormattedMessage id="Alert" /> /*警報中*/ },
  {
    color: "#F3A43B",
    describe: (
      <div>
        <FormattedMessage id="Downtime" />
        {/*停機中*/} / <FormattedMessage id="MachineIsDowntime" />
        {/*機台停機*/}
      </div>
    ),
  },
  {
    color: "#5FB946",
    describe: (
      <div>
        <FormattedMessage id="Running" />
        {/*運行中*/} / <FormattedMessage id="NoOrderRunning" />
        {/*無工單運行中*/} / <FormattedMessage id="ProductionPause" />
        {/*生產暫停*/} / <FormattedMessage id="MicroStop" />
        {/*生產微停*/}
      </div>
    ),
  },
  {
    color: "#CCC",
    describe: <FormattedMessage id="MachineIsOffline" /> /*機台離線*/,
  },
];


function AnalysisProductionOverview() {

  const defaultTimeInterval = 8;

  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [hourInterval, setHourInterval] = useState(defaultTimeInterval);
  const intl = useIntl();

  return (
    <div>
      <AnalysisTabs tab="4" />
      <div className="page-content shadow-lg index-production-area">
        <div className="jc-space-between">
          <SingleDatePicker
            name="date"
            date={date}
            onApply={(name, date) => setDate(date)}
          />
          <div className="ai-center">
            <SingleDropdownPicker
              value={hourInterval}
              list={[
                { label: 1 + intl.formatMessage({ id: "Hour" }), value: 1 },
                { label: 2 + intl.formatMessage({ id: "Hour" }), value: 2 },
                { label: 4 + intl.formatMessage({ id: "Hour" }), value: 4 },
                { label: 8 + intl.formatMessage({ id: "Hour" }), value: 8 },
              ]}
              onApply={setHourInterval}
              style={{ marginRight: "20px" }}
            />
          </div>
        </div>
        <hr />
        <AnalysisProductionTable
          date={date}
          hourInterval={hourInterval}
        />
        <div style={{ marginTop: "10px" }}>
          {state.map((item, i) => (
            <div
              key={i}
              className="ai-center"
              style={{ marginBottom: "5px", fontSize: "14px" }}
            >
              <FaSquareFull color={item.color} style={{ marginRight: "5px" }} />
              {item.describe}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
export { AnalysisProductionOverview };
