const index = {
  //生產總覽-機台總覽
  DeviceOverview: "Device Overview",
  remainingDays: "Time Left",
  expect_quantity: "Expect Quantity",
  OEETrend: "OEE Trend",
  ProgressTrend: "Progress Trend",

  //生產總覽-生產總覽-稼動總覽
  OperationOverview: "Operation Overview",
  DeviceName: "Device Name",
  Status: "Status",
  ProductionQuantity: "Production Quantity",
  TodayQuantity: "Actual Quantity",
  StartTime: "Start Time",

  //生產總覽-生產總覽-OEE總覽
  OEEOverview: "OEE Overview",
  DeviceQuantity: "Device Quantity",
  Unit: "Unit",
  DeviceNoOrder: "Device No Order",
  DeviceStop: "Device Stop",
  DeviceOffline: "Device Offline",
  OEEOnTheHour: "OEE On The Hour",
  OperationStatus: "Operation Status",
  OperationMode: "Operation Mode",
  LastNinetyMin: "Last Ninety Min",
  LastSixtyMin: "Last Sixty Min",
  LastThirtyMin: "Last Thirty Min",
};

export default index;
