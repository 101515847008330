const pes = {
  Ideal: "Ideal",
  Waiting: "Waiting",
  Busy: "Busy",

  //生產執行系統-生產管理
  ProductionManagement: "Production Management",
  OrderRunning: "Order Running",
  NoOrderRunning: "No Order Running",
  NextOrder: "Orders Waiting",
  NoNextOrder: "No Orders Waiting",
  ClickToComplete: "Click To Complete",
  OrderDoneIn24Hours: "Order Done In 24 Hours",
  NoOrdeDone: "No Order Done In 24 Hours",
  ClickToStart: "Click To Start",
  FinishManually: "Finish Manualy",
  FinishManuallyConfirm: "Are you sure to finish order {name} manually?",
  StartManually: "Start Manually",
  StartManuallyConfirm: "Are you sure to start order {name} manually?",
  StartManuallyRemark: "The order will be start after 1 minute.",
  StartPauseConfirm: "Are you sure to {action} order {name} manually ?",
  ChangeStatus: "Change Status",
  Split: "Split",
  SplitThe: "Split {name}",

  //生產執行系統-不良管理
  DefectiveManagement: "Defective Management",
  DefectiveList: "Defective List",
  Defect: "Defect",
  Defective: "Defective",

  //生產執行系統-停機管理
  DowntimeManagement: "Downtime Management",
  DowntimeList: "Downtime List",
  DowntimeReason: "Downtime Reason",
  DowntimeReasonClassification: "Downtime Reason Group",
  BatchEditDowntimeReason: "Batch Edit",
  DowntimeReasonFirst: "Please create a downtime reason first",
  Remark: "Remark",
  Action:"Action",
  Add: "Add",
  Merge: "Merge",
  MergeThe: "Merge {name}",

  //生產執行系統-交接報告
  HandoverReport: "Handover Report",
  shift: "Shift",
  DeviceNumber: "Device Number",
  AverageOEE: "Average OEE",
  description: "description",
  PersonInCharge: "Person In Charge",
  Edit: "Edit",
  Availability: "Availability",
  Performance: "Performance",
  Quality: "Quality",
  PlannedProductionQuantity: "Planned Production Quantity",
  actual_quantity: "Actual Quantity",
  Factory: "Factory",
  date: "Date",
  deviceIDList: "Device Number",

  MoldDifferent: "Mold Different",
  MaterialDifferent: "Material Different",
};

export default pes;
