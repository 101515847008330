import React from "react";
import { Link } from "react-router-dom";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import { Tabs } from "../Component";
import { ApiOrder_GetProduceMold, ApiQuality_GetControlLimit } from "../../api";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

function QualityTabs(props) {
  const { tab } = props;
  return (
    <Tabs>
      <li className="nav-item">
        <Link
          to="/pages/quality"
          className={`nav-link ${tab === "1" ? "active" : ""}`}
        >
          參數建立
        </Link>
      </li>
      <li className="nav-item">
        <Link
          to="/pages/quality_analysis"
          className={`nav-link ${tab === "2" ? "active" : ""}`}
        >
          參數表
        </Link>
      </li>
    </Tabs>
  );
}

class QualitySortBar extends React.Component {
  constructor() {
    super();
    let timelist = [];
    for (let i = 0; i < 24; i++) {
      let timestr = i < 10 ? "0" + i + ":00" : i + ":00";
      timelist.push(timestr);
    }

    this.state = {
      computed: false,
      moldList: [],
      columnList: [],
      selectedMold: "",
      selectedColumn: "",
      timeList: timelist,
      selectedTime: "00:00",
      close: false,
      mold: null,
      column: null,
      point: "",
      total: "",
      CLx: "",
      UCLx: "",
      LCLx: "",
      CLr: "",
      UCLr: "",
      LCLr: "",
      date: moment().format("YYYY-MM-DD"),
    };
  }

  componentDidMount() {
    console.log(localStorage.getItem("factory"));

    ApiOrder_GetProduceMold({
      factory_id: localStorage.getItem("factory"),
    }).then((res) => {
      this.setState({
        moldList: res.data.map((item) => {
          return {
            id: String(item.id),
            name: item.mold_num,
          };
        }),
        columnList: this.props.columns,
      });
    });
  }

  handleChecked = (key, checked) => {
    this.setState({
      [key]: [checked],
    });
  };

  handleInput = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  pickDate = (e, picker) => {
    const id = e.target.id;
    let date;
    if (id === "today") {
      date = moment().format("YYYY-MM-DD");
    } else if (id === "yesterday") {
      date = moment().subtract(1, "days").format("YYYY-MM-DD");
    } else if (id === "subtract") {
      date = moment(this.state.date).subtract(1, "days").format("YYYY-MM-DD");
    } else if (id === "add") {
      date = moment(this.state.date).add(1, "days").format("YYYY-MM-DD");
    } else {
      date = picker.startDate.format("YYYY-MM-DD");
    }
    if (!this.state.computed) {
      this.setState({ date: date });
    }
  };

  //show control chart
  getControlLimit = () => {
    if (!this.state.computed) {
      if (this.state.point > 25 || this.state.total > 300) {
        alert("總數至多300點，每組樣本數至多25點！");
        return;
      } else {
        ApiQuality_GetControlLimit({
          factory_id: localStorage.getItem("factory"),
          mold_id: this.state.selectedMold[0],
          parameter_best_name: this.state.selectedColumn[0],
          point: this.state.point,
          total: this.state.total,
          start_date: this.state.date,
          hour: this.state.selectedTime,
        })
          .then((res) => {
            console.log(res.data);
            this.props.loadData(res.data, this.state.selectedColumn[0]);
            this.setState({
              computed: true,
              mold: this.state.selectedMold[0],
              column: this.state.selectedColumn[0],
              CLx: res.data.CLx,
              UCLx: res.data.UCLx,
              LCLx: res.data.LCLx,
              CLr: res.data.CLr,
              UCLr: res.data.UCLr,
              LCLr: res.data.LCLr,
            });
          })
          .catch((e) => {
            let errRes = e.response;
            if (errRes.data == "too less data") alert("樣本總數不足");
            else alert("無此模具資料");
            this.setState({
              selectedMold: "",
              selectedColumn: "",
              point: "",
              total: "",
              start_date: moment().format("YYYY-MM-DD"),
              hour: "00:00",
            });
          });
      }
      document
        .querySelectorAll(".dropdown-menu")
        .forEach((item) => item.classList.remove("show"));
    } else {
      this.props.clearData();
      this.setState({
        computed: false,
        selectedMold: "",
        selectedColumn: "",
        selectedTime: "00:00",
        close: false,
        mold: null,
        column: null,
        point: "",
        total: "",
        CLx: "",
        UCLx: "",
        LCLx: "",
        CLr: "",
        UCLr: "",
        LCLr: "",
        date: moment().format("YYYY-MM-DD"),
      });
    }
  };

  //put in control chart parameters
  putIn = () => {
    const { CLx, UCLx, LCLx, CLr, UCLr, LCLr } = this.state;
    if ([CLx, UCLx, LCLx, CLr, UCLr, LCLr].indexOf("") > -1) {
      alert("無值可存入！");
      return;
    }
    this.props.putIn(this.state.mold, this.state.column, {
      date: this.state.date,
      hour: this.state.selectedTime,
      total: this.state.total,
      point: this.state.point,
      CLx: CLx,
      UCLx: UCLx,
      LCLx: LCLx,
      CLr: CLr,
      UCLr: UCLr,
      LCLr: LCLr,
    });
  };

  render() {
    return (
      <React.Fragment>
        <div
          className="btn_download list_group clear_both"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <div style={{ width: "100%" }}>
            <hr style={{ marginTop: 0 }} />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <div
                className="sltdate_sort list_group"
                style={{ marginBottom: 0 }}
              >
                <div className="btn-group">
                  <button
                    type="button"
                    id="subtract"
                    onClick={this.pickDate}
                    disabled={this.state.computed}
                  >
                    <i className="d-flex icon-navigate_before"></i>
                  </button>
                  <div
                    style={{
                      width: "100%",
                      pointerEvents: this.state.computed ? "none" : "auto",
                      opacity: this.state.computed ? "0.5" : "1"
                    }}
                    className="range_area clearfix"
                    role="group"
                    disabled={true}
                  >
                    <DateRangePicker
                      opens="center"
                      id="date"
                      singleDatePicker
                      onApply={this.pickDate}
                    >
                      <input
                        style={{
                          borderTop: "0px",
                          borderLeft: "0px",
                          borderBottom: "0px",
                          textAlign: "center",
                        }}
                        className="select_range"
                        type="text"
                        name="startDate"
                        readOnly
                        value={this.state.date}
                      />
                    </DateRangePicker>
                  </div>
                  <button
                    type="button"
                    id="add"
                    onClick={this.pickDate}
                    disabled={this.state.computed}
                  >
                    <i className="d-flex icon-navigate_nextchevron_right"></i>
                  </button>
                </div>
              </div>
              <div
                className="dropdown slt_dropdown"
                style={{ marginRight: "30px" }}
              >
                <button
                  className="btn dropdown-toggle"
                  style={{ borderLeft: "none" }}
                  type="button"
                  onClick={(e) => {
                    e.target.nextSibling.classList.toggle("show");
                  }}
                  disabled={this.state.computed}
                >
                  <span>{this.state.selectedTime}</span>
                </button>
                <div
                  className={"dropdown-menu dropdown-menu-right"}
                  style={{
                    zIndex: 10,
                    maxHeight: "356px",
                    overflow: "auto",
                    minWidth: "0rem",
                    margin: "0",
                    width: "100%",
                  }}
                  onClick={(e) => {
                    e.currentTarget.classList.toggle("show");
                  }}
                >
                  {this.state.timeList.map((item) => (
                    <label
                      style={{
                        width: "100%",
                      }}
                      className="form-check-label"
                      onClick={(e) => {
                        this.setState({
                          selectedTime: e.currentTarget.textContent,
                        });
                      }}
                    >
                      {item}
                    </label>
                  ))}
                </div>
              </div>
              <div
                className="dropdown slt_dropdown"
                style={{ marginRight: "10px" }}
              >
                <FormattedMessage id="ChooseMold">
                  {(e) => (
                    <DropdownBtn
                      name={e}
                      /*選擇模具*/ list={this.state.moldList}
                      menuRight="true"
                      handleChange={(checked) =>
                        this.handleChecked("selectedMold", checked)
                      }
                      checked={this.state.selectedMold}
                      disabled={this.state.computed}
                    />
                  )}
                </FormattedMessage>
              </div>
              <div
                className="dropdown slt_dropdown"
                style={{ marginRight: "30px" }}
              >
                <FormattedMessage id="ChooseColumn">
                  {(e) => (
                    <DropdownBtn
                      name={e}
                      /*選擇欄位*/ list={this.state.columnList}
                      handleChange={(checked) =>
                        this.handleChecked("selectedColumn", checked)
                      }
                      checked={this.state.selectedColumn}
                      disabled={this.state.computed}
                    />
                  )}
                </FormattedMessage>
              </div>
              <span style={{ fontWeight: "bold", marginRight: "10px" }}>
                計算管制限制
              </span>
              <span>樣本總數</span>
              <div style={{ width: "5vw", margin: "0 10px" }}>
                <input
                  class="form-control"
                  type="number"
                  value={this.state.total}
                  onChange={this.handleInput}
                  id="total"
                  disabled={this.state.computed ? "disabled" : ""}
                />
              </div>
              <span>每組樣本數</span>
              <div style={{ width: "5vw", height: "100%", margin: "0 10px" }}>
                <input
                  class="form-control"
                  type="number"
                  value={this.state.point}
                  onChange={this.handleInput}
                  id="point"
                  disabled={this.state.computed ? "disabled" : ""}
                />
              </div>
              <button
                className="btn_common"
                type="button"
                style={{ alignSelf: "flex-end", marginLeft: "20px" }}
                onClick={this.getControlLimit}
              >
                {this.state.computed ? "清空" : "計算"}
              </button>
            </div>
            <hr />
            <div style={{ display: "flex", alignItems: "center" }}>
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  marginLeft: "10px",
                }}
              >
                CLx：
              </span>
              <div style={{ width: "5vw", margin: "0px 10px" }}>
                <input
                  class="form-control"
                  type="number"
                  value={this.state.CLx}
                  onChange={this.handleInput}
                  disabled={this.state.computed ? "" : "disabled"}
                  id="CLx"
                />
              </div>
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  marginLeft: "10px",
                }}
              >
                UCLx：
              </span>
              <div style={{ width: "5vw", margin: "0px 10px" }}>
                <input
                  class="form-control"
                  type="number"
                  value={this.state.UCLx}
                  onChange={this.handleInput}
                  disabled={this.state.computed ? "" : "disabled"}
                  id="UCLx"
                />
              </div>
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  marginLeft: "10px",
                }}
              >
                LCLx：
              </span>
              <div style={{ width: "5vw", margin: "0px 10px" }}>
                <input
                  class="form-control"
                  type="number"
                  value={this.state.LCLx}
                  onChange={this.handleInput}
                  disabled={this.state.computed ? "" : "disabled"}
                  id="LCLx"
                />
              </div>
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  marginLeft: "10px",
                }}
              >
                CLr：
              </span>
              <div style={{ width: "5vw", margin: "0px 10px" }}>
                <input
                  class="form-control"
                  type="number"
                  value={this.state.CLr}
                  onChange={this.handleInput}
                  disabled={this.state.computed ? "" : "disabled"}
                  id="CLr"
                />
              </div>
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  marginLeft: "10px",
                }}
              >
                UCLr：
              </span>
              <div style={{ width: "5vw", margin: "0px 10px" }}>
                <input
                  class="form-control"
                  type="number"
                  value={this.state.UCLr}
                  onChange={this.handleInput}
                  disabled={this.state.computed ? "" : "disabled"}
                  id="UCLr"
                />
              </div>
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  marginLeft: "10px",
                }}
              >
                LCLr：
              </span>
              <div style={{ width: "5vw", margin: "0px 10px" }}>
                <input
                  class="form-control"
                  type="number"
                  value={this.state.LCLr}
                  onChange={this.handleInput}
                  disabled={this.state.computed ? "" : "disabled"}
                  id="LCLr"
                />
              </div>
              <button
                className="btn_common"
                type="button"
                style={{ alignSelf: "flex-end", marginLeft: "20px" }}
                disabled={this.state.computed ? "" : "disabled"}
                onClick={this.putIn}
              >
                填入
              </button>
            </div>
          </div>
        </div>
        <hr />
      </React.Fragment>
    );
  }
}

function DropdownBtn(props) {
  const handleChangeCheckBox = (e) => {
    const id = e.currentTarget.id;
    props.handleChange(id);
  };

  const handleClicked = (e) => {
    e.target.nextSibling.classList.toggle("show");
  };

  const formCheckStyle = {
    paddingRight: "0.5rem",
    display: "flex",
    margin: 0,
  };
  const dropdownDivStyle = {
    zIndex: 10,
    maxHeight: "356px",
    overflow: "auto",
  };

  const checkboxList = props.list.map((col) =>
    col != null ? (
      <a
        key={col.id}
        id={col.id}
        className="form-check dropdown-item"
        style={formCheckStyle}
        onClick={handleChangeCheckBox}
      >
        <input
          type="checkbox"
          checked={props.checked.includes(col.id)}
          className="form-check-input"
        />
        <label className="form-check-label">
          <FormattedMessage id={col.name} />
        </label>
      </a>
    ) : null
  );

  const menuRight = props.menuRight
    ? "dropdown-menu dropdown-menu-right"
    : "dropdown-menu";

  return (
    <React.Fragment>
      <button
        className="btn dropdown-toggle"
        type="button"
        onClick={handleClicked}
        disabled={props.disabled}
      >
        <span>{props.name}</span>
      </button>
      <div className={menuRight} style={dropdownDivStyle}>
        {checkboxList}
      </div>
    </React.Fragment>
  );
}

class ColumnDropdownBtn extends React.Component {
  constructor() {
    super();
    this.state = {
      data: [],
      checkedAll: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.checked !== prevProps.checked) {
      this.loadData();
    }
  }

  loadData = () => {
    let data = this.props.list.map((item) => {
      return {
        id: item.id,
        name: item.name,
        isCheck: this.props.checked.findIndex((key) => key === item.id) > -1,
      };
    });
    let checked = data.filter((item) => item.isCheck);
    this.props.handleChange(checked);
    this.setState({
      data: data,
    });
  };

  handleChangeCheckBox = (e) => {
    const id = e.currentTarget.id;
    let data = this.state.data;
    let checked = [];
    let checkedAll = this.state.checkedAll;
    if (id == "all") {
      checkedAll = !checkedAll;
    }
    data.forEach((item) => {
      if (id == "all") {
        item.isCheck = checkedAll;
      } else if (item.id == id) {
        item.isCheck = !item.isCheck;
      }
      if (item.isCheck) {
        checked.push(item);
      }
    });
    this.setState({
      data: data,
      checkedAll: checkedAll,
    });
    this.props.handleChange(checked);
    document
      .querySelectorAll(".dropdown-menu")
      .forEach((item) => item.classList.remove("show"));
  };

  handleClicked = (e) => {
    e.target.nextSibling.classList.toggle("show");
  };

  render() {
    const formCheckStyle = {
      paddingRight: "0.5rem",
      display: "flex",
      margin: 0,
    };
    const dropdownDivStyle = {
      zIndex: 10,
      maxHeight: "356px",
      overflow: "auto",
    };

    const checkboxList = this.state.data.map((col) => (
      <a
        key={col.id}
        id={col.id}
        className="form-check dropdown-item"
        style={formCheckStyle}
        onClick={this.handleChangeCheckBox}
      >
        <input
          type="checkbox"
          checked={col.isCheck}
          className="form-check-input"
        />
        <label className="form-check-label">
          <FormattedMessage id={col.name} />
        </label>
      </a>
    ));

    const menuRight = this.props.menuRight
      ? "dropdown-menu dropdown-menu-right"
      : "dropdown-menu";

    return (
      <React.Fragment>
        <button
          className="btn dropdown-toggle"
          type="button"
          onClick={this.handleClicked}
        >
          <span>{this.props.name}</span>
        </button>
        <div className={menuRight} style={dropdownDivStyle}>
          <a
            id="all"
            className="form-check dropdown-item"
            style={formCheckStyle}
            onClick={this.handleChangeCheckBox}
          >
            <input
              type="checkbox"
              checked={this.state.checkedAll}
              className="form-check-input"
            />
            <label className="form-check-label">全選</label>
          </a>
          {checkboxList}
        </div>
      </React.Fragment>
    );
  }
}

class Chart extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidUpdate(prevPros) {
    if (
      prevPros.column !== this.props.column ||
      prevPros.CL !== this.props.CL ||
      prevPros.UCL !== this.props.UCL ||
      prevPros.LCL !== this.props.LCL ||
      prevPros.data !== this.props.data
    ) {
      // re-render
    }
  }

  render() {
    const max = Math.max(
      ...this.props.data.map((item) => item.y),
      this.props.UCL
    );
    const min = Math.min(
      ...this.props.data.map((item) => item.y),
      this.props.LCL
    );
    const columnName = this.props.column;
    const options = {
      credits: {
        enabled: false,
      },
      title: {
        text: null,
      },
      subtitle: {
        text: null,
      },
      xAxis: {
        type: "datetime",
        // allowDecimals: false,
        offset: 10,
        tickWidth: 1,
        labels: {
          formatter: function () {
            return moment(this.value).format("HH:mm:ss");
          },
        },
      },
      yAxis: {
        gridLineWidth: 0,
        plotLines: [
          {
            value: this.props.CL,
            color: "green",
            label: {
              text: "CL" + this.props.tail,
              align: "right",
              x: -15,
              y: 5,
							style: {
								fontWeight: 'bold'
							}
            },
          },
          {
            value: this.props.UCL,
						dashStyle:'longdashdot',
            color: "red",
            label: {
              text: "UCL" + this.props.tail,
              align: "right",
              x: -15,
              y: 5,
							style: {
								fontWeight: 'bold'
							}
            },
          },
          {
            value: this.props.LCL,
						dashStyle:'longdashdot',
            color: "red",
            label: {
              text: "LCL" + this.props.tail,
              align: "right",
              x: -15,
              y: 5,
							style: {
								fontWeight: 'bold'
							}
            },
          },
        ],
        max: max,
        min: min === 0 ? 0 : min - 0.01,
        title: {
          text: null,
        },
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        scatter: {
          lineWidth: 1,
        },
        series: {
          turboThreshold: 8000,
          marker: {
            radius: 5,
          },
        },
      },
      navigation: {
        buttonOptions: {
          enabled: false,
        },
      },
      series: [
        {
          type: "scatter",
          name: "data",
          data: this.props.data.map((item) => {
            return {
              x: new Date(item.created_at),
              y: item.y,
            };
          }),
          tooltip: {
            pointFormatter: function () {
              return `time: <b>${moment(this.x).format(
                "YYYY-MM-DD HH:mm"
              )}</b><br/>${columnName}: <b>${this.y}</b><br/>`;
            },
          },
        },
      ],
    };

    return (
      <React.Fragment>
        <HighchartsReact highcharts={Highcharts} options={options} />
      </React.Fragment>
    );
  }
}

export { QualityTabs, QualitySortBar, DropdownBtn, ColumnDropdownBtn, Chart };
