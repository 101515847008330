const sys = {
  UsersManagement: "Users Management",
  PanelManagement: "Panel Management",
  DeviceClassificationManagement: "Device Classification Management",

  Keywords: "Keywords",
  User: "User",
  UserList: "User List",
  ProfilePicture: "Profile Picture",
  Name: "Name",
  Role: "Role",
  Email: "Email",
  EmailWarning: "Wrong email format",
  Group: "Group",
  Pwd: "Pwd",
  Remark: "Remark",
  PermissionSetting: "Permission Setting",
  ViewPermissions: "View Permissions",
  EditPermissions: "Edit Permissions",
  ClickAndUploadYourProfilePicture: "Click And Upload Your Profile Picture",
  KeyInToChangePassword: "Key In To Change Password",
  Setting: "Setting",
  Website: "Website",
  ThisUser: "This User?",
  UploadFile: "Upload File",
  //系統管理-看板管理
  Panel: "Panel",
  Title: "Title",
  ThisPanel: "This Panel?",
  UploadFileMangement: "Upload File Management",
  Sequence: "Sequence",
  Sort: "Sort",

  //系統管理-機台群組管理
  DeviceGroup: "Device Group",
  DeviceGroupName: "Device Group Name",
  ThisDeviceGroup: "This Device Group?",
  DataisLoading: "Data is Loading",
  NoFile: "No File ",
  ControlTime: "Control Time",
  ControlQuantity: "Control Quantity",
  CarouselIntervalTime: "Carousel interval time",

  Directory: "Directory",

  //Diary
  Diary: "Diary",
  Category: "Category",
  EditData: "Create {category} {name}",
  EditField: "Edit the field {field} {name}",
  DeleteData: "Delete {category} {name}",
  BeforeEditing: "Before Editing",
  AfterEditing: "After Editing",
  produce_order: "Order",
  produce_mold: "Mold",
  produce_bad_reason: "Defect Reason",
  produce_closing_reason: "Downtime Reason",
  produce_closing_management: "Downtime Reason",
  employee_efficiency_operation: "Worker",

  EmailAlreadyFound:"Email Already Found",
};

export default sys;
