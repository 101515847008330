const employee = {
  CurrentOperationItem: "當前操作項目",
  Operator: "操作人員",
  Ontime: "進行時間",
  ChoosePeople: "選擇人員",
  Start: "開始",
  Complete: "完成",
  DefectiveCount: "個不良品",
  EnterDefectiveProduct: "輸入不良品",
  ClickToAddQuantity: "點選項目以新增數量",
  end_time: "完成時間",

  Work: "工作項目",
  Classification: "類別",
  WorkName: "工作項目名稱",
  ThisWork: "此工作項目",

  Worker: "人員",
  WorkerNumber: "人員編號",
  WorkerName: "人員名稱",

  WorkerLog: "人員Log",
  ChangeMold: "換模",
  PleaseSelectTheAction: "請先選擇要執行的動作",
  Error: "錯誤",
};
export default employee;
