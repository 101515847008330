const analysis = {
  OEEAnalysis: "OEE Analysis",
  QuantityAnalysis: "Quantity Analysis",
  MonitoringParameter: "Monitoring Parameter",
  OrderSchedule: "Order Schedule",

  Overview: "Overview",
  ProductionAnalysis: "Production Analysis",

  DeviceAnalysis: "Device Analysis",

  TimeSection: "Time Zone",
  DailyReport: "Daily Report",

  OEEOfDevices: "OEE Of Devices",
  AvailabilityOfDevices: "Availability Of Devices",
  PerformanceOfDevices: "Performance Of Devices",
  QualityOfDevices: "Quality Of Devices",
  PickAnyDeviceAbove: "Pick Any Device Above",

  ProductionEfficiency: "Production Efficiency",
  ProductionQuantity: "Production Quantity",
  ProductionCycleAnalysis: "Production Cycle Analysis",
  AverageCycle: "Average Cycle",
  AvgProductionCycle: "Avg Production Cycle",
  Second: "Second",
  DowntimeTimeline: "Downtime Timeline",
  Downtime: "Downtime",

  ProductAmount: "Product Amount",
  Total: "Total",
  ModeCount: "Mode Count",
  bad_total: "Defective",
  bad_percent: "Defect Rate",

  PlannedProductionQuantity: "Planned Production Quantity",

  //生產分析報告-製程參數
  ParameterMonitoring: "Parameter Monitoring",
  ProcessSetting: "Process Setting",
  created_at: "Create Time",
  mold_close_time: "Mold Close Time",
  mold_open_time: "Mold Open Time",
  inject_time: "Fill Time",
  vp_time: "VP Transfer Time",
  hold_time: "Hold Time",
  compress_time: "Pack Time",
  feed_time: "Suck Back",
  delay_feed_time: "Screw Delay Time(s)",
  inj_peak_pressure: "Injection Max Pressure",
  inj_melt_pressure: "Injection Melt Pressure",
  cut_off_pressure: "Cut Off Pressure",
  cut_off_melt_pressure: "Cut Off Melt Pressure",
  inj_melt_integral: "Injection melt integral",
  compress_press: "Actual Compress Pressure",
  hold_avg_system_pressure: "hold avg system pressure",
  hold_avg_melt_pressure: "hold avg melt pressure",
  hold_velocity_pressure: "hold velocity pressure",
  hold_melt_pressure: "hold melt pressure",
  hold_pressure_integral: "hold pressure integral",
  feed_avg_pressure: "feed avg pressure",
  feed_pressure_velocity: "feed pressure velocity",
  melt_avg_back: "melt avg back",
  melt_velocity_back: "melt velocity back",
  vp_cavity: "vp cavity",
  mold_integral_pressure: "Integral of Cavity Pressure {number}",
  mold_velocity_pressure: "Peak of Cavity Pressure {number}",
  pos_inj_start: "pos inj start",
  vp_position: "Velocity-Pressure Transfer Point(mm)",
  vp_itinerary: "VP itinerary",
  compression_stroke: "compression stroke",
  pos_pressure_end: "Obtained Cushion(mm)",
  pos_bef_dos: "Decompression Before Plasticize Position(mm)",
  pos_dos_end: "Plast End Position(mm)",
  pos_sb_end: "Decompression After Plasticize Position(mm)",
  vp_volume: "vp volume",
  feed_volume: "feed volume",
  feed_before_volume: "feed before volume",
  feed_after_volume: "feed after volume",
  residual_volume: "Obtained Cushion (cm3)",
  inj_peak_velocity: "Injection Max. Velocity(mm/s)",
  avg_inj_speed: "avg inj speed",
  feed_speed_velocity: "feed speed velocity",
  max_plastic_speed: "max plastic speed",
  avg_plastic_speed: "avg plastic speed",
  oil_temp: "Oil Temperature(℃)",
  material_temp: "Temperature Input Zone {number}(℃)",
  throat_temp: "Hopper Temp(℃)",
  max_force: "max force",
  force_range: "force range",
  cavity_temp: "cavity temp",
  core_temp: "core temp",
  mold_controller_temp: "mold controller temp",
  mold_controller_flow: "mold controller flow",
  inlet_temp: "Flow Temp Modules Actual Temp (In {number})",
  outlet_temp: "Flow Temp Modules Actual Temp (Out {number})",
  inlet_flow: "Flow Temp Modules Actual Flow (In {number})",
  outlet_flow: "Flow Temp Modules Actual Flow (Out {number})",
  weight: "Weight {number}",
  cooldown_time: "Actual Cooldown Time",
  ejector_fwd_time: "Ejector Fwd Time",
  ejector_bwd_time: "Ejector Bwd Time",
  ejector_shake_time: "Ejector Shake Time",
  nozzle_bwd_time: "Nozzle Bwd Time",
  nozzle_fwd_time: "Nozzle Fwd Time",
  air_valve_time: "Air Valve Time",
  core_movein_act_time: "Core Move In Act. Time",
  core_moveout_act_time: "Core Move Out Act. Time",
  robot_time: "Robot Time",
  zozzle_fwd_contactact: "Nozzle Contact Hold Time",
  hold_max_pressure: "Hold Max. Pressure",
  hold_max_velocity: "Hold Max. Velocity",
  lock_magic_power: "Tie Bar Pressure {number}",
  b_inject_time: "Inj.B Fill Time",
  b_compress_time: "Inj.B Pack Time",
  b_hold_time: "Inj.B Hold Time",
  b_feed_time: "Inj.B Plast Time",
  b_delay_feed_time: "Inj.B Delay Plast Time",
  b_inj_peak_pressure: "Inj.B Injection Max. Pressure",
  b_cut_off_pressure: "Inj.B Cut off pressure",
  b_vp_position: "Inj.B Cut Off Position",
  b_pos_pressure_end: "Inj.B Hold End Position",
  b_pos_dos_end: "Inj.B Plast End Position",
  b_residual_volume: "Inj.B Cushion",
  b_inj_peak_velocity: "Inj.B Injection Max. Velocity",
  b_material_temp: "Inj.B Temperature Input Zone {number}",
  b_throat_temp: "Inj.B Throat Temperature",
  b_cooldown_time: "Inj.B Actual Cooling Time",

  a: "A {name}",
  a2: "A2 {name}",
  b: "B {name}",
  b2: "B2 {name}",
  mold_close_pressure: "Mold close Force",
  mold_close_speed: "Mold close Velocity",
  mold_close_position: "Mold close Position",
  mold_open_pressure: "Mold open Force",
  mold_open_speed: "Mold open Velocity",
  mold_open_position: "Mold open Position",
  mold_pressure: "Clamp pressure",
  mold_close_step: "Mold close Stages",
  mold_open_step: "Mold open Stages",
  mold_close_max_time: "Max. close time",
  mold_open_max_time: "Max. close time",
  mold_stocking: "Mold open during plast",
  mold_insurance: "Open after protect",
  mold_clamp_tons: "Clamp Force",
  forward_speed: "Mold adjust forward velocity",
  backward_speed: "Mold adjust backward velocity",
  mold_adjust: "Mold adjust Mode",
  mold_adjust_by_time: "Mold adjust by Time",
  mold_adjust_single: "Mold adjust by one gear",
  mold_thickness: "Mold height",
  mold_adjust_time: "Mold adjust Time",

  sp_forward: "Ejector {type} forward Force",
  sv_forward: "Ejector {type} forward Velocity",
  ss_forward: "Ejector {type} forward Position",
  sp_backward: "Ejector {type} backward Force",
  sv_backward: "Ejector {type} backward Velocity",
  ss_backward: "Ejector {type} backward Position",
  step_forward: "Ejector {type} forward Stages",
  step_backward: "Ejector {type} backward Stages",
  ejector_rdistence: "Ejector A Stroke",
  make_mould: "Eject during mold open",
  ejector_start_pos: "Ejector forward start position",
  forward_count: "Shake counter",
  blow_mode: "Air Valve Mode {number}",
  blow_position: "Air Valve Start Position {number}",
  blow_delay: "Air Valve Delay Time {number}",
  blow_time: "Air Valve Time {number}",
  inject_pressure: "Injection Pressure",
  inject_speed: "Injection Velocity",
  inject_position: "Injection Position",
  inject_pressure_segments: "Injection Stages",
  hold_pressure: "Injection Hold Pressure",
  hold_speed: "Injection Hold Velocity",
  hold_time: "Injection Hold time",
  hold_pressure_segments: "Injection Hold Stages",
  vp_time: "Injection Cut off time",
  vp_position: "Injection Cut off Position",
  vp_pressure: "Injection Cut off Pressure",
  vp_speed: "Injection Cut off Velocity",
  restocking_back_pressure: "Injection Instrusion Backpress",
  restocking_pressure: "Injection Instrusion Torque",
  restocking_speed: "Injection Instrusion Velocity",
  restocking_time: "Injection Instrusion time",
  cold_material: "Injection Cold slug eject",
  cold_material_pressure: "Injection Cold slug eject Pressure",
  cold_material_speed: "Injection Cold slug eject Velocity",
  cold_material_time: "Injection Cold slug eject Time",
  max_inject_time: "Injection Max inject time",
  colling_time: "Injection Cooling time",
  restocking_back: "Injection Backpressure",
  restocking_rotary_speed: "Injection Charge Rotation",
  restocking_position: "Injection Charge Position",
  restocking_torque: "Injection Charge torque",
  restocking_segments: "Injection Charge Stages",
  restocking_delay: "Injection Delay Plast",
  max_restocking_time: "Injection Max. Plasticize time",
  max_retreat_time: "Injection Max. Decompression Time",
  retreat_before_pressure:
    "Injection Decompression before plasticize Pressure",
  retreat_before_speed: "Injection Decompression before plasticize Velocity",
  retreat_before_position:
    "Injection Decompression before plasticize Position",
  retreat_before_time: "Injection Decompression before plasticize Time",
  retreat_after_pressure:
    "Injection Decompression after plasticize Pressure",
  retreat_after_speed: "Injection Decompression after plasticize Velocity",
  retreat_after_postition:
    "Injection Decompression after plasticize Position",
  retreat_after_time: "Injection Decompression after plasticize Time",
  seat_forward_pressure: "Injection Nozzle Forward Pressure",
  seat_forward_speed: "Injection Nozzle Forward Velocity",
  seat_forward_position: "Injection Nozzle Forward Position",
  seat_backward_pressure: "Injection Nozzle backward Pressure",
  seat_backward_speed: "Injection Nozzle backward Velocity",
  seat_backward_position: "Injection Nozzle backward Position",
  seat_contactforce_pressure: "Injection Forward Contact Force",
  seat_contactforce_speed: "Injection Forward Contact Velocity",
  seat_contactforce_pressureSetting:
    "Injection Forward Contact Force Setting",
  max_inlet_time: "Injection Max. forward time",
  max_outlet_time: "Injection Max. backward time",
  max_clear_time: "Injection Max. purge time",
  seat_control: "Injection Nozzle adjust Monitor time",
  seat_stocking: "Injection Nozzle hold during plast",
  seat_injection: "Injection Nozzle hold during inject",
  inject_clear_pressure: "Injection Auto purge injection pressure",
  inject_clear_speed: "Injection Auto purge injection flow",
  inject_clear_time: "Injection Auto purge injection position",
  unchuck_clear_pressure: "Injection Auto purge decompression pressure",
  unchuck_clear_speed: "Injection Auto purge decompression flow",
  unchuck_clear_time: "Injection Auto purge decompression position",
  stocking_clear_pressure: "Injection Auto purge plasticize pressure",
  stocking_clear_speed: "Injection Auto purge plasticize flow",
  stocking_clear_time: "Injection Auto purge plasticize time",
  heating_set: "Injection Heating zone temperature set",
  heating_max: "Injection Heating zone temperature tolerance high",
  heating_min: "Injection Heating zone temperature tolerance low",
  standby_temp: "Injection Standby temperature",
  prevent_time: "Injection Cool prevent time",
  hot_time: "Injection Max. preheat time",

  // 工單時程
  ExpectedStartTime: "Expected Start Time",
  ActualStartTime: "Actual Start Time",
  ExpectedEndTime: "Expected End Time",
  ActualEndTime: "Actual End Time",
  // 生產總覽
  DeviceStatus: "Device Status",
  TimeDuration: "Time Duration",
};

export default analysis;
