import React from "react";
import { Tabs, LinkBar } from "../common";

export function RootTabs({ tab }) {
  const list = [
    { to: "/pages/root/factory", name: "FactoryManagement" },
    { to: "/pages/root/group", name: "GroupManagement" },
    { to: "/pages/root/sql", name: "DatabaseManagement" },

  ];
  return <Tabs list={list} tab={tab} />;
}

export function FactoryMenu({ tab }) {
  const list = [
    { to: "/pages/root/factory/factory", name: "FactoryList" },
    { to: "/pages/root/factory/device", name: "DeviceList" },
    { to: "/pages/root/factory/user", name: "UserList" },
  ];
  return <LinkBar list={list} tab={tab} />;
}