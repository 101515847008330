import React, { useEffect, useState, useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { MdCreate, MdDelete } from "react-icons/md";
import update from "immutability-helper";
import { InputModalBase } from "../management/Management";
import { Table } from "../common";
import {
  ApiSysGetKanbanDataList,
  postBoard,
  putBoard,
  getDevice,
} from "../../api";
import Context from "../../context";

function BoardTable({ showModal, modal }) {
  const [data, setData] = useState([]);
  const { factory } = useContext(Context);
  const intl = useIntl();
  useEffect(() => {
    if (!modal) {
      loadData();
    }
  }, [modal]);

  const loadData = () => {
    ApiSysGetKanbanDataList({
      factoryID: factory,
    })
      .then((res) => {
        res.data.forEach((item) => {
          item.typeName = item.type.name;
          item.type = item.type.id;
        });
        setData(res.data);
      })
      .catch(console.log);
  };

  const columns = [
    {
      Header: <FormattedMessage id="Title" /> /*標題*/,
      accessor: "title",
      Filter: "",
    },
    {
      Header: <FormattedMessage id="Type" /> /*型態*/,
      accessor: "typeName",
      Filter: "",
    },
    {
      Header: <FormattedMessage id="CarouselIntervalTime" /> /*型態*/,
      accessor: "cycleTime",
      Filter: "",
    },
    {
      Header: <FormattedMessage id="DeviceName" /> /*機台名稱*/,
      accessor: "device",
      Filter: "",
    },
    {
      Header: <FormattedMessage id="Edit" />,
      accessor: "editor",
      Filter: "",
    },
  ];

  const display = data.map((item) => ({
    ...item,
    device: item.deviceList.map(({ deviceName }) => deviceName).toString(),
    cycleTime: item.boardCycleTime + intl.formatMessage({ id: "Second" }),
    editor: (
      <span>
        <MdCreate
          className="icon-btn"
          size="20px"
          onClick={() => showModal("edit", item)}
        />
        <MdDelete
          style={{ marginLeft: "10px" }}
          className="icon-btn"
          size="20px"
          onClick={() => showModal("delete", item)}
        />
      </span>
    ),
  }));

  return <Table columns={columns} data={display} />;
}

const InputModal = ({ modal, onHide, data }) => {
  const intl = useIntl();
  const { factory } = useContext(Context);
  const [deviceList, setDeviceList] = useState([]);
  const [select, setSelect] = useState({ deviceList: [] });

  useEffect(() => {
    getDevice({ factory_id: [factory] }).then((res) => setDeviceList(res.data));
  }, []);

  useEffect(() => {
    setSelect(JSON.parse(JSON.stringify(data)));
  }, [data]);

  const moveCard = (dragIndex, hoverIndex) => {
    const dragCard = select.deviceList[dragIndex];
    setSelect((prev) => ({
      ...prev,
      deviceList: update(prev.deviceList, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragCard],
        ],
      }),
    }));
  };

  const submit = () => {
    return new Promise((resolve, reject) => {
      let req = {
        ...select,
        deviceList: select.deviceList.map((device_id, order_by) => ({
          device_id,
          order_by,
        })),
      };
      if (modal === "new") {
        postBoard(req, { factoryID: factory }).then(resolve).catch(reject);
      } else {
        putBoard(select.boardID, req, { factoryID: factory })
          .then(resolve)
          .catch(reject);
      }
    });
  };

  const title = intl.formatMessage(
    { id: modal === "new" ? "Create" : "EditThe" },
    { name: intl.formatMessage({ id: "Panel" }) }
  );

  const columns = [
    { id: "title", type: "text", required: true },
    {
      id: "type",
      type: "select",
      required: true,
      options: [{ label: "2x2", value: 2 }],
    },
    { id: "boardCycleTime", type: "number", required: true },
    {
      id: "deviceList",
      type: "multi_check",
      required: true,
      options: deviceList.map(({ id, device_name }) => ({
        label: device_name,
        value: id,
      })),
    },
    {
      id: "sort",
      type: "sort",
      options: select.deviceList.map((device_id) => ({
        label: deviceList.find(({ id }) => id === device_id)?.device_name,
        value: device_id,
      })),
      callback: moveCard,
    },
  ];

  return (
    <InputModalBase
      show={modal === "new" || modal === "edit"}
      onHide={onHide}
      title={title}
      columns={columns}
      select={select}
      setSelect={setSelect}
      submit={submit}
    />
  );
};

export { BoardTable, InputModal };
