import React, { useEffect, useState, useContext } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { FormattedMessage, useIntl } from "react-intl";
import { apiGetIndexOEEChart, apiGetIndexOEEInfo } from "../../api.js";
import Context from "../../context";
import { lampStatus, machineMode } from "../../function.js";

function IndexOeeInfo() {
  const [data, setData] = useState({});
  const { factory } = useContext(Context);

  useEffect(() => {
    loadData();
    const interval = setInterval(loadData, 10000);
    return () => clearInterval(interval);
  }, []);

  const loadData = () => {
    apiGetIndexOEEInfo({
      FactoryID: factory,
    })
      .then((res) => {
        setData(res.data.OEEOverviewInfo);
      })
      .catch((err) => console.log(err));
  };

  const {
    DeviceQuantity,
    NoOrderQuantity,
    ShutdownQuantity,
    AbnormalQuantity,
    TotalOEE,
  } = data;
  return (
    <div className="oee_dashboard d-flex">
      <div className="flex-fill">
        <h4 className="text-center mt-0">
          <span>
            <b>
              {DeviceQuantity}
              <FormattedMessage id="Unit" />
              {/*台*/}
            </b>
          </span>
        </h4>
        <h6 className="text-center mb-0">
          <FormattedMessage id="DeviceQuantity" />
          {/*總機台數*/}
        </h6>
      </div>
      <div className="flex-fill">
        <h4 className="notice text-center mt-0">
          <span>
            <b>
              {NoOrderQuantity}
              <FormattedMessage id="Unit" />
              {/*台*/}
            </b>
          </span>
        </h4>
        <h6 className="text-center mb-0">
          <FormattedMessage id="DeviceNoOrder" />
          {/*無工單機台數*/}
        </h6>
      </div>
      <div className="flex-fill">
        <h4 className="notice text-center mt-0">
          <span>
            <b>
              {ShutdownQuantity}
              <FormattedMessage id="Unit" />
              {/*台*/}
            </b>
          </span>
        </h4>
        <h6 className="text-center mb-0">
          <FormattedMessage id="DeviceStop" />
          {/*停止中機台數*/}
        </h6>
      </div>
      <div className="flex-fill">
        <h4 className="notice text-center mt-0">
          <span>
            <b>
              {AbnormalQuantity}
              <FormattedMessage id="Unit" />
              {/*台*/}
            </b>
          </span>
        </h4>
        <h6 className="text-center mb-0">
          <FormattedMessage id="DeviceOffline" />
          {/*連線異常機台數*/}
        </h6>
      </div>
      <div className="flex-fill">
        <h4 className="text-center mt-0">
          <span>
            <b>
              {TotalOEE}
              <small>%</small>
            </b>
          </span>
        </h4>
        <h6 className="text-center mb-0">
          <FormattedMessage id="OEEOnTheHour" />
          {/*整點OEE*/}
        </h6>
      </div>
    </div>
  );
}

function IndexOeeChart() {
  const [data, setData] = useState([]);
  const intl = useIntl();
  const { factory } = useContext(Context);

  useEffect(() => {
    loadData();
    const interval = setInterval(loadData, 10000);
    return () => clearInterval(interval);
  }, []);

  const loadData = () => {
    apiGetIndexOEEChart({
      FactoryID: factory,
    })
      .then((res) => {
        let data = res.data.OEEDataList;
        data.forEach((item) => {
          const { color, status } = lampStatus(item.lamp_status);
          item.status = status;
          item.color = color;
          item.auto = machineMode(item.machine_mode);
        });
        setData(data);
      })
      .catch((err) => console.log(err));
  };

  return (
    <React.Fragment>
      <ul className="chart_area list-unstyled mb-0 clearfix">
        {data.map((row) => {
          const options = {
            chart: { type: "column" },
            title: null,
            xAxis: {
              type: "category",
              categories: [
                intl.formatMessage({ id: "LastNinetyMin" }),
                intl.formatMessage({ id: "LastSixtyMin" }),
                intl.formatMessage({ id: "LastThirtyMin" }),
                "NOW",
              ],
            },
            yAxis: { title: null },
            legend: { enabled: false },
            tooltip: {
              headerFormat: '<b style="font-size:14px;">{point.key}</b><table>',
              pointFormat:
                "<tr>" +
                '<td style="color: #333; padding: 0;"><b>{series.name}:</b></td>' +
                '<td style="padding: 0;"><b>{point.y:.1f}</b></td>' +
                "</tr>",
              footerFormat: "</table>",
              shared: true,
              useHTML: true,
            },
            plotOptions: {
              series: {
                dataLabels: { enabled: true },
              },
            },
            series: [
              {
                name: intl.formatMessage({ id: "Utilization" }), //'稼動率'
                type: "column",
                data: row.chartData.utilization,
                color: "#719fc7",
                dataLabels: {
                  enabled: true,
                  rotation: -90,
                  color: "#fff",
                  align: "right",
                  format: "{point.y:.2f}",
                  y: 5,
                },
              },
              {
                name: intl.formatMessage({ id: "Yield" }), //'良率'
                type: "column",
                data: row.chartData.yield,
                color: "#51c9a3",
                dataLabels: {
                  enabled: true,
                  rotation: -90,
                  color: "#fff",
                  align: "right",
                  format: "{point.y:.2f}",
                  y: 5,
                },
              },
              {
                name: intl.formatMessage({ id: "Performance" }), //'效率'
                type: "column",
                data: row.chartData.performance,
                color: "#FAB114",
                dataLabels: {
                  enabled: true,
                  rotation: -90,
                  color: "#fff",
                  align: "right",
                  format: "{point.y:.2f}",
                  y: 5,
                },
              },
              {
                name: "OEE",
                type: "spline",
                data: row.chartData.oee,
                color: "#252b4a",
                dataLabels: {
                  format: "{point.y:.2f}",
                  enabled: true,
                },
                marker: {
                  lineWidth: 2,
                  lineColor: "#252b4a",
                  fillColor: "#fff",
                },
              },
            ],
          };

          return (
            <li key={row.id}>
              <div
                className={`chart_inner card rounded-0 clearfix border ${
                  !row.enable ? "stop" : row.urgent ? "urgent" : "start"
                }`}
              >
                <div className="card-head">
                  <div className="d-flex justify-content-center">
                    <span>
                      <i
                        className={`float-left ${
                          !row.enable || row.urgent
                            ? "icon-fiber_manual_record"
                            : "icon-offline_bolt"
                        }`}
                        style={{ color: row.color }}
                      ></i>
                      <h5 className="m-0" style={{ color: row.color }}>
                        <b>{row.id}</b>
                      </h5>
                    </span>
                  </div>
                  <div className="d-flex justify-content-center">
                    <h6 className="m-0">{row.line}</h6>
                  </div>
                </div>
                <div className="card-body">
                  <div className="chart_data clearfix">
                    <div className="d-flex justify-content-around">
                      <span>
                        <h4
                          className="mt-0 text-center"
                          style={{ color: row.color }}
                        >
                          <b>{row.status}</b>
                        </h4>
                        <h6 className="mb-0 text-center">
                          <FormattedMessage id="OperationStatus" />
                        </h6>
                      </span>
                      <span></span>
                      <span>
                        <h4 className="mt-0 text-center">
                          <b>{row.auto}</b>
                        </h4>
                        <h6 className="mb-0 text-center">
                          <FormattedMessage id="OperationMode" />
                        </h6>
                      </span>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center">
                    <div className="chart_block">
                      <div>
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={options}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </React.Fragment>
  );
}

export { IndexOeeInfo, IndexOeeChart };
