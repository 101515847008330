import React, { useEffect, useState, useContext } from "react";
import { MdDelete } from "react-icons/md";
import { FormattedMessage, useIntl } from "react-intl";
import moment from "moment";
import { InputModalBase } from "../management/Management";
import {
  ApiPes_bad_get,
  ApiManagement_bad_group_get,
  getManagementBadReason,
  ApiPes_GetDeviceProductData,
  ApiPes_GetDeviceOrderData,
  ApiPes_bad_post,
  ApiPes_bad_put,
} from "../../api.js";
import Context from "../../context";

export function BadList({ deviceID, date, showModal, modal }) {
  const { factory, locale } = useContext(Context);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (deviceID && !modal) {
      ApiPes_bad_get({
        FactoryID: factory,
        DeviceID: deviceID,
        CheckDateStart: date,
      })
        .then((res) => {
          res.data.forEach((item) => {
            item["zh-TW"] = item.description;
            item.en = item.english;
            item.vi = item.vietnamese;
          });
          setData(res.data);
        })
        .catch(console.log);
    }
  }, [deviceID, date, modal]);

  const deleteClick = (e, item) => {
    e.stopPropagation();
    showModal("delete", item);
  };

  return (
    <ul className="bad-list">
      {data.map((item) => {
        return (
          <li
            key={item.id}
            className="ai-center"
            onClick={() => showModal("edit", item)}
          >
            <b className="flex-1">
              <FormattedMessage id="Amount" />：{item.product_count}
            </b>
            <b className="flex-1 text-center">{item[locale]}</b>
            <div className="jc-space-between ai-center" style={{ flex: 2 }}>
              <div className="flex-1 center">
                {item.name ? <span className="tag">{item.name}</span> : null}
              </div>
              <span style={{ margin: "0 15px" }}>
                {moment(item.datetime_start).format("lll")}
              </span>
              <MdDelete
                size="20px"
                className="icon-btn"
                onClick={(e) => deleteClick(e, item)}
              />
            </div>
          </li>
        );
      })}
    </ul>
  );
}

export const InputModal = ({ modal, onHide, deviceID, data }) => {
  const intl = useIntl();
  const { factory, locale } = useContext(Context);
  const [select, setSelect] = useState({ date: moment().format("YYYY-MM-DD") });
  const [productData, setProductData] = useState({});
  const [orderList, setOrderList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [reasonList, setReasonList] = useState([]);

  useEffect(() => {
    const group = groupList.find(({ BadReasonList }) =>
      BadReasonList.some(({ id }) => data.bad_reason === id)
    );
    setSelect({
      ...JSON.parse(JSON.stringify(data)),
      DefectGroup: group?.GroupID.toString(),
    });
  }, [data]);

  useEffect(() => {
    getManagementBadReason({ factory_id: factory }).then((res) => {
      const reasonList = res.data;
      ApiManagement_bad_group_get({ factory_id: factory })
        .then((res) => {
          res.data.forEach(({ BadReasonList }) => {
            BadReasonList.forEach((item) => {
              const reason = reasonList.find(({ id }) => id === item.id);
              item["zh-TW"] = reason?.description;
              item.en = reason?.english;
              item.vi = reason?.vietnamese;
            });
          });
          if (res.data.length === 0) {
            setReasonList(reasonList);
          } else {
            setGroupList(res.data);
          }
        })
        .catch(console.log);
    });
  }, []);

  useEffect(() => {
    if (deviceID && select.date) {
      ApiPes_GetDeviceProductData({
        FactoryID: factory,
        DeviceID: deviceID,
        CheckDateStart: select.date,
      })
        .then((res) => setProductData(res.data))
        .catch(console.log);
    }
  }, [select.date, deviceID]);

  useEffect(() => {
    setSelect((prev) => ({ ...prev, time: "null" }));
  }, [select.product_number]);

  useEffect(() => {
    if (select.date && select.time && deviceID) {
      ApiPes_GetDeviceOrderData({
        FactoryID: factory,
        DeviceID: deviceID,
        CheckDateStart: `${select.date} ${select.time}:00`,
        ProductNum: select.product_number,
      })
        .then((res) => {
          setOrderList(res.data);
          setSelect((prev) => ({ ...prev, order_id: "null" }));
        })
        .catch(console.log);
    }
  }, [select.date, select.time, deviceID]);

  useEffect(() => {
    if (select.DefectGroup) {
      const DefectGroup = groupList.find(
        ({ GroupID }) => GroupID === parseInt(select.DefectGroup)
      );
      if (DefectGroup) {
        setReasonList(DefectGroup.BadReasonList);
      } else {
        setReasonList([]);
      }
      if (
        DefectGroup.BadReasonList.every(({ id }) => id !== select.bad_reason)
      ) {
        setSelect((prev) => ({ ...prev, bad_reason: "null" }));
      }
    }
  }, [select.DefectGroup]);

  const submit = () => {
    return new Promise((resolve, reject) => {
      const { id, date, time, order_id, product_count, bad_reason } = select;
      if (modal === "new") {
        ApiPes_bad_post({
          account: localStorage.getItem("account"),
          FactoryID: factory,
          DeviceID: deviceID,
          DatetimeStart: `${date} ${time.split(" - ")[0]}:00`,
          OrderID: order_id,
          ProductCount: product_count,
          Reason: bad_reason,
        })
          .then(resolve)
          .catch(reject);
      } else {
        ApiPes_bad_put({
          account: localStorage.getItem("account"),
          FactoryID: factory,
          DeviceID: deviceID,
          ID: id,
          BadReasonID: bad_reason,
          ProductCount: product_count,
        })
          .then(resolve)
          .catch(reject);
      }
    });
  };

  const title = intl.formatMessage(
    { id: modal === "new" ? "Create" : "EditThe" },
    { name: intl.formatMessage({ id: "Defective" }) }
  );

  let timeArr = [];
  if (productData[select.product_number]) {
    let time = `${select.date} ${productData[select.product_number][0]}`;
    for (
      let i = 0;
      i < productData[select.product_number].length && moment().isAfter(time);
      ++i
    ) {
      time = `${select.date} ${productData[select.product_number][i]}`;
      const start = moment(time);
      timeArr.push(
        `${start.format("HH:mm")} - ${start.add(30, "m").format("HH:mm")}`
      );
    }
  }

  const columns = [
    {
      id: "date",
      type: "date",
      required: true,
    },
    {
      id: "product_number",
      type: "select",
      required: true,
      options: Object.keys(productData).map((product_number) => ({
        label: product_number,
        value: product_number,
      })),
    },
    {
      id: "time",
      type: "select",
      required: true,
      options: timeArr.map((time) => ({
        label: time,
        value: time,
      })),
    },
    {
      id: "order_id",
      type: "select",
      required: true,
      options: orderList.map(
        ({ id, order_num, schedule_date, actual_end_time }) => ({
          label: `${order_num} (${schedule_date}~${actual_end_time})`,
          value: id,
        })
      ),
    },
    {
      id: "product_count",
      type: "number",
      required: true,
    },
    {
      id: "DefectGroup",
      type: "select",
      options: groupList.map(({ GroupID, Title }) => ({
        label: Title,
        value: GroupID,
      })),
    },
    {
      id: "bad_reason",
      type: "select",
      required: true,
      options: reasonList.map((item) => ({
        label: item[locale],
        value: item.id,
      })),
    },
  ];

  return (
    <InputModalBase
      show={modal === "new" || modal === "edit"}
      onHide={onHide}
      title={title}
      columns={columns.filter((_, i) => !select.id || i > 3)}
      select={select}
      setSelect={setSelect}
      submit={submit}
    />
  );
};
