import React, { useEffect, useState, useContext } from "react";
import Form from "react-bootstrap/Form";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { FormattedMessage } from "react-intl";
import { RectangleButton } from "../component/common";
import { DashboardTabs } from "../component/dashboard/Dashboard";
import { BigDashboard } from "../component/dashboard/Dashboard1x1";
import { SmallDashboard } from "../component/dashboard/Dashboard2x2";
import { apiGetDeviceNameList, ApiSysGetKanbanDataList } from "../api.js";
import Context from "../context";

function Dashboard() {
  const [titleBar, setTitleBar] = useState({});
  const [deviceList, setDeviceList] = useState([]);
  const [deviceID, setDeviceID] = useState();
  const [onCarousel, setCarousel] = useState(false);
  const [fullscreen, setFullscreen] = useState(false);
  const { factory } = useContext(Context);
  const [number, setNumber] = useState(0);
  useEffect(() => {
    setNumber(0)
    apiGetDeviceNameList({
      FactoryID: factory,
    })
      .then((res) => {
        setDeviceList(res.data);
        setDeviceID(res.data[0]?.id);
      })
      .catch(console.log);
    [
      "fullscreenchange",
      "webkitfullscreenchange",
      "mozfullscreenchange",
      "msfullscreenchange",
    ].forEach((event) => document.addEventListener(event, fullscreenChange));
    return () => {
      [
        "fullscreenchange",
        "webkitfullscreenchange",
        "mozfullscreenchange",
        "msfullscreenchange",
      ].forEach((event) =>
        document.removeEventListener(event, fullscreenChange)
      );
    };
  }, []);

  useEffect(() => {
    if (!fullscreen) {
      if (document.fullscreenElement) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        }
      }
      setCarousel(false);
    } else {
      const el = document.querySelector(".page-content");
      if (document.documentElement.requestFullscreen) {
        el.requestFullscreen();
      } else if (document.documentElement.msRequestFullscreen) {
        el.msRequestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        el.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        el.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
      }
    }
  }, [fullscreen]);

  useEffect(() => {
    if (onCarousel) {
      const temp = setTimeout(slideBoard, 10000);
      return () => clearTimeout(temp);
    }
  }, [onCarousel, deviceID]);

  const fullscreenChange = () => {
    if (!document.fullscreenElement) {
      setFullscreen(false);
    }
  };

  const slideBoard = () => {
    let i = deviceList.findIndex(({ id }) => id === parseInt(deviceID));
    if (i === deviceList.length - 1) {
      i = 0;
    } else {
      ++i;
    }
    setDeviceID(deviceList[i].id);
  };
  return (
    <div>
      <DashboardTabs tab="0" />
      <div className="page-content shadow-lg">
        <div className="ai-center jc-space-between">
          <div className="ai-center">
            <h4 className="m-0">
              <b>
                <FormattedMessage id="RealtimeStatus" />
              </b>
            </h4>
            <div style={{ width: "200px", marginLeft: "15px" }}>
              <Form.Group className="mb-0">
                <Form.Control
                  as="select"
                  onChange={(e) => setDeviceID(e.target.value)}
                  value={deviceID}
                >
                  {deviceList.map(({ id, name }) => (
                    <option value={id} key={id}>
                      {name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </div>
            <RectangleButton
              backgroundColor={fullscreen ? "red" : "#e9ecef"}
              color={fullscreen ? "white" : "black"}
              style={{ marginLeft: "15px" }}
              onClick={() => setFullscreen(!fullscreen)}
            >
              <FormattedMessage id="Fullscreen" />
            </RectangleButton>
            {fullscreen ? (
              <RectangleButton
                backgroundColor={onCarousel ? "red" : "#e9ecef"}
                color={onCarousel ? "white" : "black"}
                style={{ marginLeft: "15px" }}
                onClick={() => setCarousel(!onCarousel)}
              >
                <FormattedMessage id="Carousel" />
              </RectangleButton>
            ) : null}
          </div>
          <h5 className="m-0">
            <b>
              <span className="mr-3">
                <FormattedMessage id="order_num_name" />：{titleBar[number]?.orderNum}
              </span>
              <span className="mr-3">
                <FormattedMessage id="product_num" />：{titleBar[number]?.productName}  {titleBar[number]?.productNum}
            </span>
              <span>
                <FormattedMessage id="ProcessSettingTimes" />：
                {titleBar[number]?.parameterSetTimes}
              </span>
            </b>
          </h5>
        </div>
        <hr />
        <TransitionGroup style={{ height: "100%" }}>
          {deviceID ? (
            <CSSTransition key={deviceID} timeout={1000} classNames="board">
              <BigDashboard
                deviceID={deviceID}
                setTitleBar={setTitleBar}
                onCarousel={onCarousel}
                fullscreen={fullscreen}
                number={number}
                setNumber={setNumber}
              />
            </CSSTransition>
            ) : null} 
        </TransitionGroup>
      </div>
    </div>
  );
}

function Dashboard2x2() {
  const [boardList, setBoardList] = useState([]);
  const [deviceList, setDeviceList] = useState([]);
  const [onCarousel, setCarousel] = useState(false);
  const [fullscreen, setFullscreen] = useState(false);
  const [dashboard, setDashboard] = useState();
  const [index, setIndex] = useState(0);
  const { factory } = useContext(Context);
  const [number, setNumber] = useState(0);
  const [orderList, setOrderList] = useState([]);
  useEffect(() => {
    ApiSysGetKanbanDataList({
      factoryID: factory,
    })
      .then((res) => {
        setBoardList(res.data);
        if (res.data.length > 0) {
          setOrderList(res.data[0].orders);
          setDeviceList(res.data[0].deviceList);
          setDashboard(res.data[0].boardID);
        }
      })
      .catch(console.log);

    [
      "fullscreenchange",
      "webkitfullscreenchange",
      "mozfullscreenchange",
      "msfullscreenchange",
    ].forEach((event) => document.addEventListener(event, fullscreenChange));

    return () => {
      [
        "fullscreenchange",
        "webkitfullscreenchange",
        "mozfullscreenchange",
        "msfullscreenchange",
      ].forEach((event) => document.addEventListener(event, fullscreenChange));
    };
  }, []);

  useEffect(() => {
    if (!fullscreen) {
      if (document.fullscreenElement) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        }
      }
      setCarousel(false);
    } else {
      const el = document.querySelector(".page-content");
      if (document.documentElement.requestFullscreen) {
        el.requestFullscreen();
      } else if (document.documentElement.msRequestFullscreen) {
        el.msRequestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        el.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        el.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
      }
    }
  }, [fullscreen]);

  useEffect(() => {
    if (dashboard) {
      setDeviceList(
        boardList.find(({ boardID }) => boardID === parseInt(dashboard))
          ?.deviceList
      );
      setOrderList(
        boardList.find(({ boardID }) => boardID === parseInt(dashboard))
        ?.orders
      )
    }
  }, [dashboard]);

  useEffect(() => {
    if (onCarousel) {
      const temp = setTimeout(slideBoard, 3000);
      return () => clearTimeout(temp);
    } else {
      setIndex(0);
    }
  }, [onCarousel, index]);

  const fullscreenChange = () => {
    if (!document.fullscreenElement) {
      setFullscreen(false);
    }
  };

  const displayList = deviceList.filter((_, i) => index <= i && i < index + 4);
  const displayOrder = orderList.filter((_, i) => index <= i && i < index + 4);

  const slideBoard = () => {
    let temp = index;
    if (temp + 4 > orderList.length-1) {
      temp = 0;
    } else {
      temp += 4;
    }
    setIndex(temp);
  };

  return (
    <div>
      <DashboardTabs tab="1" />
      <div className="page-content shadow-lg">
        <div className="ai-center">
          <h4 className="m-0">
            <b>
              <FormattedMessage id="KanbanList" />
            </b>
          </h4>
          <div style={{ width: "200px", marginLeft: "15px" }}>
            <Form.Group controlId="selectKanban" className="mb-0">
              <Form.Control
                as="select"
                onChange={(e) => setDashboard(e.target.value)}
                value={dashboard}
              >
                {boardList.map(({ boardID, title }) => (
                  <option key={boardID} value={boardID}>
                    {title}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </div>
          <RectangleButton
            backgroundColor={fullscreen ? "red" : "#e9ecef"}
            color={fullscreen ? "white" : "black"}
            style={{ marginLeft: "15px" }}
            onClick={() => setFullscreen(!fullscreen)}
          >
            <FormattedMessage id="Fullscreen" />
          </RectangleButton>
          {fullscreen ? (
            <RectangleButton
              backgroundColor={onCarousel ? "red" : "#e9ecef"}
              color={onCarousel ? "white" : "black"}
              style={{ marginLeft: "15px" }}
              onClick={() => setCarousel(!onCarousel)}
            >
              <FormattedMessage id="Carousel" />
            </RectangleButton>
          ) : null}          
        </div>
        <hr />
        <TransitionGroup
          className="jc-space-between"
          style={{ width: "100%", flexWrap: "wrap" }}
        >
          {displayOrder.map(({ device_id }) => (
            <CSSTransition key={device_id} timeout={1000} classNames="board">
              <SmallDashboard
                deviceID={device_id}
                onCarousel={onCarousel}
                fullscreen={fullscreen}
                number={number}
                setNumber={setNumber}
              />
            </CSSTransition>
          ))}
        </TransitionGroup>
      </div>
    </div>
  );
}

export { Dashboard, Dashboard2x2 };
