import React, { useState, useContext } from "react";
import { useIntl } from "react-intl";
import { NewButton, DeleteModal, handleError } from "../../component/common";
import { SysTabs } from "../../component/system/system";
import { BoardTable, InputModal } from "../../component/system/dashboard";
import { deleteBoard } from "../../api.js";
import Context from "../../context";

export default function Board({ checkEditAuth }) {
  const intl = useIntl();
  const { factory } = useContext(Context);
  const [modal, setModal] = useState("");
  const [select, setSelect] = useState({ deviceList: [] });

  const showModal = (modal, item = { deviceList: [] }) => {
    if (!checkEditAuth()) {
      return;
    }
    let obj = JSON.parse(JSON.stringify(item));
    if (modal === "edit") {
      obj.deviceList = obj.deviceList.map(({ deviceID }) => deviceID);
    }
    setSelect(obj);
    setModal(modal);
  };

  const onHide = () => {
    setModal();
    setSelect({ deviceList: [] });
  };

  const deleteData = () => {
    deleteBoard(select.boardID, { factoryID: factory })
      .then(onHide)
      .catch((err) => handleError(err, intl));
  };

  return (
    <div>
      <SysTabs tab="1" />
      <div className="page-content shadow-lg">
        <div className="jc-end">
          <NewButton
            onClick={() => showModal("new")}
            text={
              intl.formatMessage({ id: "New" }) +
              intl.formatMessage({ id: "Panel" })
            }
          />
        </div>
        <hr />
        <BoardTable showModal={showModal} modal={modal} />
      </div>

      <InputModal modal={modal} onHide={onHide} data={select} />

      <DeleteModal
        show={modal === "delete"}
        onHide={onHide}
        name={select.title}
        type="Panel"
        callback={deleteData}
      />
    </div>
  );
}
