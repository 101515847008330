import axios from "axios";

export const baseURL = "https://api.sandbox.imf-go.com";

// export const baseURL = process.env.REACT_APP_BASE_API || window.location.protocol + '//' + window.location.hostname + ":8080";

console.log(window.location);

export const request = axios.create({
    baseURL,
    transformRequest: [
        (data, headers) => {
            headers.Authorization = localStorage.getItem("token");
            return data;
        },
        ...axios.defaults.transformRequest
    ],
});

export const FormDataRequest = axios.create({
  baseURL,
  headers: { 'Content-Type': 'multipart/form-data' },
  transformRequest: [
    (data, headers) => {
      headers.Authorization = localStorage.getItem("token");
      return data;
    },
    ...axios.defaults.transformRequest
  ],
});
// 生產總覽
const Request = axios.create({
    baseURL,
    transformRequest: [
        (data, headers) => {
            headers.Authorization = localStorage.getItem("token");
            return data;
        },
        ...axios.defaults.transformRequest
    ],
});

// 生產分析報告
const analysis_Request = axios.create({
    baseURL,
    transformRequest: [
        (data, headers) => {
            headers.Authorization = localStorage.getItem("token");
            return data;
        },
        ...axios.defaults.transformRequest
    ],
});

// user
export const getUser = () => request.get(`/user`);
export const getUserSelf = () => request.get(`/user/self`);
export const postUserLogin = (data) => request.post("/user/login", data);
export const postUserGetResetToken = (data) => request.post("/user/reset", data);
export const postUserReset = ({ token, password }) => request.post(`/user/reset/${token}`, { password });
export const postUserCreate = (data) => request.post("/user/create", data);
export const getUserResetVerify = (token) => request.get(`/user/reset/verify/${token}`);
export const putUserUpdate = (data) => request.put("/user/update", data);
export const putUserUpdatePassword = (data) => request.put("/user/update/password", data);
export const postUserImage = (data) => request.post("/user/image", data, { headers: { "Content-Type": "multipart/form-data" } });
export const getUserImage = (name) => request.get(`/user/image/${name}`);

// 登入
export const apiReadUserNotification = (data) => request.put("/readNotification", data);

// 生產總覽-機台總覽
export const apiGetDeviceInfo = (params) => Request.get("/index_GetDeviceInfo", { params });

// 生產總覽-生產總覽-稼動總覽
export const apiGetIndexProduction = (params) => Request.get("/index_production_GetUtilizationRate", { params });

// 生產總覽-生產總覽-OEE總覽
export const apiGetIndexOEEInfo = (params) => Request.get("/index_oee_GetOEEOverviewInfo", { params });
export const apiGetIndexOEEChart = (params) => Request.get("/index_oee_GetOEEData", { params });

// 生產執行看板
export const apiGetDashboardInfo = (params) => Request.get("/dashboard_GetDeviceData", { params });

// 生產分析報告-機台分析
export const ApiAnalysis_GetAnalysisData = (params) => analysis_Request.get("/analysis_GetDeviceAnalysisData", { params });
export const ApiAnalysis_GetDeviceBadReasonData = (params) => analysis_Request.get("/analysis_bad_GetDeviceBadReasonData", { params });
export const ApiAnalysis_GetDeviceDowntimeReasonData = (params) => analysis_Request.get("/analysis_downtime_GetDeviceDowntimeReasonData", { params });
export const ApiAnalysis_GetDeviceDowntimeReasonList = (params) => analysis_Request.get("/analysis_downtime_GetDeviceDowntimeReasonList", { params });

// 生產分析報告-OEE分析-工單
export const apiGetOrderDataList = (params) => analysis_Request.get("/analysis_work_GetOrderDataList", { params });
export const apiGetOEEAnalysisData = (params) => analysis_Request.get("/analysis_work_GetOEEAnalysisData", { params });
export const apiDownloadOEEAnalysisDataExcel = (params) => analysis_Request.get("/analysis_work_DownloadOEEAnalysisDataExcel", { params });

// 生產分析報告-OEE分析-時間區段
export const apiGetDeviceNameList = (params) => analysis_Request.get("/analysis_oee_GetDeviceNameList", { params });
export const apiGetOEEDataList = (params) => analysis_Request.get("/analysis_oee_GetOEEDataList", { params });
export const apiGetAssignDeviceOEEDataList = (params) => analysis_Request.get("/analysis_oee_GetAssignDeviceOEEDataList", { params });

// 生產分析報告-機台分析-時間區間
export const apiGetAnalysisDateReport = (params) => analysis_Request.get("/analysis_report_GetDateReport", { params });
export const apiGetAnalysisOrderProductList = (params) => analysis_Request.get("/analysis_report_GetOrderProductList", { params });
export const apiDownloadAnalysisProductQuantityDateReportExcel = (params) => analysis_Request.get("/analysis_report_DownloadProductQuantityDateReportExcel", { params });
export const apiDownloadAnalysisMachineCycleDateReportExcel = (params) => analysis_Request.get("/analysis_report_DownloadMachineCycleDateReportExcel", { params });

// 生產分析報告-機台分析-日報表
export const apiGetAnalysisDailyReport = (params) => analysis_Request.get("/analysis_report_GetDailyReport", { params });
export const apiDownloadAnalysisProductQuantityDailyReportExcel = (params) => analysis_Request.get("/analysis_report_DownloadProductQuantityDailyReportExcel", { params });
export const apiDownloadAnalysisMachineCycleDailyReportExcel = (params) => analysis_Request.get("/analysis_report_DownloadMachineCycleDailyReportExcel", { params });

// 生產分析報告-製程參數-工程監查
export const apiGetAnalysisSPCMonitorProductList = (params) => analysis_Request.get("/analysis_report_GetSPCMonitorProductList", { params });
export const apiGetAnalysisSPCMonitorDataList = (params) => analysis_Request.get("/analysis_report_GetSPCMonitorDataList", { params });
export const apiDownloadAnalysisSPCMonitorExcel = (params) => analysis_Request.get("/analysis_report_DownloadSPCMonitorExcel", { params });

// 生產分析報告-製程參數-工程監查
export const apiGetAnalysisProcessProductList = (params) => analysis_Request.get("/analysis_report_GetAnalysisProcessProductList", { params });
export const apiGetAnalysisProcessDataList = (params) => analysis_Request.get("/analysis_report_GetAnalysisProcessDataList", { params });
export const apiDownloadAnalysisProcessExcel = (params) => analysis_Request.get("/analysis_report_DownloadAnalysisProcessExcel", { params });

// 生產分析報告-工單時程
export const getAnalysisReportOrders = (params) => request.get("/analysis/report/orders", { params });
export const getAnalysisReportOrdersExcel = (params) => request.get("/analysis/report/orders/excel", { params });

// 生產分析報告-生產總覽
export const apiAnalysisUtilizationRate = (params) => request.get("/analysis/utilizationRate", { params });

// 使用者 相關的 api
export const ApiSys = (data) => request.post("/sys/GetSys", data); // 列表
export const ApiSysDeleteUser = (params) => request.delete("/sys/DeleteUserData", { params }); // 刪除使用者
export const ApiSysUpdateUserAuth = (data) => request.patch("/sys/UpdateUserAuth", data);
export const ApiSysGetKanbanDataList = (params) => request.get("/board", { params }); // 取得看版列表

// board
export const postBoard = (data, params) => request.post("/board", data, { params }); // 新增看版列表
export const putBoard = (id, data, params) => request.put(`/board/${id}`, data, { params }); // 編輯看版列表
export const deleteBoard = (id, params) => request.delete(`/board/${id}`, { params }); // 刪除看版列表
export const ApiSysGetBoardById = (id, params) => request.get(`/board/${id}`, { params });

// role 
export const getRole = () => request.get("/role");
export const postRole = (data) => request.post("/role/factory", data);
export const getFactoryRole = (params) => request.get('/role/factory', { params });
export const ApiSysGetMachineGroupDataList = (params) => request.get("/sys/GetMachineGroupDataList", { params }); // 取得機台群組列表
export const ApiSysAddMachineGroupData = (data) => request.post("/sys/AddMachineGroupData", data); // 新增機台群組
export const ApiSysEditMachineGroupData = (data) => request.patch("/sys/EditMachineGroupData", data); // 編輯機台群組
export const ApiSysDeleteMachineGroupData = (params) => request.delete("/sys/DeleteMachineGroupData", { params }); // 刪除機台群組

// 假日管理 相關的 api
export const ApiManagement_holiday = (data) => request.post("/management_holiday/SendHoliday", data);
export const ApiManagement_new_holiday = (data) => request.post("/management_holiday/SendNewHoliday", data);
export const ApiManagement_update_holiday = (data) => request.put("/management_holiday/SendUpdateHoliday", data);
export const ApiManagement_delete_holiday = (data) => request.delete("/management_holiday/SendDeleteHoliday", { data }); // dalete 的後端要多一層data才傳的進去

// 班別管理 相關的 api
export const ApiManagement_classShowDateData = (params) => request.get("/management_class/ShowDateData", { params });
export const ApiManagement_class = (data) => request.post("/management_class/SetWorkDay", data);
export const ApiManagement_classGetDeviceList = (params) => request.get("/management_class/GetDeviceList", { params });
export const ApiManagement_classGetSetWorkDay = (params) => request.get("/management_class/GetSetWorkDay", { params });
export const ApiManagement_classGetScheduleNewList = (params) => request.get("/management_class/GetScheduleNewList", { params });
export const ApiManagement_classGetScheduleChangeList = (params) => request.get("/management_class/GetScheduleChangeList", { params });
export const ApiManagement_classScheduleChange = (data) => request.post("/management_class/ScheduleChange", data);
export const ApiManagement_classUpdateScheduleNew = (data) => request.put("/management_class/UpdateScheduleNew", data);
export const ApiManagement_classScheduleNew = (data) => request.post("/management_class/ScheduleNew", data);
export const ApiManagement_classDeleteScheduleNew = (params) => request.delete("/management_class/DeleteScheduleNew", { params });
export const ApiManagement_classDeleteScheduleChange = (params) => request.delete("/management_class/DeleteScheduleChange", { params });

// 模具管理 相關的 api
export const ApiManagement_mold = (params) => request.get("/management_mold/SendMold", { params });
export const ApiManagement_new_mold = (data) => request.post("/management_mold/SendNewMold", data);
export const ApiManagement_update_mold = (data) => request.put("/management_mold/SendUpdateMold", data);
export const ApiManagement_delete_mold = (params) => request.delete("/management_mold/SendDeleteMold", { params });
export const ApiManagement_show_mold = (params) => request.get("/management_mold/GetAssignMold", { params });
export const ApiManagement_mold_photo = (data) => request.post("/management_mold/MoldPhoto", data);
export const ApiManagement_upload_mold = (data) => request.post("/management_mold/UploadMoldData", data, { headers: { "Content-Type": "multipart/form-data" } });
export const ApiManagement_export_mold = (params) => request.get("/management_mold/ExportMoldData", { params });
export const ApiManagement_download_mold_dataExample = (params) => request.get("/management_mold/DownloadMoldExcelExample", { params });

// 材料管理 相關的 api
export const ApiManagement_material = (data) => request.post("/management_material/SendMaterial", data);
export const ApiManagement_new_material = (data) => request.post("/management_material/SendNewMaterial", data);
export const ApiManagement_update_material = (data) => request.put("/management_material/SendUpdateMaterial", data);
export const ApiManagement_delete_material = (params) => request.delete("/management_material/SendDeleteMaterial", { params });
export const ApiManagement_show_material = (params) => request.get("/management_material/GetAssignMaterial", { params });
export const ApiManagement_upload_material = (data) => request.post("/management_material/UploadMaterialData", data, { headers: { "Content-Type": "multipart/form-data" } });
export const ApiManagement_export_material = (params) => request.get("/management_material/ExportMaterialData", { params });
export const ApiManagement_download_material_dataExample = (params) => request.get("/management_material/DownloadMaterialExcelExample", { params });

// 生產管理-產品管理 相關的 api
export const ApiManagement_ProductList = (data) => request.post("/management_product/ProductList", data);
export const ApiManagement_ProductMaterial = (params) => request.get("/management_product/ProductMaterial", { params });
export const ApiManagement_ProductInsert = (data) => request.post("/management_product/ProductInsert", data);
export const ApiManagement_ProductID = (data) => request.post("/management_product/ProductID", data);
export const ApiManagement_ProductUpdate = (data) => request.put("/management_product/ProductUpdate", data);
export const ApiManagement_ProductDelete = (params) => request.delete("/management_product/ProductDelete", { params });
export const ApiManagement_ProductPhoto = (data) => request.post("/management_product/ProductPhoto", data);
export const ApiManagement_upload_product = (data) => request.post("/management_product/UploadProductData", data, { headers: { "Content-Type": "multipart/form-data" } });
export const ApiManagement_export_product = (params) => request.get("/management_product/ExportProductData", { params });
export const ApiManagement_download_product_dataExample = (params) => request.get("/management_product/DownloadProductExcelExample", { params });

// 不良管理 相關的 api
export const getManagementBadReason = (params) => request.get("/management/bad/reason", { params });
export const putManagementBadReason = (id, params, data) => request.put(`/management/bad/reason/${id}`, data, { params });
export const postManagementBadReason = (params, data) => request.post("/management/bad/reason", data, { params });
export const deleteManagementBadReason = (id, params) => request.delete(`/management/bad/reason/${id}`, { params });
export const ApiManagement_bad_group_get = (params) => request.get("/management_bad/GetManagementBadGroupList", { params });
export const ApiManagement_bad_group_post = (data) => request.post("/management_bad/PostManagementBadGroup", data);
export const ApiManagement_bad_group_patch = (data) => request.patch("/management_bad/EditManagementBadGroupData", data);
export const ApiManagement_bad_group_delete = (params) => request.delete("/management_bad/DeleteManagementBadGroupData", { params });

// 停機原因管理 相關的api
export const ApiManagement_downtime_getClassify = (params) => request.get("/management_downtime/management_downtime_GetDowntimeClassify", { params });
export const getManagementClosingReason = (params) => request.get("/management/closing/reason", { params });
export const postManagementClosingReason = (params, data) => request.post("/management/closing/reason", data, { params });
export const putManagementClosingReason = (id, params, data) => request.put(`/management/closing/reason/${id}`, data, { params });
export const deleteManagementClosingReason = (id, params) => request.delete(`/management/closing/reason/${id}` , { params });

// 非生產性工單原因管理 相關的api
export const getUnproductiveOrderClass = (params) => request.get("/management_unproductiveOrder/management_unproductiveOrder_GetClass", { params });
export const ApiManagement_unproductiveOrder = (params) => request.get("/management_unproductiveOrder/management_unproductiveOrder_GetUnproductiveOrderReasonList", { params });
export const ApiManagement_unproductiveOrder_add = (data) => request.post("/management_unproductiveOrder/management_unproductiveOrder_AddUnproductiveOrderReason", data);
export const ApiManagement_unproductiveOrder_update = (data) => request.patch("/management_unproductiveOrder/management_unproductiveOrder_EditUnproductiveOrderReason", data);
export const ApiManagement_unproductiveOrder_delete = (data) => request.delete("/management_unproductiveOrder/management_unproductiveOrder_DeleteUnproductiveOrderReason", { data });

// 生產執行 相關的api
export const ApiPes_GetDeviceDataList = (params) => request.get("/pes/pes_GetDeviceDataList", { params });

// 生產執行-生產管理 相關的api
export const ApiPes_GetDeviceCurrentOrderData = (params) => request.get("/pes/pes_GetDeviceCurrentOrderData", { params });
export const ApiPes_GetOrderIn24HourFinishList = (params) => request.get("/pes/pes_GetOrderIn24HourFinishList", { params });
export const ApiPes_OrderManualFinish = (data) => request.post("/pes/pes_OrderFinish", data);
export const ApiPes_OrderManualStart = (data) => request.post("/pes/pes_OrderManualStart", data);

// 生產執行-不良管理 相關的api
export const ApiPes_GetDeviceOrderData = (params) => request.get("/pes/GetDeviceOrderData", { params });
export const ApiPes_GetDeviceProductData = (params) => request.get("/pes/GetDeviceProductData", { params });
export const ApiPes_bad_getReason = (params) => request.get("/pes/Bad/Reason", { params });
export const ApiPes_bad_get = (params) => request.get("/pes/Bad", { params });
export const ApiPes_bad_delete = (data) => request.delete("/pes/Bad", { data });
export const ApiPes_bad_post = (data) => request.post("/pes/Bad", data);
export const ApiPes_bad_put = (data) => request.put("/pes/Bad", data);

// 生產執行-停機原因 相關的api
export const ApiPes_GetDowntimeDescriptionList = params => request.get("/pes/pes_downtime_GetDowntimeDescriptionList", { params });
export const ApiPes_GetDowntimeReasonList = params => request.get("/pes/pes_downtime_GetDowntimeReasonList", { params });
export const ApiPes_GetDowntimeDetail = params => request.get("/pes/pes_downtime_cycle_time_log", { params });
export const putPesDowntimeSplitReasonList = data => request.put("/pes/downtime/SplitReasonList", data);
export const ApiPes_BatchUpdateDowntimeReason = data => request.put("/pes/pes_downtime_BatchUpdateDowntimeReason", data);
export const putPesDowntimeMergeReasonList = data => request.put("/pes/downtime/MergeReasonList", data);

// 生產執行-交接報告 相關的api
export const ApiPes_GetHandoverReportShift = data => request.get("/analysis/report/handover/getShift", { params: data });
export const ApiPes_GetHandoverReportDeviceList = data => request.get("/analysis/report/handover/getDeviceList", { params: data });
export const ApiPes_GetHandoverReport = data => request.get("/analysis/report/handover", { params: data });
export const ApiPes_newHandoverReport = (data) => request.post("/analysis/report/handover", data);
export const ApiPes_editHandoverReport = (data) => request.put("/analysis/report/handover", data);
export const ApiPes_deleteHandoverReport = (params) => request.delete("/analysis/report/handover", { params });

// 工廠管理-工廠資訊
export const getFactory = (params) => request.get("/factory", { params });
export const postFactory = (data) => request.post("/factory", data);
export const deleteFactory = (id) => request.delete(`/factory/${id}`);
export const ApiManagement_editFactory = (data) => request.put("/factory_information_edit", data);
export const getRegion = (params) => request.get("/region", { params });
export const ApiManagement_timezoneList = (params) => Request.get("/factory_information_system_management_timezone", { params });
export const getRegionAndFactoryNameList = (params) => request.get("/GetRegionAndFactoryNameList", { params });

// 工廠管理-機台清單
export const getDevice = (params) => request.get("/device", { params });
export const getConnection = (params) => request.get("/connection", { params });
export const postFactoryDevice = (factory_id, data) => request.post(`/factory/${factory_id}/device`, data);
export const putFactoryDevice = (factory_id, device_id, data) => request.put(`/factory/${factory_id}/device/${device_id}`, data);
export const deleteFactoryDevice = (factory_id, device_id) => request.delete(`/factory/${factory_id}/device/${device_id}`);
export const postDeviceImage = (data) => request.post("/device/image", data, { headers: { "Content-Type": "multipart/form-data" } });
export const getDeviceImage = (name) => request.get(`/device/image/${name}`);

// 群組管理
export const getGroup = (params) => request.get("/group", { params });
export const postGroup = (data) => request.post("/group", data);
export const putGroup = (id, data) => request.put(`/group/${id}`, data);
export const deleteGroup = (params) => request.delete("/group", { params });
export const ApiGroup_UploadImage = (data) => request.post("/group/sys_group_UploadGroupImage", data, { headers: { "Content-Type": "multipart/form-data" } });
export const getSysLogger = (params) => request.get("/sys/logger",{ params });

// 工單排程
export const postManagementScheduleAdd = (data) => request.post("/management/schedule/add", data);
export const postManagementScheduleInsert = (data) => request.post("/management/schedule/insert", data);
export const ApiOrder_ScheduleDelTime = (data) => request.put("/order_schedule_del_time", data);
export const ApiOrder_GetSchedule = (params) => request.get("/order_schedule", { params });
export const ApiOrder_GetNotSchedule = (params) => request.get("/order_not_schedule", { params });
export const ApiOrder_GetOrderScheduleFourStatus = (params) => request.get("/order_schedule_four_status", { params });
export const getOrderScheduleNonWorkingHours = (params) => request.get("/order_schedule_nonWorkingHours", { params });

// 工單管理
export const getClientOrder = () => request.get("/client/order");
export const postClientOrder = (data) => request.post("/client/order", data);
export const deleteClientOrder = (params) => request.delete("/client/order", { params });
export const putClientOrder = (id, data) => request.put(`/client/order/${id}`, data);
export const postOrderSplit = (data) => request.post("/management/schedule/orderSplit", data);

export const ApiOrder_GetOrderById = (data) => request.post("/produce_order_find_id", data);
export const ApiOrder_GetOrderClass = (params) => request.get("/produce_order_getclass", { params });
export const ApiOrder_GetProduceMold = (params) => request.get("/produce_mold", { params });
export const ApiOrder_GetProduceProduct = (params) => request.get("/produce_product", { params });
export const ApiOrder_GetProduceMoldById = (params) => request.get("/produce_mold_id", { params });
export const ApiOrder_GetProduceProductById = (params) => request.get("/produce_product_id", { params });
export const ApiOrder_PostOrder = (data) => request.post("/produce_order_add", data);
export const ApiOrder_UpdateOrder = (data) => request.put("/produce_order_update", data);
export const ApiOrder_PostOrderTest = (data) => request.post("/produce_order_add_test", data);
export const ApiOrder_UpdateOrderTest = (data) => request.put("/produce_order_update_test", data);
export const ApiOrder_DeleteOrder = (params) => request.delete("/produce_order_delete", { params });
export const ApiOrder_PostOrderCopy = (data) => request.post("/produce_order_copy", data);
export const ApiOrder_GetProduceOrder = (params) => request.get("/produce_order", { params });
export const ApiOrder_SeparateOrder = (data) => request.put("/produce_order_quantitysplit", data);
export const ApiOrder_UploadOrderData = (data) => request.post("/UploadOrderData", data, { headers: { "Content-Type": "multipart/form-data" } });
export const ApiOrder_DownloadOrderDataExample = (params) => request.get("/DownloadOrderExcelExample", { params });
export const ApiOrder_UpdateOrderStatus = (id, params) => request.post(`/order/${id}/status`, null, { params });

// 人員效率
export const ApiEmployee_GetGroupList = (params) => Request.get("/efficiency_GetWorkClassifyItem", { params });
export const ApiEmployee_PostWork = (data) => Request.post("/efficiency_WorkItemData", data);
export const ApiEmployee_PutWork = (data) => Request.put("/efficiency_WorkItemUpdate", data);
export const ApiEmployee_DeleteWork = (params) => Request.delete("/efficiency_WorkItemDelete", { params });
export const ApiEmployee_GetWorkList = (params) => Request.get("/efficiency_WorkItemDataList", { params });
export const ApiEmployee_GetEmployeeWorkList = (params) => Request.get("/efficiency_EmployeeDataList", { params });
export const ApiEmployee_PostEmployeeWorkList = (data) => Request.post("/efficiency_EmployeeData", data);
export const ApiEmployee_PutEmployeeWorkList = (data) => Request.patch("/efficiency_EmployeeUpdate", data);
export const ApiEmployee_DeleteEmployeeWorkList = (params) => Request.delete("/efficiency_EmployeeDelete", { params });
export const ApiEmployee_GetDeviceDataList = (data) => request.get("/pes/pes_GetDeviceDataList", { params: data });
export const ApiEmployee_GetEmployeeWorkItem = (params) => Request.get("/efficiency_GetEmployeeWorkItem", { params });
export const ApiEmployee_ConfirmEmployeeStatus = (params) => Request.get("/efficiency_ConfirmEmployeeStatus", { params });
export const ApiEmployee_GetEmployee = (params) => Request.get("/efficiency_GetEmployee", { params }); //取得機台資訊
export const ApiEmployee_GetEmployeeLog = (params) => Request.get("/efficiency_GetEmployeeLog", { params }); //取得機台Log
export const ApiEmployee_UpdateEmployeeWorkItemData = (data) => Request.post("/efficiency_UpdateEmployeeWorkItemData", data);
export const ApiEmployee_GetDefectiveByOrder = (config) => Request.get("/GetDefectiveByOrder", config);

// 品質管理
export const ApiQuality_AddStandard = (data) => Request.post("/moldQualityStandard", data);
export const ApiQuality_GetStandard = (params) => Request.get("/moldQualityStandard", { params });
export const ApiQuality_DeleteStandard = (params) => Request.delete("/moldQualityStandard", { params });
export const ApiQuality_GetControlLimit = (params) => Request.get("/moldControlLimit", { params });
export const ApiQuality_GetAnalysis = (params) => Request.get("/moldQualityAnalysis", { params });

export const getBadReasonClassList = (params) => Request.get("/analysis_bad_GetDeviceBadReasonClassList", { params });
export const getBadReasonList = (params) => Request.get("/analysis_bad_GetDeviceBadReasonList", { params });
export const getDowntimeReasonClassList = (params) => Request.get("/analysis_downtime_GetDeviceDowntimeClassList", { params });

// 公告通知
export const apiAnnouncement = () => Request.get("Announcement");

//notify
export const apiGetFactoryBroadcastList = (params) => request.get("/GetFactoryBroadcastList", { params });
export const apiGetMoldBroadcastList = (params) => request.get("/GetMoldBroadcastList", { params });
export const apiGetBadReasonBroadcastList = (params) => request.get("/GetBadReasonBroadcastList", { params });
export const apiEditFactoryBroadcast = (params) => request.put('/EditFactoryBroadcast', null, { params })
export const apiEditMoldBroadcast = (params) => request.put('/EditMoldBroadcast', null, { params })
export const apiEditBadReasonBroadcast = (params) => request.put('/EditBadReasonBroadcast', null, { params })
export const putBroadcastToken = (id, data, params) => request.put(`/broadcast/token/${id}`, data, { params })

//document
export const getSysDocument = (id) => request.get(`/sys/document/${id}`)
export const postSysDocument = (data, params) => request.post('/sys/document', data , { params, headers: { "Content-Type": "multipart/form-data" } } );
export const ApiSysGetDocumentbyDevice = (factory_id, device_id) => request.get(`/sys/document/${factory_id}/${device_id}`);
export const deleteSysDocument = (id, params) => request.delete(`/sys/document/${id}`, { params })

//Document
export const ApiSysGetDocument = (id) => request.get(`/sys/document/${id}`);

//sql

export const apiGetSql = () => request.get('/sql');
export const apiPostSql = (data) => request.post('/sql',data);
export const apiPutSql = (id, data) => request.put(`/sql/${id}`, data);
export const apiDeleteSql = (data) => request.delete('/sql',{data});


export const getSql = (params) => request.get("/sql", { params });
