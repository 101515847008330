import React from "react";
import { SysTabs } from "../../component/system/system";
import {
  BroadcastMenu,
  FactoryTable,
  MoldTable,
  DefectiveTable,
} from "../../component/system/notify";

function SysNotifyFactory() {
  return (
    <div>
      <SysTabs tab="4" />
      <div className="page-content shadow-lg">
        <div className="jc-end">
          <BroadcastMenu tab="0" />
        </div>
        <hr />
        <FactoryTable />
      </div>
    </div>
  );
}

function SysNotifyMold() {
  return (
    <div>
      <SysTabs tab="4" />
      <div className="page-content shadow-lg">
        <div className="jc-end">
          <BroadcastMenu tab="1" />
        </div>
        <hr />
        <MoldTable />
      </div>
    </div>
  );
}

function SysNotifyDefective() {
  return (
      <div>
        <SysTabs tab="4" />
        <div className="page-content shadow-lg">
          <div className="jc-end">
            <BroadcastMenu tab="2" />
          </div>
          <hr />
          <DefectiveTable />
        </div>
      </div>
  );
}
export { SysNotifyFactory, SysNotifyMold, SysNotifyDefective };
