import React, { useEffect, useState } from "react";
import { MdSearch } from "react-icons/md";
import { FormattedMessage, useIntl } from "react-intl";
import { RootTabs, FactoryMenu } from "../../component/root/Root";
import { UserTable, InputModal, AuthModal } from "../../component/root/user";
import {
  NewButton,
  DeleteModal,
  handleError,
  MultiDropdownPicker,
} from "../../component/common";
import {
  getFactory,
  ApiSys,
  ApiSysDeleteUser,
  getRole,
  baseURL,
  getUserImage
} from "../../api.js";

export default function User({ checkEditAuth }) {
  const [roleList, setRoleList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [factoryList, setFactoryList] = useState([]);
  const [factory_id, setFactoryID] = useState([]);
  const [modal, setModal] = useState();
  const [select, setSelect] = useState({ auth: {} });
  const [searchString, setSearchString] = useState("");
  const intl = useIntl();

  useEffect(() => {
    getRole().then((res) => setRoleList(res.data));
    getFactory().then((res) => {
      setFactoryList(res.data);
      setFactoryID(res.data.map(({ id }) => id));
    });
  }, []);

  useEffect(() => {
    if (!modal && factory_id) {
      ApiSys({
        account: localStorage.getItem("account"),
        factory_id,
      }).then(async (res) => {
        for (let i = 0; i < res.data.UserDataList.length; ++i) {
          if (res.data.UserDataList[i].photo_path) {
            try {
              const { data } = await getUserImage(
                res.data.UserDataList[i].photo_path
              );
              res.data.UserDataList[i].photo = data;
            } catch (e) {
              console.log(e);
              res.data.UserDataList[
                i
              ].photo = `${baseURL}/${res.data.UserDataList[i].photo_path}`;
            }
          }
        }
        setUserList(
          res.data.UserDataList.sort((a, b) => a.role_id - b.role_id)
        );
      });
    }
  }, [modal, factory_id]);

  const showModal = (modal, item = { auth: {} }) => {
    if (!checkEditAuth()) {
      return;
    }

    if (modal === "edit" && item.photo_path) {
      const arr = item.photo_path.split("/");
      item.fileName = arr[arr.length - 1];
    }
    setSelect(item);
    setModal(modal);
  };

  const closeModal = () => {
    setModal();
    setSelect({ auth: {} });
  };

  const deleteData = () => {
    ApiSysDeleteUser({
      account: localStorage.getItem("account"),
      Email: select.email,
    })
      .then(closeModal)
      .catch((err) => handleError(err, intl));
  };

  return (
    <div>
      <RootTabs tab="0" />
      <div className="page-content shadow-lg">
        <div className="jc-end">
          <FactoryMenu tab="2" />
        </div>
        <hr />
        <div className="jc-space-between ai-center">
          <h4 className="m-0">
            <b>
              <FormattedMessage id="UserList" />
            </b>
          </h4>
          <div className="ai-stretch">
            <div className="ai-stretch" style={{ marginRight: "20px" }}>
              <input
                className="text-input"
                style={{ borderRight: "none", width: "150px" }}
                value={searchString}
                onChange={(e) => setSearchString(e.currentTarget.value)}
                placeholder={intl.formatMessage(
                  { id: "InputThe" },
                  { name: intl.formatMessage({ id: "Keywords" }) }
                )}
              />
              <button className="input-button center">
                <MdSearch />
              </button>
            </div>
            <MultiDropdownPicker
              style={{ marginRight: "20px" }}
              title={intl.formatMessage(
                { id: "PickA" },
                { name: intl.formatMessage({ id: "Factory" }) }
              )}
              list={factoryList.map(({ id, name }) => ({
                label: name,
                value: id,
              }))}
              selected={factory_id}
              onApply={setFactoryID}
            />
            <NewButton
              onClick={() => showModal("new")}
              text={intl.formatMessage(
                { id: "Create" },
                { name: intl.formatMessage({ id: "User" }) }
              )}
            />
          </div>
        </div>
        <hr />
        <UserTable
          showModal={showModal}
          data={userList}
          searchString={searchString}
        />
      </div>

      <InputModal
        modal={modal}
        closeModal={closeModal}
        data={select}
        roleList={roleList}
      />

      <AuthModal
        modal={modal}
        closeModal={closeModal}
        data={select}
      />

      <DeleteModal
        show={modal === "delete"}
        onHide={closeModal}
        callback={deleteData}
        name={select.name}
        type="User"
      />
    </div>
  );
}
