import React, { useState, useContext } from "react";
import { useIntl } from "react-intl";
import { NewButton, DeleteModal, handleError } from "../../component/common";
import { ManagementTabs } from "../../component/management/Management";
import {
  ProductTable,
  ImportModal,
  InputModal,
} from "../../component/management/Product";
import {
  baseURL,
  ApiManagement_ProductID,
  ApiManagement_ProductDelete,
  ApiManagement_export_product,
  ApiManagement_download_product_dataExample,
} from "../../api.js";
import Context from "../../context";

function ManagementProduct({ checkEditAuth }) {
  const [modal, setModal] = useState();
  const [select, setSelect] = useState({
    MaterialNum: [],
    MoldNum: [],
  });
  const intl = useIntl();
  const { factory } = useContext(Context);

  const showModal = async (modal, item = { MaterialNum: [], MoldNum: [] }) => {
    if (!checkEditAuth()) {
      return;
    }
    if (modal === "edit") {
      ApiManagement_ProductID({
        product_id: item.ProductId,
        factory_id: factory,
      }).then((res) => {
        let photo = {};
        if (res.data.PhotoPath) {
          const arr = res.data.PhotoPath.split("/");
          photo.name = arr[arr.length - 1];
        }
        setSelect({ ...res.data, photo });
        setModal(modal);
      });
    } else {
      setSelect(item);
      setModal(modal);
    }
  };

  const closeModal = () => {
    setModal();
  };

  const deleteData = () => {
    ApiManagement_ProductDelete({
      account: localStorage.getItem("account"),
      factory_id: factory,
      product_id: select.ProductId,
    })
      .then(closeModal)
      .catch((err) => handleError(err, intl));
  };

  const exportData = () => {
    ApiManagement_export_product({
      factory_id: factory,
    })
      .then((res) => window.open(`${baseURL}/${res.data}`))
      .catch((err) => handleError(err, intl));
  };

  const downloadDataExample = () => {
    ApiManagement_download_product_dataExample()
      .then((res) => window.open(`${baseURL}/${res.data}`))
      .catch((err) => handleError(err, intl));
  };

  const name = intl.formatMessage({ id: "Product" });

  return (
    <div>
      <ManagementTabs tab="4" />
      <div className="page-content shadow-lg">
        <div className="jc-end">
          <button
            className="center btn-gray"
            style={{ marginRight: "10px" }}
            onClick={downloadDataExample}
          >
            {intl.formatMessage({ id: "DownloadExample" })}
          </button>
          <button
            className="center btn-gray"
            style={{ marginRight: "10px" }}
            onClick={exportData}
          >
            {intl.formatMessage({ id: "Export" }, { name })}
          </button>
          <button
            className="center btn-gray"
            style={{ marginRight: "10px" }}
            onClick={() => showModal("import")}
          >
            {intl.formatMessage({ id: "Import" }, { name })}
          </button>
          <NewButton
            onClick={() => showModal("new")}
            text={intl.formatMessage({ id: "Create" }, { name })}
          />
        </div>
        <hr />
        <ProductTable showModal={showModal} modal={modal} />
      </div>

      <InputModal modal={modal} onHide={closeModal} data={select} />
      <ImportModal modal={modal} onHide={closeModal} />

      <DeleteModal
        show={modal === "delete"}
        onHide={closeModal}
        callback={deleteData}
        name={select.ProductNum}
        type="Product"
      />
    </div>
  );
}
export default ManagementProduct;
