import React from "react";
import { FaSquareFull } from "react-icons/fa";
import { FormattedMessage } from "react-intl";
import { IndexTabs, IndexLinks } from "../component/index/Index";
import { IndexPieArea } from "../component/index/device";
import { IndexProductionTable } from "../component/index/activation";
import { IndexOeeChart, IndexOeeInfo } from "../component/index/OEE";

function IndexDevice() {
  return (
    <div className="index_area">
      <IndexTabs />

      <div className="tab-content tabs_content shadow-lg">
        <hr />
        <IndexOeeInfo />
        <hr />
        <IndexPieArea />
      </div>
    </div>
  );
}

function IndexProduction() {
  const state = [
    {
      color: "#617DE8",
      describe: <FormattedMessage id="Shutdown" /> /*未開機*/,
    },
    { color: "#DB4954", describe: <FormattedMessage id="Alert" /> /*警報中*/ },
    {
      color: "#F3A43B",
      describe: (
        <div>
          <FormattedMessage id="Downtime" />
          / <FormattedMessage id="MachineIsDowntime" />
        </div>
      ),
    },
    {
      color: "#5FB946",
      describe: (
        <div>
          <FormattedMessage id="Running" />
          / <FormattedMessage id="NoOrderRunning" />
          / <FormattedMessage id="ProductionPause" />
          / <FormattedMessage id="MicroStop" />
        </div>
      ),
    },
    {
      color: "#CCC",
      describe: <FormattedMessage id="MachineIsOffline" /> /*機台離線*/,
    },
  ];

  return (
    <div>
      <IndexTabs />

      <div className="page-content shadow-lg index-production-area">
        <hr />
        <IndexProductionTable />

        <div style={{ marginTop: "10px" }}>
          {state.map(({ color, describe }, i) => (
            <div
              key={i}
              className="ai-center"
              style={{ marginBottom: "5px", fontSize: "14px" }}
            >
              <FaSquareFull color={color} style={{ marginRight: "5px" }} />
              {describe}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

function IndexOee() {
  return (
    <div className="oee_area">
      <IndexTabs />

      <div className="tab-content tabs_content shadow-lg">
        <div className="jc-end">
          <IndexLinks tab="1" />
        </div>
        <hr />
        <IndexOeeInfo />
        <hr />
        <IndexOeeChart />
      </div>
    </div>
  );
}

export { IndexDevice, IndexOee, IndexProduction };
