import React, { useState, useEffect, useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { DeleteModal, NewButton } from "../../component/common";
import { ManagementTabs } from "../../component/management/Management";
import {
  DefectGroupTable,
  DefectTable,
  InputGroupModal,
  InputDefectModal,
} from "../../component/management/defective";
import { handleError } from "../../function";
import {
  getManagementBadReason,
  deleteManagementBadReason,
  ApiManagement_bad_group_delete,
} from "../../api.js";
import Context from "../../context";

function ManagementBad({ checkEditAuth }) {
  const intl = useIntl();
  const { factory } = useContext(Context);
  const [modal, setModal] = useState("");
  const [defectiveList, setDefectList] = useState([]);
  const [select, setSelect] = useState({ bad_reason_id: [] });

  useEffect(() => {
    if (!modal) {
      getManagementBadReason({ factory_id: factory })
        .then((res) => setDefectList(res.data))
        .catch(console.log);
    }
  }, [modal]);

  const showModal = (modal, item = {}) => {
    if (!checkEditAuth()) {
      return;
    }
    item.bad_reason_id = item.BadReasonList
      ? item.BadReasonList.map(({ id }) => id)
      : [];
    setSelect(item);
    setModal(modal);
  };

  const onHide = () => {
    setModal("");
    setSelect({ bad_reason_id: [] });
  };

  const deleteGroup = () => {
    ApiManagement_bad_group_delete({
      account: localStorage.getItem("account"),
      factory_id: factory,
      group_id: select.GroupID,
    })
      .then(onHide)
      .catch((err) => handleError(err, intl));
  };

  const deleteDefect = () => {
    deleteManagementBadReason(select.id, {
      factory_id: factory,
    })
      .then(onHide)
      .catch((err) => handleError(err, intl));
  };

  return (
    <div>
      <ManagementTabs tab="7" />
      <div className="page-content shadow-lg">
        <div className="jc-space-between ai-center">
          <h4 className="m-0">
            <b>
              <FormattedMessage id="DefectGroupList" />
            </b>
          </h4>
          <NewButton
            onClick={() => showModal("newGroup")}
            text={intl.formatMessage(
              { id: "Create" },
              { name: intl.formatMessage({ id: "DefectGroup" }) }
            )}
          />
        </div>
        <hr />
        <DefectGroupTable showModal={showModal} modal={modal} />
        <hr />
        <div className="jc-space-between ai-center">
          <h4 className="m-0">
            <b>
              <FormattedMessage id="DefectList" />
            </b>
          </h4>
          <NewButton
            onClick={() => showModal("newDefect")}
            text={intl.formatMessage(
              { id: "Create" },
              { name: intl.formatMessage({ id: "Defect" }) }
            )}
          />
        </div>
        <hr />
        <DefectTable showModal={showModal} data={defectiveList} />
      </div>

      <InputGroupModal
        modal={modal}
        onHide={onHide}
        data={select}
        defectiveList={defectiveList}
      />
      <InputDefectModal modal={modal} onHide={onHide} data={select} />

      <DeleteModal
        show={modal.includes("delete")}
        onHide={onHide}
        name={modal.includes("Group") ? select.Title : select.description}
        type={modal.includes("Group") ? "DefectGroup" : "Defect"}
        callback={modal.includes("Group") ? deleteGroup : deleteDefect}
      />
    </div>
  );
}

export default ManagementBad;
