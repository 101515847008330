import { SysTabs } from "../../component/system/system";
import React, { useContext, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import { FormattedMessage, useIntl } from "react-intl";
import { InputModalBase } from "../../component/management/Management";
import { NewButton, RectangleButton } from "../../component/common";
import { AuthTable } from "../../component/root/user";
import { RoleList } from "../../component/system/role";
import { postRole } from "../../api.js";
import Context from "../../context";

export default function Role({ checkEditAuth }) {
  const intl = useIntl();
  const { factory } = useContext(Context);
  const [modal, setModal] = useState();
  const [select, setSelect] = useState({ auth: {} });
  const [page, setPage] = useState(0);
  const [error, setError] = useState("");

  useEffect(() => {
    setError("");
  }, [select.id]);

  const showModal = (modal) => {
    if (!checkEditAuth()) {
      return;
    }
    if (modal === "new") {
      let obj = { auth: {} };
      Object.keys(select).forEach((key) => {
        obj.auth[key] = 2;
      });
      setSelect(obj);
    }
    setModal(modal);
  };

  const onHide = () => {
    if (modal === "new") {
      setSelect({ auth: {} });
    }
    setError("");
    setModal();
  };

  const submit = () => {
    return new Promise((resolve, reject) => {
      if (!checkEditAuth()) {
        return;
      }
      postRole({
        ...select.auth,
        factory_id: select.factory_id ? select.factory_id : factory,
        role: select.role,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  return (
    <div>
      <SysTabs tab="7" />
      <div className="page-content shadow-lg">
        <div className="jc-end">
          <NewButton
            onClick={() => showModal("new")}
            text={intl.formatMessage(
              { id: "Create" },
              { name: intl.formatMessage({ id: "Role" }) }
            )}
          />
        </div>
        <hr />
        <div className="jc-end">
          <div className="ai-center jc-end">
            <RectangleButton
              backgroundColor={page === 0 ? "#8fc31f" : "#efefef"}
              color={page === 0 ? "white" : "#808080"}
              onClick={() => setPage(0)}
            >
              <FormattedMessage id="Website" />
            </RectangleButton>
            <RectangleButton
              backgroundColor={page === 1 ? "#8fc31f" : "#efefef"}
              color={page === 1 ? "white" : "#808080"}
              onClick={() => setPage(1)}
            >
              APP
            </RectangleButton>
          </div>
        </div>
        <hr />
        <div className="Auth_Container" style={{ display: "flex" }}>
          <Col sm={2}>
            <RoleList select={select} setSelect={setSelect} modal={modal} />
          </Col>
          <Col sm={10} className="modal_style">
            <AuthTable page={page} select={select} setSelect={setSelect} />
            <Modal.Footer className="jc-end">
              <span className="warn mr-3">{error}</span>
              <button className="btn btn-confirm" onClick={submit}>
                <FormattedMessage id="Confirm" />
              </button>
            </Modal.Footer>
          </Col>
        </div>
      </div>

      <InputModalBase
        show={modal}
        onHide={onHide}
        title={intl.formatMessage(
          { id: "Create" },
          { name: intl.formatMessage({ id: "Role" }) }
        )}
        columns={[{ id: "role", type: "text" }]}
        select={select}
        setSelect={setSelect}
        submit={submit}
      />
    </div>
  );
}
