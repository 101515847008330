import React from "react";
import { StickyTable, ColumnFilter } from "../common";
import { FormattedMessage } from "react-intl";

function ReportAmountTable({ data, totalData }) {
  const columns = [
    {
      Header: <FormattedMessage id="order_num_name" /> /*工單編號*/,
      accessor: "order",
      Filter: ({ column }) => ColumnFilter(column, "order_num_name"),
    },
    {
      Header: <FormattedMessage id="DeviceNumber" /> /*機台編號*/,
      accessor: "device",
      Filter: ({ column }) => ColumnFilter(column, "DeviceNumber"),
    },
    {
      Header: <FormattedMessage id="product_num" /> /*產品編號*/,
      accessor: "product_num",
      Filter: ({ column }) => ColumnFilter(column, "product_num"),
    },
    {
      Header: <FormattedMessage id="product_name" /> /*產品名稱*/,
      accessor: "product_name",
      Filter: "",
    },
    ...(data[0]
      ? data[0].data.map(({ time }) => ({
          Header: time,
          accessor: time,
          Filter: "",
        }))
      : []),
    {
      Header: <FormattedMessage id="Total" /> /*總數*/,
      accessor: "total",
      Filter: "",
    },
  ];

  const display = data.map((item) => {
    item.data.map(({ time, quantity }) => {
      item[time] = quantity;
    });
    return item;
  });

  if (totalData) {
    let obj = {};
    totalData.data.map(({ time, quantity }) => {
      obj[time] = quantity;
    });
    obj.order = <FormattedMessage id="Total" /> /*總數*/;
    obj.total = totalData.total;
    display.push(obj);
  }

  return <StickyTable columns={columns} data={display} sticky={4} />;
}

function ReportCycleTable({ data }) {
  const columns = [
    {
      Header: <FormattedMessage id="DeviceNumber" /> /*機台編號*/,
      accessor: "device",
      Filter: ({ column }) => ColumnFilter(column, "DeviceNumber"),
    },
    ...(data[0]
      ? data[0].data.map(({ time }) => ({
          Header: time,
          accessor: time,
          Filter: "",
        }))
      : []),
    {
      Header: <FormattedMessage id="Total" /> /*總數*/,
      accessor: "total",
      Filter: "",
    },
  ];

  const display = data.map((item, i) => {
    item.data.map((e) => {
      item[e.time] = e.quantity;
    });
    return item;
  });

  return <StickyTable columns={columns} data={display} sticky={1} />;
}

export { ReportAmountTable, ReportCycleTable };
