const sys = {
  UsersManagement: "使用者管理",
  PanelManagement: "看板管理",
  DeviceClassificationManagement: "機台群組管理",

  Keywords: "關鍵字",
  User: "使用者",
  UserList: "使用者清單",
  ProfilePicture: "頭像",
  Name: "姓名",
  Role: "角色",
  Email: "電子信箱",
  EmailWarning: "信箱格式錯誤",
  Group: "群組",
  Pwd: "密碼",
  Remark: "描述",
  PermissionSetting: "權限設定",
  ViewPermissions: "檢視權限",
  EditPermissions: "編輯權限",
  ClickAndUploadYourProfilePicture: "點擊上方上傳大頭照。副檔名必須是小寫。",
  KeyInToChangePassword: "若需修改密碼請輸入新密碼",
  Setting: "設定",
  Website: "網頁",
  ThisUser: "此使用者？",
  UploadFile: "上傳文件",
  UploadFileMangement: "文件管理",
  //系統管理-看板管理
  Panel: "看板",
  CreatePanelMenu: "新增看板",
  Title: "標題",
  ThisPanel: "此看板？",
  Sequence: "順序",
  Sort: "排序",

  //系統管理-機台群組管理
  DeviceGroup: "機台群組",
  DeviceGroupName: "機台群組名稱",
  DataisLoading: "資料讀取中",
  NoFile: "暫無文件",
  ControlTime: "管制時間",
  ControlQuantity: "管制數量",
  CarouselIntervalTime: "輪播間隔時間",

  Directory: "資料夾",

  line_token: "Token",
  notify_time: "管制時間",
  notify_num: "管制數量",

  //sys-日誌
  Diary: "日誌",
  Category: "種類",
  CreateData: "新增{category}{name}",
  EditData: "修改{name}的{field} ",
  DeleteData: "刪除{category}{name}",
  BeforeEditing: "修改前",
  AfterEditing: "修改後",
  produce_order: "工單",
  produce_mold: "模具",
  produce_bad_reason: "不良原因",
  produce_closing_reason: "停機原因",
  produce_closing_management: "停機原因",
  order_unproductive_reason: "非生產性工單原因",
  employee_efficiency_operation: "人員",
  produce_bad_reason_group: "不良原因類別",
  Vietnamese: "越南文",
  English: "英文",

  role: "角色",

  EmailAlreadyFound:"Email已經存在",
};

export default sys;
