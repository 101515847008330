import { FormattedMessage, useIntl } from "react-intl";
import React from "react";
import { apiGetDashboardInfo } from "../../api";
import { lampStatus, machineMode } from "../../function";
import { Tabs } from "../common";

function DashboardTabs({ tab }) {
  const list = [
    { to: "/pages/dashboard/1x1", name: "1X1" },
    { to: "/pages/dashboard/2x2", name: "2X2" },
  ];
  return <Tabs list={list} tab={tab} />;
}

function ProgressBar({ value, max, isIdeal, ideal, label }) {
  return (
    <div className="ai-center position-relative progress-back jc-end">
      <div
        className="progress-bar position-absolute"
        style={{
          borderRadius: value >= 100 ? "0.25rem" : "0.25rem 0 0 0.25rem",
          width: `${value}%`,
          backgroundColor: isIdeal ? "#00d85a" : "#e40026",
        }}
      />
      {label ? (
        <b style={{ color: value >= 90 ? "white" : "#808080" }}>{label}</b>
      ) : null}
      {ideal && max ? (
        <div
          className="position-absolute ideal-line"
          style={{ width: ideal !== 0 ? `${(ideal / max) * 100}%` : 0 }}
        />
      ) : null}
      {ideal && max ? (
        <b
          className="position-absolute ideal-label"
          style={{ left: ideal !== 0 ? `${(ideal / max) * 100}%` : 0 }}
        >
          {ideal}
          <FormattedMessage id="Sec" /*秒*/ />
        </b>
      ) : null}
    </div>
  );
}

function ProduceQuantityChart({
  pickDataInterval,
  dataInterval,
  max,
  ideal,
  least,
  list,
}) {
  const intl = useIntl();
  return (
    <div className="jc-space-between flex-column chart-area">
      <b className="jc-space-between">
        <span>
          <FormattedMessage id="ProductionPerHour" />
          {/*每小時生產數量*/}
        </span>
        <select onChange={pickDataInterval} value={dataInterval}>
          <option value="1">1 {intl.formatMessage({ id: "Hour" })}</option>
          <option value="2">2 {intl.formatMessage({ id: "Hour" })}</option>
          <option value="4">4 {intl.formatMessage({ id: "Hour" })}</option>
          <option value="8">8 {intl.formatMessage({ id: "Hour" })}</option>
        </select>
      </b>
      <div className="jc-space-around position-relative">
        <div
          className="position-absolute ideal-line"
          style={{ bottom: max !== 0 ? `${(ideal * 100) / max}%` : 0 }}
        >
          <b style={{ float: "right" }}>{ideal}</b>
        </div>
        {list?.map((row, i) => (
          <VerticalProgressBar
            key={i}
            value={row.value}
            max={max}
            isIdeal={row.value > ideal}
            time={row.time}
          />
        ))}
      </div>
    </div>
  );
}

function VerticalProgressBar({ value, max, isIdeal, time }) {
  return (
    <div>
      <div className="position-relative progress-back">
        <b className="position-absolute text-center value">{value}</b>
        <div
          className="position-absolute bar"
          style={{
            height: max !== 0 ? `${(value * 100) / max}%` : 0,
            backgroundColor: isIdeal ? "#00d85a" : "#e76558",
          }}
        />
        <b className="position-absolute text-center time">{time}</b>
      </div>
    </div>
  );
}

function ListBlock({ list, title, style, className }) {
  return (
    <div className={`list-block ${className}`} style={style}>
      <div>
        <b>
          <FormattedMessage id={title} />
        </b>
      </div>
      {list?.map((item, i) => (
        <div key={i}>{`${i + 1}. ${item}`}</div>
      ))}
    </div>
  );
}

export const loadData = (req) => {
  return new Promise((resolve, reject) => {
    apiGetDashboardInfo(req)
      .then((res) => {
        for (let i = 0; i < res.data.length; i++) {
          const {
            produceQuantityByHour,
            idealProduceQuantity,
            leastProduceQuantity,
            oee,
            produceStatus,
          } = res.data[i];
          res.data[i].produceQuantityByTime = produceQuantityByHour.time.map(
            (time, i) => {
              const value = produceQuantityByHour.quantity[i];
              return {
                time,
                value,
                status:
                  value < leastProduceQuantity || value > idealProduceQuantity
                    ? "urgent"
                    : "normal",
              };
            }
          );
          if (oee < 25) {
            res.data[i].oeeColor = "#FF0000";
            res.data[i].oeeTitle = <FormattedMessage id="Unacceptable" />;
          } else if (oee < 40) {
            res.data[i].oeeColor = "#FF9921";
            res.data[i].oeeTitle = <FormattedMessage id="NeedsImprovement" />;
          } else if (oee < 55) {
            res.data[i].oeeColor = "#FFD028";
            res.data[i].oeeTitle = <FormattedMessage id="NeedsImprovement" />;
          } else if (oee < 70) {
            res.data[i].oeeColor = "#4087B8";
            res.data[i].oeeTitle = <FormattedMessage id="Typical" />;
          } else if (oee < 85) {
            res.data[i].oeeColor = "#00D9a1";
            res.data[i].oeeTitle = <FormattedMessage id="HighPerformance" />;
          } else {
            res.data[i].oeeColor = "#61C739";
            res.data[i].oeeTitle = <FormattedMessage id="WorldClass" />;
          }
          const { status, color } = lampStatus(res.data[i].lampStatus);
          res.data[i].deviceStatus = status;
          res.data[i].statusColor = color;
          res.data[i].deviceMachineMode = machineMode(res.data[i].machineMode);
          const arr = [
            <FormattedMessage id="Behind" />,
            <FormattedMessage id="OnSchedule" />,
            <FormattedMessage id="Ahead" />,
          ];
          res.data[i].produceStatus.status = arr[produceStatus.statusCode + 1];
        }
        resolve(res.data);
      })
      .catch(reject);
  });
};

export const gaugeOption = ({ color, dial, tickLength, tickWidth, oee }) => {
  let tickPositions = [];
  for (let i = 1; i < 33; ++i) {
    tickPositions.push(3 * i);
  }

  return {
    chart: {
      type: "gauge",
    },
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    title: null,
    pane: {
      startAngle: -90,
      endAngle: 90,
      borderWidth: 0,
      center: ["50%", "90%"],
      size: "150%",
      shape: "arc",
      background: null,
    },
    plotOptions: {
      gauge: {
        dataLabels: {
          enabled: false,
        },
        dial,
      },
    },
    yAxis: {
      min: 0,
      max: 100,
      lineWidth: 0,
      tickLength,
      tickWidth,
      tickColor: "white",
      tickPositions,
      minorTicks: false,
      labels: {
        enabled: false,
      },
      plotBands: [
        {
          from: 0,
          to: 100,
          outerRadius: "100%",
          thickness: 70,
          color,
        },
      ],
    },
    tooltip: {
      enabled: false,
    },
    series: [
      {
        data: [Math.min(100, oee)],
      },
    ],
  };
};

export {
  DashboardTabs,
  VerticalProgressBar,
  ProduceQuantityChart,
  ProgressBar,
  ListBlock,
};
