import React, { useEffect, useState, useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import moment from "moment";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { MdPlaylistAdd, MdStop } from "react-icons/md";
import { PesTabs, DeviceList } from "../../component/mes/mes";
import {
  DowntimeList,
  BatchModal,
  EditModal,
  DetailModal,
  ModeModal,
  MergeModal,
  AddModal,
} from "../../component/mes/Downtime";
import { getManagementClosingReason } from "../../api.js";
import {
  SingleDatePicker,
  DateRadio,
  MultiDropdownPicker,
} from "../../component/common";
import Context from "../../context";

export default function PesDowntime({ checkEditAuth }) {
  const intl = useIntl();
  const { factory } = useContext(Context);
  const [deviceID, setDeviceID] = useState();
  const [reasonList, setReasonList] = useState([]);
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [modal, setModal] = useState();
  const [filter, setFilter] = useState([0, 1, 2, 3, 5, 6, 7, 8, 9]);
  const [select, setSelect] = useState({});

  useEffect(() => {
    getManagementClosingReason({ factory_id: factory }).then((res) => {
      res.data.forEach((item) => {
        item["zh-TW"] = item.description;
        item.en = item.english;
        item.vi = item.vietnamese;
      });
      setReasonList(res.data);
    });
  }, []);

  const onChange = (e) => {
    const { value } = e.target;
    setDate(value);
  };

  const pickDate = (_, date) => {
    setDate(date);
  };

  const showModal = (modal, item = {}) => {
    if (!checkEditAuth()) {
      return;
    }
    setSelect(item);
    setModal(modal);
  };

  const closeModal = () => {
    setModal();
    setSelect({});
  };

  const statusList = [
    {
      label: <FormattedMessage id="Shutdown" />,
      value: 0,
    },
    {
      label: <FormattedMessage id="Alert" />,
      value: 1,
    },
    {
      label: <FormattedMessage id="Standby" />,
      value: 2,
    },
    {
      label: <FormattedMessage id="Running" />,
      value: 3,
    },
    {
      label: <FormattedMessage id="NoOrderRunning" />,
      value: 5,
    },
    {
      label: <FormattedMessage id="MachineIsDowntime" />,
      value: 6,
    },
    {
      label: <FormattedMessage id="ProductionPause" />,
      value: 7,
    },
    {
      label: <FormattedMessage id="MicroStop" />,
      value: 8,
    },
    {
      label: <FormattedMessage id="MachineIsOffline" />,
      value: 9,
    },
  ];

  const legend = [
    { id: "Shutdown", color: "#617DE8" },
    { id: "Alert", color: "#DB4954" },
    { id: "Standby", color: "#F3A43B" },
    { id: "NoOrderRunning", color: "#5FB946" },
    { id: "MachineIsOffline", color: "#cccccc" },
  ];

  return (
    <div>
      <PesTabs tab="2" />
      <div className="page-content shadow-lg pes-area">
        <div className="top-bar ai-stretch">
          <DateRadio
            list={[{ label: <FormattedMessage id="Date" />, value: "date" }]}
          />
          <DateRadio
            list={[
              {
                label: <FormattedMessage id="Yesterday" />,
                value: moment().subtract(1, "d").format("YYYY-MM-DD"),
              },
              {
                label: <FormattedMessage id="Today" />,
                value: moment().format("YYYY-MM-DD"),
              },
            ]}
            value={date}
            onChange={onChange}
          />
          <SingleDatePicker date={date} onApply={pickDate} />
          {legend.map(({ id, color }) => (
            <span className="ai-center" key={id}>
              <MdStop size="30px" color={color} />
              <FormattedMessage id={id} />
            </span>
          ))}
        </div>
        <hr />
        <Row>
          <Col lg={4}>
            <DeviceList
              pickDevice={setDeviceID}
              deviceID={deviceID}
              modal={modal}
            />
          </Col>
          <Col lg={8} className="downtime-area">
            <div className="jc-space-between ai-center mb-3">
              <h5 className="m-0 ai-center">
                <MdPlaylistAdd size="30px" className="mr-2" />
                <FormattedMessage id="DowntimeList" />
              </h5>
              <div className="ai-stretch">
                <MultiDropdownPicker
                  style={{ marginRight: "10px" }}
                  title={intl.formatMessage({ id: "LampStatus" })}
                  list={statusList}
                  selected={filter}
                  onApply={setFilter}
                />
                <button
                  className="center btn-gray"
                  onClick={() => setModal("batch")}
                >
                  <FormattedMessage id="BatchEditDowntimeReason" />
                </button>
              </div>
            </div>
            <DowntimeList
              deviceID={deviceID}
              checkEditAuth={checkEditAuth}
              filter={filter}
              reasonList={reasonList}
              date={date}
              update={modal}
              showModal={showModal}
            />
          </Col>
        </Row>
      </div>

      <BatchModal
        modal={modal}
        closeModal={closeModal}
        deviceID={deviceID}
        reasonList={reasonList}
      />
      <EditModal
        modal={modal}
        setModal={setModal}
        closeModal={closeModal}
        deviceID={deviceID}
        data={select}
        reasonList={reasonList}
      />
      <AddModal
        modal={modal}
        setModal={setModal}
        closeModal={closeModal}
        data={select}
        reasonList={reasonList}
        deviceID={deviceID}
        date={date}
      />
      <ModeModal modal={modal} setModal={setModal} closeModal={closeModal} />
      <MergeModal
        modal={modal}
        setModal={setModal}
        closeModal={closeModal}
        data={select}
        deviceID={deviceID}
        date={date}
        reasonList={reasonList}
      />
      <DetailModal modal={modal} closeModal={closeModal} id={select.id} />
    </div>
  );
}
