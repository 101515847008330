import React, { useState, useEffect, useContext, useMemo } from "react";
import { useTable, useSortBy } from "react-table";
import { MdFiberManualRecord, MdReport } from "react-icons/md";
import { BiBoltCircle } from "react-icons/bi";
import { FaSort, FaSortUp, FaSortDown } from "react-icons/fa";
import { FormattedMessage } from "react-intl";
import Tooltip from "@material-ui/core/Tooltip";
import moment from "moment";
import { lampStatus } from "../../function";
import { apiGetIndexProduction } from "../../api.js";
import Context from "../../context";

function IndexProductionTable() {
  const [data, setData] = useState({});
  const [devices, setDevices] = useState([]);
  const { factory } = useContext(Context);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    apiGetIndexProduction({
      FactoryID: factory,
    })
      .then(({ data }) => {
        const totalTime =
          moment(data.TotalDeviceShiftTime.end_at).diff(
            data.TotalDeviceShiftTime.start_at,
            "s"
          ) / 100;
        data.ProductionQuantityCountData.forEach((item) => {
          const { status, color } = lampStatus(item.State);
          item.state = item.State;
          item.status = status;
          item.color = color;
          item.OEE = `${item.OEE}%`;
          item.Performance = `${item.Performance}%`;
          item.Utilization = `${item.Utilization}%`;
          item.Yield = `${item.Yield}%`;
          let duration = 0;
          data.OperationStatusData[item.DeviceID].forEach((item) => {
            item.color = lampStatus(item.state).color;
            item.left = Math.max(duration / totalTime, 0);
            duration += item.time;
            item.width = item.time / totalTime;
          });
          item.timeline = data.OperationStatusData[item.DeviceID];
        });
        setDevices(data.ProductionQuantityCountData);
        setData({
          ...data.TotalDeviceShiftTime,
          OEE: `${data.OEE}%`,
          Performance: `${data.Performance}%`,
          Utilization: `${data.Utilization}%`,
          Yield: `${data.Yield}%`,
          TodayQuantity: data.TodayQuantity,
        });
      })
      .catch(console.log);
  };

  const columns = [
    {
      Header: <FormattedMessage id="DeviceName" />,
      accessor: "DeviceName",
    },
    {
      Header: <FormattedMessage id="Status" />,
      accessor: "status",
    },
    {
      Header: <FormattedMessage id="OEE" />,
      accessor: "OEE",
    },
    {
      Header: <FormattedMessage id="Utilization" />,
      accessor: "Utilization",
    },
    {
      Header: <FormattedMessage id="Performance" />,
      accessor: "Performance",
    },
    {
      Header: <FormattedMessage id="Yield" />,
      accessor: "Yield",
    },
    {
      Header: <FormattedMessage id="TodayQuantity" />,
      accessor: "TodayQuantity",
    },
  ];

  return (
    <React.Fragment>
      <ProductionBar data={data} />
      <hr />
      <ProductionTable
        columns={columns}
        data={devices}
        start_at={data.start_at}
        end_at={data.end_at}
      />
    </React.Fragment>
  );
}

export const ProductionBar = ({ data }) => {
  const columns = [
    { key: "OEE", color: "green" },
    { key: "Utilization", color: "#3f497d" },
    { key: "Performance", color: "#3f497d" },
    { key: "Yield", color: "#3f497d" },
    { key: "TodayQuantity", color: "orange" },
  ];
  return (
    <div className="d-flex">
      {columns.map(({ key, color }, i) => (
        <div
          className="ai-center flex-column flex-1"
          key={key}
          style={{
            borderRight: i === 4 ? "none" : "1px solid #808080",
          }}
        >
          <b
            style={{
              fontSize: "1.5rem",
              color,
            }}
          >
            {data[key]}
          </b>
          <span style={{ color: "#808080", fontWeight: 500 }}>
            <FormattedMessage id={key} />
          </span>
        </div>
      ))}
    </div>
  );
};

export const ProductionTable = ({
  data,
  start_at,
  end_at,
  tooltip,
  columns,
  hourInterval
}) => {
  let timeArr = [];
  if (start_at && end_at) {

    const start = moment(`${moment().format("YYYY-MM-DD")} 08:00:00`);
    timeArr.push(start.format("HH:mm"));
    for (let i = 1; i < 24 / hourInterval; ++i) {
      timeArr.push(start.add(hourInterval, "h").format("HH:mm"));
    }
  }

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy);
  return (
    <div className="table-area w-100 table">
      <table
        {...getTableProps()}
        className="strike-table w-100"
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, i) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className="text-center"
                  style={{
                    position: "sticky",
                    top: 0,
                    zIndex: 1,
                  }}
                >
                  <span>{column.render("Header")}</span>
                  <span style={{ marginLeft: "5px", fontSize: "14px" }}>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <FaSortDown />
                      ) : (
                        <FaSortUp />
                      )
                    ) : (
                      <FaSort />
                    )}
                  </span>
                </th>
              ))}
              <th className="timeline">
                <ul className="list-unstyled">
                  {timeArr.map((time) => (
                    <li key={time} style={{ minWidth: "120px" }}>
                      <span>{time}</span>
                    </li>
                  ))}
                </ul>
              </th>
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            const { state, color, timeline } = row.original;
            let icon;
            let fontColor = "black";
            if (state === 0 || state === 2 || state === 6 || state === 9) {
              icon = (
                <MdFiberManualRecord
                  color={color}
                  size="20px"
                  style={{ marginRight: "5px" }}
                />
              );
            } else if (state === 1) {
              icon = (
                <MdReport
                  color={color}
                  size="20px"
                  style={{ marginRight: "5px" }}
                />
              );
            } else if (
              state === 3 ||
              state === 5 ||
              state === 7 ||
              state === 8
            ) {
              icon = (
                <BiBoltCircle
                  color={color}
                  size="20px"
                  style={{ marginRight: "5px" }}
                />
              );
            }
            if (state === 0 || state === 9) {
              fontColor = "#cccccc";
            }
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell, i) => (
                  <td
                    {...cell.getCellProps()}
                    style={{ color: fontColor }}
                    className="text-center"
                  >
                    <span>
                      {i === 1 ? icon : null}
                      {cell.render("Cell")}
                    </span>
                  </td>
                ))}
                <td className="timeline" style={{ overflow: "hidden" }}>
                  <div>
                    {timeline.map(
                      (
                        {
                          width,
                          color,
                          status,
                          description,
                          startTime,
                          durationString,
                          left,
                        },
                        i
                      ) =>
                        tooltip ? (
                          <Tooltip
                            key={i}
                            placement="top"
                            title={TooltipContent({
                              status,
                              description,
                              startTime,
                              durationString,
                            })}
                          >
                            <Block
                              style={{
                                width: `${width}%`,
                                backgroundColor: color,
                                position: "absolute",
                                left: `${left}%`,
                                height: "100%",
                              }}
                            />
                          </Tooltip>
                        ) : (
                          <Block
                            key={i}
                            style={{
                              width: `${width}%`,
                              backgroundColor: color,
                              position: "absolute",
                              left: `${left}%`,
                              height: "100%",
                            }}
                          />
                        )
                    )}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const Block = React.forwardRef((props, ref) => <span {...props} ref={ref} />);

const TooltipContent = ({ status, description, startTime, durationString }) => {
  return (
    <div
      className="flex-column"
      style={{
        fontSize: "20px",
        lineHeight: 1.2,
        padding: "5px",
      }}
    >
      <span>
        <FormattedMessage id="DeviceStatus" />：{status}
      </span>
      <span>
        <FormattedMessage id="DowntimeReason" />：{description}
      </span>
      <span>
        <FormattedMessage id="StartTime" />：{startTime}
      </span>
      <span>
        <FormattedMessage id="TimeDuration" />：{durationString}
      </span>
    </div>
  );
};

export { IndexProductionTable };
