import React, { useMemo } from "react";
import { ColumnFilter, StickyTable } from "../common";

export function ParameterTable({ data, columns }) {
  const tableColumns = useMemo(
    () =>
      columns
        .filter(({ id }) => data.some((e) => e[id]))
        .map(({ name, id }) => ({
          Header: name,
          accessor: id,
          Filter: ({ column }) => ColumnFilter(column, name),
        })),
    [data, columns]
  );

  return (
    <StickyTable
      columns={tableColumns}
      data={data}
      sticky={1}
      showDifference={true}
    />
  );
}
