import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import { FormattedMessage, useIntl } from "react-intl";
import {
  NewButton,
  DeleteModal,
  DateRadio,
  SingleDropdownPicker,
  RangeDatePicker,
  handleError,
} from "../../component/common";
import { ManagementTabs } from "../../component/management/Management";
import {
  OrderTable,
  ImportModal,
  UnproductiveModal,
  InputModal,
  ConfirmModal,
  MultiDeleteModal,
} from "../../component/management/order";
import {
  baseURL,
  ApiOrder_GetOrderById,
  ApiOrder_DeleteOrder,
  ApiOrder_DownloadOrderDataExample,
} from "../../api.js";
import Context from "../../context";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function Management({ checkEditAuth }) {
  const intl = useIntl();
  const { factory } = useContext(Context);
  const [date, setDate] = useState({
    startDate: moment().subtract(30, "d").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  });
  const [sort, setSort] = useState("date");
  const [dataInterval, setDataInterval] = useState("thirty");
  const [month, setMonth] = useState(0);
  const [modal, setModal] = useState("");
  const [confirmModal, setConfirmModal] = useState(false);
  const [select, setSelect] = useState({ orderInfo: [] });
  const [groupList, setGroupList] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(false);
  }, [modal]);

  useEffect(() => {
    if (sort === "date") {
      setDataInterval("thirty");
    } else {
      setMonth(moment().month());
    }
  }, [sort]);

  useEffect(() => {
    setDate({
      startDate: moment().month(month).startOf("month").format("YYYY-MM-DD"),
      endDate: moment().month(month).endOf("month").format("YYYY-MM-DD"),
    });
  }, [month]);

  useEffect(() => {
    if (dataInterval === "seven") {
      setDate({
        startDate: moment().subtract(7, "d").format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
      });
    } else if (dataInterval === "thirty") {
      setDate({
        startDate: moment().subtract(30, "d").format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
      });
    }
  }, [dataInterval]);

  const pickDateRange = (startDate, endDate) => {
    setDate((prev) => ({ ...prev, startDate, endDate }));
    setDataInterval("");
  };

  const showModal = (
    modal,
    item = { orderInfo: [{ material_id: [] }], auto_finish: true }
  ) => {
    if (!checkEditAuth()) {
      return;
    }

    if (modal === "edit" || modal === "copy" || modal === "split") {
      ApiOrder_GetOrderById({
        factory_id: factory,
        id: item.id,
      }).then((res) => {
        const order = res.data[0];
        order.order_id = item.id;
        if (order.order_class == 0) {
          order.orderInfo = order.id.map((id, i) => ({
            id,
            order_num: order.order_num_name[i],
            product_id: order.product_id[i].toString(),
            material_id: order.material_id[i],
            mold_id: order.mold_id[i],
            cavity_amount: order.cavity_amount[i],
            expect_quantity: order.expect_quantity[i],
          }));
          if (order.auto_finish) {
            order.auto_finish = order.auto_finish === 1;
          }
        } else {
          order.expected_time = order.ideal_total_time;
          modal = `unproductive_${modal}`;
        }
        setSelect(order);
        setModal(modal);
      });
    } else {
      item.order_id = item.id;
      item.orderInfo = [{ material_id: [] }];
      setSelect(item);
      setModal(modal);
    }
  };

  const showComfirmModal = (data) => {
    setSelect(data);
    setConfirmModal(true);
  };

  const onHide = () => {
    setModal("");
    setConfirmModal(false);
    setSelect({ orderInfo: [] });
  };

  const deleteData = () => {
    ApiOrder_DeleteOrder({
      account: localStorage.getItem("account"),
      factory_id: factory,
      order_id: select.order_id,
    })
      .then(onHide)
      .catch((err) => handleError(err, intl));
  };

  const downloadDataExample = () => {
    ApiOrder_DownloadOrderDataExample()
      .then((res) => window.open(`${baseURL}/${res.data}`))
      .catch((err) => handleError(err, intl));
  };

  return (
    <div>
      <ManagementTabs />
      <div className="page-content shadow-lg">
        <div className="ai-stretch mb-3">
          <DateRadio
            list={[
              {
                label: <FormattedMessage id="Interval" />,
                value: "date",
              },
              { label: <FormattedMessage id="Month" />, value: "month" },
            ]}
            value={sort}
            onChange={(e) => setSort(e.target.value)}
          />
          {sort === "date" ? (
            <DateRadio
              list={[
                {
                  label: <FormattedMessage id="LastSevenDays" />,
                  value: "seven",
                },
                {
                  label: <FormattedMessage id="LastThirtyDays" />,
                  value: "thirty",
                },
              ]}
              value={dataInterval}
              onChange={(e) => setDataInterval(e.target.value)}
              style={{ margin: "0 20px" }}
            />
          ) : (
            <DateRadio
              list={[
                {
                  label: <FormattedMessage id="LastMonth" />,
                  value: moment().subtract(1, "months").month(),
                },
                {
                  label: <FormattedMessage id="ThisMonth" />,
                  value: moment().month(),
                },
              ]}
              value={month}
              onChange={(e) => setMonth(parseInt(e.target.value))}
              style={{ margin: "0 20px" }}
            />
          )}
          {sort === "date" ? (
            <RangeDatePicker
              startDate={date.startDate}
              endDate={date.endDate}
              timePicker={false}
              onApply={pickDateRange}
            />
          ) : (
            <SingleDropdownPicker
              style={{ width: "100px" }}
              value={month}
              list={months.map((id, i) => ({
                label: intl.formatMessage({ id }),
                value: i,
              }))}
              onApply={setMonth}
            />
          )}
        </div>
        <div className="ai-center jc-space-between">
          <h4 className="m-0">
            <b>
              <FormattedMessage id="OrderList" />
            </b>
          </h4>
          <div className="ai-center">
            <button
              className="center btn-gray"
              style={{ marginRight: "10px" }}
              onClick={() => showModal("multiDelete")}
            >
              <FormattedMessage id="BatchDelete" />
            </button>
            <button
              className="center btn-gray"
              style={{ marginRight: "10px" }}
              onClick={downloadDataExample}
            >
              <FormattedMessage id="DownloadExample" />
            </button>
            <button
              className="center btn-gray"
              style={{ marginRight: "10px" }}
              onClick={() => showModal("import")}
            >
              {intl.formatMessage(
                { id: "Import" },
                { name: intl.formatMessage({ id: "Order" }) }
              )}
            </button>
            <NewButton
              style={{ marginRight: "10px" }}
              onClick={() => showModal("unproductive_new")}
              text={intl.formatMessage(
                { id: "Create" },
                { name: intl.formatMessage({ id: "UnproductiveOrder" }) }
              )}
            />
            <NewButton
              onClick={() => showModal("new")}
              text={intl.formatMessage(
                { id: "Create" },
                { name: intl.formatMessage({ id: "Order" }) }
              )}
            />
          </div>
        </div>
        <hr />
        <OrderTable
          showModal={showModal}
          date={date}
          modal={modal}
          groupList={groupList}
        />
      </div>

      <ImportModal onHide={onHide} modal={modal} />
      <InputModal
        callback={showComfirmModal}
        onHide={onHide}
        modal={modal}
        data={select}
        open={open}
        setOpen={setOpen}
      />
      <UnproductiveModal onHide={onHide} modal={modal} data={select} />
      <ConfirmModal
        modal={modal}
        data={select}
        show={confirmModal}
        onCancel={() => setConfirmModal(false)}
        onHide={onHide}
      />
      <DeleteModal
        show={modal === "delete"}
        onHide={onHide}
        callback={deleteData}
        name={
          select.order_group_name
            ? select.order_group_name
            : select.order_num_name
        }
        type="Order"
      />
      <MultiDeleteModal onHide={onHide} modal={modal} date={date} />
    </div>
  );
}

export default Management;
