import React, { useContext, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { MdCreate, MdDelete } from "react-icons/md";
import { InputModalBase } from "./Management";
import { ColumnFilter, Table } from "../common";
import {
  ApiManagement_unproductiveOrder,
  ApiManagement_unproductiveOrder_add,
  ApiManagement_unproductiveOrder_update,
} from "../../api";
import Context from "../../context";

export function UnproductiveTable({ modal, showModal, groupList }) {
  const { factory } = useContext(Context);
  const [data, setData] = useState([]);
  useEffect(() => {
    if (!modal) {
      ApiManagement_unproductiveOrder({
        factory_id: factory,
      })
        .then((res) => {
          res.data.forEach((item) => {
            item.order_class = groupList.find(
              ({ id }) => id === item.classify_id
            )?.classify;
          });
          setData(res.data);
        })
        .catch(console.log);
    }
  }, [modal, groupList]);

  const columns = [
    {
      Header: <FormattedMessage id="description" /> /*描述*/,
      accessor: "description",
      Filter: ({ column }) => ColumnFilter(column, "description"),
    },
    {
      Header: <FormattedMessage id="order_class" />,
      accessor: "order_class",
      Filter: ({ column }) => ColumnFilter(column, "order_class"),
    },
    {
      Header: <FormattedMessage id="Edit" />,
      accessor: "editor",
      Filter: "",
    },
  ];

  const display = data.map((item) => ({
    ...item,
    editor: (
      <span>
        <MdCreate
          className="icon-btn"
          size="20px"
          onClick={() => showModal("edit", item)}
        />
        <MdDelete
          style={{ marginLeft: "10px" }}
          className="icon-btn"
          size="20px"
          onClick={() => showModal("delete", item)}
        />
      </span>
    ),
  }));

  return <Table columns={columns} data={display} />;
}

export const InputModal = ({ modal, onHide, data, groupList }) => {
  const intl = useIntl();
  const { factory } = useContext(Context);
  const [select, setSelect] = useState({});

  useEffect(() => {
    setSelect(JSON.parse(JSON.stringify(data)));
  }, [data]);

  const submit = () => {
    return new Promise((resolve, reject) => {
      let req = {
        account: localStorage.getItem("account"),
        factory_id: factory,
        ...select,
        classify_id: parseInt(select.classify_id),
      };
      if (modal === "new") {
        ApiManagement_unproductiveOrder_add(req).then(resolve).catch(reject);
      } else {
        req.id = select.id;
        ApiManagement_unproductiveOrder_update(req).then(resolve).catch(reject);
      }
    });
  };

  const columns = [
    {
      id: "classify_id",
      type: "select",
      required: true,
      options: groupList.map(({ id, classify }) => ({
        label: classify,
        value: id,
      })),
    },
    { id: "description", type: "text", required: true },
  ];

  const title = intl.formatMessage(
    { id: modal === "new" ? "Create" : "EditThe" },
    { name: intl.formatMessage({ id: "UnproductiveOrderReason" }) }
  );

  return (
    <InputModalBase
      show={modal === "new" || modal === "edit"}
      onHide={onHide}
      title={title}
      columns={columns}
      select={select}
      setSelect={setSelect}
      submit={submit}
    />
  );
};
