import React, { useContext, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { FormattedMessage, useIntl } from "react-intl";
import moment from "moment";
import {
  Tabs,
  LinkBar,
  DateRadio,
  SingleDatePicker,
  RangeDatePicker,
  SingleDropdownPicker,
} from "../common";
import { ApiSysGetMachineGroupDataList } from "../../api";
import Context from "../../context";

function AnalysisTabs({ tab }) {
  let list = [
    { to: "/pages/analysis/oee", name: "OEEAnalysis" },
    { to: "/pages/analysis/amount", name: "QuantityAnalysis" },
    { to: "/pages/analysis/parameter", name: "MonitoringParameter" },
    { to: "/pages/analysis/schedule", name: "OrderSchedule" },
    { to: "/pages/analysis/production", name: "ProductionOverview" },
  ];
  return <Tabs list={list} tab={tab} />;
}

function AnalysisMenu({ tab }) {
  const list = [
    { to: "/pages/analysis/oee/overview", name: "Overview" },
    { to: "/pages/analysis/oee/order", name: "Order" },
    { to: "/pages/analysis/oee/production", name: "ProductionAnalysis" },
    { to: "/pages/analysis/oee/defective", name: "Defect" },
    { to: "/pages/analysis/oee/downtime", name: "DowntimeReason" },
  ];
  return <LinkBar list={list} tab={tab} />;
}

export function AmountMenu({ tab }) {
  const list = [
    { to: "/pages/analysis/amount/interval", name: "TimeSection" },
    { to: "/pages/analysis/amount/daily", name: "DailyReport" },
  ];
  return <LinkBar list={list} tab={tab} />;
}

export function ParameterMenu({ tab }) {
  const list = [
    { to: "/pages/analysis/parameter/control", name: "ParameterMonitoring" },
    { to: "/pages/analysis/parameter/process", name: "ProcessSetting" },
  ];
  return <LinkBar list={list} tab={tab} />;
}

function DateBar({ sort, setSort, setDate, date }) {
  const today = moment().format("YYYY-MM-DD");
  const yesterday = moment().subtract(1, "d").format("YYYY-MM-DD");

  useEffect(() => {
    if (sort === "date") {
      setDate({ startDate: today });
    } else if (sort === "interval") {
      setDate({
        startDate: moment().format("YYYY-MM-DD HH:mm"),
        endDate: moment().format("YYYY-MM-DD HH:mm"),
      });
    } else if (sort === "dateTime") {
      setDate({
        startDate: today,
        endDate: today,
        startTime: "00:00",
        endTime: "24:00",
      });
    }
  }, [sort]);

  const pickDate = (name, date) => {
    setDate((prev) => ({ ...prev, [name]: date }));
  };

  const pickDateRange = (startDate, endDate) => {
    setDate((prev) => ({ ...prev, startDate: startDate, endDate: endDate }));
  };

  const timeArr = [];
  for (let i = 0; i <= 24; ++i) {
    let str = i.toString();
    if (str.length < 2) {
      str = `0${str}`;
    }
    timeArr.push(`${str}:00`);
  }

  return (
    <div className="ai-stretch">
      <DateRadio
        style={{ marginRight: "20px" }}
        list={[
          { label: <FormattedMessage id="Date" />, value: "date" },
          { label: <FormattedMessage id="Interval" />, value: "interval" },
          {
            label: <FormattedMessage id="DatesTimeQuantum" />,
            value: "dateTime",
          },
        ]}
        value={sort}
        onChange={(e) => setSort(e.currentTarget.value)}
      />
      {sort === "date" ? (
        <React.Fragment>
          <DateRadio
            style={{ marginRight: "20px" }}
            list={[
              { label: <FormattedMessage id="Today" />, value: today },
              { label: <FormattedMessage id="Yesterday" />, value: yesterday },
            ]}
            value={date.startDate}
            onChange={(e) => setDate({ startDate: e.currentTarget.value })}
          />
          <SingleDatePicker
            name="startDate"
            date={date.startDate}
            onApply={pickDate}
          />
        </React.Fragment>
      ) : sort === "interval" ? (
        <RangeDatePicker
          startDate={date.startDate}
          endDate={date.endDate}
          timePicker={true}
          onApply={pickDateRange}
        />
      ) : (
        <React.Fragment>
          <RangeDatePicker
            style={{ marginRight: "20px" }}
            startDate={date.startDate}
            endDate={date.endDate}
            timePicker={false}
            onApply={pickDateRange}
          />
          <SingleDropdownPicker
            value={date.startTime}
            list={timeArr.map((item) => ({ label: item, value: item }))}
            onApply={(startTime) => setDate((prev) => ({ ...prev, startTime }))}
          />
          <SingleDropdownPicker
            value={date.endTime}
            list={timeArr.map((item) => ({ label: item, value: item }))}
            onApply={(endTime) => setDate((prev) => ({ ...prev, endTime }))}
          />
        </React.Fragment>
      )}
    </div>
  );
}

export function SingleDeviceList({ setDeviceID, deviceID }) {
  const intl = useIntl();
  const { factory } = useContext(Context);
  const [groupList, setGroupList] = useState([]);
  const [deviceList, setDeviceList] = useState([]);

  useEffect(() => {
    ApiSysGetMachineGroupDataList({ factory_id: factory }).then((res) => {
      setGroupList(res.data);
      if (
        localStorage.getItem("analysisDevice") &&
        localStorage.getItem("analysisGroup")
      ) {
        const group = res.data.find(
          ({ GroupID }) =>
            GroupID === parseInt(localStorage.getItem("analysisGroup"))
        );
        if (group) {
          setDeviceList(group.DeviceList);
          const device = group.DeviceList.find(
            ({ DeviceID }) =>
              DeviceID === parseInt(localStorage.getItem("analysisDevice"))
          );
          pickDevice(device?.DeviceID);
        }
      }
    });
  }, []);

  const pickDevice = (deviceID) => {
    localStorage.setItem("analysisDevice", deviceID);
    setDeviceID(deviceID);
  };

  const pickGroup = ({ GroupID, DeviceList }) => {
    localStorage.setItem("analysisGroup", GroupID);
    setDeviceList(DeviceList);
  };

  const back = () => {
    localStorage.removeItem("analysisDevice");
    setDeviceList([]);
  };

  return (
    <div className="col-md-4 col-lg-3 col-xl-2">
      <h4>
        <b>
          {intl.formatMessage(
            { id: "PickA" },
            { name: intl.formatMessage({ id: "Device" }) }
          )}
        </b>
      </h4>
      <div className="analysis-list">
        <ul className="list-group list-unstyled mb-0">
          {deviceList.length === 0
            ? groupList.map(({ GroupID, Title, DeviceList }) => (
                <li
                  className="list-group-item"
                  key={GroupID}
                  onClick={() => pickGroup({ GroupID, DeviceList })}
                >
                  <span>{Title}</span>
                </li>
              ))
            : deviceList.map(({ DeviceID, DeviceName }) => (
                <li
                  className={`list-group-item ${
                    DeviceID === deviceID ? "checked" : ""
                  }`}
                  key={DeviceID}
                  onClick={() => pickDevice(DeviceID)}
                >
                  <span>{DeviceName}</span>
                </li>
              ))}
          {deviceList.length === 0 ? null : (
            <li className="list-group-item" onClick={back}>
              <span>
                <FormattedMessage id="Prev" />
              </span>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
}

function MultiDeviceList({ device, setDevice }) {
  const { factory } = useContext(Context);
  const intl = useIntl();
  const [groupList, setGroupList] = useState([]);
  const [deviceList, setDeviceList] = useState([]);
  const checkedAll = deviceList.every(
    ({ DeviceID }) => device.findIndex((e) => e.DeviceID === DeviceID) > -1
  );

  useEffect(() => {
    ApiSysGetMachineGroupDataList({ factory_id: factory }).then((res) => {
      setGroupList(res.data);
    });
  }, []);

  const _handleCheck = (item) => {
    if (item === "all") {
      if (checkedAll) {
        setDevice([]);
      } else {
        setDevice(deviceList);
      }
    } else {
      let temp = device.slice();
      const index = device.findIndex(
        ({ DeviceID }) => DeviceID === item.DeviceID
      );
      if (index > -1) {
        temp.splice(index, 1);
      } else {
        temp.push(item);
      }
      setDevice(temp);
    }
  };

  return (
    <div className="col-md-4 col-lg-3 col-xl-2">
      <h4>
        <b>
          {intl.formatMessage(
            { id: "PickA" },
            { name: intl.formatMessage({ id: "Device" }) }
          )}
        </b>
      </h4>
      <div className="analysis-list">
        <ul className="list-group list-unstyled mb-0">
          {deviceList.length === 0 ? (
            groupList.map(({ GroupID, Title, DeviceList }) => (
              <li
                className="list-group-item"
                key={GroupID}
                onClick={() => setDeviceList(DeviceList)}
              >
                <span>{Title}</span>
              </li>
            ))
          ) : (
            <React.Fragment>
              <li
                className={`list-group-item ${checkedAll ? "checked" : ""}`}
                onClick={() => _handleCheck("all")}
              >
                <Form.Check
                  type="checkbox"
                  value="all"
                  label={intl.formatMessage({ id: "PickAll" })}
                  checked={checkedAll}
                  readOnly
                />
              </li>
              {deviceList.map((item) => {
                const isChecked =
                  device.findIndex(
                    ({ DeviceID }) => DeviceID === item.DeviceID
                  ) > -1;
                return (
                  <li
                    className={`list-group-item ${isChecked ? "checked" : ""}`}
                    key={item.DeviceID}
                    onClick={() => _handleCheck(item)}
                  >
                    <Form.Check
                      type="checkbox"
                      value={item.DeviceID}
                      label={item.DeviceName}
                      checked={isChecked}
                      readOnly
                    />
                  </li>
                );
              })}
            </React.Fragment>
          )}
          {deviceList.length === 0 ? null : (
            <li className="list-group-item" onClick={() => setDeviceList([])}>
              <span>{intl.formatMessage({ id: "Prev" })}</span>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
}

const handleRequest = (req, sort, date) => {
  if (sort === "date") {
    req.CheckDateStart = date.startDate;
  } else {
    const startDate = moment(date.startDate);
    const endDate = moment(date.endDate);
    req.CheckDateStart = startDate.format("YYYY-MM-DD");
    req.CheckDateEnd = endDate.format("YYYY-MM-DD");
    if (sort === "interval") {
      req.CheckTimeStart = startDate.format("HH:mm:ss");
      req.CheckTimeEnd = endDate.format("HH:mm:ss");
      req.checkModeCondition = 1;
    } else if (sort === "dateTime") {
      req.CheckTimeStart = `${date.startTime}:00`;
      req.CheckTimeEnd = `${date.endTime}:00`;
      req.checkModeCondition = 2;
    }
  }
  return req;
};

export { AnalysisTabs, AnalysisMenu, DateBar, MultiDeviceList, handleRequest };
