import React, { useState, useEffect, useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { MdCreate } from "react-icons/md";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { InputModalBase } from "../management/Management";
import { Table, LinkBar } from "../common";
import {
  apiGetMoldBroadcastList,
  apiGetFactoryBroadcastList,
  apiGetBadReasonBroadcastList,
  apiEditMoldBroadcast,
  apiEditBadReasonBroadcast,
  putBroadcastToken,
  getFactory,
} from "../../api";
import Context from "../../context";

function BroadcastMenu({ tab }) {
  const list = [
    { to: "/pages/system/notify/factory", name: "Factory" },
    { to: "/pages/system/notify/mold", name: "Mold" },
    { to: "/pages/system/notify/defective", name: "Defective" },
  ];
  return <LinkBar list={list} tab={tab} />;
}

function FactoryTable() {
  const { factory } = useContext(Context);
  const intl = useIntl();
  const [data, setData] = useState([]);
  const [modal, setModal] = useState(false);
  const [select, setSelect] = useState({});
  const [factoryList, setFactoryList] = useState([]);

  useEffect(() => {
    getFactory().then((res) => setFactoryList(res.data));
  }, []);

  useEffect(() => {
    if (!modal) {
      apiGetFactoryBroadcastList({ factory_id: factory }).then((res) => {
        res.data.forEach((item) => {
          item.factory_name = factoryList.find(
            ({ id }) => id === item.factory_id
          )?.name;
        });
        setData(res.data);
      });
    }
  }, [modal, factoryList]);

  const showModal = (item) => {
    setModal(true);
    setSelect(item);
  };

  const onHide = () => {
    setModal(false);
    setSelect({});
  };

  const submit = () => {
    return new Promise((resolve, reject) => {
      putBroadcastToken(
        select.id,
        { token: select.line_token },
        { factory_id: factory }
      )
        .then(resolve)
        .catch(reject);
    });
  };

  const columns = [
    {
      Header: <FormattedMessage id="FactoryName" /> /*工廠代碼*/,
      accessor: "factory_name",
      Filter: "",
    },
    {
      Header: <FormattedMessage id="Token" /> /*工廠代碼*/,
      accessor: "line_token",
      Filter: "",
    },
    {
      Header: <FormattedMessage id="Edit" />,
      accessor: "editor",
      Filter: "",
    },
  ];

  const display = data.map((item) => ({
    ...item,
    editor: (
      <span>
        <MdCreate
          className="icon-btn"
          size="20px"
          onClick={() => showModal(item)}
        />
      </span>
    ),
  }));

  return (
    <>
      <Table columns={columns} data={display} />
      <InputModalBase
        show={modal}
        onHide={onHide}
        title={intl.formatMessage(
          { id: "EditThe" },
          { name: intl.formatMessage({ id: "Notify" }) }
        )}
        columns={[{ id: "line_token", type: "text" }]}
        select={select}
        setSelect={setSelect}
        submit={submit}
      />
    </>
  );
}

function MoldTable() {
  const { factory } = useContext(Context);
  const intl = useIntl();
  const [data, setData] = useState([]);
  const [modal, setModal] = useState(false);
  const [select, setSelect] = useState({});

  useEffect(() => {
    if (!modal) {
      loadData();
    }
  }, [modal]);

  const loadData = () => {
    apiGetMoldBroadcastList({
      factory_id: factory,
    })
      .then((res) => setData(res.data.msg))
      .catch(console.log);
  };

  const showModal = (item) => {
    setModal(true);
    setSelect(item);
  };

  const onHide = () => {
    setModal(false);
    setSelect({});
  };

  const onChange = ({ id, notify_time, is_notify }) => {
    apiEditMoldBroadcast({
      mold_id: id,
      factory_id: factory,
      notify_time,
      is_notify: !is_notify ? 1 : 0,
    }).then(loadData);
  };

  const submit = () => {
    return new Promise((resolve, reject) => {
      apiEditMoldBroadcast({
        ...select,
        mold_id: select.id,
        factory_id: factory,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  const columns = [
    {
      Header: <FormattedMessage id="mold_num" />,
      accessor: "mold_num",
      Filter: "",
    },
    {
      Header: <FormattedMessage id="ControlTime" />,
      accessor: "notify_time",
      Filter: "",
    },
    {
      Header: <FormattedMessage id="PushNotification" />,
      accessor: "is_notify",
      Filter: "",
    },
    {
      Header: <FormattedMessage id="Edit" />,
      accessor: "editor",
      Filter: "",
    },
  ];

  const display = data.map((item) => ({
    ...item,
    is_notify: (
      <input
        type="checkbox"
        onChange={() => onChange(item)}
        checked={item.is_notify}
        className="mr-2"
      />
    ),
    editor: (
      <span>
        <MdCreate
          className="icon-btn"
          size="20px"
          onClick={() => showModal(item)}
        />
      </span>
    ),
  }));

  return (
    <>
      <Table columns={columns} data={display} />
      <InputModalBase
        show={modal}
        onHide={onHide}
        title={intl.formatMessage({ id: "Edit" })}
        columns={[{ id: "notify_time", type: "number" }]}
        select={select}
        setSelect={setSelect}
        submit={submit}
      />
    </>
  );
}

function DefectiveTable() {
  const { factory } = useContext(Context);
  const intl = useIntl();
  const [data, setData] = useState([]);
  const [modal, setModal] = useState(false);
  const [select, setSelect] = useState({});

  useEffect(() => {
    if (!modal) {
      loadData();
    }
  }, [modal]);

  const loadData = () => {
    apiGetBadReasonBroadcastList({
      factory_id: factory,
    })
      .then((res) => setData(res.data.msg))
      .catch(console.log);
  };

  const showModal = (item) => {
    setModal(true);
    setSelect(item);
  };

  const onHide = () => {
    setModal(false);
    setSelect({});
  };

  const onChange = ({ id, notify_num, is_notify }) => {
    apiEditBadReasonBroadcast({
      bad_reason_id: id,
      factory_id: factory,
      notify_num,
      is_notify: !is_notify ? 1 : 0,
    }).then(loadData);
  };

  const submit = () => {
    return new Promise((resolve, reject) => {
      apiEditBadReasonBroadcast({
        ...select,
        bad_reason_id: select.id,
        factory_id: factory,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  const columns = [
    {
      Header: <FormattedMessage id="description" />,
      accessor: "description",
      Filter: "",
    },
    {
      Header: <FormattedMessage id="ControlQuantity" />,
      accessor: "notify_num",
      Filter: "",
    },
    {
      Header: <FormattedMessage id="PushNotification" />,
      accessor: "is_notify",
      Filter: "",
    },
    {
      Header: <FormattedMessage id="Edit" />,
      accessor: "editor",
      Filter: "",
    },
  ];

  const display = data.map((item) => ({
    ...item,
    is_notify: (
      <input
        type="checkbox"
        onChange={() => onChange(item)}
        checked={item.is_notify}
        className="mr-2"
      />
    ),
    editor: (
      <span>
        <MdCreate
          className="icon-btn"
          size="20px"
          onClick={() => showModal(item)}
        />
      </span>
    ),
  }));

  return (
    <>
      <Table columns={columns} data={display} />
      <InputModalBase
        show={modal}
        onHide={onHide}
        title={intl.formatMessage({ id: "Edit" })}
        columns={[{ id: "notify_num", type: "number" }]}
        select={select}
        setSelect={setSelect}
        submit={submit}
      />
    </>
  );
}

export { BroadcastMenu, FactoryTable, MoldTable, DefectiveTable };
