import React, { useState, useEffect, useContext } from 'react';
import { FormattedMessage } from "react-intl";
import moment from 'moment'
import { ColumnFilter, Table } from '../common';
import { ApiEmployee_GetEmployeeLog } from '../../api.js';
import Context from "../../context";

function LogTable() {
    const [data, setData] = useState([]);
	const { factory } = useContext(Context);

    useEffect(() => {
        ApiEmployee_GetEmployeeLog({ factory_id: factory })
            .then(res => setData(res.data.map((item) => ({
                ...item,
                start_time: moment(item.start_time).format('LLL'),
                end_time: moment(item.end_time).format('LLL')
            }))))
    }, []);

    const columns = [
        {
            Header: <FormattedMessage id="DeviceName" />/*'機台編號'*/,
            accessor: 'device_name',
            Filter: ({ column }) => ColumnFilter(column, 'DeviceName')
        },
        {
            Header: <FormattedMessage id="DeviceNumber" />/*'機台編號'*/,
            accessor: 'device_num',
            Filter: ({ column }) => ColumnFilter(column, 'DeviceNumber')
        },
        {
            Header: <FormattedMessage id="WorkerNumber" />,
            accessor: 'employee_num',
            Filter: ({ column }) => ColumnFilter(column, 'WorkerNumber')
        },
        {
            Header: <FormattedMessage id="WorkerName" />,
            accessor: 'employee_name',
            disableSortBy: true,
            Filter: ({ column }) => ColumnFilter(column, 'WorkerName')
        },
        {
            Header: <FormattedMessage id="Work" />,
            accessor: 'work_item_name',
            Filter: ({ column }) => ColumnFilter(column, 'Work')
        },
        {
            Header: <FormattedMessage id="StartTime" />/*開始時間*/,
            accessor: 'start_time',
            Filter: ''
        },
        {
            Header: <FormattedMessage id="EndTime" />/*結束時間*/,
            accessor: 'end_time',
            Filter: ''
        },
    ];

    return (
        <Table columns={columns} data={data} />
    );
}

export { LogTable }