import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import Form from "react-bootstrap/Form";
import { useIntl } from "react-intl";
import { ManagementTabs } from "../../component/management/Management";
import { HolidayTable, InputModal } from "../../component/management/Holiday";
import { NewButton, DeleteModal, handleError } from "../../component/common";
import { ApiManagement_delete_holiday, ApiManagement_holiday } from "../../api";
import Context from "../../context";

function ManagementHoliday({ checkEditAuth }) {
  const [modal, setModal] = useState();
  const [select, setSelect] = useState({});
  const [year, setYear] = useState(moment().get("year"));
  const [data, setData] = useState([]);
  const intl = useIntl();
  const { factory } = useContext(Context);

  useEffect(() => {
    if (!modal) {
      ApiManagement_holiday({
        factory_id: factory,
        year,
      })
        .then((res) => setData(res.data))
        .catch(console.log);
    }
  }, [modal, year]);

  const showModal = (modal, item = {}) => {
    if (!checkEditAuth()) {
      return;
    }
    if (modal === "edit") {
      item.is_holiday = item.is_holiday.toString();
    }
    setSelect(item);
    setModal(modal);
  };

  const onHide = () => {
    setModal();
    setSelect({});
  };

  const deleteData = () => {
    ApiManagement_delete_holiday({
      account: localStorage.getItem("account"),
      factory_id: factory,
      id: select.id,
    })
      .then(onHide)
      .catch((err) => handleError(err, intl));
  };

  const thisYear = moment().get("year");
  return (
    <div>
      <ManagementTabs tab="6" />
      <div className="page-content shadow-lg">
        <div className="jc-space-between ai-center">
          <Form.Group controlId="year" className="m-0">
            <Form.Control
              as="select"
              value={year}
              onChange={(e) => setYear(e.target.value)}
            >
              <option>{thisYear}</option>
              <option>{thisYear + 1}</option>
              <option>{thisYear + 2}</option>
              <option>{thisYear + 3}</option>
            </Form.Control>
          </Form.Group>
          <NewButton
            onClick={() => showModal("new")}
            text={intl.formatMessage(
              { id: "Create" },
              { name: intl.formatMessage({ id: "is_holiday" }) }
            )}
          />
        </div>
        <hr />
        <HolidayTable showModal={showModal} data={data} />
      </div>

      <InputModal
        modal={modal}
        onHide={onHide}
        data={select}
        list={data}
      />

      <DeleteModal
        show={modal === "delete"}
        onHide={onHide}
        callback={deleteData}
        name={select.holiday}
        type="Date"
      />
    </div>
  );
}

export default ManagementHoliday;
