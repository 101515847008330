import React, { useState } from "react";
import { useIntl } from "react-intl";
import { RootTabs } from "../../component/root/Root";
import { GroupTable, InputModal } from "../../component/root/Group";
import { NewButton, DeleteModal, handleError } from "../../component/common";
import { deleteGroup } from "../../api.js";

export default function Group({ checkEditAuth }) {
  const [modal, setModal] = useState();
  const [select, setSelect] = useState({});
  const intl = useIntl();

  const showModal = (modal, item = {}) => {
    if (!checkEditAuth()) {
      return;
    }
    let photo = {};
    if (item.photo_path) {
      const arr = item.photo_path.split("/");
      photo.name = arr[arr.length - 1];
    }
    setSelect({ ...item, photo });
    setModal(modal);
  };

  const onHide = () => {
    setModal();
    setSelect({});
  };

  const deleteData = () => {
    deleteGroup({ id: select.id })
      .then(onHide)
      .catch((err) => handleError(err, intl));
  };

  return (
    <div>
      <RootTabs tab="1" />
      <div className="page-content shadow-lg">
        <div className="jc-end">
          <NewButton
            onClick={() => showModal("new")}
            text={intl.formatMessage(
              { id: "Create" },
              { name: intl.formatMessage({ id: "Group" }) }
            )}
          />
        </div>
        <hr />
        <GroupTable showModal={showModal} modal={modal} />
      </div>

      <InputModal modal={modal} onHide={onHide} data={select} />

      <DeleteModal
        show={modal === "delete"}
        onHide={onHide}
        name={select.name}
        type="Group"
        callback={deleteData}
      />
    </div>
  );
}
