import React, { useState, useEffect, useContext } from 'react';
import { FormattedMessage } from "react-intl";
import { MdCreate, MdDelete } from 'react-icons/md'
import { ColumnFilter, Table } from '../common';
import { ApiEmployee_GetWorkList } from "../../api";
import Context from "../../context";

function WorkTable({ showModal, modal }) {
    const [data, setData] = useState([]);
	const { factory } = useContext(Context);

    useEffect(() => {
        if (!modal) {
            ApiEmployee_GetWorkList({ factory_id: factory })
                .then(res => setData(res.data));
        }
    }, [modal]);

    const columns = [
        {
            Header: <FormattedMessage id="Classification" />,
            accessor: 'classify',
            Filter: ({ column }) => ColumnFilter(column, 'Classification')
        },
        {
            Header: <FormattedMessage id="WorkName" />,
            accessor: 'work_item_name',
            Filter: ({ column }) => ColumnFilter(column, 'WorkName')
        },
        {
            Header: <FormattedMessage id="Edit" />,
            accessor: 'editor',
            Filter: ''
        }
    ];

    const display = data.map(item => ({
        ...item,
        editor:
            <span>
                <MdCreate className="icon-btn" size="20px" onClick={() => showModal('edit', item)} />
                <MdDelete style={{ marginLeft: '10px' }} className="icon-btn" size="20px" onClick={() => showModal('delete', item)} />
            </span>
    }));

    return (
        <Table columns={columns} data={display} />
    );
}

export { WorkTable }