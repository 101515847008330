import React, { useState, useContext } from "react";
import { useIntl } from "react-intl";
import moment from "moment";
import { NewButton, DeleteModal, handleError } from "../../component/common";
import { ManagementTabs } from "../../component/management/Management";
import {
  MoldTable,
  InputModal,
  ImportModal,
} from "../../component/management/Mold";
import {
  baseURL,
  ApiManagement_delete_mold,
  ApiManagement_export_mold,
  ApiManagement_download_mold_dataExample,
} from "../../api.js";
import Context from "../../context";

function ManagementMold({ checkEditAuth }) {
  const intl = useIntl();
  const { factory } = useContext(Context);
  const [modal, setModal] = useState();
  const [select, setSelect] = useState({});

  const showModal = async (modal, item = {}) => {
    if (!checkEditAuth()) {
      return;
    }
    if (modal === "edit") {
      const { maintainence_last, purchase_date, disable_date, photo_path } =
        item;
      let photo = {};
      if (photo_path) {
        const arr = photo_path.split("/");
        photo.name = arr[arr.length - 1];
      }
      setSelect({
        ...item,
        maintainence_last: maintainence_last
          ? moment(maintainence_last).format("YYYY-MM-DD")
          : "",
        purchase_date: purchase_date
          ? moment(purchase_date).format("YYYY-MM-DD")
          : "",
        disable_date: disable_date
          ? moment(disable_date).format("YYYY-MM-DD")
          : "",
        photo,
      });
    } else {
      setSelect(item);
    }
    setModal(modal);
  };

  const onHide = () => {
    setModal();
  };

  const deleteData = () => {
    ApiManagement_delete_mold({
      account: localStorage.getItem("account"),
      factory_id: factory,
      mold_id: select.id,
    })
      .then(onHide)
      .catch((err) => handleError(err, intl));
  };

  const exportData = () => {
    ApiManagement_export_mold({
      factory_id: factory,
    })
      .then((res) => window.open(`${baseURL}/${res.data}`))
      .catch((err) => handleError(err, intl));
  };

  const downloadDataExample = () => {
    ApiManagement_download_mold_dataExample()
      .then((res) => window.open(`${baseURL}/${res.data}`))
      .catch((err) => handleError(err, intl));
  };

  return (
    <div>
      <ManagementTabs />
      <div className="page-content shadow-lg">
        <div className="jc-end">
          <button
            className="center btn-gray"
            style={{ marginRight: "10px" }}
            onClick={downloadDataExample}
          >
            {intl.formatMessage({ id: "DownloadExample" })}
          </button>
          <button
            className="center btn-gray"
            style={{ marginRight: "10px" }}
            onClick={exportData}
          >
            {intl.formatMessage(
              { id: "Export" },
              { name: intl.formatMessage({ id: "Mold" }) }
            )}
          </button>
          <button
            className="center btn-gray"
            style={{ marginRight: "10px" }}
            onClick={() => showModal("import")}
          >
            {intl.formatMessage(
              { id: "Import" },
              { name: intl.formatMessage({ id: "Mold" }) }
            )}
          </button>
          <NewButton
            onClick={() => showModal("new")}
            text={intl.formatMessage(
              { id: "Create" },
              { name: intl.formatMessage({ id: "Mold" }) }
            )}
          />
        </div>
        <hr />
        <MoldTable showModal={showModal} modal={modal} />
      </div>

      <ImportModal modal={modal} onHide={onHide} />

      <InputModal modal={modal} onHide={onHide} data={select} />

      <DeleteModal
        show={modal === "delete"}
        onHide={onHide}
        callback={deleteData}
        name={select.mold_num}
        type="Mold"
      />
    </div>
  );
}

export default ManagementMold;
