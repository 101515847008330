import React, { useContext, useEffect, useState } from "react";
import { apiGetDeviceInfo } from "./../../api.js";
import { FormattedMessage } from "react-intl";
import Context from "../../context";
import { lampStatus } from "../../function";
import Slider from "react-slick";

export function IndexPieArea() {
  const [data, setData] = useState([]);
  const { factory } = useContext(Context);

  useEffect(() => {
    loadData();
    const interval = setInterval(loadData, 10000);
    return () => clearInterval(interval);
  }, []);

  const loadData = () => {
    apiGetDeviceInfo({
      FactoryID: factory,
    })
      .then((res) => setData(res.data.DeviceInfoList))
      .catch(console.log);
  };

  return (
    <ul
      className="pie_area list-unstyled mb-0 d-flex"
      style={{ flexWrap: "wrap", alignContent: "stretch" }}
    >
      {data.map((row) => (
        <IndexPie data={row} key={row.machineid} />
      ))}
    </ul>
  );
}

function IndexPie({
  data: {
    targetnum,
    producenum,
    LampStatus,
    machineid,
    remainingTime,
    remainingTimeUnit,
    product_num,
    product_name,
    time,
    second,
    oeestatus,
    order_num_name,
    ...data
  },
}) {
  const { color, status } = lampStatus(LampStatus);
  let percent = [];

  order_num_name.forEach((item, index) => {
    percent[index] = 0;
    if (targetnum[index] !== 0) {
      percent[index] = Math.floor((producenum[index] / targetnum[index]) * 100);
    }
  });

  const units = [
    <FormattedMessage id="Days" />,
    <FormattedMessage id="Hours" />,
    <FormattedMessage id="Minutes" />,
  ];
  remainingTimeUnit = units[remainingTimeUnit];

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: dots => (
      <ul style={{ "padding-bottom": "50px" }}> {dots} </ul>
    )
  };

  return (
    <li>
      <div
        style={{ height: "100%" }}
        className="pie_inner card rounded-0 clearfix border"
      >
        <div className="card-head" style={{ backgroundColor: color }}>
          <div className="d-flex justify-content-start">
            <h5 className="mt-0">
              <b>{machineid}</b>
            </h5>
          </div>
          <div className="d-flex justify-content-between">
            <h6 className="mb-0">
              <b>{status}</b>
            </h6>
            <h6 className="mb-0">
              <FormattedMessage id="remainingDays" />：
              {remainingTime ? (
                <b>
                  {remainingTime}
                  {remainingTimeUnit}
                </b>
              ) : (
                "--"
              )}
            </h6>
          </div>
        </div>
        <Slider {...settings}>
          {order_num_name.map((row, index) => (
            <div className="card-body jc-space-between flex-column">
              <div className="d-flex" style={{ marginBottom: "10px" }}>
                <div className="flex-1" style={{ height: "24px" }}>
                  {order_num_name[index]}
                </div>
                <div className="flex-1" style={{ textAlign: "end", height: "24px" }}>
                  {product_num[index]}
                </div>
              </div>
              <div className="flex-1" style={{ textAlign: "end", height: "24px" }}>
                {product_name[index]}
              </div>
              <div style={{ height: "15px" }}></div>
              <div>
                <div className="pie_data top clearfix">
                  <div className="d-flex justify-content-around">
                    <span>
                      <h4 className="mt-0 text-center">
                        <b>{producenum[index]}</b>
                      </h4>
                      <h6 className="mb-0 text-center">
                        <FormattedMessage id="ProductionQuantity" />
                      </h6>
                    </span>
                    <span></span>
                    <span>
                      <h4 className="mt-0 text-center">
                        <b>{targetnum[index]}</b>
                      </h4>
                      <h6 className="mb-0 text-center">
                        <FormattedMessage id="expect_quantity" />
                      </h6>
                    </span>
                  </div>
                </div>
                <div className="d-flex justify-content-center">
                  <div
                    className={`pie_chart rounded d-flex justify-content-center align-items-center rounded-circle pct_${Math.min(
                      percent[index],
                      100
                    )}`}
                  >
                    <div className="pie_info">
                      <h4 className="text-center mt-0">
                        <span>
                          <b>
                            {percent[index]}
                            <small>%</small>
                          </b>
                        </span>
                      </h4>
                      <h6 className="text-center m-0">
                        <span>{time}</span>
                        <small>{second}</small>
                      </h6>
                    </div>
                  </div>
                </div>
                <div className="pie_data bottom clearfix">
                  <div className="d-flex justify-content-between">
                    <span>
                      <i className={`text-center d-block ${oeestatus[index]}`}></i>
                      <h6 className="mb-0 text-center">
                        <FormattedMessage id="OEETrend" />
                      </h6>
                    </span>
                    <span>
                      <i className={`text-center d-block ${data.status[index]}`}></i>
                      <h6 className="mb-0 text-center">
                        <FormattedMessage id="ProgressTrend" />
                      </h6>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </li>
  );
}