import React, { useState, useEffect, useContext } from "react";
import { useIntl } from "react-intl";
import { DeleteModal, NewButton, handleError } from "../../component/common";
import { UnproductiveTable, InputModal } from "../../component/management/unproductive";
import { ManagementTabs } from "../../component/management/Management";
import {
  getUnproductiveOrderClass,
  ApiManagement_unproductiveOrder_delete,
} from "../../api.js";
import Context from "../../context";

function ManagementUnproductiveOrder({ checkEditAuth }) {
  const intl = useIntl();
  const { factory } = useContext(Context);
  const [modal, setModal] = useState();
  const [select, setSelect] = useState({});
  const [groupList, setGroupList] = useState([]);

  useEffect(() => {
    getUnproductiveOrderClass({
      factory_id: factory,
    }).then((res) => setGroupList(res.data));
  }, []);

  const showModal = (modal, item = {}) => {
    if (!checkEditAuth()) {
      return;
    }
    setSelect(item);
    setModal(modal);
  };

  const onHide = () => {
    setModal();
    setSelect({});
  };

  const deleteData = () => {
    ApiManagement_unproductiveOrder_delete({
      account: localStorage.getItem("account"),
      factory_id: factory,
      id: select.id,
    })
      .then(onHide)
      .catch((err) => handleError(err, intl));
  };

  return (
    <div>
      <ManagementTabs tab="9" />
      <div className="page-content shadow-lg">
        <div className="jc-end">
          <NewButton
            onClick={() => showModal("new")}
            text={intl.formatMessage(
              { id: "Create" },
              { name: intl.formatMessage({ id: "UnproductiveOrderReason" }) }
            )}
          />
        </div>
        <hr />
        <UnproductiveTable
          modal={modal}
          showModal={showModal}
          groupList={groupList}
        />
      </div>

      <InputModal
        modal={modal}
        onHide={onHide}
        data={select}
        groupList={groupList}
      />

      <DeleteModal
        show={modal === "delete"}
        onHide={onHide}
        name={select.description}
        type={"UnproductiveOrderReason"}
        callback={deleteData}
      />
    </div>
  );
}

export default ManagementUnproductiveOrder;
