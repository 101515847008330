import React, { useEffect, useState, useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { MdCreate, MdDelete } from "react-icons/md";
import { ColumnFilter, Table } from "../common";
import { InputModalBase } from "./Management";
import {
  ApiManagement_material,
  ApiManagement_new_material,
  ApiManagement_update_material,
  ApiManagement_upload_material,
} from "../../api.js";
import Context from "../../context";

export function MaterialTable({ showModal, modal }) {
  const [data, setData] = useState([]);
  const { factory } = useContext(Context);
  useEffect(() => {
    if (!modal) {
      ApiManagement_material({
        factory_id: factory,
      })
        .then((res) => setData(res.data))
        .catch(console.log);
    }
  }, [modal]);

  const columns = [
    {
      Header: <FormattedMessage id="material_num" /> /*原料料號*/,
      accessor: "material_num",
      Filter: ({ column }) => ColumnFilter(column, "material_num"),
    },
    {
      Header: <FormattedMessage id="material_model" /> /*材料型號*/,
      accessor: "material_model",
      Filter: ({ column }) => ColumnFilter(column, "material_model"),
    },
    {
      Header: <FormattedMessage id="base_material" /> /*基底材料*/,
      accessor: "base_material",
      Filter: ({ column }) => ColumnFilter(column, "base_material"),
    },
    {
      Header: <FormattedMessage id="supplier" /> /*供應商*/,
      accessor: "supplier",
      Filter: ({ column }) => ColumnFilter(column, "supplier"),
    },
    {
      Header: <FormattedMessage id="Edit" />,
      accessor: "editor",
      Filter: "",
    },
  ];

  const display = data.map((item) => ({
    ...item,
    photo: item.photo_path,
    editor: (
      <span>
        <MdCreate
          className="icon-btn"
          size="20px"
          onClick={() => showModal("edit", item)}
        />
        <MdDelete
          style={{ marginLeft: "10px" }}
          className="icon-btn"
          size="20px"
          onClick={() => showModal("delete", item)}
        />
      </span>
    ),
  }));

  return <Table columns={columns} data={display} />;
}

export const ImportModal = ({ onHide, modal }) => {
  const { factory } = useContext(Context);
  const intl = useIntl();
  const [select, setSelect] = useState({});

  useEffect(() => {
    if (!modal) {
      setSelect({});
    }
  }, [modal]);

  const submit = () => {
    return new Promise((resolve, reject) => {
      const data = new FormData();
      data.append("file", select.xls);
      data.append("factory_id", factory);
      ApiManagement_upload_material(data).then(resolve).catch(reject);
    });
  };

  const columns = [
    {
      id: "xls",
      type: "file",
      required: true,
      remark: "MaterialImportRemark",
      extensions: ["xls", "xlsx"],
    },
  ];

  return (
    <InputModalBase
      show={modal === "import"}
      onHide={onHide}
      title={intl.formatMessage(
        { id: "Import" },
        { name: intl.formatMessage({ id: "Material" }) }
      )}
      columns={columns}
      select={select}
      setSelect={setSelect}
      submit={submit}
    />
  );
};

export const InputModal = ({ modal, onHide, data }) => {
  const { factory } = useContext(Context);
  const intl = useIntl();
  const [select, setSelect] = useState({});
  const [expand, setExpand] = useState(false);

  const columns = [
    { id: "material_num", type: "text", required: true },
    { id: "material_model", type: "text", required: true },
    { id: "base_material", type: "text" },
    { id: "supplier", type: "text" },
    { id: "melt_density", type: "number" },
    { id: "soild_state_density", type: "number" },
    { id: "lowest_melt", type: "number" },
    { id: "hightest_melt", type: "number" },
    { id: "average_melt", type: "number" },
    { id: "lowest_mold", type: "number" },
    { id: "highest_mold", type: "number" },
    { id: "average_mold", type: "number" },
    { id: "aridity", type: "number" },
    { id: "drying_time", type: "number" },
    { id: "fiber_content", type: "number" },
    { id: "melting_index", type: "number" },
    { id: "weight", type: "number" },
  ];

  useEffect(() => {
    if (!modal) {
      setExpand(false);
    }
    setSelect(JSON.parse(JSON.stringify(data)));
  }, [modal, data]);

  const submit = () => {
    return new Promise((resolve, reject) => {
      let req = {
        ...select,
        account: localStorage.getItem("account"),
        factory_id: factory,
      };
      columns.forEach(({ id, type }) => {
        if (req[id] === undefined) {
          if (type === "text") {
            req[id] = "";
          } else if (type === "number") {
            req[id] = 0;
          }
        }
      });
      if (modal === "new") {
        ApiManagement_new_material(req).then(resolve).catch(reject);
      } else if (modal === "edit") {
        req.material_id = select.id;
        ApiManagement_update_material(req).then(resolve).catch(reject);
      }
    });
  };

  const title = intl.formatMessage(
    { id: modal === "new" ? "Create" : "EditThe" },
    { name: intl.formatMessage({ id: "Material" }) }
  );

  return (
    <InputModalBase
      show={modal === "new" || modal === "edit"}
      onHide={onHide}
      title={title}
      columns={columns.filter((_, i) => expand || i < 2)}
      select={select}
      setSelect={setSelect}
      submit={submit}
      expand={expand}
      setExpand={setExpand}
    />
  );
};
