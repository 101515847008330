import React, { useState, useContext } from "react";
import { useIntl } from "react-intl";
import { NewButton, DeleteModal, handleError } from "../../component/common";
import { SysTabs } from "../../component/system/system";
import {
  DeviceGroupTable,
  InputModal,
} from "../../component/system/deviceGroup";
import { ApiSysDeleteMachineGroupData } from "../../api.js";
import Context from "../../context";

export default function DeviceGroup({ checkEditAuth }) {
  const [modal, setModal] = useState("");
  const [select, setSelect] = useState({ device_id: [] });
  const intl = useIntl();
  const { factory } = useContext(Context);

  const showModal = (modal, item = { device_id: [] }) => {
    if (!checkEditAuth()) {
      return;
    }
    if (modal === "edit" || modal === "delete") {
      let data = {
        group_id: item.GroupID,
        title: item.Title,
        device_id: item.DeviceList.map(({ DeviceID }) => DeviceID),
      };
      setSelect(data);
    } else {
      setSelect(item);
    }
    setModal(modal);
  };

  const onHide = () => {
    setModal();
  };

  const deleteData = () => {
    ApiSysDeleteMachineGroupData({
      account: localStorage.getItem("account"),
      factory_id: factory,
      group_id: select.group_id,
    })
      .then(onHide)
      .catch((err) => handleError(err, intl));
  };

  return (
    <React.Fragment>
      <div>
        <SysTabs tab="2" />
        <div className="page-content shadow-lg">
          <div className="jc-end">
            <NewButton
              onClick={() => showModal("new")}
              text={intl.formatMessage(
                { id: "Create" },
                { name: intl.formatMessage({ id: "DeviceGroup" }) }
              )}
            />
          </div>
          <hr />
          <DeviceGroupTable showModal={showModal} modal={modal} />
        </div>
      </div>

      <InputModal modal={modal} onHide={onHide} data={select} />

      <DeleteModal
        show={modal === "delete"}
        onHide={onHide}
        name={select.title}
        type={"DeviceGroup"}
        callback={deleteData}
      />
    </React.Fragment>
  );
}
