const employee = {
  CurrentOperationItem: "Current operation item",
  Operator: "Operator",
  Ontime: "Ontime",
  ChoosePeople: "Choose People",
  Start: "Start",
  Complete: "Complete",
  DefectiveCount: "Defective",
  EnterDefectiveProduct: "Enter defective products ",
  ClickToAddQuantity: "Click on the item to add quantity ",
  end_time: "End Time",

  Work: "Work",
  Classification: "Classification",
  WorkName: "Work Name",
  ThisWork:"This Work",
  
  Worker: "Worker",
  WorkerNumber: "Worker Number",
  WorkerName: "Worker Name",
  
  WorkerLog: "Worker Log",
  ChangeMold: "Change Mold",
  PleaseSelectTheAction: "Please select the action",
  Error:"Error"
};
export default employee;
