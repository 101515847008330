import React from "react";
import { Tabs } from "../common";

export function SysTabs({ tab }) {
  let list = [
    { to: "/pages/system/user", name: "UsersManagement" },
    { to: "/pages/system/dashboard", name: "PanelManagement" },
    { to: "/pages/system/devicegroup", name: "DeviceClassificationManagement" },
    { to: "/pages/system/factory", name: "FactoryManagement" },
    { to: "/pages/system/notify", name: "PushNotification" },
    { to: "/pages/system/document", name: "UploadFileMangement" },
    { to: "/pages/system/log", name: "Diary" },
    { to: "/pages/system/role", name: "RoleManagement" },
  ];
  return <Tabs list={list} tab={tab} />;
}
