import index from "./en/index";
import dashboard from "./en/dashboard";
import pes from "./en/pes";
import analysis from "./en/analysis";
import management from "./en/management";
import sys from "./en/sys";
import root from "./en/root";
import employee from "./en/employee";
import errors from "./en/errors";
import document from "./en/document";
const obj = {
  //登入頁面
  UserLogin: "User Login",
  Login: "Login",
  KeepLogin: "Keep Login",
  ForgetPassword: "Forget Password",
  VerificationCode: "Verification Code",
  NewPassword: "New Password",
  ConfirmPassword: "Confirm Password",
  PleaseEnterThePasswordAgain: "Please Enter The Password Again",
  ToMobile: "Go To Download App",
  VerificationCodeSendToEmail: "Verification Code Is Already Send To Email",
  EMAIL_NOT_FOUND: "User Not Found",
  WrongVerificationCode: "Wrong Verification Code",
  VerifyFailed: "Verify Failed",
  ConfrimPasswordWrong: "Confrim Password Wrong",
  KeepLogin: "Keep Login",
  Password: "Password",

  //Header
  ChangePassword: "Change Password",
  Language: "Language",
  Logout: "Logout",
  Hello: "Hello",

  //Footer
  Footer: `Copyright © FCS - v ${process.env.REACT_APP_VERSION} ALL RIGHTS RESERVED`,

  //leftmenu
  ProductionOverview: "Production Overview",
  Monitoring: "Monitoring",
  Operation: "Operation",
  ProduceAnalysisReport: "Produce Analysis Report",
  ProductionAdmin: "Production Admin",
  SystemAdmin: "System Admin",
  Root: "Root",
  EmployeeEfficiency: "Employee Efficiency",
  QualityManagement: "Quality Management",

  OEE: "OEE",
  Utilization: "Availability",
  Performance: "Performance",
  Quality: "Quality",

  Percentage: "Percentage",
  DownloadExcel: "Download Excel",

  //常出現
  New: "New ",
  Increase: "Increase ",
  Modify: "Modify",
  Edit: "Edit",
  Copy: "Copy {name}",
  Delete: "Delete",
  Yes: "Yes",
  No: "No",
  OK: "OK",
  Cancel: "Cancel",
  Prev: "Last Step",
  PreviousPage: "Previous Page",
  NextPage: "Next Page",
  PickAll: "Pick All",
  ConfirmationMessage: "Confirmation Message",
  AreYouSureToDelete: "Are You Sure To Delete ",
  DeleteConfirm: "Are you sure to delete {name}?",
  AreYouSureTo: "Are You Sure To ",
  The: "The",
  Page: "Page",
  Required: "Required",
  Latest: "Latest",
  EndTime: "End Time",
  description: "description",
  Photo: "Photo",
  File: "File",
  Column: "Column",
  NoData: "No Data",
  Time: "Time",
  Quantity: "Quantity",
  CannotBeSmallerThan1: "Cannot Be Smaller Than 1",
  QuestionMark: "?",
  LargerThan: "Larger Than",
  SmallerThan: "Smaller Than",
  Import: "Import {name}",
  Upload: "Upload {name}",
  PickA: "Pick a {name}",
  Create: "Create {name}",
  EditThe: "Edit {name}",
  InputThe: "Input the {name}",
  ToSmall: "Can not be smaller than {number}",
  Export: "Export {name}",

  //單位
  Sec: "Sec",
  Amount: "Amount",
  Days: "Days",
  Today: "Today",
  Yesterday: "Yesterday",
  Tomorrow: "Tomorrow",
  Date: "Date",
  Interval: "Interval",
  DatesTimeQuantum: "Dates Time Quantum",
  Year: "Year",
  Month: "Month",
  Day: "Day",
  Time: "Time",
  Hour: "Hour",
  Hours: "Hours",
  Minutes: "Minutes",
  Seconds: "Seconds",
  Week: "Week",

  //月份
  January: "January",
  February: "February",
  March: "March",
  April: "April",
  May: "May",
  June: "June",
  July: "July",
  August: "August",
  September: "September",
  October: "October",
  November: "November",
  December: "December",

  //燈號
  LampStatus: "Lamp Status",
  Shutdown: "Shutdown",
  Alert: "Alert",
  Standby: "Standby",
  Running: "Running",
  NoOrderRunning: "No Order Running",
  MachineIsDowntime: "Machine Is Downtime",
  ProductionPause: "Production Pause",
  MicroStop: "Micro Stop",
  MachineIsOffline: "Machine Is Offline",

  PleaseEnterThe: "Please Enter The ",
  PleasePickThe: "Please Pick The ",
  PleasePickOrEnterThe: "Please Pick Or Enter The ",
  400: "Some fields were not filled, please fix and try again",
  404: "No this data, please try again",
  409: "Same data is already existed, please try again",
  Succeed: "Succeed",
  Failed: "Failed",
  WrongFileType: "Wrong File Type",
  ShouldBeLargerThen: "Should Be Larger Then ",
  Existed: "Existed",
  WrongFormat: "Wrong Format",
  WRONG_PASSWORD: "Wrong Password!",
  EmptyUnable: "Empty Unable",
  NoAuth: "No Auth",
  SureToRepair:"Are you sure to repair ?",
  classify:"classify",
  StartTime:"Start Time"
};

const zh_TW = Object.assign(
  obj,
  index,
  dashboard,
  pes,
  analysis,
  management,
  sys,
  root,
  employee,
  errors,
  document
);
export default zh_TW;
