import React, { useEffect, useRef, useState, useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartsMore from "highcharts/highcharts-more.js";
import highchartsExport from "highcharts/modules/exporting.js";
import { FaCircle, FaRegClock } from "react-icons/fa";
import { MdArrowDownward, MdArrowUpward, MdRemove } from "react-icons/md";
import { baseURL } from "../../api.js";
import {
  ProgressBar,
  ProduceQuantityChart,
  ListBlock,
  loadData,
  gaugeOption,
} from "./Dashboard";
import Context from "../../context";
highchartsMore(Highcharts);
highchartsExport(Highcharts);

function BigDashboard({ onCarousel, deviceID, setTitleBar, fullscreen, number, setNumber }) {
  const chartComponent = useRef();
  const [data, setData] = useState({
    produceStatus: {},
    produceQuantityByTime: [],
    badReason: [],
    closingReason: [],
    produceQuantityByTime: [],
  });
  const [dataInterval, setDataInterval] = useState(1);
  const intl = useIntl();
  const { factory } = useContext(Context);
  const [newData, setNewData] = useState([])
  useEffect(() => {
    setNumber(0)
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, [deviceID]);
  function useInterval(callback, delay) {
    const savedCallback = useRef();
  
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);
  
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }
  useInterval(() => {
    if(onCarousel&&newData.length===2){
      setNumber(number+1)
    }
    
  }, 5000);
  useEffect(() => {
    if (deviceID) {
      _loadData();
      const temp = setInterval(_loadData, 10000);
      return () => clearInterval(temp);
    }
  }, [deviceID, dataInterval, onCarousel]);

  useEffect(() => {
    let timeout = setTimeout(updateDimensions, 100);
    return () => clearTimeout(timeout);
  }, [fullscreen, data.deviceName]);

  const _loadData = () => {
    loadData({
      FactoryID: factory,
      DashboardQuantity: 1,
      DeviceID: deviceID,
      CheckTimeInterval: dataInterval,
    })
      .then((data) => {
        for (let i = 0; i < data.length; i++) {
          if (data[i].remainingDays) {
            data[i].remainingDays = data[i].remainingDays
              .replace("天", intl.formatMessage({ id: "Days" }))
              .replace("時", intl.formatMessage({ id: "Hours" }))
              .replace("分", intl.formatMessage({ id: "Minutes" }));
          }
        }
        setNewData(data);
        setData(data);
        setTitleBar(data);
        updateDimensions();
      })
      .catch(console.log);
  };
  const updateDimensions = () => {
    const container = chartComponent.current?.container.current;
    if (container) {
      const chart = document.getElementById("chart");
      container.style.height = chart.clientHeight + "px";
      container.style.width = chart.clientWidth + "px";
      chartComponent.current.chart.reflow();
    }
  };

  
  let arrow; 
  if (newData[number]?.oeeStatus == -1) {
    arrow = <MdArrowDownward size="30px" />;
  } else if (newData[number]?.oeeStatus == 1) {
    arrow = <MdArrowUpward size="30px" />;
  } else {
    arrow = <MdRemove size="30px" />;
  }


  const options = gaugeOption({
    color: newData[number]?.oeeColor,
    dial: {
      radius: "30%",
      borderWidth: 0,
      baseWidth: 30,
      topWidth: 0,
      baseLength: "50%",
      rearLength: "0%",
    },
    tickLength: 70,
    tickWidth: 10,
    oee: newData[number]?.oee,
  });
  const columns = ["createTime", "endTime", "expectEndTime", "remainingDays"];
  if (newData.length === 0||newData===undefined) {

    // if (!newData[0].deviceName) {
      return <div />;
    // }
  }
  else{
  return (
    
    <div className="dashboard-big">
      <div
        className="ai-stretch jc-space-between position-relative"
        style={{ height: "100px" }}
      >
        <div
          className="ai-center border-right bar-left"
          style={{ color: newData[number]?.statusColor }}
        >
          <div className="flex-column">
            <b>{newData[number]?.deviceName}</b>
            <span>
              {newData[number]?.deviceStatus}
              {newData[number]?.deviceMachineMode ? "：" : ""}
              {newData[number]?.deviceMachineMode}
            </span>
          </div>
          <FaCircle size="50px" style={{ margin: "0 15px" }} />
        </div>
        
         <div className="jc-space-around ai-stretch flex-1 bar-right">
          <div
            className="center"
            style={{
              backgroundColor:
              newData[number]?.oeeStatus === -1
                  ? "#e40026"
                  : newData[number]?.oeeStatus === 1
                  ? "#2dc76d"
                  : "#0080FF",
            }}
          >
            <b style={{ fontSize: "32px", marginRight: "15px" }}>
              <FormattedMessage id="OEETrend" />
            </b>
            {arrow}
          </div>
          <div
            className="center"
            style={{
              backgroundColor:
              newData[number]?.produceStatus.statusCode === -1
                  ? "#e40026"
                  : newData[number]?.produceStatus.statusCode === 1
                  ? "#2dc76d"
                  : "#0080FF",
            }}
          >
            <FaRegClock size="40px" style={{ marginRight: "15px" }} />
            <span className="flex-column al-center">
              <span style={{ fontSize: "32px" }}>{newData[number]?.produceStatus.time}</span>
              <span style={{ fontSize: "24px" }}>{newData[number]?.produceStatus.status}</span>
            </span>
          </div>
          <div
            className="center flex-column"
            style={{
              backgroundColor: newData[number]?.badProductQuantity >= 1 ? "#f8d44a" : "#cccccc",
            }}
          >
            <span style={{ fontSize: "32px" }}>{newData[number]?.badProductQuantity}</span>
            <span style={{ fontSize: "24px" }}>
              <b>
                <FormattedMessage id="Defective" />
              </b>
            </span>
          </div>
        </div>
        {/*good */}
        {newData[number]?.producePhotoPath ? (
          <img
            src={`${baseURL}/${newData[number]?.producePhotoPath}`}
            className="fit-contain"
            style={{ height: "100px" }}
          />
        ) : null}
      </div>
      <hr />
      <h4>
        <b>
          <FormattedMessage id="CapacityStatus" />
        </b>
      </h4>
      <div
        className="jc-space-between ai-stretch mt-3"
        style={{ height: fullscreen ? "60vh" : "50vh" }}
      >
        <div className="flex-column ai-stretch main-left">
          <div className="ai-center mb-3">
            <span className="flex-1 text-center">
              <FormattedMessage id="ProductionProgress" />
            </span>
            <b className="flex-1 text-center">{newData[number]?.produceProgress}%</b>
            <ProgressBar
              value={newData[number]?.produceProgress}
              label={`${newData[number]?.actualProduceQuantity}/${newData[number]?.expectProduceQuantity}`}
            />
          </div>
          <div className="ai-center mb-5">
            <span className="flex-1 text-center">
              <FormattedMessage id="TopDieCycle" />
            </span>
            <b className="flex-1 text-center">
              {newData[number]?.cycleTime ? newData[number]?.cycleTime : 0}
              <FormattedMessage id="Sec" />
            </b>
            <ProgressBar
              value={(newData[number]?.cycleTime / (newData[number]?.bestCycleTime * 2.5)) * 100}
              max={newData[number]?.estCycleTime * 2.5}
              isIdeal={newData[number]?.cycleTime < newData[number]?.bestCycleTime}
              ideal={newData[number]?.bestCycleTime}
            />
          </div>
          <div className="jc-space-between" style={{ height: "100%" }}>
            <ProduceQuantityChart
              pickDataInterval={(e) => setDataInterval(e.target.value)}
              dataInterval={newData[number]?.dataInterval}
              max={newData[number]?.produceQuantityMaximum}
              ideal={newData[number]?.idealProduceQuantity}
              least={newData[number]?.leastProduceQuantity}
              list={newData[number]?.produceQuantityByTime}
            />
            <div style={{ width: "15vw" }}>
              <ListBlock
                className="mb-3"
                list={newData[number]?.badReason.map(
                  ({ description, quantity }) =>
                    `${description} ${intl.formatMessage(
                      { id: "Count" },
                      { count: quantity }
                    )}`
                )}
                title="Defective"
              />
              <ListBlock list={newData[number]?.closingReason} title="DowntimeReason" />
            </div>
          </div>
        </div>
        {/*works well */}
        <div
          className="position-relative border p-3"
          style={{
            width: "55%",
          }}
        >
          <h4>
            <b>OEE</b>
          </h4>
          <div
            className="position-relative"
            id="chart"
            style={{ width: "100%", height: "90%" }}
          >
            <HighchartsReact
              highcharts={Highcharts}
              options={options}
              ref={chartComponent}
            />
            <span
              className="position-absolute text-center"
              style={{ top: "50%", fontSize: "32px", width: "100%" }}
            >
              <b>{newData[number]?.oeeTitle}</b>
            </span>
            <div
              className="position-absolute jc-center"
              style={{ width: "100%", top: "90%" }}
            >
              <span
                className="jc-space-between"
                style={{ width: "70%", fontSize: "36px" }}
              >
                <b> 0</b>
                <b>100</b>
              </span>
            </div>
          </div>
          <span
            className="position-absolute"
            style={{
              top: "50px",
              right: "50px",
              color: newData[number]?.oeeColor,
              fontSize: "72px",
            }}
          >
            <b>{newData[number]?.oee}%</b>
          </span>
        </div>
      </div>
      <div className="ai-stretch footer">
        {columns.map((id, i) => (
          <div
            key={id}
            className={`flex-1 flex-column ${
              i < columns.length - 1 ? "bolder-right" : ""
            }`}
          >
            <b>{intl.formatMessage({ id })}</b>
            <span>{newData[number]?.[id]}</span>
          </div>
        ))}
      </div>
    </div> 


  );
          }
}

export { BigDashboard };
