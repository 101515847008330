import React, { useState, useEffect, useContext, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Grid from "@material-ui/core/Grid";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import Typography from "@material-ui/core/Typography";
import { MdFiberManualRecord } from "react-icons/md";
import moment from "moment";
import {
  ApiPes_GetDowntimeReasonList,
  ApiPes_GetDowntimeDetail,
  ApiPes_BatchUpdateDowntimeReason,
  putPesDowntimeSplitReasonList,
  putPesDowntimeMergeReasonList,
} from "../../api.js";
import { CustomRadio, Pagination, RangeDatePicker } from "../common";
import { lampStatus, showWarn } from "../../function";
import Context from "../../context";

export function DowntimeList({
  deviceID,
  filter,
  date,
  update,
  showModal,
  reasonList,
}) {
  const intl = useIntl();
  const { factory, locale } = useContext(Context);
  const [page, setPage] = useState(0);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (deviceID) {
      ApiPes_GetDowntimeReasonList({
        FactoryID: factory,
        DeviceID: deviceID,
        CheckDateStart: date,
      })
        .then((res) => {
          res.data.forEach((item) => {
            const { color } = lampStatus(item.lamp_status);
            item.color = color;
            item.total = item.total
              .replace("小時", intl.formatMessage({ id: "Hours" }))
              .replace("分鐘", intl.formatMessage({ id: "Minutes" }))
              .replace("秒", intl.formatMessage({ id: "Sec" }));
            const reason = reasonList.find(({ id }) => id === item.reason_id);
            item["zh-TW"] = reason?.description;
            item.en = reason?.english;
            item.vi = reason?.vietnamese;
          });
          setData(res.data);
        })
        .catch(console.log);
    }
  }, [deviceID, date, update]);

  const showDetail = (e, id) => {
    e.stopPropagation();
    showModal("detail", { id });
  };

  const filterData = useMemo(() => {
    return data.filter(({ lamp_status }) => filter.indexOf(lamp_status) > -1);
  }, [data, filter]);

  return (
    <React.Fragment>
      <ul className="downtime-list">
        {filterData.slice(page * 50, (page + 1) * 50).map((item) => (
          <li
            key={item.id}
            className="ai-center"
            onClick={() => showModal("edit", item)}
            style={{ borderLeftColor: item.color }}
          >
            <span>{moment(item.time).format("lll")}</span>
            <b className="flex-1 text-center">{item.total}</b>
            <b className="flex-1 text-center">{item.group}</b>
            <b className="flex-1 text-center">{item.classify}</b>
            <b className="flex-1 text-center">{item[locale]}</b>
            <b className="flex-1 text-center">{item.remark}</b>
            <div className="flex-1 center">
              {item.name ? <span className="tag">{item.name}</span> : null}
            </div>
            <div className="center" style={{ width: "50px" }}>
              {item.reason === "生產微停" || item.reason === "生產暫停" ? (
                <MdFiberManualRecord
                  color="red"
                  size="30px"
                  onClick={(e) => showDetail(e, item.id)}
                />
              ) : null}
            </div>
          </li>
        ))}
      </ul>
      <div className="jc-end">
        <Pagination
          pageIndex={page}
          canPreviousPage={page > 0}
          previousPage={() => setPage(page - 1)}
          canNextPage={page < Math.ceil(filterData.length / 50) - 1}
          nextPage={() => setPage(page + 1)}
          pageCount={Math.ceil(filterData.length / 50)}
          gotoPage={setPage}
        />
      </div>
    </React.Fragment>
  );
}

export const EditModal = ({
  modal,
  closeModal,
  data,
  reasonList,
  deviceID,
  setModal,
}) => {
  const intl = useIntl();
  const { factory, locale } = useContext(Context);
  const [select, setSelect] = useState({});
  const [warn, setWarn] = useState();

  useEffect(() => {
    if (modal === "edit") {
      setSelect(JSON.parse(JSON.stringify(data)));
    } else {
      setSelect({});
    }
  }, [modal, data]);

  const onChange = (e) => {
    const { id, value } = e.target;
    setSelect((prev) => ({ ...prev, [id]: value }));
  };

  const submit = () => {
    putPesDowntimeSplitReasonList({
      FactoryID: factory,
      DeviceID: deviceID,
      DowntimeID: select.id,
      RenewList: [
        {
          account: localStorage.getItem("account"),
          duration: select.closing_time_total,
          reason_id: select.reason_id,
          reason_remark: select.remark,
        },
      ],
    })
      .then(closeModal)
      .catch((e) => setWarn(e.response?.data.errors[0]?.msg));
  };

  return (
    <Modal
      className="modal_style fade"
      size="lg"
      centered
      show={modal === "edit"}
      onHide={closeModal}
    >
      <Modal.Header closeButton>
        <Modal.Title as="h5">
          <b>
            {intl.formatMessage(
              { id: "EditThe" },
              { name: intl.formatMessage({ id: "DowntimeReason" }) }
            )}
          </b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Grid container spacing={2} className="mb-3">
          {reasonList.length === 0 ? (
            <span>
              <FormattedMessage id="DowntimeReasonFirst" />
            </span>
          ) : (
            reasonList.map((item) => (
              <Grid key={item.id} item xs={3}>
                <CustomRadio
                  id={`reason_${item.id}`}
                  value={item.id}
                  onChange={() =>
                    setSelect((prev) => ({
                      ...prev,
                      reason_id: item.id,
                    }))
                  }
                  checked={select.reason_id === item.id}
                  className="center flex-column"
                >
                  {item[locale]}
                </CustomRadio>
              </Grid>
            ))
          )}
        </Grid>
        <Form.Group as={Row} controlId="remark">
          <Form.Label column lg="3">
            <FormattedMessage id="description" />
          </Form.Label>
          <Col lg="9">
            <Form.Control
              placeholder={intl.formatMessage(
                { id: "InputThe" },
                { name: intl.formatMessage({ id: "description" }) }
              )}
              onChange={onChange}
              value={select.remark ? select.remark : ""}
            />
            <span className="warn" />
          </Col>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer className="jc-space-between ai-center">
        <span className="warn">{warn}</span>
        <div className="ai-center">
          <button className="btn btn-more" onClick={() => setModal("mode")}>
            <FormattedMessage id="AdvancedSettings" />
          </button>
          <button className="btn btn-confirm" onClick={submit}>
            <FormattedMessage id="OK" />
          </button>
          <button className="btn btn-cancel" onClick={closeModal}>
            <FormattedMessage id="Cancel" />
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export const ModeModal = ({ modal, closeModal, setModal }) => {
  const intl = useIntl();
  return (
    <Modal
      className="modal_style fade"
      size="lg"
      centered
      show={modal === "mode"}
      onHide={closeModal}
    >
      <Modal.Header closeButton>
        <Modal.Title as="h5">
          <b>
            {intl.formatMessage(
              { id: "PickA" },
              { name: intl.formatMessage({ id: "Action" }) }
            )}
          </b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <button
              className="custom-radio-label"
              onClick={() => setModal("add")}
            >
              <FormattedMessage id="Add" />
            </button>
          </Grid>
          <Grid item xs={6}>
            <button
              className="custom-radio-label"
              onClick={() => setModal("merge")}
            >
              <FormattedMessage id="Merge" />
            </button>
          </Grid>
        </Grid>
      </Modal.Body>
      <Modal.Footer className="jc-end">
        <button className="btn btn-cancel" onClick={() => setModal("edit")}>
          <FormattedMessage id="Cancel" />
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export const AddModal = ({
  modal,
  closeModal,
  setModal,
  data,
  deviceID,
  date,
  reasonList,
}) => {
  const intl = useIntl();
  const { factory, locale } = useContext(Context);
  const [RenewList, setRenewList] = useState([]);
  const [select, setSelect] = useState({});
  const [warn, setWarn] = useState();

  useEffect(() => {
    if (modal === "add") {
      const result = reasonList.find(({ id }) => id === data.reason_id);
      setRenewList([
        {
          time: data.time,
        },
        {
          time: moment(data.time)
            .add(data.closing_time_total, "s")
            .format("YYYY-MM-DD HH:mm:ss"),
          reason_id: data.reason_id,
          "zh-TW": result?.description,
          en: result?.english,
          vi: result?.vietnamese,
        },
      ]);
    } else {
      setRenewList([]);
      setSelect({});
      setWarn();
    }
  }, [modal, data]);

  const onChange = (e) => {
    const { id, value } = e.target;
    setSelect((prev) => ({ ...prev, [id]: value }));
  };

  const insert = () => {
    document.querySelectorAll(".warn").forEach((item) => (item.hidden = true));
    let pass = true;
    const time = `${date} ${select.time}`;
    if (!select.time) {
      pass = false;
      showWarn("time", "Required", intl);
    } else if (!moment(time).isValid()) {
      showWarn("time", "WrongFormat", intl);
      pass = false;
    }
    if (!select.reason_id || select.reason_id === "null") {
      showWarn("reason_id", "Required", intl);
      pass = false;
    }
    if (pass) {
      const index = RenewList.findIndex((e) =>
        moment(e.time).isSameOrBefore(time)
      );
      if (
        moment(time).isBetween(
          RenewList[0].time,
          RenewList[RenewList.length - 1][0]
        )
      ) {
        let list = RenewList.slice();
        const result = reasonList.find(
          ({ id }) => id === parseInt(select.reason_id)
        );
        list.splice(index + 1, 0, {
          ...select,
          reason_id: parseInt(select.reason_id),
          time,
          "zh-TW": result?.description,
          en: result?.english,
          vi: result?.vietnamese,
        });
        setRenewList(list);
        setSelect({});
      } else {
        showWarn("time", "WrongFormat", intl);
      }
    }
  };

  const submit = () => {
    for (let i = 1; i < RenewList.length; ++i) {
      RenewList[i].duration = moment(RenewList[i].time).diff(
        RenewList[i - 1].time,
        "s"
      );
    }
    putPesDowntimeSplitReasonList({
      FactoryID: factory,
      DeviceID: deviceID,
      DowntimeID: data.id,
      RenewList: RenewList.slice(1, RenewList.length).map(
        ({ reason_id, duration, remark }) => ({
          account: localStorage.getItem("account"),
          reason_id,
          duration,
          reason_remark: remark,
        })
      ),
    })
      .then(closeModal)
      .catch((e) => setWarn(e.response?.data.errors[0]?.msg));
  };

  return (
    <Modal
      className="modal_style fade"
      size="lg"
      centered
      show={modal === "add"}
      onHide={closeModal}
    >
      <Modal.Header closeButton>
        <Modal.Title as="h5">
          <b>
            {intl.formatMessage(
              { id: "Create" },
              { name: intl.formatMessage({ id: "DowntimeReason" }) }
            )}
          </b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pes-area">
        <Grid container>
          <Grid item xs={6}>
            <Form.Group as={Row} controlId="time">
              <Form.Label column lg="3">
                <FormattedMessage id="EndTime" />
              </Form.Label>
              <Col lg="9">
                <Form.Control
                  placeholder={intl.formatMessage(
                    { id: "InputThe" },
                    { name: intl.formatMessage({ id: "EndTime" }) }
                  )}
                  onChange={onChange}
                  value={select.time ? select.time : ""}
                />
                <span className="warn" />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="reason_id">
              <Form.Label column lg={3} md={4}>
                <FormattedMessage id="DowntimeReason" />
              </Form.Label>
              <Col lg={9} md={8}>
                <Form.Control
                  as="select"
                  onChange={onChange}
                  value={select.reason_id ? select.reason_id : "null"}
                >
                  <option value="null">
                    {intl.formatMessage(
                      { id: "PickA" },
                      { name: intl.formatMessage({ id: "DowntimeReason" }) }
                    )}
                  </option>
                  {reasonList.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item[locale]}
                    </option>
                  ))}
                </Form.Control>
                <span className="warn" />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="remark">
              <Form.Label column lg="3">
                <FormattedMessage id="description" />
              </Form.Label>
              <Col lg="9">
                <Form.Control
                  placeholder={intl.formatMessage(
                    { id: "InputThe" },
                    { name: intl.formatMessage({ id: "description" }) }
                  )}
                  onChange={onChange}
                  value={select.remark ? select.remark : ""}
                />
                <span className="warn" />
              </Col>
            </Form.Group>
            <button className="custom-radio-label" onClick={insert}>
              <FormattedMessage id="Add" />
            </button>
          </Grid>
          <Grid item xs={6}>
            <Timeline className="mb-0">
              {RenewList.map((item, i) => (
                <TimelineItem
                  key={i}
                  style={{
                    minHeight: i < RenewList.length - 1 ? undefined : "unset",
                  }}
                >
                  <TimelineOppositeContent>
                    <Typography>
                      {moment(item.time).format("HH:mm:ss")}
                    </Typography>
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot />
                    {i < RenewList.length - 1 ? <TimelineConnector /> : null}
                  </TimelineSeparator>
                  <TimelineContent>{item[locale]}</TimelineContent>
                </TimelineItem>
              ))}
            </Timeline>
          </Grid>
        </Grid>
      </Modal.Body>
      <Modal.Footer className="jc-space-between ai-center">
        <span style={{ color: "red" }}>{warn}</span>
        <div className="ai-center">
          <button className="btn btn-confirm" onClick={submit}>
            <FormattedMessage id="Confirm" />
          </button>
          <button className="btn btn-cancel" onClick={() => setModal("mode")}>
            <FormattedMessage id="Cancel" />
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export const MergeModal = ({
  modal,
  closeModal,
  setModal,
  data,
  deviceID,
  date,
  reasonList,
}) => {
  const intl = useIntl();
  const { factory, locale } = useContext(Context);
  const [MergeList, setMergeList] = useState([]);
  const [select, setSelect] = useState({});
  const [warn, setWarn] = useState();
  const [downtimeList, setDowntimeList] = useState([]);
  const [page, setPage] = useState(0);
  useEffect(() => {
    if (deviceID && modal) {
      ApiPes_GetDowntimeReasonList({
        FactoryID: factory,
        DeviceID: deviceID,
        CheckDateStart: date,
      })
        .then((res) => {
          res.data.forEach((item) => {
            const { color } = lampStatus(item.lamp_status);
            item.color = color;
            item.total = item.total
              .replace("小時", intl.formatMessage({ id: "Hours" }))
              .replace("分鐘", intl.formatMessage({ id: "Minutes" }))
              .replace("秒", intl.formatMessage({ id: "Sec" }));
            item["zh-TW"] = item.description;
            item.en = item.english;
            item.vi = item.vietnamese;
          });
          setDowntimeList(res.data);
        })
        .catch(console.log);
    }
  }, [deviceID, modal]);

  useEffect(() => {
    if (modal === "merge") {
      setSelect(JSON.parse(JSON.stringify(data)));
    } else {
      setSelect({});
      setPage(0);
      setWarn();
      setMergeList([]);
    }
  }, [modal, data]);

  const onClick = (id) => {
    const list = MergeList.slice();
    const index = MergeList.indexOf(id);
    if (index > -1) {
      list.splice(index, 1);
    } else {
      list.push(id);
    }
    setMergeList(list);
  };
  
  const onChange = (e) => {
    const { id, value } = e.target;
    setSelect((prev) => ({ ...prev, [id]: value }));
  };

  const submit = () => {
    document.querySelectorAll(".warn").forEach((item) => (item.hidden = true));
    let pass = true;
    if (!select.reason_id || select.reason_id === "null") {
      pass = false;
    }
    if (MergeList.length === 0) {
      pass = false;
    }

    if (pass) {
      putPesDowntimeMergeReasonList({
        Account: localStorage.getItem("account"),
        FactoryID: factory,
        DeviceID: deviceID,
        ReasonId: select.reason_id,
        ReasonRemark: select.remark,
        MergeList,
      })
        .then(closeModal)
        .catch((e) => setWarn(e.response?.data.errors[0]?.msg));
    }
  };

  return (
    <Modal
      className="modal_style fade"
      size="lg"
      centered
      show={modal === "merge"}
      onHide={closeModal}
    >
      <Modal.Header closeButton>
        <Modal.Title as="h5">
          <b>
            {intl.formatMessage(
              { id: "Merge" },
              { name: intl.formatMessage({ id: "DowntimeReason" }) }
            )}
          </b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pes-area">
        <Form.Group as={Row} controlId="reason_id">
          <Form.Label column lg={3} md={4}>
            <FormattedMessage id="DowntimeReason" />
          </Form.Label>
          <Col lg={9} md={8}>
            <Form.Control
              as="select"
              onChange={onChange}
              value={select.reason_id}
            >
              <option value="null">
                {intl.formatMessage(
                  { id: "PickA" },
                  { name: intl.formatMessage({ id: "DowntimeReason" }) }
                )}
              </option>
              {reasonList.map((item) => (
                <option key={item.id} value={item.id}>
                  {item[locale]}
                </option>
              ))}
            </Form.Control>
            <span className="warn" />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="remark">
          <Form.Label column lg="3">
            <FormattedMessage id="description" />
          </Form.Label>
          <Col lg="9">
            <Form.Control
              placeholder={intl.formatMessage(
                { id: "InputThe" },
                { name: intl.formatMessage({ id: "description" }) }
              )}
              onChange={onChange}
              value={select.remark ? select.remark : ""}
            />
            <span className="warn" />
          </Col>
        </Form.Group>
        <ul className="downtime-list">
          {downtimeList
            .slice(page * 50, (page + 1) * 50)
            .map(
              ({
                id,
                color,
                time,
                total,
                group,
                classify,
                reason,
                remark,
                name,
              }) => (
                <li
                  key={id}
                  className="ai-center"
                  onClick={() => onClick(id)}
                  style={{ borderLeftColor: color }}
                >
                  <span>{moment(time).format("lll")}</span>
                  <b className="flex-1 text-center">{total}</b>
                  <b className="flex-1 text-center">{group}</b>
                  <b className="flex-1 text-center">{classify}</b>
                  <b className="flex-1 text-center">{reason}</b>
                  <b className="flex-1 text-center">{remark}</b>
                  <div className="flex-1 center">
                    <span className="tag">{name}</span>
                  </div>
                  <input type="checkbox" checked={MergeList.indexOf(id) > -1} />
                </li>
              )
            )}
        </ul>
        <div className="jc-end">
          <Pagination
            pageIndex={page}
            canPreviousPage={page > 0}
            previousPage={() => setPage(page - 1)}
            canNextPage={page < Math.ceil(downtimeList.length / 50) - 1}
            nextPage={() => setPage(page + 1)}
            pageCount={Math.ceil(downtimeList.length / 50)}
            gotoPage={setPage}
          />
        </div>
      </Modal.Body>
      <Modal.Footer className="jc-space-between ai-center">
        <span style={{ color: "red" }}>{warn}</span>
        <div className="ai-center">
          <button className="btn btn-confirm" onClick={submit}>
            <FormattedMessage id="OK" />
          </button>
          <button className="btn btn-cancel" onClick={() => setModal("mode")}>
            <FormattedMessage id="Cancel" />
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export const DetailModal = ({ modal, closeModal, id }) => {
  const intl = useIntl();
  const { factory } = useContext(Context);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (id) {
      ApiPes_GetDowntimeDetail({
        FactoryID: factory,
        id,
      }).then((res) => setData(res.data));
    }
  }, [id]);

  return (
    <Modal
      className="modal_style fade"
      size="lg"
      centered
      show={modal === "detail"}
      onHide={closeModal}
    >
      <Modal.Header closeButton>
        <Modal.Title as="h5">
          <b>
            <FormattedMessage id="CycleTime" />
          </b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {data.length === 0 ? (
          <span>
            <FormattedMessage id="NoData" />
          </span>
        ) : (
          <div className="ai-center">
            <b className="flex-1 text-center">
              <FormattedMessage id="CycleTime" />
            </b>
            <b className="flex-1 text-center">
              <FormattedMessage id="CreateTime" />
            </b>
          </div>
        )}
        {data.map(({ cycle_time, created_at }) => (
          <div className="ai-center">
            <span className="flex-1 text-center">
              {cycle_time}
              <FormattedMessage id="Sec" />
            </span>
            <span className="flex-1 text-center">{created_at}</span>
          </div>
        ))}
      </Modal.Body>
    </Modal>
  );
};

export const BatchModal = ({ modal, closeModal, deviceID, reasonList }) => {
  const { factory, locale } = useContext(Context);
  const [date, setDate] = useState({
    startDate: moment().startOf("d").format("YYYY-MM-DD HH:mm"),
    endDate: moment().endOf("d").format("YYYY-MM-DD HH:mm"),
  });
  const [reason, setReason] = useState();

  useEffect(() => {
    if (!modal) {
      setDate({
        startDate: moment().startOf("d").format("YYYY-MM-DD HH:mm"),
        endDate: moment().endOf("d").format("YYYY-MM-DD HH:mm"),
      });
      setReason(reasonList[0]?.id);
    }
  }, [modal, reasonList]);

  const pickDateRange = (startDate, endDate) => {
    setDate((prev) => ({ ...prev, startDate, endDate }));
  };

  const submit = () => {
    ApiPes_BatchUpdateDowntimeReason({
      account: localStorage.getItem("account"),
      FactoryID: factory,
      DeviceID: deviceID,
      DowntimeID: reason,
      CheckDateStart: `${date.startDate}:00`,
      CheckDateEnd: `${date.endDate}:00`,
    }).then(closeModal);
  };

  return (
    <Modal
      className="modal_style fade"
      size="lg"
      centered
      show={modal === "batch"}
      onHide={closeModal}
    >
      <Modal.Header closeButton>
        <Modal.Title as="h5">
          <b>
            <FormattedMessage id="BatchEditDowntimeReason" />
          </b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="center flex-column">
        <div className="mb-3">
          <RangeDatePicker
            startDate={date.startDate}
            endDate={date.endDate}
            timePicker={true}
            onApply={pickDateRange}
          />
        </div>
        <Grid container spacing={2}>
          {reasonList.map((item) => (
            <Grid item key={item.id} xs={3}>
              <CustomRadio
                id={`reason_${item.id}`}
                value={item.id}
                onChange={() => setReason(item.id)}
                checked={reason === item.id}
                className="center flex-column"
              >
                {item[locale]}
              </CustomRadio>
            </Grid>
          ))}
        </Grid>
      </Modal.Body>
      <Modal.Footer className="jc-end">
        <button className="btn btn-confirm" onClick={submit}>
          <FormattedMessage id="OK" />
        </button>
        <button className="btn btn-cancel" onClick={closeModal}>
          <FormattedMessage id="Cancel" />
        </button>
      </Modal.Footer>
    </Modal>
  );
};
