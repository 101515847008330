import React, { useContext, useEffect, useMemo, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import Box from "@material-ui/core/Box";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { FormattedMessage, useIntl } from "react-intl";
import { getEmptyImage } from "react-dnd-html5-backend";
import { useDrag, useDrop, useDragLayer } from "react-dnd";
import { InputModalBase } from "./Management";
import {
  ApiOrder_GetNotSchedule,
  ApiOrder_ScheduleDelTime,
  ApiOrder_GetOrderScheduleFourStatus,
  postManagementScheduleAdd,
  postManagementScheduleInsert,
  baseURL,
} from "../../api";
import { SingleDropdownPicker } from "../common";
import Context from "../../context";

export const TimelineHead = ({ timeArr }) => {
  let head = [];
  if (timeArr.length > 12) {
    for (let i = 0; i < timeArr.length; i += 12) {
      head.push(moment(timeArr[i]).format("M/D"));
    }
  } else {
    head = timeArr.map((time) => moment(time).format("HH:mm"));
  }

  return (
    <div
      className="d-flex"
      style={{
        position: "sticky",
        top: 0,
        zIndex: 3,
      }}
    >
      <div
        style={{
          position: "sticky",
          height: "50px",
          width: "150px",
          left: 0,
          backgroundColor: "#1d2139",
        }}
        className="center"
      />
      {head.map((item, i) => (
        <div
          key={item}
          style={{
            height: "50px",
            backgroundColor: i % 2 === 0 ? "#252b4a" : "#1d2139",
            color: "white",
          }}
          className="center flex-1"
        >
          {item}
        </div>
      ))}
    </div>
  );
};

export const TimeRow = ({
  device_id,
  name,
  orderList,
  showModal,
  nonWorking,
  isDragging,
  setIsDragging,
  insertMode,
  refresh,
}) => {
  const { factory } = useContext(Context);
  const [status, setStatus] = useState({});

  const [{}, dropRef] = useDrop({
    accept: "card",
    drop: (item) => {
      const start_time = moment(
        document.getElementById("hint").textContent
      ).format("YYYY-MM-DD HH:mm:ss");
      onDrop({ ...item, start_time });
    },
  });

  useEffect(() => {
    if (isDragging) {
      ApiOrder_GetOrderScheduleFourStatus({
        factory_id: factory,
        device_id,
        order_num: isDragging,
      }).then((res) => setStatus(res.data));
    }
  }, [isDragging]);

  const onDrop = ({ start_time, order_num }) => {
    const req = {
      factory_id: factory,
      device_id,
      order_num,
      start_time,
    };
    if (insertMode) {
      postManagementScheduleInsert(req).then(refresh).catch(console.log);
    } else {
      postManagementScheduleAdd(req).then(refresh).catch(console.log);
    }
  };

  let background;
  let backgroundColor;
  let arr = [];
  let total = 0;
  if (isDragging) {
    if (status.status1) {
      backgroundColor = "rgba(125, 168, 255, 0.4)";
    } else if (status.status2) {
      backgroundColor = "rgba(131, 255, 125, 0.4)";
    } else if (status.status3) {
      backgroundColor = "rgba(248, 255, 125, 0.4)";
    } else if (status.status4) {
      backgroundColor = "rgba(222, 125, 255, 0.4)";
    } else if (status.status5) {
      backgroundColor = "rgba(255, 166, 102, 0.4)";
    }
  }
  background = `linear-gradient(90deg, ${backgroundColor} 0%, ${backgroundColor} 100%`;

  if (nonWorking && !backgroundColor) {
    background = "linear-gradient(90deg, ";
    Object.values(nonWorking).forEach((value) => {
      value.forEach((item) => {
        arr.push(item);
        total += item.time;
      });
    });
    let start = 0;
    arr.forEach(({ type, time }) => {
      const color =
        type === 1 ? "rgba(222, 45, 77, 0.1)" : "rgb(245, 246, 250)";
      background += `${color} ${start}%, ${color} ${
        start + (time / total) * 100
      }%, `;
      start += (time / total) * 100;
    });
    background = background.substring(0, background.length - 2) + ")";
  }

  return (
    <div className="d-flex">
      <div
        className="center"
        style={{
          height: "50px",
          width: "150px",
          position: "sticky",
          left: 0,
          backgroundColor: "#252b4a",
          color: "white",
          zIndex: 2,
          textAlign: "center",
        }}
      >
        {name}
      </div>
      <div
        className="d-flex"
        style={{
          position: "relative",
          width: `calc(100% - 150px)`,
          background,
        }}
        ref={dropRef}
      >
        {orderList.map((item, i) => (
          <OrderCard
            key={item.order_num}
            i={i}
            data={item}
            showModal={showModal}
            setIsDragging={setIsDragging}
          />
        ))}
      </div>
    </div>
  );
};

export const DragLayer = ({ date }) => {
  const { item, currentOffset, isDragging } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  const getItemStyles = () => {
    if (!currentOffset) {
      return {
        display: "none",
      };
    }
    const { x, y } = currentOffset;
    const transform = `translate(${x}px, ${y}px)`;

    return {
      transform,
      WebkitTransform: transform,
    };
  };

  if (item && currentOffset && isDragging) {
    const { duration_label, order_num_name, width, maxWidth, backgroundColor } =
      item;
    const cursor = isDragging ? "grabbing" : "grab";
    const ele = document.getElementById("scroll-area");
    let text;
    if (currentOffset.x - (ele.offsetLeft + 208) < ele.offsetWidth - 150) {
      text = moment(date.startDate)
        .add(
          ((currentOffset.x - (ele.offsetLeft + 208)) /
            (ele.offsetWidth - 150)) *
            3600 *
            24 *
            (moment(date.endDate).diff(date.startDate, "d") + 1),
          "s"
        )
        .format("YYYY-MM-DD HH:mm");
    }

    return (
      <div
        style={{
          position: "fixed",
          pointerEvents: "none",
          zIndex: 100,
          left: 0,
          top: 0,
        }}
      >
        <div
          className="ai-center pt-2 pb-2"
          style={{
            ...getItemStyles(),
            position: "relative",
            borderLeft: "5px solid orange",
          }}
        >
          {text && (
            <div
              style={{
                backgroundColor: "#cccccc",
                whiteSpace: "nowrap",
                width: "150px",
                textAlign: "center",
                marginRight: "10px",
                position: "absolute",
                right: "100%",
              }}
              id="hint"
            >
              {text}
            </div>
          )}
          <Box
            style={{
              backgroundColor: backgroundColor ? backgroundColor : "#aaaaaa",
              overflow: "hidden",
              whiteSpace: "nowrap",
              color: "white",
              zIndex: 1,
              cursor,
              width,
              maxWidth,
            }}
            className="ai-center jc-space-between p-2"
          >
            <div className="ai-center">
              <div
                style={{
                  border: "2px solid white",
                  width: "100px",
                  padding: "2px 0",
                }}
                className="center"
              >
                {duration_label}
              </div>
              <span className="ml-2">{order_num_name}</span>
            </div>
            <ChevronRightIcon />
          </Box>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

const OrderCard = ({ data, showModal, setIsDragging }) => {
  const {
    left,
    maxWidth,
    order_num,
    width,
    duration_label,
    order,
  } = data;
  const colors = [
    undefined,
    "#8B4513",
    "#2e40c7",
    "#7ca647",
    "#e93630",
    "gray",
  ];
  const { order_group_name, order_status, startDate, endDate } = order[0];
  const order_num_name = order_group_name || order[0].order_num_name;

  const [{ isDragging }, dragRef] = useDrag({
    type: "card",
    item: {
      order_num,
      duration_label,
      order_num_name,
      maxWidth,
      width,
      backgroundColor: colors[order_status],
    },
    canDrag: () => moment().isBefore(startDate),
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
  });

  useEffect(() => {
    if (isDragging) {
      setIsDragging(order_num);
    } else {
      setIsDragging(false);
    }
  }, [isDragging]);

  const cursor = isDragging ? "grabbing" : "grab";

  return (
    <OrderToolTip data={data}>
      <Box
        style={{
          left,
          width,
          backgroundColor: colors[order_status],
          maxWidth,
          position: "absolute",
          height: "50px",
          overflow: "hidden",
          whiteSpace: "nowrap",
          color: "white",
          padding: "0 10px",
          zIndex: 1,
          cursor,
        }}
        className="ai-center"
        ref={dragRef}
        onClick={
          moment().isBetween(startDate, endDate)
            ? undefined
            : () =>
                showModal(moment().isAfter(endDate) ? "delete" : "edit", data)
        }
        onMouseDown={(e) => e.stopPropagation()}
      >
        <div style={{ border: "2px solid white", padding: "2px 20px" }}>
          {duration_label}
        </div>
        <span className="ml-2">
          {order_num_name} {startDate}~{endDate}
        </span>
      </Box>
    </OrderToolTip>
  );
};

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  customWidth: {
    maxWidth: 500,
  },
  noMaxWidth: {
    maxWidth: "none",
  },
}));

const OrderToolTip = (props) => {
  const classes = useStyles();
  const list = [
    { key: "product_num", name: "product_num" },
    { key: "product_name", name: "product_name" },
    { key: "mold_num", name: "mold_num" },
    { key: "expect_quantity", name: "expect_quantity" },
  ];

  return (
    <Tooltip
      classes={{ tooltip: classes.noMaxWidth }}
      title={
        <div style={{ width: "350px" }}>
          {props.data.order?.map(
            ({
              id,
              order_num_name,
              startDate,
              endDate,
              duration_label,
              photo_path,
              ...data
            }) => (
              <div key={id} className="ai-center">
                <Grid
                  container
                  spacing={1}
                  className="p-2"
                  style={{
                    color: "white",
                    fontSize: "16px",
                    lineHeight: 1.1,
                  }}
                >
                  <Grid item xs={12}>
                    {order_num_name}
                  </Grid>
                  <Grid item xs={12}>
                    {startDate && endDate ? `${startDate}~${endDate}` : ""}(
                    {duration_label})
                  </Grid>
                  {list.map(({ key, name }) => (
                    <Grid key={key} item xs={12}>
                      <FormattedMessage id={name} />：{data[key]}
                    </Grid>
                  ))}
                </Grid>
                <img
                  src={photo_path}
                  style={{
                    objectFit: "contain",
                    height: "150px",
                    width: "150px",
                  }}
                  className="ml-3"
                />
              </div>
            )
          )}
        </div>
      }
      {...props}
    />
  );
};

export const RightColumn = ({
  style,
  trigger,
  refresh,
  showModal,
  setIsDragging,
}) => {
  const { factory } = useContext(Context);
  const intl = useIntl();
  const [orderList, setOrderList] = useState([]);
  const [selected, setSelected] = useState("product_num");
  const [text, setText] = useState("");
  const [{}, dropRef] = useDrop({
    accept: "card",
    drop: (item) => onDrop(item),
  });

  const onDrop = ({ order_num }) => {
    ApiOrder_ScheduleDelTime({
      factory_id: factory,
      order_num,
    })
      .then(refresh)
      .catch(console.log);
  };

  useEffect(() => {
    ApiOrder_GetNotSchedule({ factory_id: factory }).then((res) => {
      for (let item of res.data) {
        const h = Math.floor(item.duration / 3600);
        const m = Math.ceil((item.duration - h * 3600) / 60);
        item.duration_label = `${h}h${m}m`;
        for (let order of item.order) {
          const h = Math.floor(order.duration / 3600);
          const m = Math.ceil((order.duration - h * 3600) / 60);
          order.duration_label = `${h}h${m}m`;
          if (order.photo_path) {
            order.photo_path = baseURL + order.photo_path;
          }
        }
      }
      setOrderList(res.data);
    });
  }, [factory, trigger]);

  const pickerList = [
    {
      value: "product_num",
      label: intl.formatMessage({ id: "product_num" }),
    },
    {
      value: "mold_num",
      label: intl.formatMessage({ id: "mold_num" }),
    },
    {
      value: "order_num_name",
      label: intl.formatMessage({ id: "order_num_name" }),
    },
  ];

  const List = useMemo(() => {
    return (
      <div
        style={{
          overflow: "auto",
          height: "70vh",

          backgroundColor: "#efefef",
        }}
        className="p-2"
      >
        {orderList
          .filter((item) =>
            text
              ? item[selected]
                  ?.toLowerCase()
                  .startsWith(text.toLocaleLowerCase())
              : true
          )
          .map((item) => (
            <UnscheduledOrderCard
              key={item.order_num}
              data={item}
              showModal={showModal}
              setIsDragging={setIsDragging}
            />
          ))}
      </div>
    );
  }, [text, orderList, selected]);

  return (
    <div style={style} ref={dropRef}>
      <div className="ai-center jc-space-between mb-3">
        <span>
          <FormattedMessage id="UnassignedOrders" />
        </span>
        <div className="ai-stretch">
          <SingleDropdownPicker
            style={{ width: "100px" }}
            list={pickerList}
            value={selected}
            onApply={(val) => setSelected(val)}
          />
          <input
            className="text-input"
            style={{ borderRight: "none", width: "6vw" }}
            value={text}
            onChange={(e) => setText(e.currentTarget.value)}
          />
          <button className="input-button center">
            <SearchIcon />
          </button>
        </div>
      </div>
      {List}
    </div>
  );
};

const UnscheduledOrderCard = ({ data, showModal, setIsDragging }) => {
  const { duration_label, order, order_num } = data;
  const order_num_name = order[0]?.order_group_name || order[0]?.order_num_name;

  const [{ isDragging }, dragRef, preview] = useDrag({
    type: "card",
    item: { order_num, order_num_name, duration_label },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, []);

  useEffect(() => {
    if (isDragging) {
      setIsDragging(order_num);
    } else {
      setIsDragging(false);
    }
  }, [isDragging]);

  const cursor = isDragging ? "grabbing" : "grab";

  return (
    <OrderToolTip data={data}>
      <Box
        style={{
          backgroundColor: "#aaaaaa",
          whiteSpace: "nowrap",
          color: "white",
          zIndex: 1,
          cursor,
        }}
        className="ai-center jc-space-between mb-2 p-2"
        ref={dragRef}
        onClick={() => showModal("new", data)}
      >
        <div className="ai-center">
          <div
            style={{
              border: "2px solid white",
              width: "100px",
              padding: "2px 0",
            }}
            className="center"
          >
            {duration_label}
          </div>
          <span className="ml-2">{order_num_name}</span>
        </div>
        <ChevronRightIcon />
      </Box>
    </OrderToolTip>
  );
};

export const ScheduleModal = ({
  modal,
  onHide,
  data,
  deviceList,
  insertMode,
}) => {
  const intl = useIntl();
  const { factory } = useContext(Context);
  const [select, setSelect] = useState({});

  useEffect(() => {
    setSelect(JSON.parse(JSON.stringify(data)));
  }, [data]);

  const submit = () => {
    return new Promise((resolve, reject) => {
      const req = {
        ...select,
        factory_id: factory,
        start_time: moment(select.start_time).format("YYYY-MM-DD HH:mm:ss"),
      };
      if (insertMode) {
        postManagementScheduleInsert(req).then(resolve).catch(reject);
      } else {
        postManagementScheduleAdd(req).then(resolve).catch(reject);
      }
    });
  };

  const deleteSchedule = () => {
    ApiOrder_ScheduleDelTime({
      factory_id: factory,
      order_num: select.order_num,
    })
      .then(onHide)
      .catch(console.log);
  };

  const title = intl.formatMessage(
    { id: modal === "new" ? "Create" : "EditThe" },
    { name: intl.formatMessage({ id: "Schedule" }) }
  );

  const columns = [
    {
      id: data.order_group_name ? "order_group_name" : "order_num_name",
      type: "text",
      required: true,
      disabled: true,
    },
    {
      id: "start_time",
      type: "datetime",
      required: true,
    },
    {
      id: "device_id",
      type: "check",
      required: true,
      options: deviceList.map(({ id, device_name }) => ({
        label: device_name,
        value: id,
      })),
    },
  ];

  return (
    <InputModalBase
      show={modal === "new" || modal === "edit"}
      onHide={onHide}
      title={title}
      columns={columns}
      select={select}
      setSelect={setSelect}
      submit={submit}
      hasDelete={modal === "edit"}
      deleteData={deleteSchedule}
    />
  );
};
