import React, { useEffect, useState } from "react";
import { MdSearch } from "react-icons/md";
import { FormattedMessage, useIntl } from "react-intl";
import { SysTabs } from "../../component/system/system";
import {
  FactoryTable,
  InputModal as FactoryInputModal,
} from "../../component/root/factory";
import {
  DeviceTable,
  InputModal as DeviceInputModal,
} from "../../component/root/Device";
import {
  UserTable,
  InputModal as UserInputModal,
  AuthModal,
} from "../../component/root/user";
import {
  NewButton,
  DeleteModal,
  handleError,
  MultiDropdownPicker,
  LinkBar,
} from "../../component/common";
import {
  deleteFactory,
  getConnection,
  deleteFactoryDevice,
  getFactory,
  ApiSys,
  ApiSysDeleteUser,
  baseURL,
  getRole,
  getUserImage,
  getRegionAndFactoryNameList,
} from "../../api.js";

const Menu = ({ tab }) => {
  const list = [
    { to: "/pages/system/factory/factory", name: "FactoryList" },
    { to: "/pages/system/factory/device", name: "DeviceList" },
    { to: "/pages/system/factory/user", name: "UserList" },
  ];
  return <LinkBar list={list} tab={tab} />;
};

function SysFactoryFactory({ checkEditAuth }) {
  const [modal, setModal] = useState("");
  const [select, setSelect] = useState({});
  const intl = useIntl();

  const showModal = (modal, item = {}) => {
    if (!checkEditAuth()) {
      return;
    }
    setSelect(item);
    setModal(modal);
  };

  const closeModal = () => {
    setModal();
  };

  const deleteData = () => {
    deleteFactory(select.id)
      .then(closeModal)
      .catch((err) => handleError(err, intl));
  };

  return (
    <div>
      <SysTabs tab="3" />
      <div className="page-content shadow-lg">
        <div className="jc-end">
          <Menu tab="0" />
        </div>
        <hr />
        <div className="jc-space-between ai-center">
          <h4 className="m-0">
            <b>
              <FormattedMessage id="FactoryList" />
            </b>
          </h4>
          <NewButton
            onClick={() => showModal("new")}
            text={intl.formatMessage(
              { id: "Create" },
              { name: intl.formatMessage({ id: "Factory" }) }
            )}
          />
        </div>
        <hr />
        <FactoryTable showModal={showModal} modal={modal} />
      </div>
      <FactoryInputModal modal={modal} data={select} onHide={closeModal} />

      <DeleteModal
        show={modal === "delete"}
        onHide={closeModal}
        name={select.name}
        type="Factory"
        callback={deleteData}
      />
    </div>
  );
}

function SysFactoryDevice({ checkEditAuth }) {
  const [modal, setModal] = useState("");
  const [connectionList, setConnectionList] = useState([]);
  const [factoryList, setFactoryList] = useState([]);
  const [factory_id, setFactoryID] = useState([]);
  const [select, setSelect] = useState({});
  const intl = useIntl();

  useEffect(() => {
    getRegionAndFactoryNameList({
      account: localStorage.getItem("account"),
    }).then((res) => {
      setFactoryList(res.data);
      setFactoryID(res.data.map(({ id }) => id));
    });
    getConnection().then((res) => setConnectionList(res.data));
  }, []);

  const showModal = (modal, item = {}) => {
    if (!checkEditAuth()) {
      return;
    }
    setSelect(item);
    setModal(modal);
  };

  const closeModal = () => {
    setModal();
    setSelect({});
  };

  const deleteData = () => {
    deleteFactoryDevice(select.factory_id, select.id)
      .then(closeModal)
      .catch((err) => handleError(err, intl));
  };

  return (
    <div>
      <SysTabs tab="3" />
      <div className="page-content shadow-lg">
        <div className="jc-end">
          <Menu tab="1" />
        </div>
        <hr />
        <div className="jc-space-between ai-center">
          <h4 className="m-0">
            <b>
              <FormattedMessage id="DeviceList" />
            </b>
          </h4>
          <div className="ai-stretch">
            <MultiDropdownPicker
              title={intl.formatMessage(
                { id: "PickA" },
                { name: intl.formatMessage({ id: "Factory" }) }
              )}
              list={factoryList.map(({ id, name }) => ({
                value: id,
                label: name,
              }))}
              selected={factory_id}
              onApply={setFactoryID}
              style={{ marginRight: "20px" }}
            />
            <NewButton
              onClick={() => showModal("new")}
              text={intl.formatMessage(
                { id: "Create" },
                { name: intl.formatMessage({ id: "Device" }) }
              )}
            />
          </div>
        </div>
        <hr />
        <DeviceTable
          showModal={showModal}
          modal={modal}
          factory_id={factory_id}
          connectionList={connectionList}
        />
      </div>

      <DeviceInputModal
        modal={modal}
        closeModal={closeModal}
        data={select}
        factoryList={factoryList}
        connectionList={connectionList}
      />

      <DeleteModal
        show={modal === "delete"}
        onHide={closeModal}
        name={select.device_num}
        type={"ThisDevice"}
        callback={deleteData}
      />
    </div>
  );
}

function SysFactoryUsers({ checkEditAuth }) {
  const [roleList, setRoleList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [factoryList, setFactoryList] = useState([]);
  const [factory_id, setFactoryID] = useState([]);
  const [modal, setModal] = useState();
  const [select, setSelect] = useState({ auth: {} });
  const [auth, setAuth] = useState();
  const [searchString, setSearchString] = useState("");
  const intl = useIntl();

  useEffect(() => {
    getRole().then((res) => setRoleList(res.data));
    getRegionAndFactoryNameList({
      account: localStorage.getItem("account"),
    }).then((res) => {
      setFactoryList(res.data);
      setFactoryID(res.data.map(({ id }) => id));
    });
  }, []);

  useEffect(() => {
    if (!modal && factory_id) {
      ApiSys({
        account: localStorage.getItem("account"),
        factory_id,
      }).then(async (res) => {
        for (let i = 0; i < res.data.UserDataList.length; ++i) {
          if (res.data.UserDataList[i].photo_path) {
            try {
              const { data } = await getUserImage(
                res.data.UserDataList[i].photo_path
              );
              res.data.UserDataList[i].photo = data;
            } catch (e) {
              console.log(e);
              res.data.UserDataList[
                i
              ].photo = `${baseURL}/${res.data.UserDataList[i].photo_path}`;
            }
          }
        }
        setUserList(
          res.data.UserDataList.sort((a, b) => a.role_id - b.role_id)
        );
        setAuth(res.data.AuthLevelCheck);
      });
    }
  }, [modal, factory_id]);

  const showModal = (modal, item = { auth: {} }) => {
    if (!checkEditAuth()) {
      return;
    }

    if (modal === "edit" && item.photo_path) {
      const arr = item.photo_path.split("/");
      item.fileName = arr[arr.length - 1];
    }
    setSelect(item);
    setModal(modal);
  };

  const closeModal = () => {
    setModal();
    setSelect({ auth: {} });
  };

  const deleteData = () => {
    ApiSysDeleteUser({
      account: localStorage.getItem("account"),
      Email: select.email,
    })
      .then(closeModal)
      .catch((err) => handleError(err, intl));
  };

  return (
    <div>
      <SysTabs tab="3" />
      <div className="page-content shadow-lg">
        <div className="jc-end">
          <Menu tab="2" />
        </div>
        <hr />
        <div className="jc-space-between ai-center">
          <h4 className="m-0">
            <b>
              <FormattedMessage id="UserList" />
            </b>
          </h4>
          <div className="ai-stretch">
            <div className="ai-stretch" style={{ marginRight: "20px" }}>
              <input
                className="text-input"
                style={{ borderRight: "none", width: "150px" }}
                value={searchString}
                onChange={(e) => setSearchString(e.currentTarget.value)}
                placeholder={intl.formatMessage(
                  { id: "InputThe" },
                  { name: intl.formatMessage({ id: "Keywords" }) }
                )}
              />
              <button className="input-button center">
                <MdSearch />
              </button>
            </div>
            <MultiDropdownPicker
              style={{ marginRight: "20px" }}
              title={intl.formatMessage(
                { id: "PickA" },
                { name: intl.formatMessage({ id: "Factory" }) }
              )}
              list={factoryList.map(({ id, name }) => ({
                label: name,
                value: id,
              }))}
              selected={factory_id}
              onApply={setFactoryID}
            />
            <NewButton
              onClick={() => showModal("new")}
              text={intl.formatMessage(
                { id: "Create" },
                { name: intl.formatMessage({ id: "User" }) }
              )}
            />
          </div>
        </div>
        <hr />
        <UserTable
          showModal={showModal}
          data={userList}
          searchString={searchString}
        />
      </div>

      <UserInputModal
        modal={modal}
        closeModal={closeModal}
        data={select}
        roleList={roleList}
      />

      <AuthModal
        modal={modal}
        closeModal={closeModal}
        data={select}
        auth={auth}
      />

      <DeleteModal
        show={modal === "delete"}
        onHide={closeModal}
        callback={deleteData}
        name={select.name}
        type="User"
      />
    </div>
  );
}

export { SysFactoryFactory, SysFactoryDevice, SysFactoryUsers };
