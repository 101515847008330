import React, { useEffect, useState, useContext } from "react";
import { MdTimelapse, MdLocationOn, MdAccessTime } from "react-icons/md";
import { Tabs } from "../common";
import { orderStatus } from "../../function";
import { baseURL, ApiPes_GetDeviceDataList } from "../../api.js";
import Context from "../../context";

function PesTabs({ tab }) {
  let list = [
    { to: "/pages/mes/production", name: "ProductionManagement" },
    { to: "/pages/mes/defective", name: "DefectiveManagement" },
    { to: "/pages/mes/downtime", name: "DowntimeManagement" },
    { to: "/pages/mes/handover", name: "HandoverReport" },
  ];
  return <Tabs list={list} tab={tab} />;
}

function DeviceList({ deviceID, pickDevice, modal }) {
  const [deviceList, setDeviceList] = useState([]);
  const { factory } = useContext(Context);

  useEffect(() => {
    if (!modal) {
      loadData();
    }
  }, [modal, deviceID]);

  const loadData = () => {
    ApiPes_GetDeviceDataList({
      FactoryID: factory,
    })
      .then((res) => {
        if (!deviceID) {
          pickDevice(res.data[0].DeviceID);
        }
        setDeviceList(res.data);
      })
      .catch(console.log);
  };

  return (
    <ul className="list-unstyled m-0 flex-column ai-stretch device-list">
      {deviceList.map((device) => {
        const onProduce = device.order_status === 2;
        const active = device.DeviceID === deviceID;
        return (
          <li
            className={`jc-space-between ${active ? "active" : ""}`}
            key={device.DeviceID}
            onClick={() => pickDevice(device.DeviceID)}
          >
            <div className="flex-column">
              <h6>
                <b>{device.id}</b>
              </h6>
              {onProduce ? (
                <h6 className="ai-center">
                  <MdTimelapse style={{ marginRight: "5px" }} />
                  {`${device.cycle}(sec)`}
                </h6>
              ) : null}
              {onProduce ? (
                <h6 className="ai-center">
                  <MdLocationOn style={{ marginRight: "5px" }} />
                  {device.line}
                </h6>
              ) : null}
              {onProduce ? (
                <h6 className="ai-center">
                  <MdAccessTime style={{ marginRight: "5px" }} />
                  {device.endtime}
                </h6>
              ) : null}
            </div>
            <div className="flex-column ai-end">
              <b className={onProduce ? "active" : ""}>
                {orderStatus(device.order_status)}
              </b>
              {onProduce ? (
                <img
                  className="fit-container"
                  src={device.photo_path ? baseURL + device.photo_path : ""}
                  style={{ height: "6vh" }}
                />
              ) : null}
            </div>
          </li>
        );
      })}
    </ul>
  );
}

export { PesTabs, DeviceList };
