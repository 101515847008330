import React, { useState, useEffect, useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link, useHistory } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Dropdown from "react-bootstrap/Dropdown";
import Accordion from "react-bootstrap/Accordion";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import {
  MdMenu,
  MdLock,
  MdArrowDropDown,
  MdLanguage,
  MdFullscreen,
  MdFullscreenExit,
  MdHome,
  MdDvr,
  MdStorage,
  MdEqualizer,
  MdPeople,
  MdSettings,
} from "react-icons/md";
import { FiLogOut } from "react-icons/fi";
import { FaRegUserCircle } from "react-icons/fa";
import { useTable, useSortBy, useFilters, usePagination } from "react-table";
import "bootstrap/dist/js/bootstrap.min.js";

import "./component.css";
import {
  getFactory,
  apiReadUserNotification,
  baseURL,
  getGroup,
  putUserUpdatePassword,
  getRegion,
  getRegionAndFactoryNameList,
} from "../api.js";
import Context from "../context";
import { showWarn } from "../function";
import "../scss/component.scss";

function Frame({ locale, setLocale }) {
  const [sidebarHover, setHover] = useState(false);
  const [menuClick, setClick] = useState(false);

  const handleMenuClick = () => {
    setClick((prev) => !prev);
  };

  const handleMouseEnter = () => {
    setHover(true);
  };

  const handleMouseLeave = () => {
    setHover(false);
  };

  return (
    <React.Fragment>
      <Header
        sidebarHover={sidebarHover}
        menuClick={menuClick}
        handleMenuClick={handleMenuClick}
        locale={locale}
        setLocale={setLocale}
      />
      <SideNav
        sidebarHover={sidebarHover}
        menuClick={menuClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      />
    </React.Fragment>
  );
}

function Header({ setLocale, handleMenuClick, sidebarHover, menuClick }) {
  const [factoryName, setFactoryName] = useState("");
  const [fullScreen, setFullScreen] = useState(false);
  const [modal, setModal] = useState(false);
  const [regionList, setRegionList] = useState([]);
  const [factoryList, setFactoryList] = useState([]);
  const [inputData, setInputData] = useState({});
  const [logo, setLogo] = useState("");
  const intl = useIntl();

  const { name, photoPath, group_id, factory, setFactory, setToken } =
    useContext(Context);

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    const result = factoryList.find(({ id }) => id === factory);
    if (result) {
      setFactoryName(result.name);
    }
  }, [factory, factoryList]);

  const _setLocale = (locale) => {
    localStorage.setItem("locale", locale);
    setLocale(locale);
  };

  const closeModal = () => {
    setInputData({});
    setModal(false);
  };

  const toggleFullScreen = () => {
    if (fullScreen) {
      if (document.fullscreenElement) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        }
      }
    } else {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.msRequestFullscreen) {
        document.documentElement.msRequestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    }
    setFullScreen((prev) => !prev);
  };

  const chooseFactory = (factory) => {
    localStorage.setItem("factory", factory);
    setFactory(factory);
    window.location.reload();
  };

  const loadData = () => {
    getRegion()
      .then((res) => {
        const regionList = res.data;
        regionList.sort((a, b) => a.id - b.id);
        getRegionAndFactoryNameList({
          account: localStorage.getItem("account"),
        })
          .then((res) => {
            let factoryList = [];
            res.data.forEach(({ regionId, id, name }) => {
              const region_id = regionId;
              const index = factoryList.findIndex(
                (item) => item.region_id === region_id
              );
              if (index === -1) {
                factoryList.push({
                  region_id,
                  region_name: regionList[region_id - 1].region,
                  data: [{ id, name }],
                });
              } else {
                factoryList[index].data.push({ id, name });
              }
            });
            if (!factory) {
              chooseFactory(res.data[0].id);
            }
            setRegionList(factoryList);
            setFactoryList(res.data);
          })
          .catch(console.log);
      })
      .catch(console.log);

    getGroup()
      .then((res) => {
        const result = res.data.find(({ id }) => id === group_id);
        if (result) {
          setLogo(`${baseURL}/${result.photo_path.replace("web/", "")}`);
        } else {
          setLogo("./images/logo/logo.png");
        }
      })
      .catch(console.log);
  };

  const changeState = (event) => {
    const { id, value } = event.currentTarget;
    setInputData((prev) => ({ ...prev, [id]: value }));
  };

  const changePassword = () => {
    document.querySelectorAll(".warn").forEach((ele) => (ele.hidden = true));
    const { password, confirmPassword } = inputData;
    if (password && password === confirmPassword) {
      putUserUpdatePassword({ password })
        .then(() => {
          alert(intl.formatMessage({ id: "Succeed" }));
          closeModal();
        })
        .catch(() => {
          const ele = document.getElementById("error");
          ele.textContent = intl.formatMessage({ id: "Failed" });
          ele.hidden = false;
        });
    } else {
      if (password !== confirmPassword) {
        showWarn("confirmPassword", "ConfrimPasswordWrong", intl);
      } else {
        showWarn("password", "Required", intl);
        showWarn("ConfrimPasswordWrong", "Required", intl);
      }
    }
  };

  const logout = () => {
    localStorage.clear();
    setToken(null);
    setFactory();
  };

  const onToggle = (isOpen, e, metadata) => {
    if (metadata.source === "rootClose") {
      return;
    }
  };

  const languages = [
    { id: "en", name: "english" },
    { id: "zh-TW", name: "繁體中文" },
    { id: "vi", name: "Tiếng Việt" },
  ];

  return (
    <React.Fragment>
      <header className="header-area">
        <div className="icon ai-center">
          <MdMenu
            onClick={handleMenuClick}
            color={sidebarHover || menuClick ? "white" : ""}
          />
        </div>
        <div style={{ flex: 1 }} className="ai-center jc-space-between">
          <Link to="/" style={{ height: "100%" }} className="ai-center">
            <img src={logo} className="fit-contain" style={{ height: "80%" }} />
          </Link>
          {fullScreen ? (
            <MdFullscreenExit onClick={toggleFullScreen} className="icon" />
          ) : (
            <MdFullscreen onClick={toggleFullScreen} className="icon" />
          )}
        </div>
        <div className="ai-stretch">
          <Dropdown onToggle={onToggle}>
            <Dropdown.Toggle
              as="a"
              style={{ height: "100%" }}
              className="center"
            >
              {factoryName}
            </Dropdown.Toggle>
            <Accordion>
              <Dropdown.Menu>
                {regionList.map(({ region_id, region_name, data }) => (
                  <div key={region_id}>
                    <Accordion.Toggle
                      as="a"
                      eventKey={region_id}
                      className="dropdown-item border-bottom"
                    >
                      <span>
                        {region_name}
                        <MdArrowDropDown />
                      </span>
                    </Accordion.Toggle>
                    <Accordion.Collapse
                      eventKey={region_id}
                      style={{
                        maxHeight: window.innerHeight - 60 - 60,
                        overflowY: "scroll",
                      }}
                    >
                      <div className="border-bottom w-100">
                        {data.map(({ id, name }) => (
                          <Dropdown.Item
                            key={id}
                            onClick={() => chooseFactory(id)}
                          >
                            {name}
                          </Dropdown.Item>
                        ))}
                      </div>
                    </Accordion.Collapse>
                  </div>
                ))}
              </Dropdown.Menu>
            </Accordion>
          </Dropdown>
        </div>
        <div className="ai-center">
          <FormattedMessage id="Hello" />，{name}！
          <Dropdown style={{ height: "100%" }}>
            <Dropdown.Toggle
              as="a"
              className="hide-after ai-center"
              style={{ height: "100%", margin: "0 10px" }}
            >
              {photoPath ? (
                <img className="user-pic" src={baseURL + photoPath} />
              ) : (
                <FaRegUserCircle size="30px" />
              )}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => setModal(true)}
                className="border-bottom"
              >
                <MdLock style={{ marginRight: "10px" }} />
                <FormattedMessage id="ChangePassword" />
              </Dropdown.Item>

              <Accordion>
                <Accordion.Toggle
                  as="a"
                  eventKey="0"
                  className="dropdown-item jc-space-between border-bottom"
                >
                  <span>
                    <MdLanguage style={{ marginRight: "10px" }} />
                    <FormattedMessage id="Language" />
                  </span>
                  <MdArrowDropDown />
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0" className="border-bottom">
                  <div>
                    {languages.map((item) => (
                      <Dropdown.Item
                        key={item.id}
                        onClick={() => _setLocale(item.id)}
                      >
                        {item.name}
                      </Dropdown.Item>
                    ))}
                  </div>
                </Accordion.Collapse>
              </Accordion>

              <Dropdown.Item onClick={logout}>
                <FiLogOut style={{ marginRight: "10px" }} />
                <FormattedMessage id="Logout" />
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </header>

      <Modal
        className="modal_style fade"
        size="lg"
        show={modal}
        onHide={closeModal}
      >
        <Modal.Header closeButton>
          <Modal.Title as="h5">
            <b>
              <FormattedMessage id="ChangePassword" />
            </b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group as={Row} controlId="password">
              <Form.Label column lg="3">
                <FormattedMessage id="NewPassword" />
              </Form.Label>
              <Col lg="9">
                <Form.Control
                  type="password"
                  placeholder={
                    intl.formatMessage({ id: "PleaseEnterThe" }) +
                    intl.formatMessage({ id: "NewPassword" })
                  }
                  onChange={changeState}
                  value={inputData.password}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="confirmPassword">
              <Form.Label column lg="3">
                <FormattedMessage id="ConfirmPassword" />
              </Form.Label>
              <Col lg="9">
                <Form.Control
                  type="password"
                  placeholder={
                    intl.formatMessage({ id: "PleaseEnterThe" }) +
                    intl.formatMessage({ id: "ConfirmPassword" })
                  }
                  onChange={changeState}
                  value={inputData.confirmPassword}
                />
                <span className="warn" />
              </Col>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-end">
          <span id="error" className="warn mr-3" />
          <button className="btn btn-confirm" onClick={changePassword}>
            <FormattedMessage id="OK" />
          </button>
          <button className="btn btn-cancel" onClick={closeModal}>
            <FormattedMessage id="Cancel" />
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

function SideNav({ menuClick, onMouseEnter, onMouseLeave }) {
  const history = useHistory();
  const { auth } = useContext(Context);
  const [_auth, setAuth] = useState({});

  useEffect(() => {
    if (auth) {
      const pages = [
        "index",
        "dashboard",
        "mes",
        "analysis",
        "management",
        "system",
        "root",
        "employee",
        "quality",
      ];
      let _auth = {};
      pages.forEach((key) => (_auth[key] = { auth: false }));
      Object.keys(auth).forEach((key) => {
        const page = pages.find((e) => key.startsWith(e) && auth[key] > 0);
        if (page && !_auth[page].auth) {
          _auth[page].auth = true;
          const arr = key.split("_");
          _auth[page].page = arr.join("/");
          if (page === "mes") {
            _auth[page].page = "mes/production";
          }
          if (page === "analysis") {
            _auth[page].page = "analysis/oee";
          }
          if (page === "management") {
            _auth[page].page = "management/order";
          }
          if (page === "system") {
            _auth[page].page = "system/user";
          }
          if (page === "employee") {
            _auth[page].page = "employee/device";
          }
        }
      });
      setAuth(_auth);
    }
  }, [auth]);

  const items = [
    {
      key: "index",
      icon: <MdHome />,
      name: "ProductionOverview",
    },
    {
      key: "dashboard",
      icon: <MdDvr />,
      name: "Monitoring",
    },
    {
      key: "mes",
      icon: <MdStorage />,
      name: "Operation",
    },
    {
      key: "analysis",
      icon: <MdEqualizer />,
      name: "ProduceAnalysisReport",
    },
    {
      key: "management",
      icon: <MdPeople />,
      name: "ProductionAdmin",
    },
    {
      key: "system",
      icon: <MdSettings />,
      name: "SystemAdmin",
    },
    {
      key: "root",
      icon: <MdSettings />,
      name: "Root",
    },
    {
      key: "employee",
      icon: <MdPeople />,
      name: "EmployeeEfficiency",
    },
    {
      key: "quality",
      icon: <MdEqualizer />,
      name: "QualityManagement",
    },
  ];

  return (
    <nav
      className={`sidebar-area ${menuClick ? "active" : ""}`}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <ul className="list-unstyled mb-0">
        {items.map(({ key, icon, name }) => {
          if (_auth[key]?.auth) {
            const path = `/pages/${_auth[key].page}`;
            return (
              <li key={key}>
                <Link
                  to={path}
                  className={
                    history.location.pathname.startsWith(`/pages/${key}`)
                      ? "active"
                      : ""
                  }
                >
                  <div className="icon center">{icon}</div>
                  <span>
                    <FormattedMessage id={name} />
                  </span>
                </Link>
              </li>
            );
          } else {
            return null;
          }
        })}
      </ul>
    </nav>
  );
}

function Tabs({ children }) {
  return <ul className="nav nav-tabs tabs_area clearfix">{children}</ul>;
}

function Table({ columns, data, tableOption }) {
  var hiddenPagination;

  if (tableOption) {
    hiddenPagination = tableOption.hiddenPagination;
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: tableOption
          ? tableOption.pageSize
            ? tableOption.pageSize
            : 10
          : 10,
      },
    },
    useFilters,
    useSortBy,
    usePagination
  );
  let editor = false;
  let tfoot = false;
  let withFilter = false;

  if (tableOption) {
    if (tableOption.editorHtml) editor = true;
    if (tableOption.tfootHtml) tfoot = true;
    if (tableOption.withFilter) withFilter = true;
  }

  return (
    <>
      <table {...getTableProps()} className="table table-striped mb-0">
        <thead>
          {headerGroups.map((headerGroup, i) => (
            <React.Fragment key={i}>
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(
                  (
                    column,
                    index // Add the sorting props to control sorting. For this example // we can add them into the header props
                  ) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className={
                        "th_style " + (index == 0 ? "" : "text-center")
                      }
                      style={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      {/* Add a sort direction indicator */}
                      <span>
                        {column.render("Header")}
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <span className="th_sort sort_desc"></span>
                          ) : (
                            <span className="th_sort sort_asc"></span>
                          )
                        ) : (
                          <span
                            className={column.canSort ? "th_sort" : "th_style"}
                          ></span>
                        )}
                      </span>
                    </th>
                  )
                )}
                {editor && tableOption.monitor != null
                  ? tableOption.monitor[0]
                  : null}
                {editor ? tableOption.editorHtml[0] : null}
              </tr>
              {withFilter ? (
                <tr>
                  {headerGroup.headers.map((column, index) => (
                    <td
                      key={index}
                      className={
                        "pes_input " + (index == 0 ? "" : "text-center")
                      }
                    >
                      {column.canFilter ? column.render("Filter") : null}
                    </td>
                  ))}
                </tr>
              ) : null}
            </React.Fragment>
          ))}
        </thead>
        {tfoot ? tableOption.tfootHtml[0] : null}
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);

            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell, index) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      value={cell.row.original.mold_id}
                      id={cell.row.original.id}
                      className={index === 0 ? "" : "text-center"}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
                {editor && tableOption.monitor != null
                  ? tableOption.monitor_render(row.original.monitor)
                  : null}
                {editor ? tableOption.editorHtml[1] : null}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="d-flex justify-content-xl-end justify-content-md-center">
        <nav className="pagination_area clearfix">
          <ul className="pagination mb-0">
            <li className="page-item">
              <button
                hidden={hiddenPagination}
                id="previousPage"
                className="page-link"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                <FormattedMessage id="PreviousPage" /> {/*上一頁*/}
              </button>
            </li>
            <li className="page-item">
              <span hidden={hiddenPagination} className="page-link">
                <FormattedMessage id="The" /> {/*第*/}
                {pageIndex + 1 + "/" + (pageCount == 0 ? 1 : pageCount)}
                <FormattedMessage id="Page" /> {/*頁*/}
              </span>
            </li>
            <li className="page-item">
              <button
                hidden={hiddenPagination}
                id="nextPage"
                className="page-link"
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                <FormattedMessage id="NextPage" /> {/*下一頁*/}
              </button>
            </li>
            <input
              hidden={true}
              id="gotoPage"
              onClick={(e) => gotoPage(e.target.value)}
            />
          </ul>
        </nav>
      </div>
    </>
  );
}

// * 元件使用方法：https://developer.aliyun.com/mirror/npm/package/marquee-react-dwyer
function MaintenanceBar({ data }) {
  const [text, set_text] = React.useState("");

  //處理顯示資料
  React.useEffect(() => {
    let result = "";
    // let t = `${data[0].content}時間: ${moment(data[0].date).format("YYYY-MM-DD")}`;
    let t = `${data[0].content}`;
    for (let i = 0; i < 20; i++) {
      //加入空白
      t += "\u00A0";
    }
    for (let i = 0; i < 10; i++) {
      result += t;
    }
    set_text(result);
  }, []);

  return (
    <div className="marquee">
      <marquee direction="left" scrillamount="2" style={{ color: "#ffffff" }}>
        {text}
      </marquee>
    </div>
  );
}

export { Frame, Tabs, Table, Header, MaintenanceBar };
