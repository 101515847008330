import React, { useContext, useEffect, useState } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { Frame, MaintenanceBar } from "../component/Component";
import { apiAnnouncement } from "../api";
import { IndexDevice, IndexOee, IndexProduction } from "./index";
import { Dashboard, Dashboard2x2 } from "./dashboard";
import MesProduction from "./mes/Production";
import MesDefective from "./mes/Defective";
import MesDowntime from "./mes/Downtime";
import MesHandover from "./mes/Handover";
import {
  AnalysisOrder,
  AnalysisProduction,
  AnalysisBad,
  AnalysisDowntime,
} from "./analysis/analysis";
import AnalysisOeeOverview from "./analysis/OeeOverview";
import AnalysisAmountInterval from "./analysis/AmountInterval";
import AnalysisAmountDaily from "./analysis/AmountDaily";
import AnalysisParameterControl from "./analysis/ParameterControl";
import AnalysisParameterProcess from "./analysis/ParameterProcess";
import AnalysisSchedule from "./analysis/order";
import { AnalysisProductionOverview } from "./analysis/production";
import ManagementOrder from "./management/Order";
import ManagementSchedule from "./management/schedule";
import ManagementMaterial from "./management/material";
import ManagementMold from "./management/mold";
import ManagementProduct from "./management/product";
import ManagementShift from "./management/Shift";
import ManagementHoliday from "./management/holiday";
import ManagementDefective from "./management/Defective";
import ManagementDowntime from "./management/downtime";
import ManagementUnproductiveOrder from "./management/Unproductive";
import Sys from "./system/User";
import SysDashboard from "./system/Dashboard";
import SysDeviceGroup from "./system/DeviceGroup";
import {
  SysFactoryFactory,
  SysFactoryDevice,
  SysFactoryUsers,
} from "./system/factory";
import {
  SysNotifyFactory,
  SysNotifyMold,
  SysNotifyDefective,
} from "./system/Notify";
import SysFile from "./system/File";
import SystemLog from "./system/Log";
import SysRole from "./system/Role";
import RootFactory from "./root/factory";
import RootDevice from "./root/Device";
import RootUsers from "./root/User";
import RootGroup from "./root/group";
import RootSql from "./root/sql";
import {
  EmployeeWork,
  EmployeeSetting,
  EmployeeLog,
} from "./employee/employee";
import { EmployeeMachineList } from "./employee/MachineList";
import { Quality, QualityAnalysis } from "./quality";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Context from "../context";

function PrivateRoute({ path, children, ...rest }) {
  const { token, auth } = useContext(Context);
  const intl = useIntl();

  const checkEditAuth = () => {
    if (path) {
      const arr = path.split("/");
      if (arr.length > 3) {
        checkPath = `${arr[2]}_${arr[3]}`;
        const pass = auth[checkPath] === 2;
        if (!pass) {
          alert(intl.formatMessage({ id: "NoAuth" }));
        }
        return pass;
      }
    }
  };

  let checkPath;
  if (path) {
    const arr = path.split("/");
    if (arr.length > 3) {
      checkPath = `${arr[2]}_${arr[3]}`;
    }
  }

  return (
    <Route
      {...rest}
      auth={auth}
      render={({ location }) => {
        if (token === null) {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location },
              }}
            />
          );
        } else if (auth === undefined) {
          return <div />;
        } else if (auth[checkPath] > 0 || !checkPath) {
          return (
            <React.Fragment>
              {React.Children.map(children, (child) => {
                if (React.isValidElement(child)) {
                  return React.cloneElement(child, { checkEditAuth });
                }
                return child;
              })}
            </React.Fragment>
          );
        } else {
          return <Redirect to="/pages" />;
        }
      }}
    />
  );
}

function Pages({ locale, setLocale }) {
  const { auth } = useContext(Context);
  const [announcement, setAnnouncement] = useState([]);

  useEffect(() => {
    apiAnnouncement()
      .then((res) => setAnnouncement(res.data))
      .catch(console.log);
  }, []);

  let page = "";
  if (auth) {
    const initialPage = Object.keys(auth).find(
      (key) =>
        auth[key] !== 0 && !key.includes("_app") && key.split("_").length === 2
    );
    if (initialPage) {
      const arr = initialPage.split("_");
      page = `/pages/${arr[0]}/${arr[1]}`;
    }
  }

  return (
    <div>
      <DndProvider backend={HTML5Backend}>
        <Frame locale={locale} setLocale={setLocale} />
        {announcement.length > 0 ? (
          <MaintenanceBar data={announcement} />
        ) : null}
        <main className="main-area">
          <Switch>
            <PrivateRoute path="/pages" exact>
              <Redirect to={page} />
            </PrivateRoute>
            <PrivateRoute path="/pages/index/device">
              <IndexDevice />
            </PrivateRoute>
            <PrivateRoute path="/pages/index/production" exact>
              <Redirect to="/pages/index/production/activation" />
            </PrivateRoute>
            <PrivateRoute path="/pages/index/production/activation">
              <IndexProduction />
            </PrivateRoute>
            <PrivateRoute path="/pages/index/production/oee">
              <IndexOee />
            </PrivateRoute>

            <PrivateRoute path="/pages/dashboard/1x1">
              <Dashboard />
            </PrivateRoute>
            <PrivateRoute path="/pages/dashboard/2x2">
              <Dashboard2x2 />
            </PrivateRoute>

            <PrivateRoute path="/pages/mes/production">
              <MesProduction />
            </PrivateRoute>
            <PrivateRoute path="/pages/mes/defective">
              <MesDefective />
            </PrivateRoute>
            <PrivateRoute path="/pages/mes/downtime">
              <MesDowntime />
            </PrivateRoute>
            <PrivateRoute path="/pages/mes/handover">
              <MesHandover />
            </PrivateRoute>

            <PrivateRoute path="/pages/analysis/oee" exact>
              <Redirect to="/pages/analysis/oee/overview" />
            </PrivateRoute>
            <PrivateRoute path="/pages/analysis/oee/overview">
              <AnalysisOeeOverview />
            </PrivateRoute>
            <PrivateRoute path="/pages/analysis/oee/order">
              <AnalysisOrder />
            </PrivateRoute>
            <PrivateRoute path="/pages/analysis/oee/production">
              <AnalysisProduction />
            </PrivateRoute>
            <PrivateRoute path="/pages/analysis/oee/defective">
              <AnalysisBad />
            </PrivateRoute>
            <PrivateRoute path="/pages/analysis/oee/downtime">
              <AnalysisDowntime />
            </PrivateRoute>
            <PrivateRoute path="/pages/analysis/amount" exact>
              <Redirect to="/pages/analysis/amount/interval" />
            </PrivateRoute>
            <PrivateRoute path="/pages/analysis/amount/interval">
              <AnalysisAmountInterval />
            </PrivateRoute>
            <PrivateRoute path="/pages/analysis/amount/daily">
              <AnalysisAmountDaily />
            </PrivateRoute>
            <PrivateRoute path="/pages/analysis/parameter" exact>
              <Redirect to="/pages/analysis/parameter/control" />
            </PrivateRoute>
            <PrivateRoute path="/pages/analysis/parameter/control">
              <AnalysisParameterControl />
            </PrivateRoute>
            <PrivateRoute path="/pages/analysis/parameter/process">
              <AnalysisParameterProcess />
            </PrivateRoute>
            <PrivateRoute path="/pages/analysis/schedule">
              <AnalysisSchedule />
            </PrivateRoute>
            <PrivateRoute path="/pages/analysis/production">
              <AnalysisProductionOverview />
            </PrivateRoute>

            <PrivateRoute path="/pages/management/order">
              <ManagementOrder />
            </PrivateRoute>
            <PrivateRoute path="/pages/management/schedule">
              <ManagementSchedule />
            </PrivateRoute>
            <PrivateRoute path="/pages/management/material">
              <ManagementMaterial />
            </PrivateRoute>
            <PrivateRoute path="/pages/management/mold">
              <ManagementMold />
            </PrivateRoute>
            <PrivateRoute path="/pages/management/product">
              <ManagementProduct />
            </PrivateRoute>
            <PrivateRoute path="/pages/management/shift">
              <ManagementShift />
            </PrivateRoute>
            <PrivateRoute path="/pages/management/holiday">
              <ManagementHoliday />
            </PrivateRoute>
            <PrivateRoute path="/pages/management/defect">
              <ManagementDefective />
            </PrivateRoute>
            <PrivateRoute path="/pages/management/downtime">
              <ManagementDowntime />
            </PrivateRoute>
            <PrivateRoute path="/pages/management/unproductive">
              <ManagementUnproductiveOrder />
            </PrivateRoute>

            <PrivateRoute path="/pages/system/user">
              <Sys />
            </PrivateRoute>
            <PrivateRoute path="/pages/system/dashboard">
              <SysDashboard />
            </PrivateRoute>
            <PrivateRoute path="/pages/system/devicegroup">
              <SysDeviceGroup />
            </PrivateRoute>
            <PrivateRoute path="/pages/system/factory" exact>
              <Redirect to="/pages/system/factory/factory" />
            </PrivateRoute>
            <PrivateRoute path="/pages/system/factory/factory">
              <SysFactoryFactory />
            </PrivateRoute>
            <PrivateRoute path="/pages/system/factory/device">
              <SysFactoryDevice />
            </PrivateRoute>
            <PrivateRoute path="/pages/system/factory/user">
              <SysFactoryUsers />
            </PrivateRoute>
            <PrivateRoute path="/pages/system/notify" exact>
              <Redirect to="/pages/system/notify/factory" />
            </PrivateRoute>
            <PrivateRoute path="/pages/system/notify/factory">
              <SysNotifyFactory />
            </PrivateRoute>
            <PrivateRoute path="/pages/system/notify/mold">
              <SysNotifyMold />
            </PrivateRoute>
            <PrivateRoute path="/pages/system/notify/defective">
              <SysNotifyDefective />
            </PrivateRoute>
            <PrivateRoute path="/pages/system/role">
              <SysRole />
            </PrivateRoute>
            <PrivateRoute path="/pages/system/document">
              <SysFile />
            </PrivateRoute>
            <PrivateRoute path="/pages/system/log">
              <SystemLog />
            </PrivateRoute>

            <PrivateRoute path="/pages/root/factory" exact>
              <Redirect to="/pages/root/factory/factory" />
            </PrivateRoute>
            <PrivateRoute path="/pages/root/factory/factory">
              <RootFactory />
            </PrivateRoute>
            <PrivateRoute path="/pages/root/factory/user">
              <RootUsers />
            </PrivateRoute>
            <PrivateRoute path="/pages/root/factory/device">
              <RootDevice />
            </PrivateRoute>
            <PrivateRoute path="/pages/root/group">
              <RootGroup />
            </PrivateRoute>
            <PrivateRoute path="/pages/root/sql">
              <RootSql />
            </PrivateRoute>

            <PrivateRoute path="/pages/employee" exact>
              <Redirect to="/pages/employee/device" />
            </PrivateRoute>
            <PrivateRoute path="/pages/employee/device">
              <EmployeeMachineList />
            </PrivateRoute>
            <PrivateRoute path="/pages/employee/work">
              <EmployeeWork />
            </PrivateRoute>
            <PrivateRoute path="/pages/employee/setting">
              <EmployeeSetting />
            </PrivateRoute>
            <PrivateRoute path="/pages/employee/log">
              <EmployeeLog />
            </PrivateRoute>

            <PrivateRoute path="/pages/quality">
              <Quality />
            </PrivateRoute>
            <PrivateRoute path="/pages/quality_analysis">
              <QualityAnalysis />
            </PrivateRoute>

            <PrivateRoute />
          </Switch>
          <div
            className="center"
            style={{
              position: "absolute",
              zIndex: 1,
              bottom: 0,
              color: "grey",
              width: "calc(100% - 60px)",
              paddingBottom: "15px",
              fontSize: "12px",
            }}
          >
            <span>
              <FormattedMessage
                id="Footer"
                defaultMessage={`Copyright © FCS - v ${process.env.REACT_APP_VERSION} ALL RIGHTS RESERVED`}
              />
            </span>
          </div>
        </main>
      </DndProvider>
    </div>
  );
}
export { Pages };
