import React, { useState, useEffect } from "react";
import moment from "moment";
import { FormattedMessage, useIntl } from "react-intl";
import { SingleDropdownPicker } from "../../component/common";
import {
  AnalysisTabs,
  AnalysisMenu,
  DateBar,
  MultiDeviceList,
} from "../../component/analysis/Analysis";
import { OEEChart, AssignDeviceChart } from "../../component/analysis/oeeOverview";

export default function Analysis() {
  const intl = useIntl();
  const [device, setDevice] = useState([]);
  const [select, setSelect] = useState({});
  const [dataType, setDataType] = useState("oee");
  const [average, setAverage] = useState({});
  const [date, setDate] = useState({
    startDate: moment().format("YYYY-MM-DD"),
  });
  const [sort, setSort] = useState("date");
  const handleSelect = (deviceName) => {
    setSelect(device.find((item) => item.DeviceName === deviceName));
  };
  const columns = [
    { key: "OEE", name: "OEEOfDevices" },
    { key: "utilization", name: "AvailabilityOfDevices" },
    { key: "performance", name: "PerformanceOfDevices" },
    { key: "yield", name: "QualityOfDevices" },
  ];

  return (
    <div>
      <AnalysisTabs tab="0" />
      <div className="page-content shadow-lg">
        <div className="jc-end ai-center">
          <AnalysisMenu tab="0" />
        </div>
        <hr />
        <DateBar sort={sort} date={date} setSort={setSort} setDate={setDate} />
        <hr />
        <div className="row">
          <MultiDeviceList device={device} setDevice={setDevice} />
          <div className="col-md-8 col-lg-9 col-xl-10">
            <div className="ai-stretch">
              {columns.map(({ key, name }, i) => (
                <div
                  className={`flex-1 flex-column center ${
                    i !== columns.length - 1 ? "border-right" : ""
                  }`}
                >
                  <span style={{ color: "#3f497d", fontSize: "1.5rem" }}>
                    {average[key]}%
                  </span>
                  <span style={{ color: "#808080", fontWeight: 500 }}>
                    <FormattedMessage id={name} />
                  </span>
                </div>
              ))}
            </div>
            {device.length ===0 ?  <></>: <OEEChart
              setAverage={setAverage}
              handleSelect={handleSelect}
              sort={sort}
              date={date}
              device={device}
            />}
          
            <div className="jc-space-between">
              <h4 className="m-0">
                <b>{select.DeviceName}</b>
              </h4>
              <SingleDropdownPicker
                value={dataType}
                list={[
                  { label: "OEE", value: "oee" },
                  {
                    label: <FormattedMessage id="Utilization" />,
                    value: "utilization",
                  },
                  {
                    label: <FormattedMessage id="Performance" />,
                    value: "performance",
                  },
                  { label: <FormattedMessage id="Yield" />, value: "yield" },
                ]}
                onApply={setDataType}
              />
            </div>
            {select.DeviceID ? (
              <AssignDeviceChart
                device={select}
                date={date}
                dataType={dataType}
                sort={sort}
              />
            ) : (
              <div
                className="center"
                style={{ width: "100%", height: "300px" }}
              >
                <b style={{ fontSize: "24px", color: "#cccccc" }}>
                  <FormattedMessage id="PickAnyDeviceAbove" />
                </b>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
