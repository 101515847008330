import React, { useEffect, useState, useContext } from "react";
import moment from "moment";
import ClipLoader from "react-spinners/ClipLoader";
import { FormattedMessage, useIntl } from "react-intl";
import {
  DateRadio,
  SingleDatePicker,
  MultiDropdownPicker,
  RangeDatePicker,
} from "../../component/common";
import { AnalysisTabs, ParameterMenu } from "../../component/analysis/Analysis";
import { ParameterTable } from "../../component/analysis/Parameter";
import {
  apiGetAnalysisSPCMonitorDataList,
  apiDownloadAnalysisSPCMonitorExcel,
  baseURL,
  apiGetDeviceNameList,
  apiGetAnalysisSPCMonitorProductList,
} from "../../api.js";
import Context from "../../context";

const columns = [
  { id: "created_at", name: "建立時間" },
  { id: "order_num", name: "工單編號" },
  { id: "device_name", name: "機台名稱" },
  { id: "product_num", name: "產品編號" },
  { id: "product_name", name: "產品名稱" },
  { id: "cycle_time", name: "上模週期" },
  { id: "mold_close_time", name: "關模時間" },
  { id: "mold_open_time", name: "開模時間" },
  { id: "inject_time", name: "射出時間" },
  { id: "vp_time", name: "VP切換響應時間" },
  { id: "compress_time", name: "壓縮時間" },
  { id: "hold_time", name: "保壓時間" },
  { id: "feed_time", name: "加料時間" },
  { id: "delay_feed_time", name: "延遲加料時間" },
  {
    id: "inj_peak_pressure",
    name: "射出系統壓力峰值",
  },
  {
    id: "inj_melt_pressure",
    name: "射出熔體壓力峰值",
  },
  { id: "cut_off_pressure", name: "射轉保系統壓力" },
  { id: "cut_off_melt_pressure", name: "射轉保熔體壓力" },
  { id: "inj_melt_integral", name: "射出熔體壓力積分值" },
  { id: "compress_press", name: "壓縮模穴壓差" },
  { id: "hold_avg_system_pressure", name: "保壓平均系統壓力" },
  { id: "hold_avg_melt_pressure", name: "保壓平均熔體壓力" },
  { id: "hold_velocity_pressure", name: "保壓系統壓力峰值" },
  { id: "hold_melt_pressure", name: "保壓熔體壓力峰值" },
  { id: "hold_pressure_integral", name: "保壓壓力積分值" },
  { id: "feed_avg_pressure", name: "加料平均系統壓力" },
  { id: "feed_pressure_velocity", name: "加料系統壓力峰值" },
  { id: "melt_avg_back", name: "熔體平均背壓" },
  { id: "melt_velocity_back", name: "熔體背壓峰值" },
  { id: "vp_cavity", name: "VP切換點壓力值(模穴)" },
  {
    id: "mold_integral_pressure1",
    name: "模穴壓力積分值1",
  },
  {
    id: "mold_integral_pressure2",
    name: "模穴壓力積分值2",
  },
  {
    id: "mold_integral_pressure3",
    name: "模穴壓力積分值3",
  },
  {
    id: "mold_integral_pressure4",
    name: "模穴壓力積分值4",
  },
  {
    id: "mold_integral_pressure5",
    name: "模穴壓力積分值5",
  },
  {
    id: "mold_integral_pressure6",
    name: "模穴壓力積分值6",
  },
  {
    id: "mold_integral_pressure7",
    name: "模穴壓力積分值7",
  },
  {
    id: "mold_integral_pressure8",
    name: "模穴壓力積分值8",
  },
  {
    id: "mold_velocity_pressure1",
    name: "模穴壓力峰值1",
  },
  {
    id: "mold_velocity_pressure2",
    name: "模穴壓力峰值2",
  },
  {
    id: "mold_velocity_pressure3",
    name: "模穴壓力峰值3",
  },
  {
    id: "mold_velocity_pressure4",
    name: "模穴壓力峰值4",
  },
  {
    id: "mold_velocity_pressure5",
    name: "模穴壓力峰值5",
  },
  {
    id: "mold_velocity_pressure6",
    name: "模穴壓力峰值6",
  },
  {
    id: "mold_velocity_pressure7",
    name: "模穴壓力峰值7",
  },
  {
    id: "mold_velocity_pressure8",
    name: "模穴壓力峰值8",
  },
  { id: "pos_inj_start", name: "射出起始位置" },
  {
    id: "vp_position",
    name: "VP切換響應位置",
  },
  { id: "vp_itinerary", name: "VP切換響應行程" },
  { id: "compression_stroke", name: "壓縮行程" },
  { id: "pos_pressure_end", name: "保壓完位置" },
  {
    id: "pos_bef_dos",
    name: "前鬆退位置",
  },
  { id: "pos_dos_end", name: "加料完位置" },
  {
    id: "pos_sb_end",
    name: "後鬆退位置",
  },
  { id: "vp_volume", name: "VP切換響應容積" },
  { id: "feed_volume", name: "加料容積" },
  { id: "feed_before_volume", name: "加料前鬆退容積" },
  { id: "feed_after_volume", name: "加料後鬆退容積" },
  { id: "residual_volume", name: "殘量容積" },
  { id: "inj_peak_velocity", name: "射出速度峰值" },
  { id: "avg_inj_speed", name: "平均射出速度" },
  { id: "feed_speed_velocity", name: "加料轉速峰值" },
  { id: "max_plastic_speed", name: "最大塑料塑化線速度" },
  { id: "avg_plastic_speed", name: "平均塑料塑化線速度" },
  { id: "oil_temp", name: "機台油溫" },
  { id: "material_temp1", name: "第一段料溫" },
  { id: "material_temp2", name: "第二段料溫" },
  { id: "material_temp3", name: "第三段料溫" },
  { id: "material_temp4", name: "第四段料溫" },
  { id: "material_temp5", name: "第五段料溫" },
  { id: "material_temp6", name: "第六段料溫" },
  { id: "material_temp7", name: "第七段料溫" },
  { id: "material_temp8", name: "第八段料溫" },
  { id: "throat_temp", name: "入料喉部溫度" },
  { id: "max_force", name: "單支柱最大受力" },
  { id: "force_range", name: "大柱受立全距" },
  { id: "cavity_temp", name: "母模模具溫度" },
  { id: "core_temp", name: "公模模具溫度" },
  { id: "mold_controller_temp", name: "模溫機溫度" },
  { id: "mold_controller_flow", name: "模溫機水路流量" },
  {
    id: "inlet_temp1",
    name: "模具水路入水溫度1",
  },
  {
    id: "inlet_temp2",
    name: "模具水路入水溫度2",
  },
  {
    id: "inlet_temp3",
    name: "模具水路入水溫度3",
  },
  {
    id: "inlet_temp4",
    name: "模具水路入水溫度4",
  },
  {
    id: "outlet_temp1",
    name: "模具水路出水溫度1",
  },
  {
    id: "outlet_temp2",
    name: "模具水路出水溫度2",
  },
  {
    id: "outlet_temp3",
    name: "模具水路出水溫度3",
  },
  {
    id: "outlet_temp4",
    name: "模具水路出水溫度4",
  },
  {
    id: "inlet_flow1",
    name: "模具水路入水流量1",
  },
  {
    id: "inlet_flow2",
    name: "模具水路入水流量2",
  },
  {
    id: "inlet_flow3",
    name: "模具水路入水流量3",
  },
  {
    id: "inlet_flow4",
    name: "模具水路入水流量4",
  },
  {
    id: "outlet_flow1",
    name: "模具水路出水流量1",
  },
  {
    id: "outlet_flow2",
    name: "模具水路出水流量2",
  },
  {
    id: "outlet_flow3",
    name: "模具水路出水流量3",
  },
  {
    id: "outlet_flow4",
    name: "模具水路出水流量4",
  },
  { id: "weight1", name: "重量1" },
  { id: "weight2", name: "重量2" },
  { id: "cooldown_time", name: "實際冷卻時間" },
  { id: "ejector_fwd_time", name: "頂出時間" },
  { id: "ejector_bwd_time", name: "頂退時間" },
  { id: "ejector_shake_time", name: "震動時間" },
  { id: "nozzle_bwd_time", name: "座退時間" },
  { id: "nozzle_fwd_time", name: "座進時間" },
  { id: "air_valve_time", name: "吹風時間" },
  { id: "core_movein_act_time", name: "中子入" },
  { id: "core_moveout_act_time", name: "中子出" },
  { id: "robot_time", name: "機械手時間" },
  {
    id: "zozzle_fwd_contactact",
    name: "zozzle_fwd_con座進保持時間tactact",
  },
  { id: "hold_max_pressure", name: "保壓壓力峰值" },
  { id: "hold_max_velocity", name: "保壓速度峰值" },
  { id: "lock_magic_power1", name: "鎖模力1" },
  { id: "lock_magic_power2", name: "鎖模力2" },
  { id: "lock_magic_power3", name: "鎖模力3" },
  { id: "lock_magic_power4", name: "鎖模力4" },
  { id: "b_inject_time", name: "B射射出時間" },
  { id: "b_vp_time", name: "B射VP切換響應時間" },
  { id: "b_compress_time", name: "B射壓縮時間" },
  { id: "b_hold_time", name: "B射保壓時間" },
  { id: "b_feed_time", name: "B射加料時間" },
  { id: "b_delay_feed_time", name: "B射延遲加料時間" },
  {
    id: "b_inj_peak_pressure",
    name: "B射射出系統壓力峰值",
  },
  { id: "b_inj_melt_pressure", name: "B射射出熔體壓力峰值" },
  {
    id: "b_cut_off_pressure",
    name: "B射射轉保系統壓力",
  },
  { id: "b_cut_off_melt_pressure", name: "B射射轉保熔體壓力" },
  { id: "b_inj_melt_integral", name: "B射射出熔體壓力積分值" },
  { id: "b_hold_avg_system_pressure", name: "B射保壓平均系統壓力" },
  { id: "b_hold_avg_melt_pressure", name: "B射保壓平均熔體壓力" },
  { id: "b_hold_velocity_pressure", name: "B射保壓系統壓力峰值" },
  { id: "b_hold_melt_pressure", name: "B射保壓熔體壓力峰值" },
  { id: "b_hold_pressure_integral", name: "B射保壓壓力積分值" },
  { id: "b_feed_avg_pressure", name: "B射加料平均系統壓力" },
  { id: "b_feed_pressure_velocity", name: "B射加料系統壓力峰值" },
  { id: "b_melt_avg_back", name: "B射熔體平均背壓" },
  { id: "b_melt_velocity_back", name: "B射熔體背壓峰值" },
  { id: "b_pos_inj_start", name: "B射射出起始位置" },
  { id: "b_vp_position", name: "B射VP切換響應位置" },
  { id: "b_vp_itinerary", name: "B射VP切換響應行程" },
  { id: "b_compression_stroke", name: "B射壓縮行程" },
  { id: "b_pos_pressure_end", name: "B射保壓完位置" },
  { id: "b_pos_bef_dos", name: "B射前鬆退位置" },
  { id: "b_pos_dos_end", name: "B射加料完位置" },
  { id: "b_pos_sb_end", name: "B射後鬆退位置" },
  { id: "b_vp_volume", name: "B射VP切換響應容積" },
  { id: "b_feed_volume", name: "B射加料容積" },
  { id: "b_feed_before_volume", name: "B射加料前鬆退容積" },
  { id: "b_feed_after_volume", name: "B射加料後鬆退容積" },
  { id: "b_residual_volume", name: "B射殘量容積" },
  {
    id: "b_inj_peak_velocity",
    name: "B射射出速度峰值",
  },
  { id: "b_avg_inj_speed", name: "B射平均射出速度" },
  { id: "b_feed_speed_velocity", name: "B射加料轉速峰值" },
  { id: "b_max_plastic_speed", name: "B射最大塑料塑化線速度" },
  { id: "b_avg_plastic_speed", name: "B射平均塑料塑化線速度" },
  {
    id: "b_material_temp1",
    name: "B射第一段料溫",
  },
  {
    id: "b_material_temp2",
    name: "B射第二段料溫",
  },
  {
    id: "b_material_temp3",
    name: "B射第三段料溫",
  },
  {
    id: "b_material_temp4",
    name: "B射第四段料溫",
  },
  {
    id: "b_material_temp5",
    name: "B射第五段料溫",
  },
  {
    id: "b_material_temp6",
    name: "B射第六段料溫",
  },
  { id: "b_throat_temp", name: "B射入料喉部溫度" },
  {
    id: "b_cooldown_time",
    name: "B射實際冷卻時間",
  },
];

export default function AnalysisParameter() {
  const today = moment().format("YYYY-MM-DD");
  const yesterday = moment().subtract(1, "d").format("YYYY-MM-DD");
  const [date, setDate] = useState({
    startDate: today,
  });
  const [deviceList, setDeviceList] = useState([]);
  const [checkedDevice, setCheckedDevice] = useState([]);
  const [productList, setProductList] = useState([]);
  const [checkedProduct, setCheckedProduct] = useState([]);
  const [checkedColumns, setCheckedColumns] = useState(
    columns.map(({ id }) => id)
  );
  const [sort, setSort] = useState("date");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const intl = useIntl();
  const { factory } = useContext(Context);

  useEffect(() => {
    apiGetDeviceNameList({
      FactoryID: factory,
    }).then((res) => setDeviceList(res.data));
  }, [factory]);

  useEffect(() => {
    if (sort === "date") {
      setDate({
        startDate: today,
      });
    } else if (sort === "interval") {
      setDate({
        startDate: moment().format("YYYY-MM-DD HH:mm:ss"),
        endDate: moment().format("YYYY-MM-DD HH:mm:ss"),
      });
    }
  }, [sort]);

  useEffect(() => {
    if (checkedDevice.length !== 0) {
      let req = {
        FactoryID: factory,
        DeviceID: checkedDevice,
      };
      req = handleRequest(req);
      apiGetAnalysisSPCMonitorProductList(req)
        .then((res) => setProductList(res.data.data))
        .catch(console.log);
    }
  }, [checkedDevice, date, factory]);

  useEffect(() => {
    if (checkedDevice.length !== 0 && checkedProduct.length !== 0) {
      setLoading(true);
      loadData();
      const temp = setInterval(() => loadData, 30000);
      return () => clearInterval(temp);
    }
  }, [checkedDevice, checkedProduct, date, factory]);

  const loadData = () => {
    let req = {
      FactoryID: factory,
      DeviceID: checkedDevice,
      CheckProduct: checkedProduct,
    };
    req = handleRequest(req);
    apiGetAnalysisSPCMonitorDataList(req)
      .then((res) => {
        setData(res.data.data);
        setLoading(false);
      })
      .catch(console.log);
  };

  const downloadExcel = () => {
    const language = localStorage.getItem("locale");
    let req = {
      FactoryID: factory,
      DeviceID: checkedDevice,
      CheckProduct: checkedProduct,
      CheckDataField: checkedColumns,
      Language: language ? language : "zh",
    };
    req = handleRequest(req);
    apiDownloadAnalysisSPCMonitorExcel(req)
      .then((res) => window.open(`${baseURL}/${res.data}`))
      .catch(console.log);
  };

  const pickDateRange = (startDate, endDate) => {
    setDate({ startDate, endDate });
  };

  const handleRequest = (req) => {
    if (sort === "date") {
      req.CheckDateStart = date.startDate;
    } else if (sort === "interval") {
      const startDate = moment(date.startDate);
      const endDate = moment(date.endDate);
      req.CheckDateStart = startDate.format("YYYY-MM-DD");
      req.CheckDateEnd = endDate.format("YYYY-MM-DD");
      req.CheckTimeStart = startDate.format("HH:mm:ss");
      req.CheckTimeEnd = endDate.format("HH:mm:ss");
    }
    return req;
  };

  return (
    <div>
      <AnalysisTabs tab="2" />
      <div className="page-content shadow-lg">
        <div className="jc-end">
          <ParameterMenu tab="0" />
        </div>
        <hr />
        <div className="ai-stretch">
          <DateRadio
            style={{ marginRight: "20px" }}
            list={[
              { label: <FormattedMessage id="Date" />, value: "date" },
              { label: <FormattedMessage id="Interval" />, value: "interval" },
            ]}
            value={sort}
            onChange={(e) => setSort(e.target.value)}
          />
          {sort === "date" ? (
            <React.Fragment>
              <DateRadio
                style={{ marginRight: "20px" }}
                list={[
                  { label: <FormattedMessage id="Today" />, value: today },
                  {
                    label: <FormattedMessage id="Yesterday" />,
                    value: yesterday,
                  },
                ]}
                value={date.startDate}
                onChange={(e) => setDate({ startDate: e.target.value })}
              />
              <SingleDatePicker
                style={{ marginRight: "20px" }}
                date={date.startDate}
                onApply={(name, startDate) => setDate({ startDate })}
              />
            </React.Fragment>
          ) : (
            <RangeDatePicker
              startDate={date.startDate}
              endDate={date.endDate}
              timePicker={true}
              onApply={pickDateRange}
            />
          )}
        </div>
        <hr />
        <div className="jc-space-between ai-stretch">
          <button className="btn-gray" onClick={downloadExcel}>
            <FormattedMessage id="DownloadExcel" />
          </button>
          <div className="ai-stretch">
            <MultiDropdownPicker
              title={intl.formatMessage(
                { id: "PickA" },
                { name: intl.formatMessage({ id: "Device" }) }
              )}
              list={deviceList.map(({ id, name }) => ({
                label: name,
                value: id,
              }))}
              selected={checkedDevice}
              onApply={setCheckedDevice}
              style={{ marginRight: "20px" }}
            />
            <MultiDropdownPicker
              title={intl.formatMessage(
                { id: "PickA" },
                { name: intl.formatMessage({ id: "Product" }) }
              )}
              list={productList.map(({ id, name }) => ({
                label: name,
                value: id,
              }))}
              selected={checkedProduct}
              onApply={setCheckedProduct}
              style={{ marginRight: "20px" }}
            />
            <MultiDropdownPicker
              title={intl.formatMessage(
                { id: "PickA" },
                { name: intl.formatMessage({ id: "Column" }) }
              )}
              list={columns.map(({ id }) => ({
                label: isNaN(parseInt(id.substr(id.length - 1)))
                  ? intl.formatMessage({ id })
                  : intl.formatMessage(
                      {
                        id: id.substr(0, id.length - 1),
                      },
                      { number: id.substr(id.length - 1) }
                    ),
                value: id,
              }))}
              selected={checkedColumns}
              onApply={setCheckedColumns}
            />
          </div>
        </div>
        <hr />
        {loading ? (
          <div className="center" style={{ width: "100%" }}>
            <ClipLoader size={50} color="#123abc" loading={loading} />
          </div>
        ) : (
          <ParameterTable
            columns={columns
              .filter(({ id }) => checkedColumns.indexOf(id) > -1)
              .map(({ id }) => ({
                id,
                name: isNaN(parseInt(id.substr(id.length - 1)))
                  ? intl.formatMessage({ id })
                  : intl.formatMessage(
                      {
                        id: id.substr(0, id.length - 1),
                      },
                      { number: id.substr(id.length - 1) }
                    ),
              }))}
            data={data}
          />
        )}
      </div>
    </div>
  );
}
