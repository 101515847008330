import React, { useEffect, useState, useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { MdCreate, MdDelete } from "react-icons/md";
import Switch from "@material-ui/core/Switch";
import { ColumnFilter, Table } from "../common";
import { InputModalBase } from "./Management";
import {
  baseURL,
  ApiManagement_ProductList,
  ApiManagement_ProductPhoto,
  ApiOrder_GetProduceMold,
  ApiManagement_upload_product,
  ApiManagement_ProductUpdate,
  ApiManagement_ProductInsert,
  ApiManagement_ProductMaterial,
} from "../../api.js";
import Context from "../../context";

function ProductTable({ showModal, modal }) {
  const { factory } = useContext(Context);
  const [data, setData] = useState([]);
  const [trigger, setTrigger] = useState(false);
  
  useEffect(() => {
    if (!modal) {
      loadData();
    }
  }, [modal, trigger]);

  const loadData = () => {
    ApiManagement_ProductList({
      factory_id: factory,
    })
      .then((res) => {
        res.data.forEach((item) => {
          item.material = item.MaterialNum.toString();
          item.mold = item.MoldNum.toString();
          if (item.PhotoPath) {
            item.photo = `${baseURL}/${item.PhotoPath}`;
          }
        });
        setData(res.data);
      })
      .catch((err) => console.log(err));
  };

  const onChange = (item) => {
    ApiManagement_ProductUpdate({
      ...item,
      account: localStorage.getItem("account"),
      factory_id: factory,
      product_id: item.ProductId,
      product_num: item.ProductNum,
      product_name: item.ProductName,
      material_num: item.MaterialNum,
      mold_num: item.MoldNum,
      photo_path: item.PhotoPath,
      is_use: !item.IsUse ? 1 : 0,
    })
      .then(() => setTrigger(!trigger))
      .catch(console.log);
  };

  const columns = [
    {
      Header: <FormattedMessage id="Photo" /> /*照片*/,
      accessor: "photo",
      Cell: ({ cell: { value } }) => (
        <img src={value} className="fit-contain" style={{ width: "100px" }} />
      ),
      Filter: "",
    },
    {
      Header: <FormattedMessage id="product_num" /> /*產品編號*/,
      accessor: "ProductNum",
      Filter: ({ column }) => ColumnFilter(column, "product_num"),
    },
    {
      Header: <FormattedMessage id="product_name" /> /*產品名稱*/,
      accessor: "ProductName",
      Filter: ({ column }) => ColumnFilter(column, "product_name"),
    },
    {
      Header: <FormattedMessage id="material_num" /> /*原料料號*/,
      accessor: "material",
      Filter: ({ column }) => ColumnFilter(column, "material_num"),
    },
    {
      Header: <FormattedMessage id="mold_num" /> /*模具編號*/,
      accessor: "mold",
      Filter: ({ column }) => ColumnFilter(column, "mold_num"),
    },
    {
      Header: <FormattedMessage id="is_use" />,
      accessor: "is_use",
      Filter: "",
    },
    {
      Header: <FormattedMessage id="Edit" />,
      accessor: "editor",
      Filter: "",
    },
  ];

  const display = data.map((item) => ({
    ...item,
    material: item.MaterialNum.toString(),
    mold: item.MoldNum.toString(),
    is_use: (
      <Switch
        checked={item.IsUse}
        onChange={() => onChange(item)}
        color="primary"
      />
    ),
    editor: (
      <span>
        <MdCreate
          className="icon-btn"
          size="20px"
          onClick={() => showModal("edit", item)}
        />
        <MdDelete
          style={{ marginLeft: "10px" }}
          className="icon-btn"
          size="20px"
          onClick={() => showModal("delete", item)}
        />
      </span>
    ),
  }));

  return <Table columns={columns} data={display} />;
}

const InputModal = ({ onHide, modal, data }) => {
  const { factory } = useContext(Context);
  const intl = useIntl();
  const [select, setSelect] = useState({ material_num: [], mold_num: [] });
  const [materialList, setMaterialList] = useState([]);
  const [moldList, setMoldList] = useState([]);
  const [expand, setExpand] = useState(false);

  const columns = [
    { id: "product_num", type: "text", required: true },
    { id: "product_name", type: "text", required: true },
    {
      id: "material_num",
      type: "multi_select",
      required: true,
      options: materialList.map(({ material_num }) => ({
        label: material_num,
        value: material_num,
      })),
    },
    {
      id: "mold_num",
      type: "multi_select",
      required: true,
      options: moldList.map(({ mold_num }) => ({
        label: mold_num,
        value: mold_num,
      })),
    },
    { id: "product_type", type: "text" },
    { id: "part_weight", type: "number" },
    { id: "runner_weight", type: "number" },
    { id: "pcs_weight", type: "number" },
    { id: "product_cost", type: "number" },
    { id: "qc_point", type: "text" },
    {
      id: "photo",
      type: "file",
      extensions: ["webp", "jpg", "png", "jpeg", "bmp", "gif"],
      remark: "PhotoRemark",
    },
    {
      id: "is_use",
      type: "check_box",
    },
  ];

  useEffect(() => {
    ApiManagement_ProductMaterial({ FactoryID: factory })
      .then((res) => setMaterialList(res.data))
      .catch(console.log);
    ApiOrder_GetProduceMold({ factory_id: factory })
      .then((res) => setMoldList(res.data))
      .catch(console.log);
  }, []);

  useEffect(() => {
    if (!modal) {
      setExpand(false);
    }
    data.product_num = data.ProductNum;
    data.product_name = data.ProductName;
    data.material_num = data.MaterialNum;
    data.mold_num = data.MoldNum;
    data.photo_path = data.PhotoPath;
    data.is_use = data.IsUse;
    setSelect(JSON.parse(JSON.stringify(data)));
  }, [modal, data]);

  const submit = async () => {
    return new Promise(async (resolve, reject) => {
      let req = {
        ...select,
        account: localStorage.getItem("account"),
        factory_id: factory,
        photo_path: select.photo_path ? select.photo_path : "",
      };
      if (select.photo?.size) {
        const data = new FormData();
        data.append("avast", select.photo);
        try {
          const res = await ApiManagement_ProductPhoto(data);
          req.photo_path = res.data.message;
        } catch (e) {
          reject(e);
        }
      }
      columns.forEach(({ id, type }) => {
        if (req[id] === undefined) {
          if (type === "number") {
            req[id] = 0;
          } else {
            req[id] = "";
          }
        }
      });
      if (modal === "new") {
        ApiManagement_ProductInsert(req).then(resolve).catch(reject);
      } else if (modal === "edit") {
        req.product_id = select.ProductId;
        ApiManagement_ProductUpdate(req).then(resolve).catch(reject);
      }
    });
  };

  const title = intl.formatMessage(
    { id: modal === "new" ? "Create" : "EditThe" },
    { name: intl.formatMessage({ id: "Product" }) }
  );

  return (
    <InputModalBase
      show={modal === "new" || modal === "edit"}
      onHide={onHide}
      title={title}
      columns={columns.filter((_, i) => expand || i < 4)}
      select={select}
      setSelect={setSelect}
      submit={submit}
      expand={expand}
      setExpand={setExpand}
    />
  );
};

const ImportModal = ({ onHide, modal }) => {
  const { factory } = useContext(Context);
  const intl = useIntl();
  const [select, setSelect] = useState({});

  useEffect(() => {
    if (!modal) {
      setSelect({});
    }
  }, [modal]);

  const submit = () => {
    return new Promise((resolve, reject) => {
      const data = new FormData();
      data.append("file", select.xls);
      data.append("factory_id", factory);
      ApiManagement_upload_product(data).then(resolve).catch(reject);
    });
  };

  const columns = [
    {
      id: "xls",
      type: "file",
      required: true,
      remark: "ProductImportRemark",
      extensions: ["xls", "xlsx"],
    },
  ];

  return (
    <InputModalBase
      show={modal === "import"}
      onHide={onHide}
      title={intl.formatMessage(
        { id: "Import" },
        { name: intl.formatMessage({ id: "Product" }) }
      )}
      columns={columns}
      select={select}
      setSelect={setSelect}
      submit={submit}
    />
  );
};
export { ProductTable, InputModal, ImportModal };
