import React, { useState, useRef, useContext, useEffect } from "react";
import moment from "moment";
import { useIntl } from "react-intl";
import FullCalendar from "@fullcalendar/react";
import dayGrid from "@fullcalendar/daygrid";
import timeGrid from "@fullcalendar/timegrid";
import interaction from "@fullcalendar/interaction";
import "../../scss/fullcalendar.scss";
import { ManagementTabs } from "../../component/management/Management";
import {
  InputModal,
  SetModal,
  ImportModal,
} from "../../component/management/shift";
import { handleError, DeleteModal } from "../../component/common";
import {
  ApiManagement_classShowDateData,
  ApiManagement_classDeleteScheduleNew,
  ApiManagement_classDeleteScheduleChange,
} from "../../api.js";
import Context from "../../context";

function ManagementClass({ checkEditAuth }) {
  const [modal, setModal] = useState("");
  const [events, setEvents] = useState([]);
  const [select, setSelect] = useState({ DeviceList: [] });
  const [info, setInfo] = useState({
    type: "month",
    date: moment().format("YYYY-MM"),
  });
  const intl = useIntl();
  const { factory, locale } = useContext(Context);

  useEffect(() => {
    if (!modal) {
      const { type, date } = info;
      let data = [];
      ApiManagement_classShowDateData({
        FactoryID: factory,
        CheckDateCondition: type,
        CheckDateStart: date,
      })
        .then((res) => {
          Object.keys(res.data).forEach((date) => {
            data.push({
              allDay: true,
              start: date,
              display: "background",
              color: "transparent",
              backgroundColor: res.data[date].is_workday
                ? "#FFFFFF"
                : "rgba(222, 45, 77, 0.1)",
            });
            Object.keys(res.data[date].Schedule).forEach((id) => {
              const workday = `workday${parseInt(id) + 1}`;
              const start = moment(
                `${date} ${
                  res.data[date].Schedule[id][`${workday}_start`]
                }`
              );
              let end = moment(
                `${date} ${
                  res.data[date].Schedule[id][`${workday}_end`]
                }`
              );
              if (end.isBefore(start)) {
                end.add(1, "d");
              }
              data.push({
                title: intl.formatMessage({
                  id: `WorkHours${(parseInt(id) % 4) + 1}`,
                }),
                start: start.toDate(),
                end: end.toDate(),
                id: parseInt(id) % 4,
                color: "#656b82",
                borderColor: "transparent",
                classNames: ["d-flex", `workday${(parseInt(id) % 4) + 1}`],
              });
            });
            Object.keys(res.data[date].Additional).forEach((id) => {
              let title, color, className;
              if (res.data[date].Additional[id].status == 0) {
                title = intl.formatMessage({ id: "WorkOvertime" });
                color = "#1890ff";
                className = "overtime";
              } else {
                title = intl.formatMessage({ id: "ScheduledMaintenance" });
                color = "#FF8000";
                className = "maintain";
              }
              const { workday_start, workday_end, status } =
                res.data[date].Additional[id];
              const start = moment(`${date} ${workday_start}`);
              let end = moment(`${date} ${workday_end}`);
              if (end.isBefore(start)) {
                end.add(1, "d");
              }
              data.push({
                title,
                start: start.toDate(),
                end: end.toDate(),
                id,
                color,
                borderColor: "transparent",
                classNames: ["d-flex", className],
                status,
              });
            });
          });
          setEvents(data);
        })
        .catch(console.log);
    }
  }, [info, modal]);

  const datesSet = (dateInfo) => {
    const { currentStart, type } = dateInfo.view;
    setInfo({
      type: type.includes("Month") ? "month" : "week",
      date: moment(currentStart).format(
        type.includes("Month") ? "YYYY-MM" : "YYYY-MM-DD"
      ),
    });
  };

  const onHide = () => {
    setModal();
    setSelect({});
  };

  const showModal = (modal, item = {}) => {
    if (!checkEditAuth()) {
      return;
    }
    setSelect(item);
    setModal(modal);
  };

  const dateClick = (e) => {
    showModal("new", {
      date: moment(e.date).format("YYYY-MM-DD"),
    });
  };

  const eventClick = async (e) => {
    const {
      extendedProps: { status },
      id,
      start,
      end,
    } = e.event;
    showModal("edit", {
      id,
      status,
      date: moment(start).format("YYYY-MM-DD"),
      workday_start: moment(start).format("HH:mm"),
      workday_end: moment(end).format("HH:mm"),
    });
  };

  const deleteData = () => {
    const { id, date, status } = select;
    let req = {
      account: localStorage.getItem("account"),
      FactoryID: factory,
      ConfirmDate: date,
    };
    if (status !== undefined) {
      req.NewScheduleID = id;
      ApiManagement_classDeleteScheduleNew(req)
        .then(onHide)
        .catch((err) => handleError(err, intl));
    } else {
      req.ShiftID = id;
      ApiManagement_classDeleteScheduleChange(req)
        .then(onHide)
        .catch((err) => handleError(err, intl));
    }
  };

  const calendarComponentRef = useRef();

  return (
    <div>
      <ManagementTabs tab="5" />
      <div className="page-content shadow-lg">
        <div className="calendar_area" style={{ height: "85vh" }}>
          <FullCalendar
            ref={calendarComponentRef}
            initialView="dayGridMonth"
            plugins={[dayGrid, timeGrid, interaction]}
            events={events}
            headerToolbar={{
              start: "today prev,next",
              center: "title",
              end: "dayGridMonth timeGridWeek btn_upload btn_set",
            }}
            locale={locale}
            buttonText={{
              today: intl.formatMessage({ id: "Today" }),
              month: intl.formatMessage({ id: "Month" }),
              week: intl.formatMessage({ id: "Week" }),
            }}
            customButtons={{
              btn_upload: {
                icon: "upload icon-cloud_uploadbackup",
                click: () => showModal("import"),
              },
              btn_set: {
                icon: "set icon-settings",
                click: () => showModal("set"),
              },
            }}
            eventDisplay="list-item"
            eventBorderColor="transparent"
            displayEventEnd={true}
            dateClick={dateClick}
            eventClick={eventClick}
            eventTimeFormat={{
              hour: "2-digit",
              minute: "2-digit",
              hour12: false,
            }}
            datesSet={datesSet}
          />
        </div>
      </div>

      <InputModal
        modal={modal}
        onHide={onHide}
        data={select}
        setModal={setModal}
      />
      <ImportModal modal={modal} onHide={onHide} />
      <SetModal modal={modal} onHide={onHide} />

      <DeleteModal
        show={modal === "delete"}
        onHide={onHide}
        name=""
        type="Shift"
        callback={deleteData}
      />
    </div>
  );
}

export default ManagementClass;
