import React, { useEffect, useState, useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Table, ColumnFilter } from "../common";
import { getSysLogger } from "../../api";
import Context from "../../context";
import moment from "moment";

function LogTable({ date }) {
  const intl = useIntl();
  const { factory } = useContext(Context);
  const [data, setData] = useState([]);

  useEffect(() => {
    getSysLogger({
      FactoryID: factory,
      CheckDateStart: date.startDate,
      CheckDateEnd: date.endDate,
    })
      .then((res) => setData(res.data))
      .catch(console.log);
  }, []);

  const columns = [
    {
      Header: <FormattedMessage id="CreateTime" />,
      accessor: "created_at",
      Filter: ({ column }) => ColumnFilter(column, "CreateTime"),
    },
    {
      Header: <FormattedMessage id="Category" />,
      accessor: "field_classify",
      Filter: ({ column }) => ColumnFilter(column, "Category"),
    },
    {
      Header: <FormattedMessage id="User" />,
      accessor: "user",
      Filter: ({ column }) => ColumnFilter(column, "User"),
    },
    {
      Header: <FormattedMessage id="description" />,
      accessor: "description",
      Filter: ({ column }) => ColumnFilter(column, "description"),
    },
    {
      Header: <FormattedMessage id="BeforeEditing" />,
      accessor: "edit_before",
      Filter: ({ column }) => ColumnFilter(column, "BeforeEditing"),
    },
    {
      Header: <FormattedMessage id="AfterEditing" />,
      accessor: "edit_after",
      Filter: ({ column }) => ColumnFilter(column, "AfterEditing"),
    },
  ];

  const display = data.map((item) => ({
    ...item,
    created_at: moment(item.created_at).format("lll"),
    field_classify: intl.formatMessage({ id: item.classify }),
    description:
      item.action === "INSERT"
        ? intl.formatMessage(
            { id: "CreateData" },
            {
              category: intl.formatMessage({ id: item.classify }),
              name: item.field_name,
            }
          )
        : item.action === "UPDATE"
        ? intl.formatMessage(
            { id: "EditData" },
            {
              name: item.field_name,
              field: intl.formatMessage({ id: item.field_edit }),
            }
          )
        : item.action === "DELETE"
        ? intl.formatMessage(
            { id: "DeleteData" },
            {
              category: intl.formatMessage({ id: item.classify }),
              name: item.field_name,
            }
          )
        : "",
  }));

  return <Table columns={columns} data={display} />;
}
export { LogTable };
