import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { DateRadio, RangeDatePicker, SingleDropdownPicker } from "../../component/common";
import { SysTabs } from "../../component/system/system";
import { LogTable } from "../../component/system/log";
import moment from "moment";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export default function Log({ checkEditAuth }) {
  const intl = useIntl();
  const [date, setDate] = useState({
    startDate: moment().subtract(30, "d").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  });
  const [sort, setSort] = useState("date");
  const [dataInterval, setDataInterval] = useState("thirty");
  const [month, setMonth] = useState(0);

  useEffect(() => {
    if (sort === "date") {
      setDataInterval("thirty");
    } else {
      setMonth(moment().month());
    }
  }, [sort]);

  useEffect(() => {
    setDate({
      startDate: moment().month(month).startOf("month").format("YYYY-MM-DD"),
      endDate: moment().month(month).endOf("month").format("YYYY-MM-DD"),
    });
  }, [month]);

  useEffect(() => {
    if (dataInterval === "seven") {
      setDate({
        startDate: moment().subtract(7, "d").format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
      });
    } else if (dataInterval === "thirty") {
      setDate({
        startDate: moment().subtract(30, "d").format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
      });
    }
  }, [dataInterval]);

  const pickDateRange = (startDate, endDate) => {
    setDate((prev) => ({ ...prev, startDate, endDate }));
    setDataInterval("");
  };

  return (
    <div>
      <SysTabs tab="6" />
      <div className="page-content shadow-lg">
        <div className="ai-stretch mb-3">
          <DateRadio
            list={[
              {
                label: <FormattedMessage id="Interval" />,
                value: "date",
              },
              { label: <FormattedMessage id="Month" />, value: "month" },
            ]}
            value={sort}
            onChange={(e) => setSort(e.target.value)}
          />
          {sort === "date" ? (
            <DateRadio
              list={[
                {
                  label: <FormattedMessage id="LastSevenDays" />,
                  value: "seven",
                },
                {
                  label: <FormattedMessage id="LastThirtyDays" />,
                  value: "thirty",
                },
              ]}
              value={dataInterval}
              onChange={(e) => setDataInterval(e.target.value)}
              style={{ margin: "0 20px" }}
            />
          ) : (
            <DateRadio
              list={[
                {
                  label: <FormattedMessage id="LastMonth" />,
                  value: moment().subtract(1, "months").month(),
                },
                {
                  label: <FormattedMessage id="ThisMonth" />,
                  value: moment().month(),
                },
              ]}
              value={month}
              onChange={(e) => setMonth(parseInt(e.target.value))}
              style={{ margin: "0 20px" }}
            />
          )}
          {sort === "date" ? (
            <RangeDatePicker
              startDate={date.startDate}
              endDate={date.endDate}
              timePicker={false}
              onApply={pickDateRange}
            />
          ) : (
            <SingleDropdownPicker
              style={{ width: "100px" }}
              value={month}
              list={months.map((id, i) => ({
                label: intl.formatMessage({ id }),
                value: i,
              }))}
              onApply={setMonth}
            />
          )}
        </div>
        <hr />
        <LogTable date={date} />
      </div>
    </div>
  );
}
