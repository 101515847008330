import React, { useState, useEffect, useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Collapse from "@material-ui/core/Collapse";
import CancelIcon from "@material-ui/icons/Cancel";
import Alert from "@material-ui/lab/Alert";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import moment from "moment";
import { MdCreate, MdOpenInNew, MdDelete, MdContentCopy } from "react-icons/md";
import { SuggestionInput, InputModalBase } from "./Management";
import { ColumnFilter, Table, Pagination } from "../common";
import {
  ApiOrder_GetProduceOrder,
  ApiOrder_UploadOrderData,
  ApiManagement_unproductiveOrder,
  ApiOrder_UpdateOrderTest,
  ApiOrder_PostOrderTest,
  ApiOrder_GetProduceProduct,
  ApiOrder_GetProduceProductById,
  ApiOrder_GetProduceMoldById,
  ApiManagement_show_mold,
  ApiOrder_UpdateOrder,
  ApiOrder_PostOrder,
  ApiOrder_SeparateOrder,
  postOrderSplit,
  ApiOrder_DeleteOrder,
} from "../../api.js";
import Context from "../../context";
import { showWarn, orderStatus } from "../../function";

export function OrderTable({ date, modal, showModal }) {
  const [data, setData] = useState([]);
  const { factory } = useContext(Context);

  useEffect(() => {
    if (!modal) {
      loadData();
    }
  }, [date, modal]);

  const loadData = () => {
    ApiOrder_GetProduceOrder({
      factory_id: factory,
      date: `${date.startDate}to${date.endDate}`,
    })
      .then((res) => {
        let data = [];
        for (let item of res.data) {
          if (
            item.order_group_name === "null" ||
            item.order_group_name === null
          ) {
            data.push(item);
          } else {
            const index = data.findIndex(
              ({ order_num }) => order_num === item.order_num
            );
            if (index > -1) {
              data[index].order_group_name_label = (
                <span>
                  <ArrowRightIcon />
                  {item.order_group_name}
                </span>
              );
              if (!("children" in data[index])) {
                data[index].children = [];
              }
              data[index].children.push(item);
            } else {
              item.order_group_name_label = item.order_group_name;
              data.push(item);
            }
          }
        }
        setData(
          data.map((item, i) => {
            item.backgroundColor = i % 2 === 0 ? "#ededed" : "white";
            return item;
          })
        );
      })
      .catch(console.log);
  };

  const onClick = (row) => {
    const index = data.findIndex(
      ({ order_num }) => order_num === row.order_num
    );
    if (index > -1) {
      if (data[index + 1]?.order_num != row.order_num) {
        if (row.children) {
          setData((prev) => {
            const arr = prev.slice();
            arr[index].order_group_name_label = (
              <span>
                <ArrowDropDownIcon />
                {arr[index].order_group_name}
              </span>
            );
            row.children.forEach((item, i) => {
              item.order_group_name = null;
              item.backgroundColor = "#fafafa";
              arr.splice(index + 1 + i, 0, item);
            });
            return arr;
          });
        }
      } else {
        setData((prev) => {
          const arr = prev.slice();
          arr[index].order_group_name_label = (
            <span>
              <ArrowRightIcon />
              {arr[index].order_group_name}
            </span>
          );
          arr.splice(index + 1, row.children.length);
          return arr;
        });
      }
    }
  };

  const _showModal = (e, modal, item) => {
    e.preventDefault();
    showModal(modal, item);
  };

  const columns = [
    {
      Header: <FormattedMessage id="order_group_name" />,
      accessor: "order_group_name_label",
      Filter: ({ column }) => ColumnFilter(column, "order_group_name"),
    },
    {
      Header: <FormattedMessage id="order_num_name" />,
      accessor: "order_num_name",
      Filter: ({ column }) => ColumnFilter(column, "order_num_name"),
    },
    {
      Header: <FormattedMessage id="CreateDate" />,
      accessor: "create_date",
      Filter: "",
    },
    {
      Header: <FormattedMessage id="Status" />,
      accessor: "order_status",
      Cell: ({ cell: { value } }) => orderStatus(value),
      Filter: ({ column }) => StatusFilter(column, "Status"),
    },
    {
      Header: <FormattedMessage id="product_id" />,
      accessor: "product_id",
      Filter: ({ column }) => ColumnFilter(column, "product_id"),
    },
    {
      Header: <FormattedMessage id="mold_id" />,
      accessor: "mold_id",
      Filter: ({ column }) => ColumnFilter(column, "mold_id"),
    },
    {
      Header: <FormattedMessage id="expect_quantity" />,
      accessor: "expect_quantity",
      Filter: "",
    },
    {
      Header: <FormattedMessage id="actual_quantity" />,
      accessor: "actual_quantity",
      Filter: "",
    },
    {
      Header: <FormattedMessage id="DeviceNumber" />,
      accessor: "device_id",
      Filter: ({ column }) => ColumnFilter(column, "DeviceNumber"),
    },
    {
      Header: <FormattedMessage id="Edit" />,
      accessor: "editor",
      Filter: "",
    },
  ];

  const display = data.map((item) => ({
    ...item,
    editor: (
      <span className="ai-center jd-end" style={{ width: "120px" }}>
        <span className="flex-1">
          {item.order_status !== 2 && (
            <MdCreate
              className="icon-btn"
              size="20px"
              onClick={(e) => _showModal(e, "edit", item)}
            />
          )}
        </span>
        <span className="flex-1">
          <MdContentCopy
            className="icon-btn"
            size="20px"
            onClick={(e) => _showModal(e, "copy", item)}
          />
        </span>
        <span className="flex-1">
          {item.order_status !== 3 &&
            item.order_status !== 4 &&
            item.product_id && (
              <MdOpenInNew
                className="icon-btn"
                size="20px"
                onClick={(e) => _showModal(e, `split`, item)}
              />
            )}
        </span>
        <span className="flex-1">
          {item.order_status !== 2 && (
            <MdDelete
              className="icon-btn"
              size="20px"
              onClick={(e) => _showModal(e, "delete", item)}
            />
          )}
        </span>
      </span>
    ),
  }));

  return <Table columns={columns} data={display} onClick={onClick} />;
}

export const StatusFilter = ({ filterValue, setFilter }) => {
  const intl = useIntl();
  return (
    <select
      value={filterValue || ""}
      onChange={(e) => setFilter(e.target.value || "")}
      style={{ width: "60px" }}
    >
      <option value="">{intl.formatMessage({ id: "PickAll" })}</option>
      <option value="0">{intl.formatMessage({ id: "Unscheduled" })}</option>
      <option value="1">{intl.formatMessage({ id: "Waiting" })}</option>
      <option value="2">{intl.formatMessage({ id: "Producing" })}</option>
      <option value="3">
        {intl.formatMessage({ id: "FinishedManually" })}
      </option>
      <option value="4">
        {intl.formatMessage({ id: "FinishedAutomatically" })}
      </option>
    </select>
  );
};

export const ImportModal = ({ modal, onHide }) => {
  const intl = useIntl();
  const { factory } = useContext(Context);
  const [select, setSelect] = useState({});

  useEffect(() => {
    if (!modal) {
      setSelect({});
    }
  }, [modal]);

  const submit = () => {
    return new Promise((resolve, reject) => {
      const data = new FormData();
      data.append("file", select.xls);
      data.append("factory_id", factory);
      ApiOrder_UploadOrderData(data).then(resolve).catch(reject);
    });
  };

  const columns = [
    {
      id: "xls",
      type: "file",
      required: true,
      remark: "OrderImportRemark",
      extensions: ["xls", "xlsx"],
    },
  ];

  return (
    <InputModalBase
      show={modal === "import"}
      onHide={onHide}
      title={intl.formatMessage(
        { id: "Import" },
        { name: intl.formatMessage({ id: "Order" }) }
      )}
      columns={columns}
      select={select}
      setSelect={setSelect}
      submit={submit}
    />
  );
};

export const InputModal = ({
  modal,
  onHide,
  data,
  callback,
  open,
  setOpen,
}) => {
  const intl = useIntl();
  const { factory, auth } = useContext(Context);
  const [displayMore, setDisplayMore] = useState(false);
  const [select, setSelect] = useState({ orderInfo: [{ material_id: [] }] });
  const [productList, setProductList] = useState([]);
  const [error, setError] = useState("");
  const disabled = modal === "split";
  const columns = [
    { id: "order_group_name", type: "text", disabled },
    { id: "best_cycle", type: "number", required: true, disabled },
    { id: "normal_amount", type: "number" },
    { id: "qc_point", type: "text" },
    { id: "mold_time", type: "number", required: select.die_time },
    { id: "die_time", type: "number", required: select.mold_time },
    { id: "end_time", type: "datetime" },
    { id: "auto_finish", type: "check_box" },
  ];
  useEffect(() => {
    ApiOrder_GetProduceProduct({
      factory_id: factory,
    })
      .then((res) =>
        setProductList(
          res.data.sort((a, b) => (a.product_num > b.product_num ? 1 : -1))
        )
      )
      .catch(console.log);
  }, []);

  useEffect(() => {
    if (!modal) {
      setDisplayMore(false);
      setError("");
    }
    setSelect(JSON.parse(JSON.stringify(data)));
  }, [modal, data]);

  const onChange = (key, val) => {
    setSelect((prev) => ({ ...prev, [key]: val }));
  };

  const checkInput = async () => {
    document.querySelectorAll(".warn").forEach((item) => (item.hidden = true));
    let pass = true;
    columns.forEach(({ id, type, required }) => {
      if (
        required &&
        (!select[id] || select[id] === "null" || select[id].length === 0)
      ) {
        showWarn(`${id}-warn`, "Required", intl);
        pass = false;
      } else if (
        select[id] &&
        (type === "date" || type === "datetime") &&
        !moment(select[id]).isValid()
      ) {
        showWarn(`${id}-warn`, "WrongFormat", intl);
        pass = false;
      }
    });
    const subColumns = [
      { id: "order_num", required: true },
      { id: "product_id", required: true },
      { id: "material_id", required: true },
      { id: "mold_id", required: true },
      { id: "cavity_amount", required: true },
      { id: "expect_quantity", required: true },
    ];
    select.orderInfo.forEach((item, i) => {
      subColumns.forEach(({ id, required }) => {
        if (
          required &&
          (!item[id] || item[id] === "null" || item[id].length === 0)
        ) {
          showWarn(`${id}-warn-${i}`, "Required", intl);
          pass = false;
        }
      });
    });

    for (let i = 0; i < select.orderInfo.length; i++) {
      if (select.orderInfo[i].mold_id != select.orderInfo[0].mold_id) {
        for (let j = 0; j < select.orderInfo.length; j++) {
          showWarn(`mold_id-warn-${j}`, "NeedSame", intl);
        }
        pass = false;
        break;
      }
    }

    if (pass) {
      for (let i = 0; i < select.orderInfo.length; ++i) {
        try {
          const res = await ApiManagement_show_mold({
            id: select.orderInfo[i].mold_id,
            factory_id: factory,
          });
          const product = productList.find(
            ({ product_id }) =>
              product_id === parseInt(select.orderInfo[i].product_id)
          );
          select.orderInfo[i].product_name = product?.product_name;
          if (res.data.length > 0) {
            select.orderInfo[i].mold_num = res.data[0].mold_num;
            select.orderInfo[i].last_modify_time = moment(
              res.data[0].last_modify_time
            ).format("YYYY-MM-DD HH:mm");
          }
        } catch (e) {
          setError(intl.formatMessage({ id: e.response?.status.toString() }));
          console.log(e);
        }
      }
      columns.forEach(({ id, type }) => {
        if (select[id] === undefined) {
          if (type === "number") {
            select[id] = 0;
          } else if (type === "text" || type === "datetime") {
            select[id] = "";
          }
        }
      });
      callback(select);
    }
  };

  const add = () => {
    setOpen(true);
    setSelect((prev) => {
      prev.orderInfo.push({ material_id: [] });
      return JSON.parse(JSON.stringify(prev));
    });
  };

  const title = intl.formatMessage(
    {
      id: modal.includes("new")
        ? "Create"
        : modal.includes("copy")
        ? "Copy"
        : modal.includes("split")
        ? "SplitThe"
        : "EditThe",
    },
    { name: intl.formatMessage({ id: "Order" }) }
  );

  const handleOpen = () => {
    setOpen(!open);
  };
  return (
    <Modal
      className="modal_style fade"
      size="lg"
      show={
        modal === "new" ||
        modal === "edit" ||
        modal === "copy" ||
        modal === "split"
      }
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title as="h5">
          <b>{title}</b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {select.orderInfo.map((item, i) => (
            <OrderBlock
              modal={modal}
              key={i}
              index={i}
              select={item}
              setSelect={setSelect}
              productList={productList}
              disabled={disabled}
              product_id={data.orderInfo[i]?.product_id}
            />
          ))}
          {auth.multi_order === 2 ? (
            select.orderInfo.length < 2 && (
              <div
                className="center"
                style={{
                  backgroundColor: "#cccccc",
                  cursor: "pointer",
                  color: "#808299",
                }}
                onClick={add}
              >
                <span>+add</span>
              </div>
            )
          ) : (
            <></>
          )}
          <hr />
          {open ? (
            columns
              .filter((_, i) => i < 2 || displayMore)
              .map(({ id, type, disabled }) => {
                if (
                  type === "text" ||
                  type === "number" ||
                  type === "password"
                ) {
                  return (
                    <Form.Group key={id} as={Row} controlId={id}>
                      <Form.Label column lg={3} md={4}>
                        {intl.formatMessage({ id })}
                      </Form.Label>
                      <Col lg={9} md={8}>
                        <Form.Control
                          onChange={(e) =>
                            onChange(
                              id,
                              type === "number"
                                ? parseFloat(e.target.value)
                                : e.target.value
                            )
                          }
                          value={select[id] ? select[id] : ""}
                          placeholder={intl.formatMessage(
                            { id: "InputThe" },
                            { name: intl.formatMessage({ id }) }
                          )}
                          type={type}
                          disabled={disabled}
                        />
                        <span id={`${id}-warn`} className="warn" />
                      </Col>
                    </Form.Group>
                  );
                }
              })
          ) : (
            <></>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer className="jc-space-between ai-center">
        <Collapse in={error !== ""}>
          <Alert severity="error">{error}</Alert>
        </Collapse>
        <div className="ai-center">
          <button className="btn btn-more" onClick={handleOpen}>
            <FormattedMessage id="AdvancedSettings" />
          </button>
          <button
            type="submit"
            className="btn btn-confirm"
            onClick={checkInput}
          >
            <FormattedMessage id="Confirm" />
          </button>
          <button className="btn btn-cancel" onClick={onHide}>
            <FormattedMessage id="Cancel" />
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export const OrderBlock = ({
  select,
  setSelect,
  productList,
  index,
  disabled,
  product_id,
}) => {
  const intl = useIntl();
  const { factory } = useContext(Context);
  const [moldList, setMoldList] = useState([]);
  const [materialList, setMaterialList] = useState([]);
  const subColumns = [
    { id: "order_num", type: "text", required: true, disabled },
    {
      id: "product_id",
      type: "select",
      required: true,
      options: productList.map(({ product_id, product_name, product_num }) => ({
        label: `${product_num} - ${product_name}`,
        value: product_id,
      })),
      disabled,
    },
    {
      id: "material_id",
      type: "multi_select",
      required: true,
      options: materialList.map(({ material_id, material_num }) => ({
        label: material_num,
        value: material_id,
      })),
      disabled,
    },
    {
      id: "mold_id",
      type: "select",
      required: true,
      options: moldList.map(({ mold_id, mold_num }) => ({
        label: mold_num,
        value: mold_id,
      })),
      disabled,
    },
    { id: "cavity_amount", type: "number", required: true, disabled },
    { id: "expect_quantity", type: "number", required: true },
  ];

  useEffect(() => {
    if (select.product_id && select.product_id !== "null") {
      ApiOrder_GetProduceProductById({
        factory_id: factory,
        product_id: select.product_id,
      }).then(async (res) => {
        if (res.data.length > 0) {
          const { MaterialNum, MoldNum } = res.data[0];
          let best_cycle;
          let cavity_amount = select.cavity_amount;
          if (MoldNum.length > 0 && select.product_id !== product_id) {
            const res = await ApiOrder_GetProduceMoldById({
              factory_id: factory,
              mold_id: MoldNum[0]?.mold_id,
            });
            best_cycle = res.data[0].best_cycle;
            cavity_amount = res.data[0].cavity_amount;
          }
          setSelect((prev) => {
            prev.orderInfo[index] = {
              ...prev.orderInfo[index],
              material_id: MaterialNum.map(({ material_id }) => material_id),
              mold_id: MoldNum[0]?.mold_id,
              mold_num: MoldNum[0]?.mold_num,
              cavity_amount,
            };
            if (best_cycle) {
              prev.best_cycle = best_cycle;
            }
            return JSON.parse(JSON.stringify(prev));
          });
          setMaterialList(MaterialNum);
          setMoldList(MoldNum);
        }
      });
    } else if (select.product_id === "null") {
      setMaterialList([]);
      setMoldList([]);
    }
  }, [select.product_id]);

  useEffect(() => {
    if (select.mold_id) {
      const mold = moldList.find(
        (item) => item.mold_id === parseInt(select.mold_id)
      );
      if (mold && mold.mold_num !== select.mold_num) {
        ApiOrder_GetProduceMoldById({
          factory_id: factory,
          mold_id: select.mold_id,
        }).then((res) => {
          setSelect((prev) => {
            prev.orderInfo[index].mold_num = mold?.mold_num;
            if (
              !prev.best_cycle ||
              res.data[0].best_cycle > parseInt(prev.best_cycle)
            ) {
              prev.best_cycle = res.data[0].best_cycle;
            }
            prev.orderInfo[index].cavity_amount = res.data[0].cavity_amount;
            return JSON.parse(JSON.stringify(prev));
          });
        });
      }
    }
  }, [select.mold_id]);

  const remove = () => {
    setSelect((prev) => {
      prev.orderInfo.splice(index, 1);
      return JSON.parse(JSON.stringify(prev));
    });
  };

  const onChange = (key, val) => {
    setSelect((prev) => {
      prev.orderInfo[index][key] = val;
      return JSON.parse(JSON.stringify(prev));
    });
  };

  return (
    <React.Fragment>
      {index !== 0 && (
        <div className="jc-end">
          <CancelIcon
            className="mb-3"
            onClick={remove}
            style={{ cursor: "pointer" }}
          />
        </div>
      )}
      {subColumns.map(({ id, type, options, disabled }, i) => {
        if (type === "text" || type === "number" || type === "password") {
          return (
            <Form.Group key={id} as={Row} controlId={id}>
              <Form.Label column lg={3} md={4}>
                {intl.formatMessage({ id })}
              </Form.Label>
              <Col lg={9} md={8}>
                <Form.Control
                  onChange={(e) =>
                    onChange(
                      id,
                      type === "number"
                        ? parseFloat(e.target.value)
                        : e.target.value
                    )
                  }
                  value={select[id] || ""}
                  placeholder={intl.formatMessage(
                    { id: "InputThe" },
                    { name: intl.formatMessage({ id }) }
                  )}
                  type={type}
                  disabled={disabled}
                />
                <span id={`${id}-warn-${index}`} className="warn" />
              </Col>
            </Form.Group>
          );
        }
        if (type === "select") {
          return (
            <Form.Group key={id} as={Row} controlId={id}>
              <Form.Label column lg={3} md={4}>
                {intl.formatMessage({ id })}
              </Form.Label>
              <Col lg={9} md={8}>
                <Form.Control
                  as="select"
                  onChange={(e) => onChange(id, e.target.value)}
                  value={select[id] || "null"}
                  disabled={disabled}
                >
                  <option value="null">
                    {intl.formatMessage(
                      { id: "PickA" },
                      { name: intl.formatMessage({ id }) }
                    )}
                  </option>
                  {options.map(({ label, value }) => (
                    <option key={value} value={value}>
                      {label}
                    </option>
                  ))}
                </Form.Control>
                <span id={`${id}-warn-${index}`} className="warn" />
              </Col>
            </Form.Group>
          );
        }
        if (type === "multi_select") {
          return (
            <Form.Group key={id} as={Row} controlId={id}>
              <Form.Label column lg={3} md={4}>
                {intl.formatMessage({ id })}
              </Form.Label>
              <Col lg={9} md={8}>
                <SuggestionInput
                  id={id}
                  options={options}
                  value={select[id]}
                  onChange={(val) => onChange(id, val)}
                  name={id}
                  disabled={disabled}
                />
                <span id={`${id}-warn-${index}`} className="warn" />
              </Col>
            </Form.Group>
          );
        }
      })}
      <hr />
    </React.Fragment>
  );
};

export const UnproductiveModal = ({ modal, onHide, data }) => {
  const intl = useIntl();
  const { factory } = useContext(Context);
  const [select, setSelect] = useState({});
  const [reasonList, setReasonList] = useState([]);

  useEffect(() => {
    ApiManagement_unproductiveOrder({
      factory_id: factory,
    })
      .then((res) => setReasonList(res.data))
      .catch(console.log);
  }, []);

  useEffect(() => {
    setSelect(JSON.parse(JSON.stringify(data)));
  }, [data]);

  const submit = () => {
    return new Promise((resolve, reject) => {
      let req = {
        ...select,
        account: localStorage.getItem("account"),
        factory_id: factory,
        autoFinish: select.auto_finish,
      };
      if (modal.includes("new")) {
        ApiOrder_PostOrderTest(req).then(resolve).catch(reject);
      } else {
        req.order_id = select.order_id;
        ApiOrder_UpdateOrderTest(req).then(resolve).catch(reject);
      }
    });
  };

  const title = intl.formatMessage(
    {
      id: modal.includes("new")
        ? "Create"
        : modal.includes("copy")
        ? "Copy"
        : "EditThe",
    },
    { name: intl.formatMessage({ id: "UnproductiveOrder" }) }
  );

  const columns = [
    {
      id: "order_num",
      type: "text",
      required: true,
    },
    {
      id: "order_reason",
      type: "select",
      required: true,
      options: reasonList.map(({ id, description }) => ({
        label: description,
        value: id,
      })),
    },
    {
      id: "expected_time",
      type: "number",
      required: true,
      remark: "ExpextedTimeRemark",
    },
    {
      id: "auto_finish",
      type: "check_box",
    },
  ];

  return (
    <InputModalBase
      show={modal.includes("unproductive")}
      onHide={onHide}
      title={title}
      columns={columns}
      select={select}
      setSelect={setSelect}
      submit={submit}
    />
  );
};

export const ConfirmModal = ({ show, modal, onHide, onCancel, data }) => {
  const intl = useIntl();
  const { factory } = useContext(Context);
  const [error, setError] = useState("");

  const submit = () => {
    if (modal === "split") {
      const { orderInfo } = data;
      if (data.order_status === 2) {
        postOrderSplit({
          factory_id: factory,
          order_id: orderInfo.map(({ id }) => id),
          expect_quantity: orderInfo.map(
            ({ expect_quantity }) => expect_quantity
          ),
        })
          .then(onHide)
          .catch((e) =>
            setError(intl.formatMessage({ id: e.response?.status.toString() }))
          );
      } else {
        const { orderInfo } = data;
        ApiOrder_SeparateOrder({
          factory_id: factory,
          order_id: orderInfo.map(({ id }) => id),
          expect_quantity: orderInfo.map(
            ({ expect_quantity }) => expect_quantity
          ),
        })
          .then(onHide)
          .catch((e) =>
            setError(intl.formatMessage({ id: e.response?.status.toString() }))
          );
      }
    } else {
      const {
        orderInfo,
        order_group_name,
        best_cycle,
        normal_amount,
        qc_point,
        mold_time,
        die_time,
        end_time,
        auto_finish,
      } = data;
      const req = {
        account: localStorage.getItem("account"),
        factory_id: factory,
        order_id: orderInfo.map(({ id }) => id),
        order_group_name,
        best_cycle,
        normal_amount,
        qc_point,
        mold_time,
        die_time,
        end_time,
        order_num: orderInfo.map(({ order_num }) => order_num),
        product_id: orderInfo.map(({ product_id }) => parseInt(product_id)),
        mold_id: orderInfo.map(({ mold_id }) => mold_id),
        material_id: orderInfo.map(({ material_id }) => material_id),
        cavity_amount: orderInfo.map(({ cavity_amount }) => cavity_amount),
        expect_quantity: orderInfo.map(
          ({ expect_quantity }) => expect_quantity
        ),
        auto_finish,
      };

      if (modal === "edit") {
        ApiOrder_UpdateOrder(req)
          .then(onHide)
          .catch((e) =>
            setError(intl.formatMessage({ id: e.response?.status.toString() }))
          );
      } else {
        ApiOrder_PostOrder(req)
          .then(onHide)
          .catch((e) =>
            setError(intl.formatMessage({ id: e.response?.status.toString() }))
          );
      }
    }
  };

  return (
    <Modal className="modal_style fade" size="lg" show={show} onHide={onCancel}>
      <Modal.Header closeButton>
        <Modal.Title as="h5">
          <b>
            <FormattedMessage id="ConfirmationMessage" />
          </b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {data.orderInfo.map(
          (
            { last_modify_time, product_name, mold_num, expect_quantity },
            i
          ) => (
            <div key={i}>
              <h6>
                <FormattedMessage id="MoldLastUpdate" />：{last_modify_time}
              </h6>
              <h6>
                <FormattedMessage id="product_name" />：{product_name}
              </h6>
              <h6>
                <FormattedMessage id="mold_num" />：{mold_num}
              </h6>
              <h6>
                <FormattedMessage id="PlannedProductionQuantity" />：
                {expect_quantity}
              </h6>
              <hr />
            </div>
          )
        )}
        <h6>
          <FormattedMessage id="best_cycle" />：{data.best_cycle}
        </h6>
      </Modal.Body>
      <Modal.Footer className="jc-space-between">
        <Collapse in={error !== ""}>
          <Alert severity="error">{error}</Alert>
        </Collapse>
        <div className="ai-center">
          <button className="btn btn-delete " onClick={submit}>
            <FormattedMessage id="Confirm" />
          </button>
          <button className="btn btn-cancel" onClick={onCancel}>
            <FormattedMessage id="Cancel" />
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export const MultiDeleteModal = ({ modal, date, onHide }) => {
  const intl = useIntl();
  const { factory } = useContext(Context);
  const [page, setPage] = useState(0);
  const [order_id, setOrderID] = useState([]);
  const [data, setData] = useState([]);
  const [error, setError] = useState("");

  useEffect(() => {
    if (modal) {
      loadData();
    }
  }, [modal, factory, date]);

  useEffect(() => {
    if (!modal) {
      setOrderID([]);
    }
  }, [modal]);

  const loadData = () => {
    ApiOrder_GetProduceOrder({
      factory_id: factory,
      date: `${date.startDate}to${date.endDate}`,
    })
      .then((res) => setData(res.data))
      .catch(console.log);
  };

  const onClick = (id) => {
    let list = order_id.slice();
    const index = order_id.indexOf(id);
    if (index > -1) {
      list.splice(index, 1);
    } else {
      list.push(id);
    }
    setOrderID(list);
  };

  const deleteData = () => {
    ApiOrder_DeleteOrder({
      account: localStorage.getItem("account"),
      factory_id: factory,
      order_id,
    })
      .then(onHide)
      .catch((e) => {
        if (e.response) {
          if (e.response.data.errors) {
            setError(e.response.data.errors[0]?.msg);
          } else {
            setError(intl.formatMessage({ id: e.response.status.toString() }));
          }
        } else {
          console.log(e);
        }
      });
  };

  return (
    <Modal
      className="modal_style fade"
      size="lg"
      show={modal === "multiDelete"}
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title as="h5">
          <b>{intl.formatMessage({ id: "BatchDelete" })}</b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pes-area">
        <ul className="downtime-list">
          <li className="ai-center">
            <b className="flex-1 text-center">
              <FormattedMessage id="CreateTime" />
            </b>
            <b className="flex-1 text-center">
              <FormattedMessage id="productNum" />
            </b>
            <b className="flex-1 text-center">
              <FormattedMessage id="DeviceNumber" />
            </b>
            <b className="flex-1 text-center">
              <FormattedMessage id="orderNum" />
            </b>
          </li>
          {data
            .slice(page * 10, (page + 1) * 10)
            .map(
              ({
                id,
                color,
                create_date,
                product_id,
                device_id,
                order_num,
              }) => (
                <li
                  key={id}
                  className="ai-center"
                  onClick={() => onClick(id)}
                  style={{ borderLeftColor: color }}
                >
                  <b className="flex-1 text-center">{create_date}</b>
                  <b className="flex-1 text-center">{product_id}</b>
                  <b className="flex-1 text-center">{device_id}</b>
                  <b className="flex-1 text-center">{order_num}</b>
                  <input type="checkbox" checked={order_id.indexOf(id) > -1} />
                </li>
              )
            )}
        </ul>
        <div className="jc-end">
          <Pagination
            pageIndex={page}
            canPreviousPage={page > 0}
            previousPage={() => setPage(page - 1)}
            canNextPage={page < Math.ceil(data.length / 10) - 1}
            nextPage={() => setPage(page + 1)}
            pageCount={Math.ceil(data.length / 10)}
            gotoPage={setPage}
          />
        </div>
      </Modal.Body>
      <Modal.Footer className="jc-space-between ai-center">
        <Collapse in={error !== ""}>
          <Alert severity="error">{error}</Alert>
        </Collapse>
        <div className="ai-center">
          <button className="btn btn-confirm" onClick={deleteData}>
            <FormattedMessage id="OK" />
          </button>
          <button className="btn btn-cancel" onClick={onHide}>
            <FormattedMessage id="Cancel" />
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
