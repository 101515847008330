const management = {
  OrderManagement: "工單管理",
  OrderScheduling: "工單排程",
  MaterialManagement: "材料管理",
  MoldManagement: "模具管理",
  ProductManagement: "產品管理",
  ShiftManagement: "班別管理",
  HolidayManagement: "假日管理",
  DefectManagement: "不良原因管理",
  DowntimeReasonManagement: "停機原因管理",
  UnproductiveOrderReasonManagement: "非生產性工單原因管理",
  AdvancedSettings: "進階設定",

  // 工單管理
  Order: "工單",
  LastSevenDays: "近七天",
  LastThirtyDays: "近三十天",
  ThisMonth: "這個月",
  LastMonth: "上個月",
  OrderList: "工單列表",
  CreateDate: "建立日期",
  actual_quantity: "實際生產數量",
  DeviceNumber: "機台編號",
  order_group_name: "工單群組名稱",
  order_num: "工單編號",
  order_num_name: "工單編號",
  product_id: "產品編號",
  material_id: "使用原料料號",
  mold_id: "使用模具編號",
  cavity_amount: "模穴數",
  best_cycle: "最佳週期時間",
  expect_quantity: "目標數量",
  normal_amount: "進入量產模式的連續正常模次數量",
  qc_point: "品檢要點",
  mold_time: "上模時間(分)",
  die_time: "下模時間(分)",
  ApplyOrderExpirationDate: "套用工單到期時間",
  auto_finish: "工單自動完工",
  OrderExpirationDate: "工單到期時間",
  UnproductiveOrder: "非生產性工單",
  order_reason: "工單原因",
  OrderGroup: "工單群組",
  GroupName: "群組名稱",
  expected_time: "預期使用機台時間",
  ExpextedTimeRemark: "請以分鐘計算，如2小時，請輸入120",
  Separate: "拆分{name}",
  DownloadExample: "範例下載",
  Unscheduled: "未排定",
  Waiting: "待生產",
  Producing: "生產中",
  FinishedManually: "已完成（手動）",
  FinishedAutomatically: "已完成（自動）",
  OrderImportRemark:
    "請以逗號(,)區分多個產品、原料編號或模具編號，例如：Mold001,Mold002",
  MoldLastUpdate: "上次模具修改時間",
  SplitConfirm: "您確定要對工單{name}進行拆單嗎？",

  //工單排程
  Schedule: "排程",
  UnassignedOrders: "未指派工單",
  ModifyOrderSchedule: "調整工單排程",
  start_time: "開始時間",
  device_id: "機號",
  ScheduleDates: "排程時間",
  ArrangementMachineNum: "排單機台",
  CancelSchedule: "取消排程",
  WrongDateFormat: "時間格式錯誤",
  UsedBefore: "射出機曾經做過相同產品",
  MoldOutOfSide: "模具與射出機容模尺寸不合",
  NozzleOutOfSide: "模具與機台射嘴不合",
  ShotWeightInsufficient: "模次總重量大於射出機允許重量",
  ClampingForceInsufficient: "鎖模力不足",
  InsertMode: "插單模式",

  //材料管理
  Material: "材料",
  material_num: "材料編號",
  material_model: "材料型號",
  base_material: "基底材料",
  supplier: "供應商",
  melt_density: "熔體密度",
  soild_state_density: "固態密度",
  lowest_melt: "最低熔體溫度",
  hightest_melt: "最高熔體溫度",
  average_melt: "平均熔體溫度",
  lowest_mold: "最小模溫",
  highest_mold: "最大模溫",
  average_mold: "平均模溫",
  aridity: "建議乾燥溫度",
  drying_time: "建議乾燥時間",
  fiber_content: "纖維含量",
  melting_index: "熔融指數",
  weight: "庫存材料重量",
  MaterialImportRemark:
    "請以逗號(,)區分多個原料編號，例如：Material001,Material002",
  is_use: "顯示",

  //模具管理
  Mold: "模具",
  mold_num: "模具編號",
  stop_time: "停機時間",
  modus: "形式",
  allowed_cycle: "容許週期時間",
  max_cycle: "最大容許週期時間",
  maintainence_mold: "保養模次數",
  mold_height: "模具厚度",
  mold_width: "模具寬度",
  mold_length: "模具長度",
  sprue_aperture: "豎澆道孔徑1",
  sprue_aperture2: "豎澆道孔徑2",
  sprue_r: "豎繞道R角1",
  sprue_r2: "豎繞道R角2",
  mold_stroke: "開模行程",
  hot_runner_amount: "熱繞道數量",
  molds: "中子數",
  ring1: "定位環直徑1",
  ring2: "定位環直徑2",
  locking: "鎖模力",
  center_distance: "中心距",
  purchase_date: "購入日期",
  disable_date: "停用日期",
  mold_amount: "數量",
  region: "區域",
  maintainence_cycle: "保養週期",
  maintainence_count: "保養次數",
  maintainence_last: "上次保養時間",
  modulus: "生產模數",
  core_temp: "公模溫度",
  cavity_temp: "母模溫度",
  customer: "所屬客戶",
  photo: "照片",
  PhotoRemark: "請上傳圖片尺寸為200*200的圖檔。副檔名必須是小寫。",
  MoldImportRemark: "請以逗號(,)區分多個模具編號，例如：Mold001,Mold002",

  //產品管理
  Product: "產品",
  product_name: "產品名稱",
  product_type: "產品類型",
  part_weight: "產品重量",
  runner_weight: "澆道重量",
  pcs_weight: "模次重量",
  product_cost: "生產成本",
  ProductImportRemark:
    "請以逗號(,)區分多個原料編號或模具編號，例如：Mold001,Mold002",

  //班別管理
  WorkdaySetting: "設定上班日",
  WorkHours1: "上班時段 1",
  WorkHours2: "上班時段 2",
  WorkHours3: "上班時段 3",
  WorkHours4: "上班時段 4",
  To: "至",
  Repeat: "重複",
  EveryWeekday_MondayToFriday: "每個星期一到星期五",
  EveryWeekday_MondayToSaturday: "每個星期一到星期六",
  Everyday: "每天",
  Type: "型態",
  AppliedMachines: "適用機台",
  WorkOvertime: "加班",
  ScheduledMaintenance: "例行維修",
  ShiftImportRemark: "請上傳 *.ics檔案。檔案中的全日事件會被自動轉換成假日。",
  ShiftRequired: "需要有班別才能排入工單！",

  //假日管理
  is_holiday: "假日或上班日",
  Holiday: "假日",
  Workday: "上班日",
  holiday: "日期",

  //不良原因管理
  DefectGroupList: "不良原因類別列表",
  DefectList: "不良原因列表",
  DefectGroup: "不良原因類別",
  bad_reason_id: "不良原因",
  PushNotification: "推播",
  controlLimit: "管制數值",
  vietnamese: "越南文",
  english: "英文",

  //停機原因管理
  DowntimeReason: "停機原因",
  classify_id: "類別",

  //非生產性工單原因管理
  UnproductiveOrderReason: "非生產性工單原因",
  order_class: "工單類別",

  ModifyField: "修改欄位",
  DeleteField: "刪除欄位",
  InsertField: "新增欄位",

  BatchDelete: "批次刪除",
  Switch: "開關",
  Open: "開",
  Close: "關",
  PlannedQuantity: "目標數量",
  QuantityCantBeZero: "數量不能為0",
  PleaseInputPlannedQuantity: "請輸入目標數量",
  PleaseInputNumber: "請輸入數字",
  CreateTime: "建立時間",
};

export default management;
