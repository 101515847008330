import React, { useEffect, useState, useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { MdCreate, MdDelete } from "react-icons/md";
import { ColumnFilter, Table, StickyTable } from "../common";
import { InputModalBase } from "./Management";
import {
  ApiManagement_bad_group_get,
  ApiManagement_bad_group_post,
  ApiManagement_bad_group_patch,
  getManagementBadReason,
  putManagementBadReason,
  postManagementBadReason,
} from "../../api";
import Context from "../../context";

export function DefectGroupTable({ showModal, modal }) {
  const [data, setData] = useState([]);
  const { factory } = useContext(Context);

  useEffect(() => {
    if (!modal) {
      ApiManagement_bad_group_get({ factory_id: factory })
        .then((res) => {
          res.data.forEach(
            (item) =>
              (item.badReason = item.BadReasonList.map(
                ({ description }) => description
              ).toString())
          );
          setData(res.data);
        })
        .catch((e) => console.log(e));
    }
  }, [modal]);

  const columns = [
    {
      Header: <FormattedMessage id="Title" /> /*描述*/,
      accessor: "Title",
      Filter: ({ column }) => ColumnFilter(column, "Title"),
    },
    {
      Header: <FormattedMessage id="Defect" />,
      accessor: "badReason",
      Filter: "",
    },
    {
      Header: <FormattedMessage id="Edit" />,
      accessor: "editor",
      Filter: "",
    },
  ];

  const display = data.map((item) => ({
    ...item,
    badReason: item.BadReasonList.map(
      ({ description }) => description
    ).toString(),
    editor: (
      <span>
        <MdCreate
          className="icon-btn"
          size="20px"
          onClick={() => showModal("editGroup", item)}
        />
        <MdDelete
          style={{ marginLeft: "10px" }}
          className="icon-btn"
          size="20px"
          onClick={() => showModal("deleteGroup", item)}
        />
      </span>
    ),
  }));

  return <StickyTable columns={columns} data={display} />;
}

export function DefectTable({ data, showModal }) {
  const columns = [
    {
      Header: <FormattedMessage id="description" />,
      accessor: "description",
      Filter: ({ column }) => ColumnFilter(column, "description"),
    },
    {
      Header: <FormattedMessage id="vietnamese" />,
      accessor: "vietnamese",
      Filter: ({ column }) => ColumnFilter(column, "vietnamese"),
    },
    {
      Header: <FormattedMessage id="english" />,
      accessor: "english",
      Filter: ({ column }) => ColumnFilter(column, "english"),
    },
    {
      Header: <FormattedMessage id="Edit" />,
      accessor: "editor",
      Filter: "",
    },
  ];

  const display = data.map((item, i) => ({
    ...item,
    editor: (
      <span>
        <MdCreate
          className="icon-btn"
          size="20px"
          onClick={() => showModal("editDefect", item)}
        />
        <MdDelete
          style={{ marginLeft: "10px" }}
          className="icon-btn"
          size="20px"
          onClick={() => showModal("deleteDefect", item)}
        />
      </span>
    ),
  }));

  return <Table columns={columns} data={display} />;
}

export const InputGroupModal = ({ modal, onHide, data, defectiveList }) => {
  const intl = useIntl();
  const { factory } = useContext(Context);
  const [select, setSelect] = useState({ bad_reason_id: [] });

  useEffect(() => {
    setSelect(JSON.parse(JSON.stringify(data)));
  }, [data]);

  const submit = () => {
    return new Promise((resolve, reject) => {
      let req = {
        ...select,
        account: localStorage.getItem("account"),
        factory_id: factory,
        title: select.Title,
      };
      if (modal.startsWith("new")) {
        ApiManagement_bad_group_post(req).then(resolve).catch(reject);
      } else {
        req.group_id = select.GroupID;
        ApiManagement_bad_group_patch(req).then(resolve).catch(reject);
      }
    });
  };

  const title = intl.formatMessage(
    { id: modal.includes("new") ? "Create" : "EditThe" },
    { name: intl.formatMessage({ id: "DefectGroup" }) }
  );

  const columns = [
    {
      id: "Title",
      type: "text",
      required: true,
    },
    {
      id: "bad_reason_id",
      type: "multi_check",
      required: true,
      options: defectiveList.map(({ id, description }) => ({
        label: description,
        value: id,
      })),
    },
  ];

  return (
    <InputModalBase
      show={modal === "newGroup" || modal === "editGroup"}
      onHide={onHide}
      title={title}
      columns={columns}
      select={select}
      setSelect={setSelect}
      submit={submit}
    />
  );
};

export const InputDefectModal = ({ modal, onHide, data }) => {
  const intl = useIntl();
  const { factory } = useContext(Context);
  const [select, setSelect] = useState({});

  useEffect(() => {
    setSelect(JSON.parse(JSON.stringify(data)));
  }, [data]);

  const submit = () => {
    return new Promise((resolve, reject) => {
      const params = {
        factory_id: factory,
      };
      if (modal.startsWith("new")) {
        postManagementBadReason(params, select).then(resolve).catch(reject);
      } else {
        putManagementBadReason(select.id, params, select)
          .then(resolve)
          .catch(reject);
      }
    });
  };

  const title = intl.formatMessage(
    { id: modal.includes("new") ? "Create" : "EditThe" },
    { name: intl.formatMessage({ id: "Defect" }) }
  );

  const columns = [
    { id: "description", type: "text", required: true },
    { id: "english", type: "text" },
    { id: "vietnamese", type: "text" },
  ];

  return (
    <InputModalBase
      show={modal === "newDefect" || modal === "editDefect"}
      onHide={onHide}
      title={title}
      columns={columns}
      select={select}
      setSelect={setSelect}
      submit={submit}
    />
  );
};
