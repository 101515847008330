import React from "react";
import { FormattedMessage } from "react-intl";
import { StickyTable, ColumnFilter } from "../common";

export function AmountTable({ data, totalData }) {
  const columns = [
    {
      Header: <FormattedMessage id="order_num_name" /> /*工單編號*/,
      accessor: "order",
      Filter: ({ column }) => ColumnFilter(column, "order_num_name"),
    },
    {
      Header: <FormattedMessage id="DeviceNumber" /> /*機台編號*/,
      accessor: "device",
      Filter: ({ column }) => ColumnFilter(column, "DeviceNumber"),
    },
    {
      Header: <FormattedMessage id="product_num" /> /*產品編號*/,
      accessor: "product_num",
      Filter: ({ column }) => ColumnFilter(column, "product_num"),
    },
    {
      Header: <FormattedMessage id="product_name" /> /*產品名稱*/,
      accessor: "product_name",
      Filter: "",
    },
    ...(data[0]
      ? data[0].data.map(({ date }) => ({
          Header: date,
          accessor: date,
          Filter: "",
        }))
      : []),
    {
      Header: <FormattedMessage id="Total" /> /*總數*/,
      accessor: "total",
      Filter: "",
    },
    {
      Header: <FormattedMessage id="bad_total" /> /*總數*/,
      accessor: "bad_total",
      Filter: "",
    },
    {
      Header: <FormattedMessage id="bad_percent" />,
      accessor: "bad_percent",
      Filter: "",
    },
  ];

  const display = data.map((item) => {
    item.data.map(({ date, quantity }) => {
      item[date] = quantity;
    });
    item.bad_percent = Math.round(item.bad_percent * 100) / 100;
    return item;
  });

  if (totalData) {
    let obj = {};
    totalData.data.map(({ date, quantity }) => {
      obj[date.substring(5)] = quantity;
    });
    obj.order = <FormattedMessage id="Total" /> /*總數*/;
    obj.total = totalData.total;
    obj.bad_total = totalData.bad_total;
    display.push(obj);
  }

  return <StickyTable columns={columns} data={display} sticky={4} />;
}

export function CycleTable({ data }) {
  const columns = [
    {
      Header: <FormattedMessage id="DeviceNumber" /> /*機台編號*/,
      accessor: "device",
      Filter: ({ column }) => ColumnFilter(column, "DeviceNumber"),
    },
    ...(data[0]
      ? data[0].data.map(({ date }) => ({
          Header: date,
          accessor: date,
          Filter: "",
        }))
      : []),
    {
      Header: <FormattedMessage id="Total" /> /*總數*/,
      accessor: "total",
      Filter: "",
    },
  ];

  const display = data.map((item) => {
    item.data.map(({ date, quantity }) => {
      item[date] = quantity;
    });
    return item;
  });

  return <StickyTable columns={columns} data={display} sticky={1} />;
}
