const root = {
  //Root-工廠管理-工廠清單
  FactoryManagement: "工廠管理",
  FactoryList: "工廠清單",
  Factory: "工廠",
  factory_num: "工廠代碼",
  FactoryName: "工廠名稱",
  address: "地址",
  region_id: "區域",
  time_zone_id: "時區",
  PushNotificationToken: "推播Token",
  sql_id: "資料庫",
  group_id: "群組",

  //Root-工廠管理-機台清單
  DeviceList: "機台清單",
  Device: "機台",
  device_num: "機台編號",
  factory_id: "工廠",
  manufacturer: "製造商",
  device_name: "機台名稱",
  tonnage: "噸數",
  connection_id: "連線方式",
  DevicePhoto: "機台圖片",
  screw: "螺桿直徑",
  location_ring: "定位環直徑1",
  location_ring2: "定位環直徑2",
  nozzle_r: "射嘴R角1",
  nozzle_r2: "射嘴R角2",
  nozzle_aperture: "射嘴孔徑1",
  nozzle_aperture2: "射嘴孔徑2",
  max_injection_weight: "最大允許射出重量(PS)",
  max_injection_stroke: "最大允許射出行程",
  charge_rate: "增壓比",
  max_injection_pressure: "最大允許射出壓力",
  max_injection_speed: "最大允許射出速度",
  max_mold_height: "最大容模長度(H)",
  max_mold_width: "最大容模寛度(V)",
  min_mold_height: "最小容模長度(H)",
  min_mold_width: "最小容模寛度(V)",
  max_mold_pitch: "最大開模間距",
  clearance_width: "大柱內距寛度",
  clearance_height: "大柱內距長度",
  max_mold_thickness: "最大容模厚度",
  min_mold_thickness: "最小容模厚度",
  MoldWidthMinH: "最小模具尺寸寛度",
  MoldHeightMinV: "最小模具尺寸長度",

  //Root-群組管理
  GroupManagement: "群組管理",
  Group: "群組",
  GroupName: "群組名稱",
  Trademark: "商標圖片",

  DatabaseManagement: "資料庫管理",
  Database: "資料庫",
  account: "使用者名稱",
  password: "密碼",
  database_name: "資料庫名稱",
  connect_ip: "連接IP",
  connect_port: "連接Port",

  RoleManagement: "角色權限管理",
  Role: "角色",
  MonitoringPoints: "監測點",
  ScatterDiagram: "散佈圖",
  SqlManagement: "Sql管理",
};
export default root;
