import React from "react";
import Grid from "@material-ui/core/Grid";
import { Card } from "react-bootstrap";
import { MdTimelapse, MdLocationOn, MdAccessTime } from "react-icons/md";
import { makeStyles } from "@material-ui/core/styles";
import { baseURL } from "../../../api";
import { machineStatus } from "../../../function";

const useStyles = makeStyles(() => ({
  machineCard: {
    color: "gray",
  },
  statusTag: {
    color: "white",
    fontSize: "1rem",
    padding: "0 10px",
    backgroundColor: "#C4C4C4",
    "&.active": {
      backgroundColor: "#58b691",
    },
  },
  card: {
    height: "175px",
    cursor: "pointer",
    fontSize: "2rem",
    "&:hover": {
      backgroundColor: "#eee",
    },
  },
  active: {
    border: "3px solid #aed4fc",
  },
}));

const MachineCard = ({ data, onSelect }) => {
  const classes = useStyles();
  const { order_status, id, cycle, line, endtime, photo_path } = data;
  const onProduce = order_status === 2;
  return (
    <Grid item xs={4}>
      <Card
        onClick={() => onSelect(data)}
        className={`${classes.card} ${classes.machineCard} d-flex flex-row p-3`}
      >
        <div className="flex-column flex-1">
          <h6 style={{ color: "black" }}>
            <b>{id}</b>
          </h6>
          {onProduce ? (
            <React.Fragment>
              <h6 className="ai-center">
                <MdTimelapse style={{ marginRight: "5px" }} />
                {`${cycle}(sec)`}
              </h6>
              <h6 className="ai-center">
                <MdLocationOn style={{ marginRight: "5px" }} />
                {line}
              </h6>
              <h6 className="ai-center">
                <MdAccessTime style={{ marginRight: "5px" }} />
                {endtime}
              </h6>
            </React.Fragment>
          ) : null}
        </div>
        <div className="flex-column ai-end flex-1">
          <b
            className={`${classes.statusTag} ${onProduce ? "active" : ""} mb-2`}
          >
            {machineStatus(order_status)}
          </b>
          {onProduce ? (
            <img
              className="fit-container"
              src={photo_path ? baseURL + photo_path : ""}
            />
          ) : null}
        </div>
      </Card>
    </Grid>
  );
};

const StaffCard = ({
  index,
  data: { selected, EmployeeNum, EmployeeName },
  onClick,
}) => {
  const classes = useStyles();

  return (
    <Grid item xs={3}>
      <Card
        className={`${classes.card} ${
          selected ? classes.active : ""
        } p-3 flex-column`}
        onClick={() => onClick(index)}
      >
        <b>{EmployeeNum}</b>
        <b>{EmployeeName}</b>
      </Card>
    </Grid>
  );
};

const WorkItemCard = ({ index, WorkItemName, selected, onClick }) => {
  const classes = useStyles();

  return (
    <Grid item xs={3}>
      <Card
        className={`${classes.card} ${selected ? classes.active : ""} p-3`}
        onClick={() => onClick(index)}
      >
        <b>{WorkItemName}</b>
      </Card>
    </Grid>
  );
};

export { MachineCard, StaffCard, WorkItemCard };