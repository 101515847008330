import React, { useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { MdCreate, MdDelete, MdCheckCircle } from "react-icons/md";
import { Table } from "../common";
import { InputModalBase } from "../management/Management";
import {
  getFactory,
  postFactory,
  getGroup,
  getRegion,
  ApiManagement_editFactory,
  ApiManagement_timezoneList,
  getSql,
  getRegionAndFactoryNameList,
} from "../../api";

export function FactoryTable({ modal, showModal }) {
  const [data, setData] = useState([]);
  const [timeZoneList, setTimeZoneList] = useState([]);

  useEffect(() => {
    ApiManagement_timezoneList({
      account: localStorage.getItem("account"),
    })
      .then((res) => setTimeZoneList(res.data))
      .catch(console.log);
  }, []);

  useEffect(() => {
    if (!modal && timeZoneList.length > 0) {
      getRegionAndFactoryNameList({
        account: localStorage.getItem("account"),
      }).then((res) => {
        const list = res.data.map(({ id }) => id);
        getFactory().then((res) => {
          res.data.forEach((item) => {
            item.time_zone = timeZoneList[item.time_zone_id - 1].time_zone;
          });
          setData(res.data.filter(({ id }) => list.indexOf(id) > -1));
        });
      });
    }
  }, [modal, timeZoneList]);

  const columns = [
    {
      Header: <FormattedMessage id="factory_num" /> /*工廠代碼*/,
      accessor: "factory_num",
      Filter: "",
    },
    {
      Header: <FormattedMessage id="FactoryName" /> /*工廠名稱*/,
      accessor: "name",
      Filter: "",
    },
    {
      Header: <FormattedMessage id="address" /> /*地址*/,
      accessor: "address",
      Filter: "",
    },
    {
      Header: <FormattedMessage id="time_zone_id" /> /*時區*/,
      accessor: "time_zone",
      Filter: "",
    },
    {
      Header: <FormattedMessage id="Edit" />,
      accessor: "editor",
      Filter: "",
    },
  ];

  const display = data.map((item) => ({
    ...item,
    is_notify: item.pushNotification ? <MdCheckCircle color="green" /> : null,
    editor: (
      <span>
        <MdCreate
          className="icon-btn"
          size="20px"
          onClick={() => showModal("edit", item)}
        />
        <MdDelete
          style={{ marginLeft: "10px" }}
          className="icon-btn"
          size="20px"
          onClick={() => showModal("delete", item)}
        />
      </span>
    ),
  }));

  return <Table columns={columns} data={display} />;
}

export const InputModal = ({ modal, data, onHide }) => {
  const intl = useIntl();
  const [groupList, setGroupList] = useState([]);
  const [regionList, setRegionList] = useState([]);
  const [timezoneList, setTimezoneList] = useState([]);
  const [sqlList, setSqlList] = useState([]);
  const [select, setSelect] = useState({});
  const [warn, setWarn] = useState("");

  useEffect(() => {
    getGroup()
      .then((res) => setGroupList(res.data))
      .catch(console.log);
    getRegion()
      .then((res) => setRegionList(res.data))
      .catch(console.log);
    ApiManagement_timezoneList({
      account: localStorage.getItem("account"),
    })
      .then((res) => setTimezoneList(res.data))
      .catch(console.log);
    getSql()
      .then((res) => setSqlList(res.data))
      .catch(console.log);
  }, []);

  useEffect(() => {
    if (modal) {
      setSelect(data);
    } else {
      setSelect({});
      setWarn("");
    }
  }, [modal, data]);

  const submit = () => {
    return new Promise((resolve, reject) => {
      if (modal === "new") {
        postFactory(select).then(resolve).catch(reject);
      } else if (modal === "edit") {
        ApiManagement_editFactory(select).then(resolve).catch(reject);
      }
    });
  };

  const title = intl.formatMessage(
    { id: modal === "new" ? "Create" : "EditThe" },
    { name: intl.formatMessage({ id: "Factory" }) }
  );

  const columns = [
    {
      id: "group_id",
      type: "select",
      required: true,
      options: groupList.map(({ id, name }) => ({ label: name, value: id })),
    },
    {
      id: "region_id",
      type: "select",
      required: true,
      options: regionList.map(({ id, region }) => ({
        label: region,
        value: id,
      })),
    },
    {
      id: "time_zone_id",
      type: "select",
      required: true,
      options: timezoneList.map(({ id, time_zone }) => ({
        label: time_zone,
        value: id,
      })),
    },
    {
      id: "sql_id",
      type: "select",
      required: true,
      options: sqlList.map(({ id, database_name }) => ({
        label: database_name,
        value: id,
      })),
    },
    { id: "factory_num", type: "text", required: true },
    { id: "name", type: "text", required: true },
    { id: "address", type: "text", required: true },
    { id: "token", type: "text" },
  ];

  return (
    <InputModalBase
      show={modal === "new" || modal === "edit"}
      onHide={onHide}
      title={title}
      columns={columns}
      select={select}
      setSelect={setSelect}
      submit={submit}
    />
  );
};
