import React, { useEffect, useState, useContext } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { FormattedMessage, useIntl } from "react-intl";
import Form from "react-bootstrap/Form";
import moment from "moment";
import { RangeDatePicker, Table } from "../common";
import { apiGetOrderDataList } from "../../api";
import Context from "../../context";

function OrderListSelector({ pickOrder, deviceID }) {
  const [suggestions, setSuggestions] = useState([]);
  const [orderNum, setOrderNum] = useState("");
  const [orderList, setOrderList] = useState([]);
  const [date, setDate] = useState({
    startDate: moment().subtract(7, "d").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  });
  const intl = useIntl();
	const { factory } = useContext(Context);

  useEffect(() => {
    setSuggestions([]);
    setOrderNum("");
  }, [orderList]);

  useEffect(() => {
    if (date && deviceID) {
      apiGetOrderDataList({
        FactoryID: factory,
        CheckDateStart: date.startDate,
        CheckDateEnd: date.endDate,
        DeviceID: deviceID,
      })
        .then((res) => setOrderList(res.data))
        .catch((err) => console.log(err));
    }
  }, [date, deviceID]);

  const pickDateRange = (startDate, endDate) => {
    setDate((prev) => ({ ...prev, startDate: startDate, endDate: endDate }));
  };

  const getSuggestions = (e) => {
    const { value } = e.target;
    if (orderList.findIndex((item) => item === value) > -1) {
      _pickOrder(value);
    } else {
      const suggestions = orderList.filter((item) =>
        item.toLowerCase().startsWith(value.toLowerCase())
      );
      setOrderNum(value);
      setSuggestions(suggestions);
    }
  };

  const _pickOrder = (orderNum) => {
    setSuggestions([]);
    setOrderNum(orderNum);
    pickOrder(orderNum);
  };

  const onBlur = () => {
    setTimeout(() => setSuggestions([]), 200);
  };

  return (
    <div className="ai-center" style={{ marginBottom: "20px" }}>
      <RangeDatePicker
        startDate={date.startDate}
        endDate={date.endDate}
        timePicker={false}
        onApply={pickDateRange}
      />
      <b style={{ margin: "0 20px" }}>
        <FormattedMessage id="PleasePickThe" />
        <FormattedMessage id="Order" />
      </b>
      <div className="position-relative">
        <Form.Control
          placeholder={
            intl.formatMessage({ id: "PleaseEnterThe" }) +
            intl.formatMessage({ id: "order_num_name" })
          }
          onChange={getSuggestions}
          onFocus={getSuggestions}
          onBlur={onBlur}
          value={orderNum}
          autoComplete="off"
        />
        {suggestions.length !== 0 ? (
          <div className="position-absolute suggestion-area">
            {suggestions.map((item) => (
              <div onClick={() => _pickOrder(item)}>{item}</div>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
}

function OrderChart({ data }) {
  const intl = useIntl();
  const options = {
    title: null,
    credits: { enabled: false },
    exporting: { enabled: false },
    xAxis: {
      type: "datetime",
      dateTimeLabelFormats: {
        day: "%b %e",
      },
    },
    yAxis: {
      gridLineColor: "#ececec",
      title: null,
    },
    series: [
      {
        name: "OEE",
        type: "spline",
        color: "#5FC677",
        data: data.oee,
        pointStart: data.CreateTimeStart,
        pointInterval: 1800 * 1000,
      },
      {
        name: intl.formatMessage({ id: "Utilization" }),
        type: "column",
        data: data.utilization,
        pointStart: data.CreateTimeStart,
        color: "#A8BEED",
        pointInterval: 1800 * 1000,
      },
      {
        name: intl.formatMessage({ id: "Performance" }),
        type: "column",
        data: data.performance,
        pointStart: data.CreateTimeStart,
        color: "#BCB5E8",
        pointInterval: 1800 * 1000,
      },
      {
        name: intl.formatMessage({ id: "Yield" }),
        type: "column",
        data: data.yield,
        pointStart: data.CreateTimeStart,
        color: "#F7CE96",
        pointInterval: 1800 * 1000,
      },
    ],
  };
  return <HighchartsReact highcharts={Highcharts} options={options} />;
}

function OrderTable({ data }) {
  const columns = [
    {
      Header: <FormattedMessage id="Time" /> /*時間*/,
      accessor: "date",
      Filter: "",
    },
    {
      Header: <FormattedMessage id="DeviceNumber" /> /*機台編號*/,
      accessor: "num",
      Filter: "",
    },
    {
      Header: <FormattedMessage id="Factory" /> /*工廠*/,
      accessor: "factory",
      Filter: "",
    },
    {
      Header: <FormattedMessage id="region" /> /*區域*/,
      accessor: "area",
      Filter: "",
    },
    {
      Header: "OEE",
      accessor: "oee",
      Filter: "",
    },
    {
      Header: <FormattedMessage id="Utilization" />,
      accessor: "oee_a",
      Filter: "",
    },
    {
      Header: <FormattedMessage id="Performance" />,
      accessor: "oee_p",
      Filter: "",
    },
    {
      Header: <FormattedMessage id="Yield" />,
      accessor: "oee_q",
      Filter: "",
    },
  ];

  
  const display = data.map(item => ({
    ...item,
    oee_a:item.oee_a===0 ? 0:item.oee_a.toFixed(3),
    oee_p:item.oee_p===0 ? 0:item.oee_p.toFixed(3),
    oee_q:item.oee_q===0 ? 0:item.oee_q.toFixed(3),

  }));


  return <Table columns={columns} data={display} />;
}

export { OrderListSelector, OrderChart, OrderTable };
