const document = {
    //document 
    ProductionSop: "ProductionSop",
    QualityInspection: "QualityInspection",
    MoldWaterWayConnection: 'MoldWaterWayConnection',
    SelectType: 'select type',
    Video: 'Video',
    Image: 'Image',
    Document: 'Document',
    SelectDocument: 'Select Document',
    SureToDelete: 'Are you sure you want to delete ?',
    Confirm:'Confirm',
    NoData:'No Data',
    MoldingConditions:"Molding Conditions"

}

export default document;