import React, { useEffect, useState } from "react";
import { getRole } from "../../api";

export const RoleList = ({ select, setSelect, modal }) => {
  const [roleList, setRoleList] = useState([]);

  useEffect(() => {
    if (!modal) {
      getRole().then((res) => {
        setRoleList(res.data);
        if (res.data.length > 0) {
          setSelect({
            ...res.data[0],
            auth: res.data[0],
          });
        }
      });
    }
  }, [modal]);

  return (
    <div className="analysis-list">
      <ul className="list-group list-unstyled mb-0">
        {roleList.map((item) => (
          <li
            className={`list-group-item ${
              select.id === item.id ? "checked" : ""
            }`}
            key={item.id}
            onClick={() =>
              setSelect({
                ...item,
                auth: item,
              })
            }
          >
            <span>{item.role}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};
